<template>
  <Dialog
    :visible="open"
    :dismissableMask="true"
    @update:visible="closeDialog"
    position="bottom"
    modal
  >
    <template #header>
      <div class="header-wrapper">
        <div>Slack • Token</div>
        <div class="flex gap-1">
          <IconButton
            class="p-button-text"
            @click="showDeleteConfirmationDialog = true"
          >
            <i class="pi pi-trash"></i>
          </IconButton>
        </div>
      </div>
    </template>
    <div class="title">Slack Token</div>
    <FormRow title="Channel ID" :invalid-state="v$.channel_id.$invalid">
      <BaseInputText
        class="w-full"
        v-model:value="formData.channel_id"
        :errors="v$.channel_id.$errors"
      ></BaseInputText>
    </FormRow>
    <FormRow title="Token" :invalid-state="v$.token.$invalid">
      <BaseInputText
        class="w-full"
        v-model:value="formData.token"
        type="password"
        :errors="v$.token.$errors"
      ></BaseInputText>
    </FormRow>
    <FormRow title="Status">
      <div class="w-full flex justify-start size">
        <Chip
          :label="TOKEN_STATUSES_TITLES[formData.status]"
          :color="statusColor"
        ></Chip>
      </div>
    </FormRow>
    <template #footer>
      <Button class="p-button-outlined" size="large" @click="closeDialog">
        Cancel
      </Button>
      <Button size="large" @click="onEditToken">Save Changes</Button>
    </template>
    <DeleteConfirmationDialog
      v-model:open="showDeleteConfirmationDialog"
      name="this token"
      :loading="loading"
      @delete="onDeleteToken"
      input-confirmation
      subtitle="Deleting the token will delete all related and mapped data."
    ></DeleteConfirmationDialog>
  </Dialog>
</template>

<script setup lang="ts">
import Dialog from 'primevue/dialog/Dialog.vue'
import { defineProps, ref, defineEmits, watch, computed } from 'vue'
import Button from '@/components/common/buttons/Button.vue'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { useToast } from 'primevue/usetoast'
import { useStore } from '@/store'
import IconButton from '@/components/common/buttons/IconButton.vue'
import { diffFields, showToastError } from '@/utils/utils'
import FormRow from '@/components/common/form/FormRow.vue'
import BaseInputText from '@/components/common/base/BaseInputText.vue'
import { clone, pickBy } from 'ramda'
import DeleteConfirmationDialog from '@/components/common/dialogs/DeleteConfirmationDialog.vue'
import { TOKEN_STATUSES, TOKEN_STATUSES_TITLES } from '@/constants/constants'
import Chip from '@/components/common/Chip.vue'

const initialForm = () => ({
  channel_id: '',
  token: '',
  status: '',
})

const props = defineProps<{ open: boolean }>()
const emit = defineEmits<{ (e: 'update:open', value: boolean): void }>()
const toast = useToast()
const store = useStore()
const formData = ref(initialForm())
const showDeleteConfirmationDialog = ref(false)
const loading = ref(false)
const $externalResults = ref({})
const originalToken = ref({})

const formRules = {
  token: { onlyBackend: () => true, $autoDirty: true },
  channel_id: { required, $autoDirty: true, $lazy: true },
}

const v$ = useVuelidate(formRules, formData, { $externalResults })

watch(props, () => {
  if (props.open) {
    formData.value = {
      ...clone(store.state.slack.token),
      token: 'placeholder',
    }
    originalToken.value = clone(formData.value)
  }
})

async function onEditToken() {
  v$.value.$touch()
  if (v$.value.$invalid) {
    return
  }
  try {
    await store.dispatch('slack/editToken', {
      ...pickBy(diffFields(originalToken.value), formData.value),
    })
    closeDialog()
  } catch (e: any) {
    if (e.response?.data?.token || e.response?.data?.channel_id) {
      $externalResults.value = e.response.data
    } else {
      showToastError(toast, e)
    }
  }
}

async function onDeleteToken(verificationCode: string) {
  loading.value = true
  try {
    await store.dispatch('slack/deleteToken', verificationCode)
    loading.value = false
    showDeleteConfirmationDialog.value = false
    closeDialog()
  } catch (e) {
    loading.value = false
    showToastError(toast, e)
  }
}

function closeDialog() {
  formData.value = initialForm()
  v$.value.$reset()
  emit('update:open', false)
}

const statusColor = computed(() => {
  const textColor = 'text-black '
  if (formData.value.status === TOKEN_STATUSES.TOKEN_INVALID) {
    return textColor + 'bg-danger-100'
  } else if (formData.value.status === TOKEN_STATUSES.DELETION_IN_PROGRESS) {
    return textColor + 'bg-warning-100'
  } else {
    return textColor + 'bg-success-50'
  }
})
</script>

<style scoped>
.header-wrapper {
  @apply flex items-center justify-between w-full;
}
.title {
  @apply text-xl font-semi-bold mb-3;
}
.size :deep(.p-chip) {
  @apply text-base px-2;
}
</style>

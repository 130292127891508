<template>
  <StickyTopbar :title="selectedProject?.name || 'Project name'">
    <template #bigAvatar>
      <Avatar
        :url="selectedProject?.image"
        :label="selectedProject?.name"
        class="mr-2"
      ></Avatar>
    </template>
    <template #smallAvatar>
      <Avatar
        :url="selectedProject?.image"
        :label="selectedProject?.name"
        size="small"
        class="mr-2"
      ></Avatar>
    </template>
    <template #bigFavouriteIcon>
      <IconButton
        v-if="selectedProject"
        class="p-button-text"
        @click="toggleFavourite"
      >
        <i
          v-if="favouriteProjectsIds.includes(selectedProject?.id)"
          class="pi pi-star-fill text-warning-500"
          style="font-size: 20px"
        ></i>
        <i v-else class="pi pi-star text-gray-700" style="font-size: 20px"></i>
      </IconButton>
    </template>
    <template #smallFavouriteIcon>
      <IconButton
        v-if="selectedProject"
        class="p-button-text ml-1"
        @click="toggleFavourite"
        size="small"
      >
        <i
          v-if="favouriteProjectsIds.includes(selectedProject?.id)"
          class="pi pi-star-fill text-warning-500"
        ></i>
        <i v-else class="pi pi-star text-gray-700"></i>
      </IconButton>
    </template>
    <template #buttons>
      <TimeFilter
        :filters="timeFilters"
        @change-time="onDateChanged"
      ></TimeFilter>
      <AggregationFilter
        :scale-type="filters.scale_type"
        :time-filters="timeFilters"
        @change="onAggregationChanged"
      >
      </AggregationFilter>
      <SingleFilteringMenu
        name="Project"
        :filters="projects"
        :filtering-options="filteringOptions.projects"
        search
        @change-filtering="onFilterChanged($event, 'projects')"
      ></SingleFilteringMenu>
    </template>
  </StickyTopbar>
</template>

<script setup lang="ts">
import { computed, defineProps } from 'vue'
import { useStore } from '@/store'
import { Dictionary, equals, pick } from 'ramda'
import TimeFilter from '@/components/common/filters/global/time-filter/TimeFilter.vue'
import { Filters, TimeFilters } from '@/store/modules/filters'
import { useRouter } from 'vue-router'
import SingleFilteringMenu from '@/components/common/filters/global/SingleFilteringMenu.vue'
import StickyTopbar from '@/components/common/StickyTopbar.vue'
import AggregationFilter from '@/components/common/filters/global/AggregationFilter.vue'
import { calcAggregationAccordingToTimeFilters } from '@/utils/date-utils'
import IconButton from '@/components/common/buttons/IconButton.vue'
import { Project } from '@/store/modules/admin/admin'
import { showToastError } from '@/utils/utils'
import { useToast } from 'primevue/usetoast'
import Avatar from '@/components/common/Avatar.vue'

const props = defineProps<{
  filteringOptions: any
  filters: Filters
  selectedProject?: Project
}>()
const timeFilters = computed(() => pick(['since', 'until'], props.filters))
const store = useStore()
const router = useRouter()
const toast = useToast()

const favouriteProjectsIds = computed(() => {
  return store.getters['admin/favouriteProjects'].map((p: Project) => p.id)
})

const projects = computed(() => {
  const { projects } = props.filters
  return Array.isArray(projects) ? projects : [projects]
})

const onFilterChanged = (ev: number[], prop: string) => {
  !ev.length ||
    (!equals(ev, (props.filters as Dictionary<any>)[prop]) &&
      router.push({
        path: `/company/${store.getters['company/selectedCompanyId']}/projects`,
        query: { ...props.filters, ...{ [prop]: ev } },
      }))
}

const onAggregationChanged = (ev: string) => {
  if (ev === props.filters.scale_type) return
  router.push({
    path: `/company/${store.getters['company/selectedCompanyId']}/projects`,
    query: { ...props.filters, scale_type: ev },
  })
}

const onDateChanged = (dates: TimeFilters) => {
  if (
    !equals(dates.since, props.filters.since) ||
    !equals(dates.until, props.filters.until)
  ) {
    const aggregation =
      calcAggregationAccordingToTimeFilters(dates) !== props.filters.scale_type
        ? { scale_type: calcAggregationAccordingToTimeFilters(dates) }
        : {}

    router.push({
      path: `/company/${store.getters['company/selectedCompanyId']}/projects`,
      query: { ...props.filters, ...dates, ...aggregation },
    })
  }
}

async function toggleFavourite() {
  try {
    await store.dispatch(
      'admin/toggleProjectAsFavourite',
      props.selectedProject.id
    )
  } catch (e) {
    showToastError(toast, e)
  }
}
</script>

<template>
  <div class="flex justify-end pb-3">
    <IconButton
      class="p-button-outlined"
      size="small"
      icon="pi-plus"
      icon-position="left"
      icon-size="12px"
      @click="showAddRoleRateDialog = true"
      >Add</IconButton
    >
  </div>
  <LoaderWrapper class="loader-height" v-if="loading"></LoaderWrapper>
  <MyTable
    v-else
    customHeight="30vh"
    :table-data="roleRates"
    :columns-data="columnsData"
    :scrollable="scrollableTable"
  >
    <template #role="{ data }">
      {{ calcNameOfRole(data.role) }}
    </template>
    <template #rate_date="{ data }">
      {{ format(new Date(data.rate_date), 'dd.MM.yyyy') }}
    </template>
    <template #editable="{ data }">
      <IconButton
        size="small"
        class="p-button-text invisible group-hover:visible"
        @click="selectedRoleRateId = data.id"
        ><i class="pi pi-pencil" style="font-size: 12px"></i
      ></IconButton>
    </template>
  </MyTable>
  <AddRoleRateDialog
    v-model:open="showAddRoleRateDialog"
    :project_id="project_id"
    @refresh-table="getRolesRates"
  ></AddRoleRateDialog>
  <EditRoleRateDialog
    v-model:id="selectedRoleRateId"
    :project_id="project_id"
    @refresh-table="getRolesRates"
  ></EditRoleRateDialog>
</template>

<script setup lang="ts">
import IconButton from '@/components/common/buttons/IconButton.vue'
import { onMounted, ref, defineProps, computed } from 'vue'
import LoaderWrapper from '@/components/common/loader/LoaderWrapper.vue'
import MyTable from '@/components/common/table/MyTable.vue'
import { showToastError } from '@/utils/utils'
import { useStore } from '@/store'
import { useToast } from 'primevue/usetoast'
import AddRoleRateDialog from '@/components/admin-dashboard/tempo/projects/roles-rates/AddRoleRateDialog.vue'
import { format } from 'date-fns'
import { isNotEmpty } from 'ramda-adjunct'
import { TempoRole } from '@/store/modules/admin/tempo'
import EditRoleRateDialog from '@/components/admin-dashboard/tempo/projects/roles-rates/EditRoleRateDialog.vue'

const props = defineProps<{ project_id: number; editFlow: boolean }>()
const showAddRoleRateDialog = ref(false)
const selectedRoleRateId = ref(false)
const loading = ref(false)
const roleRates = ref([])
const store = useStore()
const toast = useToast()
const scrollableTable = ref(true)

const roles = computed(() => store.state.tempo.roles)

const columnsData = [
  {
    header: 'Role',
    field: 'role',
    is_sortable: true,
    use_template: true,
  },
  {
    header: 'Role Rate, CHF/h',
    field: 'rate',
    is_sortable: true,
    use_template: false,
  },
  {
    header: 'Date',
    field: 'rate_date',
    is_sortable: true,
    use_template: true,
  },
  {
    header: '',
    field: 'editable',
    small: true,
    is_sortable: false,
    use_template: true,
    styles: 'flex-grow:0; flex-basis:30px',
  },
]

onMounted(() => {
  if (props.editFlow) {
    getRolesRates()
  }
  if (isNotEmpty(roles)) {
    store.dispatch('tempo/getRoles').catch((e) => showToastError(toast, e))
  }
})

async function getRolesRates() {
  loading.value = true
  try {
    roleRates.value = (
      await store.dispatch('tempo/getRolesRates', props.project_id)
    ).map((item: any) => ({
      ...item,
      budget: new Intl.NumberFormat('de-CH').format(item.budget),
    }))
    loading.value = false
  } catch (e) {
    showToastError(toast, e)
    loading.value = false
  }
}

function calcNameOfRole(roleId: number) {
  return roles.value.find(({ id }: TempoRole) => id === roleId)?.name
}
</script>

<style>
.loader-height {
  height: 30vh;
}
</style>

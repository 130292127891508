<template>
  <TableWrapper :title="tableTitle">
    <template #button>
      <TableSearchInput
        :value="store.state.slack.search"
        @update:value="searchSlackUser"
        placeholder="Search by user"
        class="mr-2"
      ></TableSearchInput>
      <Button
        size="small"
        class="p-button-outlined buttons-gap"
        badge="2"
        @click="showEditTokenDialog = true"
      >
        Manage Token
        <Badge
          v-if="slackTokenIsInvalid"
          class="p-badge p-badge-danger ml-1 absolute -top-1.5 -right-1.5"
        ></Badge>
      </Button>
      <IconButton
        size="small"
        icon="pi-cog"
        icon-position="left"
        icon-size="12px"
        class="p-button-outlined"
        @click="showSettings = true"
        >Slack Settings</IconButton
      >
    </template>
    <template #default>
      <LoaderWrapper class="loader-height" v-if="loading"></LoaderWrapper>
      <MyTable
        v-else
        :table-data="slackUsers"
        :columns-data="columnsData"
        :lazy-table="true"
        :scrollable="scrollableTable"
        :lazy-loading="lazyLoading"
        custom-height="calc(100vh - 260px)"
        @load="onLazyLoad"
        @sort="onSort"
      >
        <template #original_name="{ data }">
          <BaseDropdown
            :options="originalUsers"
            v-model:selected="data.original_name"
            v-model:newly-created-option="newlyCreatedUser"
            table
            search
            deselect
            :option-id="data.id"
            @update:selected="onChangeOriginalUser($event, data.id)"
            @add="onAddNewUser(data.id)"
          ></BaseDropdown>
        </template>
        <template #enable_dm_notifications="{ data }">
          <BaseSelectButton
            v-model:selection="data.enable_dm_notifications"
            :options="enabledOptions"
            @change="onToggleDailyNotifications($event, data.id)"
          ></BaseSelectButton>
        </template>
      </MyTable>
      <SlackSettingsDialog v-model:open="showSettings"></SlackSettingsDialog>
      <EditSlackTokenDialog
        v-model:open="showEditTokenDialog"
      ></EditSlackTokenDialog>
      <AddUserMappingDialog
        v-model:open="showCreateUserDialog"
        @save-user-id="newlyCreatedUser.new_option_id = $event"
      ></AddUserMappingDialog>
    </template>
  </TableWrapper>
</template>

<script setup lang="ts">
import TableWrapper from '@/components/common/table/TableWrapper.vue'
import { State, useStore } from '@/store'
import { computed, onMounted, ref, watch } from 'vue'
import { debounce } from 'lodash'
import MyTable from '@/components/common/table/MyTable.vue'
import { useToast } from 'primevue/usetoast'
import { calcSortOrderingAndField, showToastError } from '@/utils/utils'
import { SlackUser } from '@/store/modules/admin/slack'
import IconButton from '@/components/common/buttons/IconButton.vue'
import Button from '@/components/common/buttons/Button.vue'
import SlackSettingsDialog from '@/components/admin-dashboard/slack/SlackSettingsDialog.vue'
import EditSlackTokenDialog from '@/components/admin-dashboard/slack/EditSlackTokenDialog.vue'
import LoaderWrapper from '@/components/common/loader/LoaderWrapper.vue'
import AddUserMappingDialog from '@/components/admin-dashboard/system/user-mapping/AddUserMappingDialog.vue'
import TableSearchInput from '@/components/common/table/TableSearchInput.vue'
import { Store } from 'vuex'
import BaseDropdown from '@/components/common/base/BaseDropdown.vue'
import BaseSelectButton from '@/components/common/base/BaseSelectButton.vue'
import { TOKEN_STATUSES } from '@/constants/constants'
import Badge from 'primevue/badge'

const store: Store<State> = useStore()
const toast = useToast()
const totalRecords = ref<number>(0)
const nextPage = ref<string | null>(null)
const slackUsers = ref<SlackUser[]>([])
const showSettings = ref(false)
const showEditTokenDialog = ref(false)
const loading = ref(false)
const lazyLoading = ref(false)
const scrollableTable = ref(true)
const showCreateUserDialog = ref(false)
const newlyCreatedUser = ref<{ option_id?: number; new_option_id?: number }>({})
const search = computed(() => store.state.slack.search)
const debouncedGetRequest = debounce(getFirstPageOfUsers, 1000)

const slackTokenIsInvalid = computed(
  () => store.state.slack.token?.status === TOKEN_STATUSES.TOKEN_INVALID
)

const searchSlackUser = (value: string) => {
  store.commit('slack/setSearchText', value || null)
}

watch([search], () => {
  if (search.value) {
    debouncedGetRequest()
  } else {
    getFirstPageOfUsers()
  }
})

const enabledOptions = [
  { value: false, name: 'No' },
  { value: true, name: 'Yes' },
]

onMounted(() => {
  getFirstPageOfUsers()
})

const originalUsers = computed(() => store.state.admin.original_users)
const tableTitle = computed(() => `Users • ${totalRecords.value}`)

const columnsData = [
  {
    header: 'User name',
    field: 'username',
    is_sortable: true,
    use_template: false,
  },
  {
    header: 'Enable daily notifications',
    field: 'enable_dm_notifications',
    is_sortable: true,
    use_template: true,
  },
  {
    header: 'Original name',
    field: 'original_name',
    use_template: true,
  },
]

async function onChangeOriginalUser(originalUserId: number, id: number) {
  try {
    await store.dispatch('slack/editUser', {
      original_name: originalUserId,
      id: id,
    })
  } catch (e) {
    showToastError(toast, e)
  }
}

async function onToggleDailyNotifications(
  enable_daily_notifications: boolean,
  id: number
) {
  try {
    await store.dispatch('slack/editUser', {
      enable_dm_notifications: enable_daily_notifications,
      id: id,
    })
  } catch (e) {
    showToastError(toast, e)
  }
}

/* istanbul ignore next */
async function onLazyLoad(event: { first: number; last: number }) {
  if (lazyLoading.value) return
  const { last } = event
  lazyLoading.value = true
  try {
    if (nextPage.value && last === slackUsers.value.length) {
      const page = await store.dispatch(
        'slack/getNextPageOfUsers',
        nextPage.value
      )
      nextPage.value = page.next
      slackUsers.value = slackUsers.value.concat(page.results)
    }
    lazyLoading.value = false
  } catch (e) {
    lazyLoading.value = false
    showToastError(toast, e)
  }
}

function getFirstPageOfUsers(ordering?: string | null) {
  if (typeof ordering !== 'undefined') {
    lazyLoading.value = true
  } else {
    loading.value = true
  }
  store
    .dispatch('slack/getFirstPageOfUsers', {
      ordering: ordering || null,
      search: search.value || null,
    })
    .then((data) => {
      totalRecords.value = data.count
      nextPage.value = data.next
      slackUsers.value = data.results
      lazyLoading.value = false
      loading.value = false
    })
    .catch((e) => {
      lazyLoading.value = false
      loading.value = false
      showToastError(toast, e)
    })
}

function onSort(event: Event) {
  getFirstPageOfUsers(calcSortOrderingAndField(event))
}

const onAddNewUser = (id: number) => {
  newlyCreatedUser.value.option_id = id
  showCreateUserDialog.value = true
}
</script>

<style scoped>
.buttons-gap {
  @apply mr-3;
}

.loader-height {
  height: calc(100vh - 260px);
}
</style>

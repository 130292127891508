<template>
  <div class="flex flex-col flex-grow justify-between">
    <div class="flex-grow flex flex-col">
      <div class="title">Connect Jira projects</div>
      <div class="flex-grow flex" v-if="loading">
        <LoaderWrapper></LoaderWrapper>
      </div>
      <div
        v-else-if="!jiraConnected"
        class="bg-gray-50 p-5 rounded-md flex-grow"
      >
        <JiraConnectionFlow></JiraConnectionFlow>
      </div>
      <div v-else>
        <div v-if="!loading" class="flex justify-end items-center">
          <TableSearchInput
            v-model:value="search"
            placeholder="Search by project"
          ></TableSearchInput>
        </div>
        <MyTable
          :table-data="allJiraProjects"
          :columns-data="columnsData"
          scrollable
          customHeight="30vh"
          v-model:selection="selectedProjects"
          selection-mode="multiple"
          :filters="filters"
          :globalFilterFields="filterFields"
        >
        </MyTable>
      </div>
    </div>
    <div class="flex justify-end py-8 gap-5">
      <Button
        size="large"
        class="mr-5 p-button-text"
        @click="$emit('next-step')"
      >
        Skip this step
      </Button>
      <IconButton
        icon="pi-arrow-right"
        icon-position="right"
        icon-size="20px"
        :disabled="!jiraConnected || !selectedProjects.length"
        size="large"
        class="next"
        @click="onAddProjects"
        >Save and Next
      </IconButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import JiraConnectionFlow from '@/components/admin-dashboard/jira/JiraConnectionFlow.vue'
import IconButton from '@/components/common/buttons/IconButton.vue'
import Button from '@/components/common/buttons/Button.vue'
import { useStore } from '@/store'
import { useToast } from 'primevue/usetoast'
import { computed, defineEmits, onMounted, ref, defineProps } from 'vue'
import { showToastError } from '@/utils/utils'
import LoaderWrapper from '@/components/common/loader/LoaderWrapper.vue'
import { JiraProject } from '@/store/modules/admin/jira'
import TableSearchInput from '@/components/common/table/TableSearchInput.vue'
import useTableSearch from '@/utils/hooks/useTableSearch'
import MyTable from '@/components/common/table/MyTable.vue'

const emit = defineEmits<{ (e: 'next-step'): void; (e: 'close'): void }>()
const props = defineProps<{ project_id: number | null }>()
const store = useStore()
const toast = useToast()
const selectedProjects = ref([])
const loading = ref(false)
const allJiraProjects = ref([])
const filterFields = ['name']
const { search, filters } = useTableSearch()

const columnsData = [
  {
    header: 'Project name',
    field: 'name',
    is_sortable: true,
  },
]

const jiraConnected = computed(() => store.state.jira.isJiraConnected)

onMounted(() => {
  if (!jiraConnected.value) {
    loading.value = true
    store
      .dispatch('jira/checkJiraConnection')
      .catch((e) => {
        showToastError(toast, e)
      })
      .finally(() => (loading.value = false))
  }
  getJiraConnectedProjects()
})

async function getJiraConnectedProjects() {
  loading.value = true
  try {
    const allProjects = await store.dispatch('jira/getJiraConnectedProjects')
    allJiraProjects.value = allProjects.filter(
      (project: JiraProject) => !project.id
    )
    loading.value = false
  } catch (e) {
    loading.value = false
    showToastError(toast, e)
  }
}

async function onAddProjects() {
  try {
    await store.dispatch(
      'jira/addProjects',
      selectedProjects.value.map((project: JiraProject) => ({
        ...project,
        original_project: props.project_id,
      }))
    )
    selectedProjects.value = []
    emit('next-step')
  } catch (e) {
    showToastError(toast, e)
  }
}
</script>

<style scoped>
.title {
  @apply text-xl font-semi-bold mb-3;
}
</style>

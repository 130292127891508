<template>
  <div class="height flex" v-if="loading">
    <LoaderWrapper></LoaderWrapper>
  </div>
  <div v-else-if="isTokenAdded">
    <Message v-if="!isNotificationsEnabled" severity="warn" class="mb-5"
      >Slack notifications are disabled. You can enable it in the
      settings</Message
    >
    <SlackUsersTable></SlackUsersTable>
  </div>
  <div v-else class="flow-wrapper">
    <SlackConnectionFlow class="w-1/2" with-title></SlackConnectionFlow>
  </div>
</template>

<script setup lang="ts">
import SlackConnectionFlow from '@/components/admin-dashboard/slack/SlackConnectionFlow.vue'
import { ref, onMounted, watchEffect } from 'vue'
import { useStore } from '@/store'
import { useToast } from 'primevue/usetoast'
import { showToastError } from '@/utils/utils'
import SlackUsersTable from '@/components/admin-dashboard/slack/SlackUsersTable.vue'
import Message from '@/components/common/Message.vue'
import LoaderWrapper from '@/components/common/loader/LoaderWrapper.vue'
const store = useStore()
const toast = useToast()
const isTokenAdded = ref(false)
const isNotificationsEnabled = ref(false)
const loading = ref(false)

onMounted(() => {
  loading.value = true
  store
    .dispatch('slack/getToken')
    .catch((e) => {
      showToastError(toast, e)
    })
    .finally(() => (loading.value = false))
  store.dispatch('slack/getSettings').catch((e) => {
    showToastError(toast, e)
  })
})

watchEffect(() => {
  isTokenAdded.value = !!store.state.slack.token
  isNotificationsEnabled.value = !!store.state.slack.settings
})
</script>

<style scoped>
.flow-wrapper {
  min-height: 380px;
  @apply bg-gray-50 p-8;
}
.height {
  height: 380px;
}
</style>

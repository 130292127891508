<template>
  <OverlayPanel ref="panel" :class="$style.panel">
    <div class="px-3 py-2 flex items-center justify-between">
      <div class="flex items-center">
        <Avatar
          :url="user?.photoURL"
          class="mr-3"
          :label="user?.displayName?.charAt(0)"
        ></Avatar>
        <div :class="$style.text">
          <div class="font-semi-bold truncate max-w-xs">
            {{ user?.displayName }}
          </div>
          <div>My profile</div>
        </div>
      </div>
      <span class="pi pi-chevron-right text-gray-400"></span>
    </div>
    <Divider :class="$style.divider"></Divider>
    <div class="overflow-auto">
      <div
        v-for="company in companies"
        :key="company.id"
        :class="[
          $style['menu-item'],
          isCompanySelectedAndUserIsOwner(company) ? $style.selected : '',
        ]"
        @click="onSelectCompany(company)"
      >
        <div class="flex items-center w-full">
          <Avatar :label="company?.name[0]" class="mr-3"></Avatar>
          <div :class="$style.text">
            <div class="font-semi-bold truncate max-w-xs">
              {{ company?.name }}
            </div>
            <div class="capitalize">
              {{ company?.role_in_company.toLowerCase() }}
            </div>
          </div>
        </div>
        <span
          class="pi pi-cog hidden text-gray-600"
          :class="$style.icon"
          @click.stop="showCompanyInfo = true"
        ></span>
      </div>
    </div>
    <div :class="$style['add-button']" @click="onCreateCompany">
      <div :class="$style.plus">
        <span class="pi pi-plus text-gray-600"></span>
      </div>
      <div :class="$style['add-title']">Add Company</div>
    </div>
    <Divider :class="$style.divider"></Divider>
    <div :class="$style['add-button']" @click="logout">
      <div :class="$style.plus">
        <span class="pi pi-sign-out text-gray-600"></span>
      </div>
      <div :class="$style['add-title']">Logout</div>
    </div>
  </OverlayPanel>
  <CompanyInfoDialog v-model:open="showCompanyInfo"></CompanyInfoDialog>
</template>

<script setup lang="ts">
import Avatar from '@/components/common/Avatar.vue'
import OverlayPanel from 'primevue/overlaypanel/OverlayPanel.vue'
import Divider from 'primevue/divider/Divider.vue'
import {
  computed,
  defineEmits,
  defineProps,
  onMounted,
  onUnmounted,
  ref,
  watchEffect,
} from 'vue'
import { useStore } from '@/store'
import { useRouter } from 'vue-router'
import { Company } from '@/store/modules/company'
import CompanyInfoDialog from '@/components/companies/CompanyInfoDialog.vue'
import { FILTERS_DATE_FORMAT, USER_ROLES } from '@/constants/constants'
import { format, subDays } from 'date-fns'
import { authService } from '@/services/auth'

const props = defineProps<{
  openSidebar: boolean
  clickEvent: Event | null
}>()

const emit = defineEmits<{ (e: 'close'): void }>()
const store = useStore()
const router = useRouter()
const panel = ref()
const companies = computed(() => store.state.company.companies)
const selectedCompanyId = computed(
  () => store.getters['company/selectedCompanyId']
)
const user = computed(() => store.state.user.currentUser)
const showCompanyInfo = ref(false)

const onEscape = (e: KeyboardEvent) => {
  if (e.keyCode === 27) {
    hideOverlayPanel()
  }
}

onMounted(() => {
  document.addEventListener('keydown', onEscape)
})

onUnmounted(() => {
  document.removeEventListener('keydown', onEscape)
})

const isCompanySelectedAndUserIsOwner = (company: Company) => {
  return (
    selectedCompanyId.value === company.id &&
    company.role_in_company &&
    [USER_ROLES.ADMIN, USER_ROLES.OWNER].includes(company.role_in_company)
  )
}

watchEffect(() => {
  if (panel.value && props.clickEvent) {
    panel.value.show(props.clickEvent)
  }
})

const onCreateCompany = () => {
  router.push('/company-creation')
}

const onSelectCompany = (company: Company) => {
  store.dispatch('company/saveSelectedCompanyInLocalStorage', company)
  store.dispatch('admin/getUsers')
  store.dispatch('admin/getActiveProjects')
  store.dispatch('filters/savePortfolioFilters', {})
  store.dispatch('filters/saveProjectFilters', {})
  router.push({
    path: `/company/${company.id}/portfolio`,
    query: {
      since: format(subDays(new Date(), 30), FILTERS_DATE_FORMAT),
      until: format(new Date(), FILTERS_DATE_FORMAT),
    },
  })
  hideOverlayPanel()
}

async function logout(): Promise<void> {
  await authService.logout()
  await router.push('/login')
  await store.dispatch('company/removeSelectedCompanyFromLocalStorage')
  await store.dispatch('filters/savePortfolioFilters', {})
  await store.dispatch('filters/saveProjectFilters', {})
}

const hideOverlayPanel = () => {
  panel.value.hide()
  emit('close')
}
</script>

<style module lang="scss">
.menu-item {
  @apply px-3 py-2 flex items-center justify-between cursor-pointer rounded-md;

  .text {
    @apply text-base text-gray-800 text-left;
  }

  .icon {
    @apply hidden;
  }
}
.menu-item:hover {
  @apply bg-black-5;
  .text {
    @apply text-black;
  }
}

.selected {
  @apply bg-black-5;
  .text {
    @apply text-black;
  }
  .icon {
    @apply inline-flex;
  }
}

.selected:hover {
  @apply bg-black-10;
}

.plus {
  @apply w-8 h-8 bg-gray-50 flex justify-center items-center rounded-xl mr-3;
}

.add-button {
  @apply flex px-3 py-2 cursor-pointer items-center rounded-md;
}

.add-title {
  @apply text-base font-semi-bold text-gray-800;
}

.add-button:hover {
  @apply bg-black-5;
  .add-title {
    @apply text-black;
  }
  .plus {
    @apply bg-black-10;
    span {
      @apply text-black;
    }
  }
}
.divider {
  @apply my-1 mx-2 w-auto;
}

.panel {
  margin: 4px 4px;
  min-width: 280px !important;
  padding: 4px;
  max-width: 320px;
}
</style>

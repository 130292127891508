<template>
  <TableWrapper :title="tableTitle" danger-title>
    <template #button>
      <TableSearchInput
        :value="store.state.admin.unmappedUsersSearch"
        @update:value="searchUnmappedUsers"
        placeholder="Search by user"
        class="mr-2"
      ></TableSearchInput>
      <IconButton
        class="p-button-text refresh-button"
        size="small"
        @click="refreshUnmappedUsers"
        ><i class="pi pi-refresh" style="font-size: 14px" aria-hidden="true"></i
      ></IconButton>
    </template>
    <template #default>
      <MyTable
        :table-data="unmappedUsers"
        :columns-data="columnsData"
        :lazy-table="true"
        :scrollable="scrollableTable"
        :lazy-loading="lazyLoading"
        @load="onLazyLoad"
        @sort="onSort"
      >
        <template #original_name="{ data }">
          <BaseDropdown
            :key="refreshData"
            :options="originalUsers"
            v-model:selected="data.original_name"
            v-model:newly-created-option="newlyCreatedUser"
            :option-id="data.id"
            search
            deselect
            table
            @update:selected="onChangeOriginalUser($event, data)"
            @add="onAddNewUser(data.id)"
          ></BaseDropdown>
        </template>
      </MyTable>
      <AddUserMappingDialog
        v-model:open="showCreateUserDialog"
        @save-user-id="newlyCreatedUser.new_option_id = $event"
      ></AddUserMappingDialog>
    </template>
  </TableWrapper>
</template>

<script setup lang="ts">
import TableWrapper from '@/components/common/table/TableWrapper.vue'
import { useStore } from '@/store'
import { computed, defineEmits, ref, watchEffect, defineProps } from 'vue'
import MyTable from '@/components/common/table/MyTable.vue'
import { useToast } from 'primevue/usetoast'
import { calcSortOrderingAndField, showToastError } from '@/utils/utils'
import { GitlabUser } from '@/store/modules/admin/gitlab'
import { Page } from '@/types/types'
import IconButton from '@/components/common/buttons/IconButton.vue'
import AddUserMappingDialog from '@/components/admin-dashboard/system/user-mapping/AddUserMappingDialog.vue'
import TableSearchInput from '@/components/common/table/TableSearchInput.vue'
import BaseDropdown from '@/components/common/base/BaseDropdown.vue'

const emit = defineEmits<{
  (e: 'load-first-page', value: null | string): void
  (e: 'search', value: string | null): void
}>()

const props = defineProps<{ page: Page | null; loading: boolean }>()
const store = useStore()
const toast = useToast()
const totalRecords = ref<number | undefined>(0)
const nextPage = ref<string | null | undefined>(null)
const unmappedUsers = ref<GitlabUser[] | undefined>([])
const scrollableTable = ref(true)
const refreshData = ref(0)
const showCreateUserDialog = ref(false)
const lazyLoading = ref(false)
const newlyCreatedUser = ref<{ option_id?: number; new_option_id?: number }>({})

watchEffect(() => {
  totalRecords.value = props.page?.count
  nextPage.value = props.page?.next
  unmappedUsers.value = props.page?.results
  lazyLoading.value = props.loading
})

const originalUsers = computed(() => store.state.admin.original_users)
const tableTitle = computed(() => `Unmapped Users • ${totalRecords.value}`)
const columnsData = [
  {
    header: 'User Name',
    field: 'username',
    is_sortable: true,
    use_template: false,
  },
  {
    header: 'Application',
    field: 'target',
    is_sortable: true,
    use_template: false,
  },
  {
    header: 'Original name',
    field: 'original_name',
    use_template: true,
  },
]

async function onChangeOriginalUser(
  originalUserId: number,
  user: {
    target: string
    id: number
    username: string
    company: number
    original_name: null | number
  }
) {
  try {
    await store.dispatch(`${user.target}/editUser`, {
      id: user.id,
      original_name: originalUserId,
    })
  } catch (e) {
    showToastError(toast, e)
  }
}

/* istanbul ignore next */
async function onLazyLoad(event: { first: number; last: number }) {
  if (lazyLoading.value) return
  const { last } = event
  lazyLoading.value = true
  try {
    if (nextPage.value && last === unmappedUsers.value?.length) {
      const page = await store.dispatch(
        'gitlab/getNextPageOfUsers',
        nextPage.value
      )
      nextPage.value = page.next
      unmappedUsers.value = unmappedUsers.value?.concat(page.results)
    }
    lazyLoading.value = false
  } catch (e) {
    lazyLoading.value = false
    showToastError(toast, e)
  }
}

function searchUnmappedUsers(event: string) {
  emit('search', event || null)
  refreshData.value++
}

function refreshUnmappedUsers() {
  emit('load-first-page', null)
  refreshData.value++
}

function onSort(event: Event) {
  emit('load-first-page', calcSortOrderingAndField(event))
}

const onAddNewUser = (id: number) => {
  newlyCreatedUser.value.option_id = id
  showCreateUserDialog.value = true
}
</script>

<template>
  <Dialog
    class="p-confirm-dialog"
    :visible="!!open"
    autoZIndex
    position="bottom"
    :closable="false"
    modal
  >
    <div class="mt-3 flex items-start">
      <img
        :src="require('../../../assets/icons/warning.svg')"
        alt="Warning icon"
        width="48"
        height="48"
        class="mr-8 mt-1"
      />
      <div>
        <div class="text-lg font-semi-bold">
          Are you sure that you want to delete
          <span>{{ name }}</span>
          ?
        </div>
        <div v-if="subtitle" class="mt-4">
          {{ subtitle }}
        </div>
        <div v-if="inputConfirmation" class="w-full mt-5">
          <div class="text-black mb-1">
            Please type “delete” to confirm token deletion.
          </div>
          <BaseInputText
            v-if="inputConfirmation"
            class="w-full"
            v-model:value="inputValue"
          ></BaseInputText>
        </div>
      </div>
    </div>

    <template #footer>
      <Button
        class="no p-button-outlined"
        :disabled="loading"
        @click="closeDialog"
      >
        No
      </Button>
      <Button
        :disabled="disableConfirmButton"
        class="yes"
        @click="onYesButtonClick"
        >Yes</Button
      >
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import Button from '@/components/common/buttons/Button.vue'
import Dialog from 'primevue/dialog/Dialog.vue'
import { defineEmits, defineProps, ref, computed } from 'vue'
import BaseInputText from '../base/BaseInputText.vue'

const props = defineProps<{
  open: boolean | number
  name?: string
  loading?: boolean
  subtitle?: string
  inputConfirmation?: boolean
}>()

const inputValue = ref('')
const emit = defineEmits<{
  (e: 'update:open', value: boolean): void
  (e: 'delete', value: string): void
}>()

const disableConfirmButton = computed(() => {
  if (props.inputConfirmation) {
    return inputValue.value !== 'delete'
  } else {
    return props.loading
  }
})

const onYesButtonClick = () => {
  emit('delete', inputValue.value)
}

const closeDialog = () => {
  if (props.loading) return
  emit('update:open', false)
}
</script>

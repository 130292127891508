<template>
  <Dialog
    :visible="open"
    :dismissableMask="true"
    @update:visible="closeDialog"
    position="bottom"
    modal
  >
    <template #header>
      <div class="header-wrapper">Step {{ step }} of 5</div>
    </template>
    <OriginalProjectStep
      v-if="step === 1"
      @projectId="createdOriginalProjectId = $event"
      @close="closeDialog"
      @nextStep="step = 2"
    ></OriginalProjectStep>
    <GitlabStep
      v-else-if="step === 2"
      :project_id="createdOriginalProjectId"
      @nextStep="step = 3"
    ></GitlabStep>
    <BitbucketStep
      v-else-if="step === 3"
      :project_id="createdOriginalProjectId"
      @nextStep="step = 4"
    ></BitbucketStep>
    <JiraStep
      v-else-if="step === 4"
      :project_id="createdOriginalProjectId"
      @nextStep="step = 5"
    ></JiraStep>
    <TempoStep
      v-else-if="step === 5"
      @nextStep="saveTempoProjectId"
      @close="closeDialog"
    ></TempoStep>
    <TempoBudgetRolesStep
      v-else-if="tempoProjectId"
      :project_id="tempoProjectId"
      @close="closeDialog"
    ></TempoBudgetRolesStep>
  </Dialog>
</template>

<script setup lang="ts">
import Dialog from 'primevue/dialog/Dialog.vue'
import { defineProps, ref, defineEmits } from 'vue'
import OriginalProjectStep from '@/components/admin-dashboard/system/project-mapping/project-setup-flow/OriginalProjectStep.vue'
import GitlabStep from '@/components/admin-dashboard/system/project-mapping/project-setup-flow/GitlabStep.vue'
import JiraStep from '@/components/admin-dashboard/system/project-mapping/project-setup-flow/JiraStep.vue'
import BitbucketStep from '@/components/admin-dashboard/system/project-mapping/project-setup-flow/BitbucketStep.vue'
import TempoStep from '@/components/admin-dashboard/system/project-mapping/project-setup-flow/TempoStep.vue'
import TempoBudgetRolesStep from '@/components/admin-dashboard/system/project-mapping/project-setup-flow/TempoBudgetRolesStep.vue'

defineProps<{ open: boolean }>()
const emit = defineEmits<{ (e: 'update:open', value: boolean): void }>()
const step = ref(1)
const tempoProjectId = ref<number | null>(null)
const createdOriginalProjectId = ref(null)

function closeDialog() {
  emit('update:open', false)
  step.value = 1
  tempoProjectId.value = null
}

const saveTempoProjectId = (projectId: number) => {
  step.value = 5
  tempoProjectId.value = projectId
}
</script>

<style scoped>
.header-wrapper {
  @apply flex items-center justify-between w-full;
}
</style>

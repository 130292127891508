<template>
  <div class="external-block overflow-visible">
    <div
      class="internal-block overflow-visible"
      :style="{
        width: progressWidth,
      }"
    ></div>
    <div class="progress-label">{{ label }}</div>
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps } from 'vue'

const props = defineProps<{ value: number; label: string }>()

const progressWidth = computed(() => `${Math.ceil(props.value * 100)}%`)
</script>

<style scoped lang="scss">
.external-block {
  min-height: 20px;
  @apply relative w-full rounded bg-gray-100;

  .internal-block {
    min-height: 20px;
    @apply bg-success-400-60 rounded h-full pl-1;
  }

  .progress-label {
    @apply absolute top-0 left-0 font-normal ml-1;
  }
}
</style>

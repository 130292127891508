import { createApp } from 'vue'
import App from './App.vue'
import './index.scss'
import router from './router'
import { store, key } from './store/'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

import { PRIMEVUE_LOCALE } from '@/constants/primevue-locales'
import PrimeVue from 'primevue/config'
import Divider from 'primevue/divider/Divider.vue'
import Tooltip from 'primevue/tooltip'
import Toast from 'primevue/toast/Toast.vue'
import 'primeicons/primeicons.css'
import ToastService from 'primevue/toastservice'
import BadgeDirective from 'primevue/badgedirective'

import { Chart, registerables } from 'chart.js'
import AnnotationPlugin from 'chartjs-plugin-annotation'
import DataLabelsPlugin from 'chartjs-plugin-datalabels'
import { getChartLabelPlugin } from 'chart.js-plugin-labels-dv'
import { MatrixController, MatrixElement } from 'chartjs-chart-matrix'

Chart.register(...registerables)
Chart.register(AnnotationPlugin)
Chart.register(DataLabelsPlugin)
Chart.register(getChartLabelPlugin())
Chart.register(MatrixController, MatrixElement)
Chart.defaults.font.family = 'Work sans, san-serif'
Chart.defaults.color = '#000000'

import axios from '@/services/axios'
import VueAxios from 'vue-axios'

export const app = createApp(App)

if (process.env.VUE_APP_SENTRY_DSN && process.env.VUE_APP_ENV) {
  Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', 'devflow.mpom.ch', /^\//],
      }),
    ],
    environment: process.env.VUE_APP_ENV,
    tracesSampleRate: 1.0,
  })
}

app
  .use(PrimeVue, {
    locale: PRIMEVUE_LOCALE,
  })
  .use(ToastService)
  .component('Divider', Divider)
  .component('Toast', Toast)
  .directive('tooltip', Tooltip)
  .directive('badge', BadgeDirective)
  .use(router)
  .use(VueAxios, axios)
  .provide('axios', app.config.globalProperties.axios)
  .use(store, key)
  .mount('#app')

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
if (window.Cypress) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.app = app
}

export default app

import axios from '@/services/axios'
import { Filters } from '@/store/modules/filters'
import { ActionContext } from 'vuex'
import { State as GlobalState } from '@/store/index'
import { path } from 'ramda'

export interface StuckIssue {
  issue_id: number
  issue_key: string
  issue_name: string
  absolute_url: string
  status_id: string
  status_name: string
  total_stuck_seconds: number
}

export interface Page {
  count: number
  next: string
  previous: string
  results: StuckIssue[]
}

export interface StuckIssueDrilldownFilters {
  since: string | null
  until: string | null
  issue: string | null
  status: string | null
}

export interface State {
  drilldown_filters: StuckIssueDrilldownFilters
}

type Context = ActionContext<Page, GlobalState>

const state = (): State => ({
  drilldown_filters: {
    since: null,
    until: null,
    issue: null,
    status: null,
  },
})

const mutations = {
  setDrilldownFilters(state: State, filters: StuckIssueDrilldownFilters): void {
    state.drilldown_filters = filters
  },
}

const actions = {
  getFirstPageOfStuckTickets(
    _: Context,
    params: { filters: Filters; project_id: number; company_id: number }
  ): Promise<void> {
    return axios
      .get(
        `/api/companies/${params.company_id}/project-mapping/${params.project_id}/charts/stuck-issues/?limit=50&ordering=-total_stuck_seconds`,
        { params: params.filters }
      )
      .then(path(['data']))
  },
  getNextPageOfStuckTickets(_: Context, url: string): Promise<void> {
    return axios.get(`${url}`).then(path(['data']))
  },
  getFirstPageOfStuckDrilldown(
    _: Context,
    params: { filters: Filters; project_id: number; company_id: number }
  ): Promise<void> {
    return axios
      .get(
        `/api/companies/${params.company_id}/project-mapping/${params.project_id}/charts/stuck-issues/drilldown/?limit=50`,
        { params: params.filters }
      )
      .then(({ data }) => data)
  },
  getNextPageOfStuckDrilldown(_: Context, url: string): Promise<void> {
    return axios.get(`${url}`).then(({ data }) => data)
  },
}

export default {
  namespaced: true,
  actions,
  mutations,
  state,
}

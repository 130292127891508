<template>
  <div>
    <InputNumber
      :model-value="inputValue"
      ref="input"
      :class="{ 'p-invalid': errors.length, 'p-disabled': disabled }"
      :placeholder="placeholder"
      @input="$emit('update:value', $event.value)"
      :input-style="inputStyle"
      v-bind="$attrs"
      :useGrouping="useGrouping"
      :max-fraction-digits="maxFractionDigits"
      :min-fraction-digits="minFractionDigits"
      :locale="locale"
    ></InputNumber>
    <div
      v-for="error of errors"
      class="text-left text-sm text-danger-600 mt-1"
      :key="error.$uid"
    >
      {{ error.$message }}
    </div>
  </div>
</template>

<script setup lang="ts">
import InputNumber from 'primevue/inputnumber/InputNumber.vue'
import { computed, defineEmits, defineProps, ref, withDefaults } from 'vue'
import { isNotNil } from 'ramda-adjunct'

const props = withDefaults(
  defineProps<{
    value: null | string | number
    errors?: any
    placeholder?: string
    inputStyle?: string
    disabled?: boolean
    useGrouping?: boolean
    maxFractionDigits?: number
    minFractionDigits?: number
    locale?: string
  }>(),
  {
    errors: [],
    inputStyle: '',
    disabled: false,
    useGrouping: true,
  }
)

defineEmits<{
  (e: 'update:value', value: null | number): void
}>()

const input = ref<{ $el: HTMLInputElement } | null>(null)
const inputValue = computed(() => {
  return isNotNil(props.value) ? Number(props.value) : props.value
})
</script>

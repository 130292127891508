<template>
  <Dialog
    :visible="open"
    :style="{ width: '90vw' }"
    :dismissableMask="true"
    @update:visible="closeDialog"
    position="bottom"
    modal
  >
    <template #header>
      <div class="header-wrapper">
        {{ userData?.name || projectData?.name }}
      </div>
      <TableSearchInput
        :value="store.state.loc.commitsFiltersSearch"
        @update:value="searchCommit"
      ></TableSearchInput>
    </template>
    <div class="text-xl font-semi-bold mb-4">Commits</div>
    <BaseCommitsTable
      table-height="65vh"
      isLOC
      :search="search"
      :filters="filters"
      :selectedSource="selectedSource"
      class="mb-8"
      @change-excluded="amountOfExcludedChanges++"
    ></BaseCommitsTable>
  </Dialog>
</template>

<script setup lang="ts">
import Dialog from 'primevue/dialog/Dialog.vue'
import { defineProps, ref, defineEmits, computed } from 'vue'
import { useStore } from '@/store'
import BaseCommitsTable from '@/components/admin-dashboard/gitlab/commits/BaseCommitsTable.vue'
import TableSearchInput from '@/components/common/table/TableSearchInput.vue'
import usePageFilters from '@/utils/hooks/useFilters'

const props = defineProps<{
  open: boolean
  userData: { id: number; name: string } | null
  projectData: { id: number; name: string } | null
  selectedSource: string | undefined
}>()
const emit = defineEmits<{ (e: 'close'): void; (e: 'reload-data'): void }>()
const amountOfExcludedChanges = ref(0)
const store = useStore()
const { pageFilters } = usePageFilters()

const search = computed(() => store.state.loc.commitsFiltersSearch)

function closeDialog() {
  emit('close')
  if (amountOfExcludedChanges.value) {
    emit('reload-data')
  }
}

const filters = computed(() => ({
  ...store.state.loc.commits_filters,
  ...pageFilters().value,
  excluded: 'false',
  ...(props.userData && { users: `${props.userData?.id}` }),
  ...(props.projectData && { projects: `${props.projectData?.id}` }),
}))

const searchCommit = (value: string) => {
  store.commit('loc/setSearchText', value || null)
}
</script>

<style scoped>
.header-wrapper {
  @apply flex items-center justify-between w-full text-base;
}
</style>

<template>
  <Dialog
    :visible="open"
    :dismissableMask="true"
    @update:visible="closeDialog"
    position="bottom"
    modal
  >
    <template #header>
      <div class="header-wrapper">
        <div>Company Profile</div>
        <div class="flex gap-2">
          <IconButton
            class="delete p-button-text"
            @click="showDeleteConfirmation = true"
          >
            <span class="pi pi-trash"></span>
          </IconButton>
        </div>
      </div>
    </template>
    <div class="text-xl font-semi-bold mb-3">Edit company</div>
    <FormRow title="Name">
      <BaseInputText
        class="w-full"
        v-model:value="formData.name"
        :errors="v$.name.$errors"
      ></BaseInputText>
    </FormRow>
    <template #footer>
      <Button class="p-button-outlined" size="large" @click="closeDialog">
        Cancel
      </Button>
      <Button size="large" class="edit" @click="onEditCompany"
        >Save changes</Button
      >
    </template>
    <DeleteConfirmationDialog
      v-model:open="showDeleteConfirmation"
      :name="company?.name"
      @delete="onDeleteCompany"
    ></DeleteConfirmationDialog>
  </Dialog>
</template>

<script setup lang="ts">
import Dialog from 'primevue/dialog/Dialog.vue'
import IconButton from '@/components/common/buttons/IconButton.vue'
import { defineEmits, defineProps, ref } from 'vue'
import FormRow from '@/components/common/form/FormRow.vue'
import { Company } from '@/store/modules/company'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import Button from '@/components/common/buttons/Button.vue'
import { showToastError } from '@/utils/utils'
import { useToast } from 'primevue/usetoast'
import { useStore } from '@/store'
import { useRouter } from 'vue-router'
import DeleteConfirmationDialog from '@/components/common/dialogs/DeleteConfirmationDialog.vue'
import BaseInputText from '@/components/common/base/BaseInputText.vue'

const props = defineProps<{ open: boolean; company: Company }>()
const emit = defineEmits<{
  (e: 'update:open', value: boolean): void
  (e: 'close-info-dialog'): void
  (e: 'save'): void
}>()
const toast = useToast()
const store = useStore()
const router = useRouter()
const showDeleteConfirmation = ref(false)

const initialForm = () => ({
  name: props.company?.name,
})

const formData = ref(initialForm())

const formRules = {
  name: { required, $lazy: true },
}

const v$ = useVuelidate(formRules, formData)

async function onEditCompany() {
  v$.value.$touch()
  if (v$.value.$invalid) {
    return
  }
  try {
    const company = await store.dispatch('company/editCompany', {
      ...formData.value,
      id: props.company?.id,
    })
    store.dispatch('company/saveSelectedCompanyInLocalStorage', company)
    closeDialog()
    emit('save')
    await store.dispatch('company/getCompanies')
  } catch (e) {
    showToastError(toast, e)
  }
}

async function onDeleteCompany() {
  try {
    await store.dispatch('company/deleteCompany', props.company?.id)
    closeDialog()
    store.dispatch('company/removeSelectedCompanyFromLocalStorage')
    router.push('/companies')
  } catch (e) {
    showToastError(toast, e)
  }
}

function closeDialog() {
  v$.value.$reset()
  emit('update:open', false)
}
</script>

<style scoped>
.header-wrapper {
  @apply flex items-center justify-between w-full;
}
</style>

<template>
  <Loader v-if="loading" color="black" class="opacity-20"></Loader>
  <div v-else class="w-full h-full relative">
    <IconButton
      icon="pi-arrow-left"
      class="back-button p-button-text"
      icon-position="left"
      icon-size="18"
      @click="logout"
      >Back</IconButton
    >
    <div class="square-content">
      <div v-if="!isCompaniesExisted" class="flex flex-col h-full w-full">
        <div class="welcome">
          Create your <br />
          first company!
        </div>
        <div class="text-left text-lg">
          Some short text about what is company in <br />
          Devlfow. To create a new company click on button the below
        </div>

        <div class="w-full mt-10 flex justify-end">
          <IconButton
            size="large"
            icon-position="right"
            icon="pi-arrow-right"
            @click="onCreateCompany"
            >Create a company</IconButton
          >
        </div>
      </div>
      <div v-else class="flex flex-col h-full w-full">
        <div class="welcome">Welcome back!</div>
        <div class="text-left text-lg">
          Companies for
          <span class="font-bold"> {{ userEmail }}: </span>
        </div>
        <Divider class="mt-3 mb-1 divider"></Divider>
        <div class="overflow-auto">
          <CompanyMenuItem
            class="w-full"
            v-for="company of companies"
            :key="company.id"
            :name="company.name"
            :role="company.role_in_company"
            @click="onCompanySelect(company)"
          ></CompanyMenuItem>
        </div>
        <Divider class="divider my-1"></Divider>
        <div class="add-button" @click="onCreateCompany">
          <div class="plus"><span class="pi pi-plus"></span></div>
          <div class="add-title">Add Company</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { authService } from '@/services/auth'
import { useRouter } from 'vue-router'
import IconButton from '@/components/common/buttons/IconButton.vue'
import { computed, onMounted, ref } from 'vue'
import { useStore } from '@/store'
import Loader from '@/components/common/loader/Loader.vue'
import { showToastError } from '@/utils/utils'
import { useToast } from 'primevue/usetoast'
import CompanyMenuItem from '@/components/companies/CompanyMenuItem.vue'
import { Company } from '@/store/modules/company'

const router = useRouter()
const store = useStore()
const toast = useToast()
const loading = ref(false)

onMounted(() => {
  getCompanies()
})

const companies = computed(() => store.state.company.companies)
const isCompaniesExisted = computed(() => !!companies.value.length)
const userEmail = computed(() => store.state.user.currentUser?.email || '')

async function logout(): Promise<void> {
  await authService.logout()
  router.push('/login')
}

const onCompanySelect = (company: Company) => {
  router.push(`/company/${company.id}/portfolio`)
}

const onCreateCompany = () => {
  router.push('/company-creation')
}

const getCompanies = () => {
  loading.value = true
  store
    .dispatch('company/getCompanies')
    .catch((e) => {
      showToastError(toast, e)
    })
    .finally(() => (loading.value = false))
}
</script>

<style scoped lang="scss">
.back-button {
  @apply absolute top-3 left-3;
}

.square-content {
  padding: 18.75% 18.75% 0;
  @apply flex flex-col w-full h-full;
}
.welcome {
  @apply w-full font-bold text-2xl text-left flex-grow-0 mb-8;
}

.divider {
  @apply bg-black-5;
}

.plus {
  @apply w-8 h-8 bg-black-10 flex justify-center items-center rounded-xl mr-3;
}

.add-button {
  @apply flex px-3 py-2 -mx-3 cursor-pointer items-center rounded-md;
}

.add-title {
  @apply text-base font-semi-bold text-gray-800;
}

.add-button:hover {
  @apply bg-black-5;
  .add-title {
    @apply text-black;
  }
}
</style>

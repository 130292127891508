<template>
  <ChartWrapper
    :title="chartTitle[chartTypes.UserActivity]"
    :loading="loading"
    :fetch-error="fetchError"
    :tooltip="chartExplanations[chartTypes.UserActivity]"
    :is-chart-data-empty="isEmpty(chartData)"
    :type="chartTypes.UserActivity"
    :icons="['gitlab']"
  >
    <template #buttons>
      <SortingMenu
        v-if="sortingOptions.length"
        :sorting-options="sortingOptions"
        @change-sorting="sorting = $event"
      ></SortingMenu>
    </template>
    <BasicChart
      :data="userActivityData"
      :options="userActivityOptions"
      type="bar"
      :chart-name="chartTypes.UserActivity"
      @dot-data="saveUserDataAndShowMenu"
    ></BasicChart>
    <UserActivityDrilldown
      :open="!!drilldownData"
      :drilldown-data="drilldownData"
      :filters="filters"
      @close="drilldownData = null"
    ></UserActivityDrilldown>
  </ChartWrapper>
</template>

<script setup lang="ts">
import ChartWrapper from '@/components/charts/ChartWrapper.vue'
import SortingMenu from '@/components/common/menu/SortingMenu.vue'
import BasicChart from '@/components/charts/BasicChart.vue'
import { Filters } from '@/store/modules/filters'
import { computed, defineProps, onMounted, ref } from 'vue'
import {
  chartExplanations,
  chartTitle,
  chartTypes,
} from '@/constants/charts/constants'
import { Dictionary, isEmpty, omit, path, sortBy } from 'ramda'
import { usedColors, USER_ACTIVITIES } from '@/constants/constants'
import { ActiveElement, ChartEvent, LegendElement, LegendItem } from 'chart.js'
import {
  hideCursorOnLegendLeave,
  showCursorOnLegendHover,
  toggleCursorOnChartDataHover,
  verticalAnnotation,
} from '@/utils/chart-utils'
import { PerUserActivity } from '@/store/modules/charts/user-activity'
import useGettingChartData from '@/utils/hooks/useGettingChartData'
import UserActivityDrilldown from '@/components/charts/user-activity/UserActivityDrilldown.vue'

const props = defineProps<{ filters: Filters; projectPage?: boolean }>()
const sorting = ref('')
const sortingOptions = ref<{ text: string; value: string }[]>([])
const drilldownData = ref<{
  user: { name: string; id: number }
  activity: string
} | null>(null)

const SORTING_OPTIONS = [
  { text: 'Author', value: 'user.name' },
  { text: 'Comments', value: 'comment_count' },
  { text: 'Commits', value: 'commit_count' },
  { text: 'Merge requests', value: 'merge_request_count' },
]

const { response, loading, fetchError } = useGettingChartData(
  { ...props, filters: omit(['scale_type'], props.filters) },
  chartTypes.UserActivity
)

const chartData = computed(() => response.value?.per_user)

const sortedLabels = computed(() => {
  const sorted = sortBy(
    path(sorting.value.split('.')) as any,
    chartData.value || []
  )
  return sorted.map((data: PerUserActivity) => data.user?.name)
})

const userActivityData = computed(() => ({
  labels: sortedLabels.value,
  datasets: [
    {
      label: 'Comments',
      key: 'comment_count',
      data: chartData.value,
      parsing: {
        xAxisKey: 'comment_count',
        yAxisKey: 'user.name',
      },
      backgroundColor: usedColors['warning-300'],
      borderRadius: 4,
    },
    {
      label: 'Commits',
      key: 'commit_count',
      data: chartData.value,
      parsing: {
        yAxisKey: 'user.name',
        xAxisKey: 'commit_count',
      },
      backgroundColor: usedColors['success-400'],
      borderRadius: 4,
      hidden: true,
    },
    {
      label: 'Merge requests',
      key: 'merge_request_count',
      data: chartData.value,
      parsing: {
        yAxisKey: 'user.name',
        xAxisKey: 'merge_request_count',
      },
      backgroundColor: usedColors['danger-400'],
      borderRadius: 4,
    },
    {
      type: 'line',
      label: 'Avg. Comments',
      key: 'avg_comment_count',
      borderColor: usedColors['warning-300'],
      backgroundColor: 'white',
      borderWidth: 2,
    },
    {
      type: 'line',
      label: 'Avg. Commits',
      key: 'avg_commit_count',
      borderColor: usedColors['success-400'],
      backgroundColor: 'white',
      borderWidth: 2,
      hidden: true,
    },
    {
      type: 'line',
      label: 'Avg. Merge requests',
      key: 'avg_merge_request_count',
      borderColor: usedColors['danger-400'],
      backgroundColor: 'white',
      borderWidth: 2,
    },
    {
      type: 'line',
      borderDash: [2, 1],
      label: 'All Avg. Comments',
      key: 'all_avg_comment_count',
      borderColor: usedColors['warning-300'],
      backgroundColor: 'white',
      borderWidth: 2,
      hidden: true,
    },
    {
      type: 'line',
      label: ' All Avg. Commits',
      key: 'all_avg_commit_count',
      backgroundColor: 'white',
      borderColor: usedColors['success-400'],
      borderDash: [2, 2],
      borderWidth: 2,
      hidden: true,
    },
    {
      type: 'line',
      label: 'All Avg. Merge requests',
      key: 'all_avg_merge_request_count',
      backgroundColor: 'white',
      borderColor: usedColors['danger-400'],
      borderDash: [2, 1],
      borderWidth: 2,
      hidden: true,
    },
  ],
}))

const userActivityOptions = computed(() => ({
  responsive: true,
  maintainAspectRatio: false,
  onHover: (e: ChartEvent, chartElement: ActiveElement[]): void =>
    toggleCursorOnChartDataHover(e, chartElement[0]),
  scales: {
    x: {
      position: 'top',
    },
    y: { grid: { drawOnChartArea: false } },
  },
  indexAxis: 'y',
  interaction: {
    mode: 'index',
  },
  plugins: {
    datalabels: null,
    tooltip: {
      mode: 'index',
    },
    legend: {
      onHover: (evt: ChartEvent): void => showCursorOnLegendHover(evt),
      onLeave: (evt: ChartEvent): void => hideCursorOnLegendLeave(evt),
      onClick: function (
        _e: ChartEvent,
        legendItem: LegendItem,
        legend: LegendElement<any>
      ): void {
        const ci = legend.chart
        const index = legendItem.datasetIndex
        const datasetKey = ci.data.datasets[index].key
        if (ci.isDatasetVisible(index)) {
          Object.keys(ci.config.options.plugins.annotation.annotations).forEach(
            (key) => {
              if (key === datasetKey) {
                ci.config.options.plugins.annotation.annotations[key].display =
                  false
              }
            }
          )
          sortingOptions.value = sortingOptions.value.filter(
            ({ value }) => value !== datasetKey
          )
          ci.hide(index)
        } else {
          Object.keys(ci.config.options.plugins.annotation.annotations).forEach(
            (key) => {
              if (key === datasetKey) {
                ci.config.options.plugins.annotation.annotations[key].display =
                  true
              }
            }
          )
          sortingOptions.value = SORTING_OPTIONS.filter(
            (item) =>
              sortingOptions.value.some(({ value }) => value === item.value) ||
              item.value === datasetKey
          )
          ci.show(index)
        }
      },
      align: 'start',
      labels: {
        borderRadius: 4,
        boxWidth: 12,
        boxHeight: 12,
      },
    },
    annotation: {
      annotations: {
        avg_comment_count: verticalAnnotation({
          color: usedColors['warning-300'],
          label: 'Avg. Comments',
          value: response.value?.avg_comment_count,
          display: true,
        }),
        avg_commit_count: verticalAnnotation({
          color: usedColors['success-400'],
          label: 'Avg. Commits',
          value: response.value?.avg_commit_count,
          display: false,
        }),
        avg_merge_request_count: verticalAnnotation({
          color: usedColors['danger-400'],
          label: 'Avg. Merge requests',
          value: response.value?.avg_merge_request_count,
          display: true,
        }),
        all_avg_comment_count: verticalAnnotation({
          color: usedColors['warning-300'],
          label: 'All Avg. Comments',
          value: response.value?.all_avg_comment_count,
          display: false,
          borderDash: [5, 5],
        }),
        all_avg_commit_count: verticalAnnotation({
          color: usedColors['success-400'],
          label: 'All Avg. Commits',
          value: response.value?.all_avg_commit_count,
          display: false,
          borderDash: [5, 5],
        }),
        all_avg_merge_request_count: verticalAnnotation({
          color: usedColors['danger-400'],
          label: 'All Avg. Merge requests',
          value: response.value?.all_avg_merge_request_count,
          display: false,
          borderDash: [5, 5],
        }),
      },
    },
  },
  categoryPercentage: 0.8,
  barPercentage: 0.8,
}))

const saveUserDataAndShowMenu = (point: any) => {
  const activities: Dictionary<string> = {
    0: USER_ACTIVITIES.COMMENTS,
    1: USER_ACTIVITIES.COMMITS,
    2: USER_ACTIVITIES.MERGE_REQUESTS,
  }
  drilldownData.value = {
    user: point.element.$context.raw.user,
    activity: activities[point.datasetIndex],
  }
}

onMounted(async () => {
  sortingOptions.value = SORTING_OPTIONS.filter(({ value }) =>
    userActivityData.value.datasets.some(
      ({ parsing, hidden }: any) =>
        !hidden &&
        [parsing?.xAxisKey, parsing?.yAxisKey, parsing?.key].includes(value)
    )
  )
})
</script>

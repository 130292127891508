<template>
  <div class="flex items-center h-6">
    <IconButton size="small" @click="onSearchIconClick" class="p-button-text"
      ><i class="pi pi-search" style="font-size: 14px" aria-hidden="true"></i
    ></IconButton>
    <span
      :class="{
        'p-input-icon-right': search,
        'show-input': showSearchInput || search,
      }"
      class="input-wrapper"
    >
      <InputText
        v-model="search"
        ref="input"
        class="custom-input"
        :class="{ 'show-input': showSearchInput || search }"
        :placeholder="placeholder ? placeholder : 'Search by title...'"
        @blur="closeOnClickOutside"
      ></InputText>
      <i
        v-if="search"
        class="pi pi-times-circle clear-icon cursor-pointer"
        @click="onClose"
        aria-hidden="true"
      ></i>
    </span>
  </div>
</template>

<script setup lang="ts">
import InputText from 'primevue/inputtext/InputText.vue'
import IconButton from '@/components/common/buttons/IconButton.vue'
import { onMounted, ref, watch, defineProps, defineEmits } from 'vue'

const props = defineProps<{
  value: string | undefined
  placeholder?: string
}>()

const emit = defineEmits<{ (e: 'update:value', value: string): void }>()

const showSearchInput = ref(false)
const search = ref(props.value)
const input = ref()

onMounted(() => {
  search.value = props.value || ''
})

watch([search], () => {
  emit('update:value', search.value || '')
})

const onSearchIconClick = () => {
  showSearchInput.value = true
  input.value?.$el.focus()
}

const onClose = () => {
  search.value = ''
  showSearchInput.value = false
}

const closeOnClickOutside = () => {
  if (search.value) return
  onClose()
}
</script>

<style scoped>
.input-wrapper {
  transition: all 0.3s ease-out;
  @apply flex items-center w-0;
}

.custom-input {
  transition: all 0.3s ease-out;
  @apply border-none hover:shadow-none focus:shadow-none w-0 opacity-0 h-6;
}

.show-input {
  opacity: 1;
  width: 170px;
}
</style>

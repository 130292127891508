<template>
  <router-link :to="link" class="sidebar-item" :class="sidebarItemClasses">
    <Avatar
      v-if="pageDetails.id"
      :url="pageDetails.image"
      :label="pageDetails.title"
      size="small"
      class="mr-4"
    ></Avatar>
    <div
      v-else
      class="sidebar-icon"
      :style="{
        'font-size': '1.25rem',
        'margin-left': '2px',
        'margin-right': open ? '17px' : 0,
      }"
    >
      <AdministrationIcon
        v-if="pageDetails.icon === 'administration'"
      ></AdministrationIcon>
      <PortfolioIcon v-if="pageDetails.icon === 'portfolio'"></PortfolioIcon>
      <ProjectIcon v-if="pageDetails.icon === 'project'"></ProjectIcon>
      <UsersIcon v-if="pageDetails.icon === 'users'"></UsersIcon>
    </div>
    <Transition>
      <span v-if="open" class="overflow-hidden">{{ pageDetails.title }}</span>
    </Transition>
  </router-link>
</template>

<script setup lang="ts">
import { defineProps, computed } from 'vue'
import { LocationQueryRaw } from 'vue-router'
import Avatar from '@/components/common/Avatar.vue'
import AdministrationIcon from '@/assets/icons/components/AdministrationIcon.vue'
import PortfolioIcon from '@/assets/icons/components/PortfolioIcon.vue'
import ProjectIcon from '@/assets/icons/components/ProjectIcon.vue'
import UsersIcon from '@/assets/icons/components/UsersIcon.vue'

const props = defineProps<{
  open: boolean
  selectedPage?: string
  pageDetails: {
    icon: string
    value: string
    title: string
    id?: number
    image?: string
    favourite_project_selected?: boolean
  }
  link: {
    path: string
    query: LocationQueryRaw | undefined
  }
  disabled?: boolean
}>()

const isCurrentPageSelected = computed(() => {
  const selected =
    props.selectedPage && props.selectedPage.includes(props.pageDetails.value)
  if (props.pageDetails.id) {
    return selected && props.pageDetails.favourite_project_selected
  }
  return selected
})

const sidebarItemClasses = computed(() => {
  const openClass = props.open ? 'w-60' : 'w-14'
  const selectedItemClasses = isCurrentPageSelected.value
    ? 'text-black bg-gray-100 hover:bg-gray-200'
    : 'text-gray-800 hover:bg-gray-100 hover:text-black'
  return [openClass, props.disabled ? 'disabled-item' : selectedItemClasses]
})
</script>

<style scoped>
.sidebar-item {
  transition: 0.3s ease-out;
  @apply px-4 py-3  cursor-pointer text-base flex font-semi-bold truncate items-center;
}

.disabled-item {
  @apply text-gray-800-50 pointer-events-none cursor-default;
}
.v-enter-active,
.v-leave-active {
  opacity: 1;
  transition: opacity 1.3s ease;
}

.v-enter-from,
.v-leave-to {
  transition: opacity 0.1s ease;
  opacity: 0;
}
</style>

<template>
  <IconButton
    size="small"
    class="p-button-text"
    @click="toggleOverlayPanel"
    aria-controls="overlay_panel"
    ><i class="pi pi-sort-amount-down-alt"></i
  ></IconButton>
  <OverlayPanel ref="op" id="overlay-panel">
    <Listbox
      class="p-1"
      v-model="selectedSorting"
      :options="sortingOptions"
      optionLabel="text"
      @change="onChangeSorting($event.value)"
    ></Listbox>
  </OverlayPanel>
</template>

<script setup lang="ts">
import OverlayPanel from 'primevue/overlaypanel/OverlayPanel.vue'
import IconButton from '@/components/common/buttons/IconButton.vue'
import Listbox from 'primevue/listbox/Listbox.vue'

import { defineEmits, defineProps, ref } from 'vue'

const props = defineProps<{
  sortingOptions: { text: string; value: string }[]
}>()

const emit = defineEmits<{
  (e: 'change-sorting', value: string): void
}>()

const op = ref()
const selectedSorting = ref(props.sortingOptions[0])

const toggleOverlayPanel = (event: Event) => {
  op.value.toggle(event)
}

const onChangeSorting = (event: { text: string; value: string }) => {
  if (event) {
    op.value.hide()
    emit('change-sorting', event.value)
  }
}
</script>

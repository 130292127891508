<template>
  <TableWrapper title="Commits">
    <template #button>
      <TableSearchInput
        :value="store.state.bitbucket.search"
        @update:value="searchCommit"
      ></TableSearchInput>
      <CommitsSettingsMenu
        @update-table="amountOfChangingExclusionCommits++"
      ></CommitsSettingsMenu>
    </template>
    <template #default>
      <BaseCommitsTable
        :search="search"
        :filters="filters"
        table-height="516px"
        :refreshTable="amountOfChangingExclusionCommits"
      ></BaseCommitsTable>
    </template>
  </TableWrapper>
</template>

<script setup lang="ts">
import TableWrapper from '@/components/common/table/TableWrapper.vue'
import { useStore } from '@/store'
import { computed, ref } from 'vue'
import CommitsSettingsMenu from '@/components/admin-dashboard/bitbucket/commits/CommitsSettingsMenu.vue'
import TableSearchInput from '@/components/common/table/TableSearchInput.vue'
import BaseCommitsTable from '@/components/admin-dashboard/bitbucket/commits/BaseCommitsTable.vue'

const store = useStore()

const filters = computed(() => store.state.bitbucket.commits_filters)
const search = computed(() => store.state.bitbucket.search)
const amountOfChangingExclusionCommits = ref(0)

const searchCommit = (value: string) => {
  store.commit('bitbucket/setSearchText', value || null)
}
</script>

<template>
  <Dialog
    :visible="!!project"
    :dismissableMask="true"
    @update:visible="closeDialog"
    position="bottom"
    modal
  >
    <template #header>
      <div class="header-wrapper">
        <div>
          Tempo • Projects • <strong>{{ project.name }}</strong>
        </div>
        <div class="flex gap-1">
          <IconButton
            class="p-button-text delete"
            @click="showDeleteConfirmationDialog = true"
          >
            <i class="pi pi-trash"></i>
          </IconButton>
        </div>
      </div>
    </template>
    <BudgetAndRolesRatesTabs
      :project_id="project.id"
      edit-flow
    ></BudgetAndRolesRatesTabs>
    <DeleteConfirmationDialog
      v-model:open="showDeleteConfirmationDialog"
      :name="project.name"
      :loading="loading"
      @delete="onDeleteProject"
    ></DeleteConfirmationDialog>
  </Dialog>
</template>

<script setup lang="ts">
import Dialog from 'primevue/dialog/Dialog.vue'
import { defineProps, defineEmits, ref } from 'vue'
import { useToast } from 'primevue/usetoast'
import { useStore } from '@/store'
import IconButton from '@/components/common/buttons/IconButton.vue'
import { showToastError } from '@/utils/utils'
import BudgetAndRolesRatesTabs from '@/components/admin-dashboard/tempo/projects/BudgetAndRolesRatesTabs.vue'
import DeleteConfirmationDialog from '@/components/common/dialogs/DeleteConfirmationDialog.vue'

const props = defineProps<{ project: { id: number; name: string } | null }>()
const emit = defineEmits<{ (e: 'update:project', value: null): void }>()
const toast = useToast()
const store = useStore()
const showDeleteConfirmationDialog = ref(false)
const loading = ref(false)

async function onDeleteProject() {
  loading.value = true
  try {
    await store.dispatch('tempo/deleteProject', props.project?.id)
    loading.value = false
    showDeleteConfirmationDialog.value = false
    closeDialog()
  } catch (e) {
    loading.value = false
    showToastError(toast, e)
  }
}

function closeDialog() {
  emit('update:project', null)
}
</script>

<style scoped>
.header-wrapper {
  @apply flex items-center justify-between w-full;
}
</style>

import { NotesPage } from './user-activity'
import axios from '@/services/axios'
import { Filters } from '@/store/modules/filters'
import { ActionContext } from 'vuex'
import { State as GlobalState } from '../../index'
import { path } from 'ramda'

export interface CommentsRatesItem {
  user: { id: number; name: string }
  data: {
    rate_1: number
    rate_2: number
    rate_3: number
    rate_4: number
    rate_5: number
  }
}

export interface CommentsRatesData {
  data: CommentsRatesItem[]
}

type Context = ActionContext<CommentsRatesData, GlobalState>

const actions = {
  getData(
    { rootGetters }: Context,
    params: Filters
  ): Promise<CommentsRatesData> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/charts/comments-rates/`,
        { params }
      )
      .then(({ data }) => data)
  },
  getFirstPageOfComments(
    { rootGetters }: Context,
    params: Filters
  ): Promise<NotesPage> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/git/comments/?limit=50`,
        { params }
      )
      .then(({ data }) => data)
  },
  getNextPageOfActivities(_: Context, url: string): Promise<void> {
    return axios.get(`${url}`).then(path(['data']))
  },
}

export default {
  namespaced: true,
  actions,
}

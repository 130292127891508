<template>
  <Dialog
    :visible="open"
    :dismissableMask="true"
    @update:visible="closeDialog"
    position="bottom"
    modal
  >
    <template #header>
      <div class="header-wrapper">Tempo • Projects • Step {{ step }} of 2</div>
    </template>
    <div v-if="step === 1">
      <div class="title">Add project</div>
      <TempoAddProjectsTemplate
        v-model:jiraProject="formData.jira_project"
        :validation="v$"
      ></TempoAddProjectsTemplate>
    </div>
    <BudgetAndRolesRatesTabs
      v-else
      :project_id="formData.id"
    ></BudgetAndRolesRatesTabs>
    <template #footer>
      <Button
        v-if="step === 1"
        class="p-button-outlined"
        size="large"
        @click="closeDialog"
      >
        Cancel
      </Button>
      <Button v-if="step === 1" size="large" class="add" @click="onAddProject"
        >Next</Button
      >
      <Button v-else size="large" class="finish" @click="closeDialog"
        >Finish</Button
      >
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import Dialog from 'primevue/dialog/Dialog.vue'
import { defineProps, ref, defineEmits } from 'vue'
import Button from '@/components/common/buttons/Button.vue'
import { useToast } from 'primevue/usetoast'
import { useStore } from '@/store'
import { showToastError } from '@/utils/utils'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import TempoAddProjectsTemplate from '@/components/admin-dashboard/tempo/projects/TempoAddProjectsTemplate.vue'
import BudgetAndRolesRatesTabs from '@/components/admin-dashboard/tempo/projects/BudgetAndRolesRatesTabs.vue'

defineProps<{ open: boolean }>()
const emit = defineEmits<{ (e: 'update:open', value: boolean): void }>()
const toast = useToast()
const store = useStore()
const step = ref(1)

const initialForm = () => ({
  token: store.state.tempo.token.id,
  jira_project: null,
})

const formData = ref(initialForm())

const formRules = {
  jira_project: { required, $lazy: true },
}

const v$ = useVuelidate(formRules, formData)

async function onAddProject() {
  v$.value.$touch()
  if (v$.value.$invalid) {
    return
  }
  try {
    formData.value = await store.dispatch('tempo/addProject', formData.value)
    step.value++
  } catch (e) {
    showToastError(toast, e)
  }
}

function closeDialog() {
  store.dispatch('tempo/getProjects')
  formData.value = initialForm()
  step.value = 1
  v$.value.$reset()
  emit('update:open', false)
}
</script>

<style scoped>
.header-wrapper {
  @apply flex items-center justify-between w-full;
}
.title {
  @apply text-xl font-semi-bold mb-3;
}
</style>

<template>
  <IconButton
    size="small"
    class="p-button-text"
    :class="$style['margin-left']"
    id="settings"
    @click="showOverlay"
    ><span class="pi pi-cog" style="font-size: 14px"></span
  ></IconButton>
  <OverlayPanel
    ref="overlay"
    @hide="onOverlayHide"
    :class="{ [$style.panel]: showExclusionRules }"
  >
    <CommitsExclusionRule
      v-if="showExclusionRules"
      :project="selectedProject"
      @close-popup="hideOverlay"
      @update-table="$emit('update-table')"
    ></CommitsExclusionRule>
    <Listbox
      v-else-if="showProjectsList"
      class="p-1"
      :list-style="{ overflow: 'auto', height: calculatedHeight }"
      v-model="selectedProject"
      :options="searchMatchedItems"
      @change="onSelectProject($event.value)"
    >
      <template #header>
        <BaseInputText
          v-model:value="searchProjectItem"
          icon-left="pi-search"
          class="w-full py-2 px-3"
          placeholder="Search"
        ></BaseInputText>
        <Divider :class="$style.divider"></Divider>
      </template>
      <template #option="slotProps">
        <div class="flex justify-between items-center">
          {{ slotProps.option.name }}
          <span class="text-gray-600 pi pi-chevron-right"></span>
        </div>
      </template>
    </Listbox>
    <div v-else class="p-1">
      <div :class="$style['exclusion-header']">Exclusion Rules</div>
      <div :class="$style['menu-item']" @click="onShowGlobalExclusionRule">
        Global
      </div>
      <div :class="$style['menu-item']" @click="showProjectsList = true">
        Project
        <span class="text-gray-600 pi pi-chevron-right"></span>
      </div>
    </div>
  </OverlayPanel>
</template>

<script setup lang="ts">
import { computed, defineEmits, ref } from 'vue'
import IconButton from '@/components/common/buttons/IconButton.vue'
import OverlayPanel from 'primevue/overlaypanel/OverlayPanel.vue'
import Listbox from 'primevue/listbox/Listbox.vue'
import { useStore } from '@/store'
import { LIST_ITEM_HEIGHT } from '@/constants/constants'
import CommitsExclusionRule from '@/components/admin-dashboard/bitbucket/commits/CommitsExclusionRule.vue'
import BaseInputText from '@/components/common/base/BaseInputText.vue'
import { BitbucketProject } from '@/store/modules/admin/bitbucket'

defineEmits<{ (e: 'update-table'): void }>()
const tableHeight = 300
const overlay = ref()
const showProjectsList = ref(false)
const showExclusionRules = ref(false)
const selectedProject = ref<BitbucketProject | null>(null)
const searchProjectItem = ref('')
const store = useStore()

const projects = computed(() => store.state.bitbucket.projects)

const searchMatchedItems = computed(() => {
  if (searchProjectItem.value) {
    return projects.value.filter((item: BitbucketProject) => {
      return (
        item.name &&
        item.name.toLowerCase().includes(searchProjectItem.value.toLowerCase())
      )
    })
  }
  return projects.value
})

const calculatedHeight = computed(() => {
  return searchMatchedItems.value.length * LIST_ITEM_HEIGHT > tableHeight
    ? '300px'
    : 'auto'
})

const showOverlay = (event: Event) => {
  overlay.value.show(event)
}

const hideOverlay = () => {
  overlay.value.hide()
}

const onSelectProject = (project: BitbucketProject) => {
  selectedProject.value = project
  showExclusionRules.value = true
}

const onOverlayHide = () => {
  showProjectsList.value = false
  showExclusionRules.value = false
  selectedProject.value = null
  searchProjectItem.value = ''
}

const onShowGlobalExclusionRule = () => {
  showExclusionRules.value = true
}
</script>

<style module>
.margin-left {
  @apply ml-1;
}
.exclusion-header {
  @apply py-2 px-3 text-sm text-gray-600 font-semi-bold;
}
.menu-item {
  @apply py-2 px-3 text-base cursor-pointer rounded hover:bg-gray-50 flex justify-between items-center;
}
.divider {
  @apply my-1 mx-3 w-auto;
}
.panel {
  min-width: 320px;
  right: 4px;
  left: auto !important;
  top: auto !important;
  bottom: 4px;
}
</style>

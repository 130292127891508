<template>
  <IconButton
    size="small"
    class="p-button-text"
    @click="toggleOverlayPanel"
    aria-controls="overlay_source_panel"
    ><i class="pi pi-sliders-v"></i
  ></IconButton>
  <OverlayPanel ref="op" id="overlay-source-panel">
    <p class="p-listbox-header">Source</p>
    <Listbox
      class="p-1"
      v-model="selectedSource"
      :options="sourceOptions"
      optionLabel="value"
      @change="onChangeSource($event.value)"
    ></Listbox>
  </OverlayPanel>
</template>

<script setup lang="ts">
import OverlayPanel from 'primevue/overlaypanel/OverlayPanel.vue'
import IconButton from '@/components/common/buttons/IconButton.vue'
import Listbox from 'primevue/listbox/Listbox.vue'

import { defineEmits, defineProps, ref } from 'vue'

const props = defineProps<{
  sourceOptions: { value: string }[]
}>()

const emit = defineEmits<{
  (e: 'change-source', value: string): void
}>()

const op = ref()
const selectedSource = ref(props.sourceOptions[0])

const toggleOverlayPanel = (event: Event) => {
  op.value.toggle(event)
}

const onChangeSource = (event: { text: string; value: string }) => {
  if (event) {
    op.value.hide()
    emit('change-source', event.value)
  }
}
</script>

<style lang="scss">
.p-listbox-header {
  @apply text-gray-600 font-bold text-sm;
  padding: 8px 15px 2px 15px;
  opacity: 1;
}
</style>

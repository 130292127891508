<template>
  <StatusIcon :tooltip="titles[status]" :status="categoryOfStatus"></StatusIcon>
</template>

<script setup lang="ts">
import { defineProps, computed } from 'vue'
import StatusIcon from '@/components/common/table/StatusIcon.vue'

const props = defineProps<{ status?: string }>()

const projectStatuses = {
  SYNCED: 'SYNCED',
  CONNECTION_ERROR: 'CONNECTION_ERROR',
  ERROR_SYNC: 'ERROR_SYNC',
  SYNCING_IN_PROGRESS: 'SYNCING_IN_PROGRESS',
  DELETION_IN_PROGRESS: 'DELETION_IN_PROGRESS',
}

const titles = {
  SYNCED: 'Synced',
  CONNECTION_ERROR: 'Syncing failed',
  ERROR_SYNC: 'Syncing failed',
  SYNCING_IN_PROGRESS: 'Syncing in progress',
  DELETION_IN_PROGRESS: 'Deletion in progress',
}

const categoryOfStatus = computed(() => {
  if (props.status === projectStatuses.SYNCED) {
    return 'success'
  } else if (
    props.status === projectStatuses.CONNECTION_ERROR ||
    props.status === projectStatuses.ERROR_SYNC
  ) {
    return 'danger'
  } else if (
    props.status === projectStatuses.DELETION_IN_PROGRESS ||
    props.status === projectStatuses.SYNCING_IN_PROGRESS
  ) {
    return 'warning'
  } else {
    return ''
  }
})
</script>

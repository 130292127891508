<template>
  <TableWrapper :title="tableTitle">
    <template #button>
      <TableSearchInput
        v-model:value="search"
        placeholder="Search by project"
        class="mr-2"
      ></TableSearchInput>
      <IconButton
        class="p-button-outlined"
        size="small"
        icon="pi-plus"
        icon-position="left"
        icon-size="12px"
        @click="showCreateDialog = true"
        >Add</IconButton
      >
    </template>
    <template #default>
      <LoaderWrapper v-if="loading"></LoaderWrapper>
      <MyTable
        v-else
        :table-data="jiraProjects"
        :columns-data="columnsData"
        :scrollable="scrollableTable"
        :filters="filters"
        :filter-fields="filterFields"
      >
        <template #status="{ data }">
          <ProjectStatusCell :status="data.status" />
        </template>
        <template #last_synced_at="{ data }">
          {{ calcDateFormat(data.last_synced_at) }}
        </template>
        <template #estimation_type="{ data }">
          <BaseDropdown
            :options="estimationTypes"
            :option-id="data.id"
            v-model:selected="data.estimation_type"
            @update:selected="onChangeEstimationType($event, data.id)"
            table
          ></BaseDropdown>
        </template>
        <template #original_project="{ data }">
          <BaseDropdown
            :options="originalProjects"
            v-model:selected="data.original_project"
            v-model:newly-created-option="newlyCreatedProject"
            target="project"
            search
            table
            :option-id="data.id"
            @update:selected="onChangeOriginalProject($event, data.id)"
            @add="onAddNewProject(data.id)"
          ></BaseDropdown>
        </template>
        <template #editable="{ data }">
          <IconButton
            size="small"
            class="p-button-text delete-button invisible group-hover:visible"
            @click="deleteConfirmationDialogId = data.id"
            ><i class="pi pi-trash" style="font-size: 12px"></i
          ></IconButton>
        </template>
      </MyTable>
      <JiraAddProjectsDialog
        v-model:open="showCreateDialog"
      ></JiraAddProjectsDialog>
      <AddProjectMappingDialog
        v-model:open="showAddProjectMappingDialog"
        @save-project-id="newlyCreatedProject.new_option_id = $event"
      ></AddProjectMappingDialog>
      <DeleteConfirmationDialog
        v-model:open="deleteConfirmationDialogId"
        :name="jiraProjects.find((project: any) => project.id === deleteConfirmationDialogId)?.name || 'this project'"
        :loading="deletionLoading"
        @delete="onDeleteProject(deleteConfirmationDialogId)"
      ></DeleteConfirmationDialog>
    </template>
  </TableWrapper>
</template>

<script setup lang="ts">
import TableWrapper from '@/components/common/table/TableWrapper.vue'
import IconButton from '@/components/common/buttons/IconButton.vue'
import { useStore } from '@/store'
import { computed, onMounted, ref, watchEffect } from 'vue'
import MyTable from '@/components/common/table/MyTable.vue'
import { useToast } from 'primevue/usetoast'
import { showToastError } from '@/utils/utils'
import { calcDateFormat } from '@/utils/date-utils'
import JiraAddProjectsDialog from '@/components/admin-dashboard/jira/projects/JiraAddProjectsDialog.vue'
import LoaderWrapper from '@/components/common/loader/LoaderWrapper.vue'
import TableSearchInput from '@/components/common/table/TableSearchInput.vue'
import useTableSearch from '@/utils/hooks/useTableSearch'
import DeleteConfirmationDialog from '@/components/common/dialogs/DeleteConfirmationDialog.vue'
import { clone } from 'ramda'
import AddProjectMappingDialog from '@/components/admin-dashboard/system/project-mapping/AddProjectMappingDialog.vue'
import BaseDropdown from '@/components/common/base/BaseDropdown.vue'
import ProjectStatusCell from '@/components/common/table/ProjectStatusCell.vue'
import { ESTIMATION_TYPE, ESTIMATION_TYPE_TITLES } from '@/constants/constants'

const store = useStore()
const toast = useToast()
const showCreateDialog = ref(false)
const loading = ref(false)
const scrollableTable = ref(true)
const showAddProjectMappingDialog = ref(false)
const filterFields = ['name', 'original_project']
const { search, filters } = useTableSearch()
const deleteConfirmationDialogId = ref(false)
const deletionLoading = ref(false)
const jiraProjects = ref([])
const newlyCreatedProject = ref<{ option_id?: number; new_option_id?: number }>(
  {}
)

onMounted(() => {
  loading.value = true
  store
    .dispatch('jira/getProjects')
    .catch((e) => {
      showToastError(toast, e)
    })
    .finally(() => (loading.value = false))
})

watchEffect(() => {
  jiraProjects.value = clone(store.state.jira.projects)
})

const originalProjects = computed(() => store.state.admin.original_projects)
const tableTitle = computed(() => `Projects • ${jiraProjects.value.length}`)
const columnsData = [
  {
    header: 'Project name',
    field: 'name',
    is_sortable: true,
    use_template: false,
    styles: 'flex-basis:150px; padding-right: 10px',
  },
  {
    header: 'Status',
    field: 'status',
    is_sortable: true,
    use_template: true,
    styles: 'flex-basis:70px; padding-right: 10px',
  },
  {
    header: 'Last synced',
    field: 'last_synced_at',
    use_template: true,
    is_sortable: true,
    styles: 'flex-basis:100px; padding-right: 10px; min-width: 120px',
  },
  {
    header: 'Estimation type',
    field: 'estimation_type',
    is_sortable: false,
    use_template: true,
    styles: 'flex-basis:40px; padding-right: 10px',
  },
  {
    header: 'Original project',
    field: 'original_project',
    is_sortable: false,
    use_template: true,
    styles: 'flex-basis:150px; padding-right: 10px',
  },
  {
    header: '',
    field: 'editable',
    is_sortable: false,
    use_template: true,
    styles: 'flex-grow:0; flex-basis:30px',
  },
]
const estimationTypes = computed(() => [
  {
    id: ESTIMATION_TYPE.TIME,
    name: ESTIMATION_TYPE_TITLES.TIME,
  },
  {
    id: ESTIMATION_TYPE.STORY_POINTS,
    name: ESTIMATION_TYPE_TITLES.STORY_POINTS,
  },
])

async function onDeleteProject(id: number | boolean) {
  if (!id) return
  deletionLoading.value = true
  try {
    await store.dispatch('jira/deleteProject', id)
    deleteConfirmationDialogId.value = false
    deletionLoading.value = false
  } catch (e) {
    deletionLoading.value = false
    showToastError(toast, e)
  }
}

async function onChangeOriginalProject(originalProjectId: number, id: number) {
  try {
    await store.dispatch('jira/editProject', {
      original_project: originalProjectId,
      id: id,
    })
  } catch (e) {
    showToastError(toast, e)
  }
}

async function onChangeEstimationType(
  estimationType: ESTIMATION_TYPE,
  id: number
) {
  try {
    await store.dispatch('jira/editProject', {
      estimation_type: estimationType,
      id: id,
    })
  } catch (e) {
    showToastError(toast, e)
  }
}

const onAddNewProject = (id: number) => {
  newlyCreatedProject.value.option_id = id
  showAddProjectMappingDialog.value = true
}
</script>

<template>
  <FormRow title="Budget" :invalid-state="validation.budget.$invalid">
    <BaseInputNumber
      v-model:value="budgetValue"
      class="w-full"
      :min="0"
      :errors="validation.budget.$errors"
      prefix="CHF "
      :minFractionDigits="2"
      :maxFractionDigits="2"
      locale="de-CH"
    ></BaseInputNumber>
  </FormRow>
  <FormRow title="Date" :invalid-state="validation.date.$invalid">
    <BaseCalendar
      v-model:value="dateValue"
      :errors="validation.date.$errors"
      class="w-full"
    ></BaseCalendar>
  </FormRow>
</template>

<script setup lang="ts">
import FormRow from '@/components/common/form/FormRow.vue'
import { defineEmits, defineProps, computed } from 'vue'
import BaseInputNumber from '@/components/common/base/BaseInputNumber.vue'
import BaseCalendar from '@/components/common/base/BaseCalendar.vue'
import { VuelidateValidation } from '@/types/types'

interface BudgetFormValidation extends VuelidateValidation {
  budget: VuelidateValidation
  date: VuelidateValidation
}

const props = defineProps<{
  budget: number | null
  date: string | null
  validation: BudgetFormValidation
}>()
const emit = defineEmits<{
  (e: 'update:budget', value: number | null): void
  (e: 'update:date', value: string | null): void
}>()

const budgetValue = computed({
  get() {
    return props.budget
  },
  set(value: number | null) {
    emit('update:budget', value)
  },
})

const dateValue = computed({
  get() {
    return props.date
  },
  set(value: string | null) {
    emit('update:date', value)
  },
})
</script>

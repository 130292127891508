<template>
  <div class="wrapper">
    <div
      class="header"
      :class="{ 'open-header': showContent, 'connected-header': connected }"
      @click="showContent = true"
    >
      <div class="flex justify-between items-center w-full">
        <div class="flex">
          <img
            :src="require(`../../assets/icons/${title.toLowerCase()}.svg`)"
            :alt="title"
            width="24"
            height="24"
            class="mr-3"
          />
          <span class="font-semi-bold text-lg">{{ title }}</span>
        </div>
        <div>
          <Button
            v-if="showContent"
            size="small"
            class="p-button-outlined"
            @click.stop="showContent = false"
            >Cancel</Button
          >
          <div v-else-if="connected" class="flex items-center">
            <span class="text-success-700 text-base font-semi-bold mr-2"
              >Connected</span
            >
            <span class="pi pi-check-circle text-success-500"></span>
          </div>
          <Button v-else class="button" size="small">Connect</Button>
        </div>
      </div>
    </div>
    <div v-if="showContent" class="content"><slot></slot></div>
  </div>
</template>

<script setup lang="ts">
import { ref, defineProps, watchEffect } from 'vue'
import Button from '@/components/common/buttons/Button.vue'
const props = defineProps<{ title?: string; connected?: boolean }>()
const showContent = ref(false)

watchEffect(() => {
  if (props.connected) {
    showContent.value = false
  }
})
</script>

<style lang="scss" scoped>
.wrapper {
  @apply mb-3;
}
.header {
  @apply bg-gray-50 rounded-md p-4 cursor-pointer;
  .button {
    @apply hidden;
  }
}

.header:hover {
  @apply bg-gray-100;

  .button {
    @apply inline-flex;
  }
}

.open-header {
  @apply rounded-b-none cursor-default hover:bg-gray-50;
}

.connected-header {
  @apply bg-success-50 pointer-events-none;
}

.content {
  border-top: 1px solid;
  @apply bg-gray-50 rounded-b-md p-8 pt-5 border-gray-100-60;
}
</style>

import axios from '@/services/axios'
import { omit, path } from 'ramda'
import { LIMIT_OF_COMMITS } from '@/constants/constants'
import { ActionContext } from 'vuex'
import { State as GlobalState } from '../../index'

export interface GitlabProject {
  id?: number | null
  original_project?: number | null
  project_id?: number
  git_http_url?: string
  name?: string
  include_all_data?: boolean
  token?: number
  commit_message_exclusion_rule: null | string
  file_path_exclusion_rule: string | null
  web_url: string
  removed_irrelevant: boolean
  last_push: null
  avatar_url: null | string
}

export interface GitlabToken {
  name?: string
  id?: number
  token?: string
  base_url?: string
  company?: number
  status?: string
}

export interface GitlabUser {
  avatar_url?: string
  company?: number
  email?: string | null
  id: number
  name?: string
  original_name?: number | null
  user_id?: number
  username?: string
}

export interface GitlabCommit {
  author: number | null
  committed_date: string
  created_at: string
  excluded: boolean
  id: string
  message: string
  title: string
  web_url: string
  original_name_name: string
  author_name: string
  deletions: number
  duplicated_lines: number
  ignored_lines: number
  insertions: number
  original_deletions: number
  original_insertions: number
  original_name: number
  original_project: number
  original_project_name: string
  project: number
  project_name: string
}

export interface CommitsFilter {
  projects: null | number[]
  users: null | number[]
  since: null | string
  until: null | string
  insertions_gte: null | string
  insertions_lte: null | string
  deletions_gte: null | string
  deletions_lte: null | string
  duplicated_lines_gte: null | string
  duplicated_lines_lte: null | string
  excluded?: string | null
}

export interface GlobalExclusionRules {
  commit_message_exclusion_rule: string | null
  file_path_exclusion_rule: string | null
  id?: number
}

export interface State {
  tokens: GitlabToken[]
  projects: GitlabProject[]
  commits_filters: CommitsFilter
  search: string | null
  global_exclusion_rules: GlobalExclusionRules
}

type Context = ActionContext<State, GlobalState>

const state = (): State => ({
  tokens: [],
  projects: [],
  commits_filters: {
    projects: null,
    users: null,
    since: null,
    until: null,
    insertions_gte: null,
    insertions_lte: null,
    deletions_gte: null,
    deletions_lte: null,
    duplicated_lines_gte: null,
    duplicated_lines_lte: null,
  },
  search: null,
  global_exclusion_rules: {
    commit_message_exclusion_rule: null,
    file_path_exclusion_rule: null,
  },
})

const mutations = {
  setTokens(state: State, tokens: GitlabToken[]): void {
    state.tokens = tokens
  },
  setProjects(state: State, projects: GitlabProject[]): void {
    state.projects = projects
  },
  setCommitsFilters(state: State, filters: CommitsFilter): void {
    state.commits_filters = filters
  },
  setSearchText(state: State, search: string | null): void {
    state.search = search
  },
  setGlobalExclusionRules(state: State, rules: GlobalExclusionRules): void {
    state.global_exclusion_rules = rules || {
      commit_message_exclusion_rule: null,
      file_path_exclusion_rule: null,
    }
  },
}

const actions = {
  getTokens({ commit, rootGetters }: Context): Promise<void> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/gitlab/tokens/`
      )
      .then((data) => commit('setTokens', data.data))
  },
  addToken(
    { dispatch, rootGetters }: Context,
    token: GitlabToken
  ): Promise<void> {
    return axios
      .post(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/gitlab/tokens/`,
        {
          ...token,
        }
      )
      .then(path(['data']))
      .then(() => dispatch('getTokens'))
  },
  getToken({ rootGetters }: Context, id: number): Promise<void> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/gitlab/tokens/${id}/`
      )
      .then(path(['data']))
  },
  editToken(
    { dispatch, rootGetters }: Context,
    token: GitlabToken
  ): Promise<void> {
    return axios
      .patch(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/gitlab/tokens/${token.id}/`,
        omit(['id'], token)
      )
      .then(path(['data']))
      .then(() => dispatch('getTokens'))
  },
  deleteToken(
    { dispatch, rootGetters }: Context,
    payload: { id: number; verificationCode: string }
  ): Promise<void> {
    return axios
      .delete(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/gitlab/tokens/${payload.id}/`,
        {
          data: {
            verification_code: payload.verificationCode,
          },
        }
      )
      .then(path(['data']))
      .then(() => dispatch('getToken'))
  },
  getProjects({ commit, rootGetters }: Context): Promise<void> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/git/repositories/?git_hosting=Gitlab`
      )
      .then((data) => commit('setProjects', data.data))
  },
  getProject({ rootGetters }: Context, id: number): Promise<void> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/git/repositories/${id}/?git_hosting=Gitlab`
      )
      .then(path(['data']))
  },
  editProject(
    { dispatch, rootGetters }: Context,
    project: GitlabProject
  ): Promise<void> {
    return axios
      .patch(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/git/repositories/${project.id}/?git_hosting=Gitlab`,
        omit(['id'], project)
      )
      .then(path(['data']))
      .then(() => dispatch('getProjects'))
  },
  deleteProject({ dispatch, rootGetters }: Context, id: number): Promise<void> {
    return axios
      .delete(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/git/repositories/${id}/?git_hosting=Gitlab`
      )
      .then(path(['data']))
      .then(() => dispatch('getProjects'))
  },
  getProjectsByToken({ rootGetters }: Context, id: number): Promise<void> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/gitlab/tokens/${id}/add_repositories/`
      )
      .then(path(['data']))
  },
  addProjects(
    { dispatch, rootGetters }: Context,
    payload: { token: string; projects: GitlabProject[] }
  ): Promise<GitlabProject[]> {
    return axios
      .post(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/gitlab/tokens/${payload.token}/add_repositories/`,
        payload.projects
      )
      .then((data) => {
        dispatch('getProjects')
        return data.data
      })
  },
  getFirstPageOfUsers(
    { rootGetters }: Context,
    params: { ordering: string | null; search: string }
  ): Promise<void> {
    return axios
      .get(
        `/api/companies/${
          rootGetters['company/selectedCompanyId']
        }/gitlab/users/?limit=50&ordering=${params.ordering}&unmapped=false${
          params.search ? `&search=${params.search}` : ''
        }`
      )
      .then(path(['data']))
  },
  getNextPageOfUsers(_: Context, url: string): Promise<void> {
    return axios.get(`${url}`).then(path(['data']))
  },
  editUser(
    { rootGetters }: Context,
    user: { original_name: number; id: number }
  ): Promise<void> {
    return axios
      .patch(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/gitlab/users/${user.id}/`,
        omit(['id'], user)
      )
      .then(path(['data']))
  },
  getFirstPageOfCommits(
    { rootGetters }: Context,
    params: { params: CommitsFilter; signal: AbortSignal }
  ): Promise<void> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/git/commits/?limit=${LIMIT_OF_COMMITS}&git_hosting=Gitlab`,
        params
      )
      .then(path(['data']))
  },
  getNextPageOfCommits(_: Context, url: string): Promise<void> {
    return axios.get(`${url}`).then(path(['data']))
  },
  editCommit(
    { rootGetters }: Context,
    commit: { excluded: boolean; id: number }
  ): Promise<void> {
    return axios
      .patch(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/git/commits/${commit.id}/?git_hosting=Gitlab`,
        omit(['id'], commit)
      )
      .then(path(['data']))
  },
  getGlobalExclusionRules({ commit, rootGetters }: Context): Promise<void> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/git/commit-exclusion-rules/`
      )
      .then((data) => commit('setGlobalExclusionRules', data.data))
  },
  addGlobalExclusionRules(
    { rootGetters }: Context,
    rules: GlobalExclusionRules
  ): Promise<void> {
    return axios
      .post(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/git/commit-exclusion-rules/`,
        rules
      )
      .then(path(['data']))
  },
  editGlobalExclusionRules(
    { rootGetters }: Context,
    rules: GlobalExclusionRules
  ): Promise<void> {
    return axios
      .put(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/git/commit-exclusion-rules/`,
        rules
      )
      .then(path(['data']))
  },
  deleteGlobalExclusionRules({ commit, rootGetters }: Context): Promise<void> {
    return axios
      .delete(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/git/commit-exclusion-rules/`
      )
      .then(() => commit('setGlobalExclusionRules', null))
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}

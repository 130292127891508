<template>
  <div class="flex flex-col flex-grow justify-between">
    <div class="flex-grow flex flex-col">
      <div class="title">Connect Gitlab projects</div>
      <div class="flex-grow flex" v-if="loading">
        <LoaderWrapper></LoaderWrapper>
      </div>
      <div v-else-if="!isGitlabConnected" class="bg-gray-50 p-5 flex-grow">
        <GitlabConnectionFlow></GitlabConnectionFlow>
      </div>
      <div v-else>
        <FormRow title="Token">
          <BaseDropdown
            v-model:selected="selectedToken"
            :options="tokens"
            @change="getProjectsByToken"
          ></BaseDropdown>
        </FormRow>
        <div
          v-if="!selectedToken"
          class="mt-5 flex flex-col flex-grow h-full justify-center items-center"
        >
          <span class="text-lg">
            Please select token to see the projects…
          </span>
        </div>
        <template v-else>
          <div v-if="!loading" class="flex justify-end items-center mt-5">
            <TableSearchInput
              v-model:value="search"
              placeholder="Search by project"
            ></TableSearchInput>
          </div>
          <MyTable
            :table-data="projectsByToken"
            :columns-data="columnsData"
            scrollable
            customHeight="30vh"
            v-model:selection="selectedProjects"
            selection-mode="multiple"
            :loading="lazyLoading"
            :filters="filters"
            :globalFilterFields="filterFields"
          >
          </MyTable>
        </template>
      </div>
    </div>
    <div class="flex justify-end py-8 gap-5">
      <Button
        size="large"
        class="mr-5 p-button-text"
        @click="$emit('next-step')"
      >
        Skip this step
      </Button>
      <IconButton
        icon="pi-arrow-right"
        icon-position="right"
        icon-size="20px"
        size="large"
        class="next"
        :disabled="!isGitlabConnected || !selectedProjects.length"
        @click="onAddProjects"
        >Save and Next
      </IconButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import IconButton from '@/components/common/buttons/IconButton.vue'
import Button from '@/components/common/buttons/Button.vue'
import GitlabConnectionFlow from '@/components/admin-dashboard/gitlab/GitlabConnectionFlow.vue'
import { computed, onMounted, ref, defineEmits, defineProps } from 'vue'
import { showGitlabProjectsErrorToasts, showToastError } from '@/utils/utils'
import { useStore } from '@/store'
import { useToast } from 'primevue/usetoast'
import { isArray, isNotEmpty } from 'ramda-adjunct'
import { GitlabProject } from '@/store/modules/admin/gitlab'
import LoaderWrapper from '@/components/common/loader/LoaderWrapper.vue'
import FormRow from '@/components/common/form/FormRow.vue'
import BaseDropdown from '@/components/common/base/BaseDropdown.vue'
import TableSearchInput from '@/components/common/table/TableSearchInput.vue'
import useTableSearch from '@/utils/hooks/useTableSearch'
import MyTable from '@/components/common/table/MyTable.vue'

const props = defineProps<{ project_id: number | null }>()
const emit = defineEmits<{ (e: 'next-step'): void }>()
const loading = ref(false)
const lazyLoading = ref(false)
const store = useStore()
const toast = useToast()
const selectedProjects = ref<GitlabProject[]>([])
const selectedToken = ref(null)
const projectsByToken = ref([])

const tokens = computed(() => store.state.gitlab.tokens)
const isGitlabConnected = computed(() => isNotEmpty(store.state.gitlab.tokens))
const filterFields = ['name']
const { search, filters } = useTableSearch()

const columnsData = [
  {
    header: 'Project name',
    field: 'name',
    is_sortable: true,
  },
]
onMounted(() => {
  if (!isGitlabConnected.value) {
    loading.value = true
    store
      .dispatch('gitlab/getTokens')
      .catch((e) => {
        showToastError(toast, e)
      })
      .finally(() => (loading.value = false))
  }
})

async function getProjectsByToken() {
  lazyLoading.value = true
  try {
    const allProjects = await store.dispatch(
      'gitlab/getProjectsByToken',
      selectedToken.value
    )
    projectsByToken.value = allProjects.filter(
      (project: GitlabProject) => !project.id
    )
    lazyLoading.value = false
  } catch (e) {
    lazyLoading.value = false
    showToastError(toast, e)
  }
}

async function onAddProjects() {
  try {
    const data = await store.dispatch('gitlab/addProjects', {
      token: selectedToken.value,
      projects: selectedProjects.value.map((project: GitlabProject) => ({
        ...project,
        original_project: props.project_id,
      })),
    })
    if (isArray(data) && data.some((error) => error.message)) {
      showGitlabProjectsErrorToasts(toast, data)
      if (data.length !== selectedProjects.value.length) {
        emit('next-step')
      }
    } else {
      selectedProjects.value = []
      selectedToken.value = null
      emit('next-step')
    }
  } catch (e) {
    showToastError(toast, e)
  }
}
</script>

<style scoped>
.title {
  @apply text-xl font-semi-bold mb-3;
}
</style>

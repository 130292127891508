<template>
  <div class="height flex" v-if="loading">
    <LoaderWrapper></LoaderWrapper>
  </div>
  <div v-else-if="areTokenAdded">
    <Message v-if="showTeamWarning" severity="warn" class="mb-5"
      >Please go to the tempo settings and select a default tempo team that will
      be the used in case there is no project specific team or multiple team are
      assigned to a project. This team should contain all users. To verify team
      members, please check the Tempo Jira application.</Message
    >
    <TempoProjectsTable></TempoProjectsTable>
  </div>
  <div v-else class="flow-wrapper">
    <TempoConnectionFlow class="w-1/2" with-title></TempoConnectionFlow>
  </div>
</template>

<script setup lang="ts">
import TempoProjectsTable from '@/components/admin-dashboard/tempo/projects/TempoProjectsTable.vue'
import LoaderWrapper from '@/components/common/loader/LoaderWrapper.vue'
import TempoConnectionFlow from '@/components/admin-dashboard/tempo/TempoConnectionFlow.vue'
import Message from '@/components/common/Message.vue'
import { useStore } from '@/store'
import { useToast } from 'primevue/usetoast'
import { computed, onMounted, ref, watchEffect } from 'vue'
import { showToastError } from '@/utils/utils'
import { TempoProject } from '@/store/modules/admin/tempo'

const store = useStore()
const toast = useToast()
const areTokenAdded = ref(false)
const loading = ref(false)
const showTeamWarning = ref(false)
const defaultTeam = computed(() => store.getters['tempo/defaultTeam'])
const projects = computed(() => store.state.tempo.projects)

onMounted(() => {
  loading.value = true
  store
    .dispatch('tempo/getToken')
    .catch((e) => {
      showToastError(toast, e)
    })
    .finally(() => (loading.value = false))
})

watchEffect(() => {
  areTokenAdded.value = !!store.state.tempo.token
  showTeamWarning.value =
    !defaultTeam.value &&
    projects.value?.filter(
      (project: TempoProject) => project.teams.length > 1 || !project.teams[0]
    ).length
})
</script>

<style scoped>
.flow-wrapper {
  @apply bg-gray-50 p-8;
}
.height {
  height: 450px;
}
</style>

import { FilterMatchMode } from 'primevue/api'
import { ref, watch } from 'vue'

export default function useTableSearch(
  initialValue = '',
  callback?: any
): {
  search: any
  filters: any
} {
  const tableSearch = ref(initialValue)
  const filters = ref({
    global: { value: initialValue, matchMode: FilterMatchMode.CONTAINS },
  })

  watch(
    [tableSearch],
    callback
      ? callback
      : () => {
          filters.value.global.value = tableSearch.value || ''
        }
  )

  return { search: tableSearch, filters }
}

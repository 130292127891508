<template>
  <ChartWrapper
    :title="chartTitle[chartTypes.EstimationAccuracy]"
    :loading="loading"
    :fetch-error="fetchError"
    :tooltip="chartTooltip"
    :is-chart-data-empty="isEmpty(chartData)"
    :type="chartTypes.EstimationAccuracy"
    :icons="['jira', 'tempo']"
  >
    <template #buttons>
      <SortingMenu
        v-if="sortingOptions.length"
        :sorting-options="sortingOptions"
        @change-sorting="sorting = $event"
      ></SortingMenu>
    </template>
    <BasicChart
      :data="estimationAccuracyData"
      :options="estimationAccuracyOptions"
      type="bar"
      :chart-name="chartTypes.EstimationAccuracy"
      @dot-data="saveDrilldownData"
    ></BasicChart>
    <EstimationAccuracyDrilldown
      :open="!!drilldownData"
      :drilldown-data="drilldownData"
      :filters="filters"
      :aggregation="aggregation"
      @close="drilldownData = null"
    ></EstimationAccuracyDrilldown>
  </ChartWrapper>
</template>

<script setup lang="ts">
import ChartWrapper from '@/components/charts/ChartWrapper.vue'
import SortingMenu from '@/components/common/menu/SortingMenu.vue'
import BasicChart from '@/components/charts/BasicChart.vue'
import { Filters } from '@/store/modules/filters'
import { computed, defineProps, ref, withDefaults } from 'vue'
import {
  chartExplanations,
  chartTitle,
  chartTypes,
} from '@/constants/charts/constants'
import { isEmpty, omit, path, sortBy } from 'ramda'
import { AGGREGATE_BY, usedColors } from '@/constants/constants'
import { ActiveElement, ChartEvent, TooltipItem } from 'chart.js'
import { EstimationAccuracy } from '@/store/modules/charts/estimation-accuracy'
import useGettingChartData from '@/utils/hooks/useGettingChartData'
import EstimationAccuracyDrilldown from '@/components/charts/estimation-accuracy/EstimationAccuracyDrilldown.vue'
import { isNotNil } from 'ramda-adjunct'
import { toggleCursorOnChartDataHover } from '@/utils/chart-utils'
import { Aggregate_By_Type } from '@/types/types'

const props = withDefaults(
  defineProps<{
    filters: Filters
    projectPage?: boolean
    aggregation?: Aggregate_By_Type
  }>(),
  { aggregation: AGGREGATE_BY.USERS }
)
const sorting = ref('')
const drilldownData = ref(null)

const isAggregationByProjects = computed(
  () => props.aggregation === AGGREGATE_BY.PROJECTS
)

const chartTooltip = computed(() =>
  isAggregationByProjects.value
    ? chartExplanations[chartTypes.EstimationAccuracy + 'PerProject']
    : chartExplanations[chartTypes.EstimationAccuracy]
)

const sortingOptions = [
  isAggregationByProjects.value
    ? { text: 'Project', value: 'project.name' }
    : { text: 'Author', value: 'user.name' },
  { text: 'Overall factor', value: 'overall_factor' },
  { text: 'Underestimation factor', value: 'underestimation_factor' },
  { text: 'Overestimation factor', value: 'overestimation_factor' },
]

const { response, loading, fetchError } = useGettingChartData(
  {
    ...props,
    filters: {
      ...omit(['scale_type'], props.filters),
      aggregate_by: isAggregationByProjects.value
        ? AGGREGATE_BY.PROJECTS
        : AGGREGATE_BY.USERS,
    },
  },
  chartTypes.EstimationAccuracy
)

const chartData = computed(() => response.value)

const sortedLabels = computed(() => {
  let sorted = sortBy(
    path(sorting.value.split('.')) as any,
    chartData.value || []
  )
  return sorted.map((data: EstimationAccuracy) =>
    isAggregationByProjects.value ? data.project.name : data.user.name
  )
})

const yKey = computed(
  () => `${isAggregationByProjects.value ? 'project.name' : 'user.name'}`
)

const estimationAccuracyData = computed(() => ({
  labels: sortedLabels.value,
  datasets: [
    {
      label: 'Total count',
      key: 'total_ticket_count',
      data: chartData.value,
      backgroundColor: 'white',
      order: 2,
      datalabels: { display: false },
    },
    {
      label: 'Total estimated time',
      key: 'total_estimated_time',
      data: chartData.value,
      backgroundColor: 'white',
      order: 2,
      datalabels: { display: false },
    },
    {
      label: 'Total spent time',
      key: 'total_spent_time',
      data: chartData.value,
      backgroundColor: 'white',
      order: 2,
      datalabels: { display: false },
    },
    {
      type: 'bar',
      label: 'Overestimation',
      key: 'overestimation_factor',
      data: chartData.value,
      parsing: {
        yAxisKey: yKey.value,
        xAxisKey: 'overestimation_factor',
      },
      categoryPercentage: 0.1,
      barPercentage: 30,
      backgroundColor: '#97A7F2',
      borderRadius: 4,
      stack: 'relative',
      order: 2,
      datalabels: { display: false },
    },
    {
      type: 'bar',
      label: 'Underestimation',
      key: 'underestimation_factor',
      data: chartData.value,
      parsing: {
        yAxisKey: yKey.value,
        xAxisKey: 'underestimation_factor',
      },
      categoryPercentage: 0.1,
      barPercentage: 30,
      backgroundColor: usedColors['danger-400'],
      borderRadius: 4,
      stack: 'relative',
      order: 2,
      datalabels: { display: false },
    },
    {
      type: 'bubble',
      label: 'Overall factor',
      key: 'overall_factor',
      data: chartData.value,
      parsing: {
        yAxisKey: yKey.value,
        xAxisKey: 'overall_factor',
      },
      backgroundColor: chartData.value?.map((item: EstimationAccuracy) =>
        item.overall_factor + 1 > 1 ? '#CB515B' : '#5063D5'
      ),
      radius: 5,
      showLine: false,
      order: 1,
    },
  ],
}))

const estimationAccuracyOptions = computed(() => ({
  responsive: true,
  maintainAspectRatio: false,
  onHover: (e: ChartEvent, chartElement: ActiveElement[]): void =>
    toggleCursorOnChartDataHover(e, chartElement[0]),
  scales: {
    x: {
      position: 'top',
      ticks: {
        stepSize: 1,
        callback: (val: number): number => val + 1,
      },
    },
  },
  indexAxis: 'y',
  interaction: {
    mode: 'index',
  },
  plugins: {
    datalabels: {
      enabled: true,
      align: 'right',
      formatter: (value: EstimationAccuracy): string => {
        return (value.overall_factor + 1).toFixed(2)
      },
    },
    legend: {
      display: false,
    },
    tooltip: {
      mode: 'index',
      callbacks: {
        label: ({ dataset, dataIndex }: TooltipItem<'bar'>): string => {
          let label = dataset.label || ''
          const currentDataset = dataset.data[dataIndex]
          if (label) {
            if (label.includes('Overall factor')) {
              label += `: ${(currentDataset[dataset.key] + 1).toFixed(2)}`
            } else if (label.includes('estimation')) {
              const firstPartOfKey = dataset.key.split('_')[0]
              if (isNotNil(currentDataset[dataset.key])) {
                const keyCount = `${firstPartOfKey}_count`
                const keySeconds = `${firstPartOfKey}_seconds`
                label += `: ${(currentDataset[dataset.key] + 1).toFixed(2)} (${
                  currentDataset[keyCount]
                } tickets / ${currentDataset[keySeconds]}h)`
              } else {
                label = ''
              }
            } else if (label.includes('time')) {
              label += `: ${currentDataset[dataset.key]}h`
            } else {
              label += `: ${currentDataset[dataset.key]}`
            }
          }
          return label
        },
        title: (context: TooltipItem<'bar'>[]): string => {
          return `${context[4].label}`
        },
      },
    },
  },
}))

const saveDrilldownData = (firstPoint: any) => {
  const dot = firstPoint.element.$context.raw
  drilldownData.value = isAggregationByProjects.value ? dot.project : dot.user
}
</script>

<template>
  <div v-if="globalLoading" class="h-full flex justify-center items-center">
    <Loader></Loader>
  </div>
  <component :is="$route.meta.layout || 'div'" class="w-full h-full flex">
    <router-view />
  </component>
</template>

<script setup lang="ts">
import { useStore } from '@/store'
import { computed } from 'vue'
import Loader from '@/components/common/loader/Loader.vue'

const store = useStore()

const globalLoading = computed(() => store.state.globalLoading.globalLoading)
</script>

<style lang="scss">
html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

#app {
  font-family: 'Work Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
  height: 100%;
  //background: #f5f5f5;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>

<template>
  <TableWrapper :title="tableTitle">
    <template #button>
      <div class="flex gap-2">
        <TableSearchInput
          v-model:value="search"
          placeholder="Search by project"
        ></TableSearchInput>
        <IconButton
          class="p-button-outlined"
          size="small"
          icon="pi-plus"
          icon-position="left"
          icon-size="12px"
          @click="showCreateDialog = true"
          >Add</IconButton
        >
        <Button
          class="p-button-outlined"
          size="small"
          @click="showTokensDialog = true"
        >
          Manage token
          <Badge
            v-if="tempoTokenIsInvalid"
            class="p-badge p-badge-danger ml-1 absolute -top-1.5 -right-1.5"
          ></Badge>
        </Button>
        <IconButton
          size="small"
          icon="pi-cog"
          icon-position="left"
          icon-size="12px"
          class="p-button-outlined"
          @click="showTempoSettings = true"
          >Tempo Settings</IconButton
        >
      </div>
    </template>
    <template #default>
      <LoaderWrapper class="loader-height" v-if="loading"></LoaderWrapper>
      <MyTable
        v-else
        :table-data="tempoProjects"
        :columns-data="columnsData"
        :scrollable="scrollableTable"
        custom-height="calc(100vh - 260px)"
        :filters="filters"
        :filter-fields="filterFields"
      >
        <template #teams="{ data }">
          <span class="truncate" v-if="defaultTeam && !data.teams[0]">
            {{ defaultTeam.name }} (Default)
          </span>
          <span
            v-if="data.teams.length > 1"
            class="truncate text-danger-600 font-bold cursor-default"
            v-tooltip.bottom="{
              value: `${data.teams.join(', ')} \n ${
                defaultTeam
                  ? defaultTeam.name + ' (Default)'
                  : 'None of the teams'
              } is used in the project`,
              class: 'max-width-tooltip',
            }"
          >
            {{ data.teams.length }} teams
          </span>
          <span v-else class="truncate">{{ data.teams.join(', ') }} </span>
        </template>
        <template #status="{ data }">
          <ProjectStatusCell :status="data.status" />
        </template>
        <template #last_synced_at="{ data }">
          {{ calcDateFormat(data.last_synced_at) }}
        </template>
        <template #editable="{ data }">
          <IconButton
            size="small"
            class="p-button-text invisible group-hover:visible"
            @click="
              selectedProject = {
                id: data.id,
                name: data.jira_project,
              }
            "
            ><i class="pi pi-pencil" style="font-size: 12px"></i
          ></IconButton>
        </template>
      </MyTable>
      <TempoAddProjectDialog
        v-model:open="showCreateDialog"
      ></TempoAddProjectDialog>
      <TempoEditProjectDialog
        v-model:project="selectedProject"
      ></TempoEditProjectDialog>
      <TempoSettingsDialog
        v-model:open="showTempoSettings"
      ></TempoSettingsDialog>
      <EditTempoTokenDialog
        v-model:open="showTokensDialog"
      ></EditTempoTokenDialog>
    </template>
  </TableWrapper>
</template>

<script setup lang="ts">
import TableWrapper from '@/components/common/table/TableWrapper.vue'
import IconButton from '@/components/common/buttons/IconButton.vue'
import Button from '@/components/common/buttons/Button.vue'
import { State, useStore } from '@/store'
import { computed, onMounted, ref } from 'vue'
import MyTable from '@/components/common/table/MyTable.vue'
import { useToast } from 'primevue/usetoast'
import { showToastError } from '@/utils/utils'
import { calcDateFormat } from '@/utils/date-utils'
import { JiraProject } from '@/store/modules/admin/jira'
import TempoAddProjectDialog from '@/components/admin-dashboard/tempo/projects/TempoAddProjectDialog.vue'
import LoaderWrapper from '@/components/common/loader/LoaderWrapper.vue'
import TempoEditProjectDialog from '@/components/admin-dashboard/tempo/projects/TempoEditProjectDialog.vue'
import TableSearchInput from '@/components/common/table/TableSearchInput.vue'
import useTableSearch from '@/utils/hooks/useTableSearch'
import { Store } from 'vuex'
import TempoSettingsDialog from '@/components/admin-dashboard/tempo/projects/TempoSettingsDialog.vue'
import ProjectStatusCell from '@/components/common/table/ProjectStatusCell.vue'
import EditTempoTokenDialog from '@/components/admin-dashboard/tempo/EditTempoTokenDialog.vue'
import { TOKEN_STATUSES } from '@/constants/constants'
import Badge from 'primevue/badge'

const store: Store<State> = useStore()
const toast = useToast()
const showCreateDialog = ref(false)
const showTokensDialog = ref(false)
const selectedProject = ref<{ id: number; name: string } | null>(null)
const loading = ref(false)
const scrollableTable = ref(true)
const showTempoSettings = ref(false)
const filterFields = ['jira_project']
const { search, filters } = useTableSearch()

const tempoTokenIsInvalid = computed(
  () => store.state.tempo.token?.status === TOKEN_STATUSES.TOKEN_INVALID
)

onMounted(async () => {
  loading.value = true
  try {
    await store.dispatch('jira/getProjects')
    await store.dispatch('tempo/getProjects')
    loading.value = false
  } catch (e) {
    showToastError(toast, e)
    loading.value = false
  }
})

const tempoProjects = computed(() =>
  store.state.tempo.projects.map((project) => ({
    ...project,
    jira_project: calcNameOfJiraProject(project.jira_project),
  }))
)
const jiraProjects = computed(() => store.state.jira.projects)
const defaultTeam = computed(() => store.getters['tempo/defaultTeam'])
const tableTitle = computed(() => `Projects • ${tempoProjects.value.length}`)
const columnsData = [
  {
    header: 'Jira project name',
    field: 'jira_project',
    is_sortable: true,
    styles: 'flex-basis: 250px; padding-right: 10px',
  },
  {
    header: 'Team',
    field: 'teams',
    is_sortable: false,
    use_template: true,
    styles: 'flex-basis: 250px; padding-right: 10px',
  },
  {
    header: 'Status',
    field: 'status',
    is_sortable: true,
    use_template: true,
    styles: 'flex-basis: 70px; padding-right: 10px',
  },
  {
    header: 'Last synced',
    field: 'last_synced_at',
    use_template: true,
    is_sortable: true,
    styles: 'flex-basis: 100px; padding-right: 10px; min-width: 120px',
  },
  {
    header: '',
    field: 'editable',
    is_sortable: false,
    use_template: true,
    styles: 'flex-grow:0; flex-basis:30px',
  },
]

function calcNameOfJiraProject(jiraProjectId: number) {
  return jiraProjects.value.find(({ id }: JiraProject) => id === jiraProjectId)
    ?.name
}
</script>

<style scoped>
.loader-height {
  height: calc(100vh - 260px);
}
</style>

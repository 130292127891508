<template>
  <div class="w-full">
    <Textarea
      class="w-full"
      :class="{ 'p-invalid': errors.length }"
      v-model="textareaValue"
      :rows="rows"
      :cols="cols"
      v-bind="$attrs"
    ></Textarea>
    <div
      v-for="error of errors"
      class="text-left text-sm text-danger-600"
      :key="error.$uid"
    >
      {{ error.$message }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, defineEmits, defineProps, withDefaults } from 'vue'
import Textarea from 'primevue/textarea/Textarea.vue'

const props = withDefaults(
  defineProps<{
    value: null | string
    errors?: any
    rows?: string
    cols?: string
  }>(),
  { errors: [], rows: '5', cols: '30' }
)

const emit = defineEmits<{
  (e: 'update:value', value: null | string): void
}>()

const textareaValue = computed({
  get() {
    return props.value
  },
  set(value: null | string) {
    emit('update:value', value ? value : null)
  },
})
</script>

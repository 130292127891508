<template>
  <div class="flex flex-col flex-grow justify-between">
    <div class="flex-grow flex flex-col">
      <div class="title">Connect Tempo project</div>
      <div class="flex-grow flex" v-if="loading">
        <LoaderWrapper></LoaderWrapper>
      </div>
      <div v-else-if="!isTempoConnected" class="bg-gray-50 p-5 flex-grow">
        <TempoConnectionFlow></TempoConnectionFlow>
      </div>
      <div v-else>
        <TempoAddProjectsTemplate
          v-model:jiraProject="formData.jira_project"
          v-model:token="formData.token"
          :validation="v$"
        ></TempoAddProjectsTemplate>
      </div>
    </div>
    <div class="flex justify-end py-8 gap-5">
      <Button size="large" class="mr-5 p-button-text" @click="$emit('close')">
        Skip 4 and 5 steps
      </Button>
      <Button
        class="save"
        size="large"
        :disabled="!isTempoConnected"
        @click="onAddProject"
        >Save and Next</Button
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import LoaderWrapper from '@/components/common/loader/LoaderWrapper.vue'
import TempoConnectionFlow from '@/components/admin-dashboard/tempo/TempoConnectionFlow.vue'
import TempoAddProjectsTemplate from '@/components/admin-dashboard/tempo/projects/TempoAddProjectsTemplate.vue'
import Button from '@/components/common/buttons/Button.vue'
import { computed, defineEmits, onMounted, ref } from 'vue'
import { showToastError } from '@/utils/utils'
import { useStore } from '@/store'
import { useToast } from 'primevue/usetoast'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { TempoProject } from '@/store/modules/admin/tempo'

const emit = defineEmits<{
  (e: 'next-step', value: number | null): void
  (e: 'close'): void
}>()
const loading = ref(false)
const store = useStore()
const toast = useToast()
const addedProject = ref<TempoProject | null>(null)

const initialForm = () => ({ token: null, jira_project: null })

const formData = ref(initialForm())
const formRules = {
  jira_project: { required, $lazy: true },
  token: { required, $lazy: true },
}
const v$ = useVuelidate(formRules, formData)

const isTempoConnected = computed(() => !!store.state.tempo.token)

onMounted(() => {
  if (!isTempoConnected.value) {
    loading.value = true
    store
      .dispatch('tempo/getToken')
      .catch((e) => {
        showToastError(toast, e)
      })
      .finally(() => (loading.value = false))
  }
})

async function onAddProject() {
  v$.value.$touch()
  if (v$.value.$invalid) {
    return
  }
  try {
    addedProject.value = await store.dispatch(
      'tempo/addProject',
      formData.value
    )
    formData.value = initialForm()
    v$.value.$reset()
    emit('next-step', addedProject.value && addedProject.value?.id)
  } catch (e) {
    showToastError(toast, e)
  }
}
</script>

<style scoped>
.title {
  @apply text-xl font-semi-bold mb-3;
}
</style>

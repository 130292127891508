<template>
  <Dialog
    :visible="open"
    :dismissableMask="true"
    @update:visible="closeDialog"
    position="bottom"
    modal
  >
    <template #header>
      <div class="header-wrapper">Gitlab • Tokens</div>
    </template>
    <div class="title">New token</div>
    <TokenForm
      v-model:name="formData.name"
      v-model:token="formData.token"
      v-model:base_url="formData.base_url"
      :v$="v$"
    ></TokenForm>
    <template #footer>
      <Button class="p-button-outlined" size="large" @click="closeDialog">
        Cancel
      </Button>
      <Button size="large" @click="onAddToken">Create</Button>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import Dialog from 'primevue/dialog/Dialog.vue'
import { ref, defineEmits, defineProps } from 'vue'
import Button from '@/components/common/buttons/Button.vue'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { useToast } from 'primevue/usetoast'
import { useStore } from '@/store'
import TokenForm from '@/components/admin-dashboard/gitlab/tokens/TokenForm.vue'
import { showToastError } from '@/utils/utils'

const initialForm = () => ({
  name: '',
  token: '',
  base_url: 'https://gitlab.com',
})

defineProps<{ open: boolean }>()
const emit = defineEmits<{ (e: 'update:open', value: boolean): void }>()
const toast = useToast()
const store = useStore()
const formData = ref(initialForm())
const $externalResults = ref({})

const formRules = {
  name: { required, $lazy: true },
  token: { required, $autoDirty: true, $lazy: true },
  base_url: { required, $autoDirty: true, $lazy: true },
}

const v$ = useVuelidate(formRules, formData, { $externalResults })

async function onAddToken() {
  v$.value.$touch()
  if (v$.value.$invalid) {
    return
  }
  try {
    await store.dispatch('gitlab/addToken', formData.value)
    closeDialog()
  } catch (e: any) {
    if (e.response?.data?.token || e.response?.data?.base_url) {
      $externalResults.value = e.response.data
    } else {
      showToastError(toast, e)
    }
  }
}

function closeDialog() {
  formData.value = initialForm()
  v$.value.$reset()
  emit('update:open', false)
}
</script>

<style scoped>
.header-wrapper {
  @apply flex items-center justify-between w-full capitalize;
}
.title {
  @apply text-xl font-semi-bold mb-3;
}
</style>

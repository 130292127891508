<template>
  <Dialog
    :visible="open"
    :style="{ width: '90vw' }"
    :dismissableMask="true"
    @update:visible="onCloseDialog"
    position="bottom"
    modal
  >
    <template #header>
      <div class="header-wrapper">
        {{ burndownDetails?.label }}
        <TableSearchInput
          :value="search"
          @update:value="search = $event || null"
          placeholder="Search by ticket..."
        ></TableSearchInput>
      </div>
    </template>
    <div class="text-xl font-semi-bold mb-4">{{ title }}</div>
    <div class="height flex mb-8" v-if="loading">
      <LoaderWrapper></LoaderWrapper>
    </div>
    <MyTable
      v-else
      :table-data="dotData"
      :columns-data="columnsData"
      customHeight="70vh"
      :scrollable="scrollableTable"
      class="mb-8"
      lazy-table
      :lazy-loading="lazyLoading"
      @load="onLazyLoad"
      @sort="onSort"
    >
      <template #issue_key="{ data }">
        <Link :href="data.absolute_url" style-class="truncate">
          {{ `${data.issue_key}: ${data.issue_name}` }}
        </Link>
      </template>
      <template #value="{ data }">
        {{ getEstimationShortReadableString(data.value, estimationType) }}
      </template>
      <template #date="{ data }">
        {{ getReadableDate(data.date ? data.date : data.changed_at) }}
      </template>
      <template #status="{ data }">
        <TicketStatusChip :status="data.status" />
      </template>
    </MyTable>
  </Dialog>
</template>

<script setup lang="ts">
import Dialog from 'primevue/dialog/Dialog.vue'
import { computed, defineEmits, defineProps, ref, watch } from 'vue'
import LoaderWrapper from '@/components/common/loader/LoaderWrapper.vue'
import MyTable from '@/components/common/table/MyTable.vue'
import { useStore } from '@/store'
import {
  calcSortOrderingAndField,
  getEstimationShortReadableString,
  showToastError,
} from '@/utils/utils'
import { useToast } from 'primevue/usetoast'
import { Dictionary, equals, omit, reject } from 'ramda'
import { format } from 'date-fns'
import { DATE_FORMATS } from '@/utils/date-utils'
import Link from '@/components/common/Link.vue'
import { debounce } from 'lodash'
import TableSearchInput from '@/components/common/table/TableSearchInput.vue'
import { ESTIMATION_TYPE } from '@/constants/constants'
import TicketStatusChip from '@/components/common/TicketStatusChip.vue'

const props = defineProps<{
  open: boolean
  burndownDetails: {
    filter_date: string
    date?: string
    week?: string
    month?: string
    year: string
    label: string
    line_type: string
    scale_type: string
    project_id: string
  } | null
  estimationType: ESTIMATION_TYPE
  sprints: number[] | null
}>()

const emit = defineEmits<{ (e: 'close'): void }>()

const dotData = ref([])
const estimationType = ref<ESTIMATION_TYPE | null>(ESTIMATION_TYPE.STORY_POINTS)
const nextPage = ref(null)
const lazyLoading = ref(false)
const loading = ref(false)
const sortField = ref<string | null>(null)
const search = ref(null)
const store = useStore()
const toast = useToast()

const scrollableTable = ref(true)
const details = computed(() => props.burndownDetails)
const isNotDateAggregation = computed(
  () => props.burndownDetails?.week || props.burndownDetails?.month
)

const debouncedGetRequest = debounce(getFirstPageOfDotDetails, 1000)

watch([sortField, search, details], () => {
  if (search.value) {
    debouncedGetRequest()
  } else {
    details.value && getFirstPageOfDotDetails()
  }
})

const title = computed(() => {
  if (!props.burndownDetails) return ''
  const { week, date, month, year } = props.burndownDetails
  let formatDate
  if (date) {
    formatDate = format(new Date(date), 'd MMMM yyyy')
  } else if (week) {
    formatDate = `week ${week} of ${year}`
  } else {
    formatDate = month && format(new Date(+year, +month - 1, 1), 'MMMM, yyyy')
  }
  return formatDate
})

const getReadableDate = (date: string): string => {
  return format(
    new Date(date),
    isNotDateAggregation.value
      ? DATE_FORMATS.DAY_MONTH_YEAR_HOUR_MINUTE
      : DATE_FORMATS.HOUR_MINUTE
  )
}

const columnsData = computed(() => [
  {
    header: 'Ticket',
    field: 'issue_key',
    is_sortable: true,
    use_template: true,
    styles: 'flex-grow: 2; flex-basis: 30%; padding-right: 10px',
  },
  {
    header:
      props.burndownDetails?.line_type === 'adaptive_spent'
        ? 'Time spent'
        : 'Original estimate',
    field: 'value',
    is_sortable: true,
    use_template: true,
  },
  {
    header: isNotDateAggregation.value ? 'Date' : 'Time',
    field: 'date',
    is_sortable: true,
    use_template: true,
  },
  {
    header: 'Status',
    field: 'status',
    is_sortable: true,
    use_template: true,
  },
])

/* istanbul ignore next */
async function onLazyLoad(event: { first: number; last: number }) {
  const { last } = event
  if (!last || lazyLoading.value) return
  try {
    if (nextPage.value && last === dotData.value.length) {
      lazyLoading.value = true
      const page = await store.dispatch(
        'burndown/getNextPageOfBurndownDetails',
        nextPage.value
      )
      nextPage.value = page.next
      dotData.value = dotData.value.concat(page.results.data)
    }
    lazyLoading.value = false
  } catch (e) {
    lazyLoading.value = false
    showToastError(toast, e)
  }
}

function onSort(event: Event) {
  sortField.value = calcSortOrderingAndField(event)
}

async function getFirstPageOfDotDetails() {
  const params = {
    ...omit(
      ['label', 'filter_date', 'month', 'year', 'week'],
      props.burndownDetails
    ),
    ordering: sortField.value,
    search: search.value,
    sprints: props.sprints,
  }
  const activeParams = {
    ...reject(equals(null))(params as Dictionary<any>),
  }
  if (activeParams.search || activeParams.ordering) {
    lazyLoading.value = true
  } else {
    loading.value = true
  }
  try {
    const page = await store.dispatch('burndown/getBurndownDotDetails', {
      ...activeParams,
      id: props.burndownDetails.project_id,
      date: props.burndownDetails?.filter_date,
    })
    nextPage.value = page?.next
    dotData.value = page?.results.data
    estimationType.value = page?.results.estimation_type
  } catch (e) {
    showToastError(toast, e)
  } finally {
    lazyLoading.value = false
    loading.value = false
  }
}

const onCloseDialog = () => {
  emit('close')
  search.value = null
  sortField.value = null
}
</script>

<style scoped>
.header-wrapper {
  @apply flex items-center justify-between w-full text-base;
}
.height {
  height: 70vh;
}
</style>

<template>
  <div class="flex text-base flex-col items-start justify-start">
    <div v-if="withTitle" class="title">Connect Jira…</div>
    <div class="subtitle">
      Add the app descriptor URL to your Jira workspace:
      <br />
      Settings → Apps → Manage app → Upload app
    </div>
    <BaseInputText
      v-model:value="url"
      class="input w-full mb-5"
      icon-right="pi-copy"
      disabled
      @icon-click="onCopyUrl"
    ></BaseInputText>
    <div class="text-left mb-3">
      Once installation complete, click <span class="font-bold">Refresh</span>
    </div>
    <Button @click="checkJiraConnection">Refresh</Button>
  </div>
</template>

<script setup lang="ts">
import Button from '@/components/common/buttons/Button.vue'
import { useStore } from '@/store'
import { useToast } from 'primevue/usetoast'
import { computed, defineProps, ref } from 'vue'
import { TOAST_LIFE_TIME } from '@/constants/constants'
import { showToastError } from '@/utils/utils'
import BaseInputText from '@/components/common/base/BaseInputText.vue'

defineProps<{ withTitle?: boolean }>()
const store = useStore()
const toast = useToast()
const url = ref(
  `${process.env.VUE_APP_BASE_URL}/api/companies/${store.getters['company/selectedCompanyId']}/jira/atlassian-connect/`
)
const isJiraConnected = computed(() => store.state.jira.isJiraConnected)

function checkJiraConnection() {
  store
    .dispatch('jira/checkJiraConnection')
    .then(() => {
      if (isJiraConnected.value) {
        toast.add({
          severity: 'success',
          detail: 'Your company is connected to Jira',
          life: TOAST_LIFE_TIME,
        })
      } else {
        showToastError(
          toast,
          'No Jira account found. Try uninstalling and installing Devflow again, then click Refresh.'
        )
      }
    })
    .catch(() => {
      showToastError(
        toast,
        'No Jira account found. Try uninstalling and installing Devflow again, then click Refresh.'
      )
    })
}

function onCopyUrl() {
  navigator.clipboard.writeText(url.value)
  toast.add({
    severity: 'success',
    detail: 'Link copied',
    life: TOAST_LIFE_TIME,
  })
}
</script>

<style scoped>
.title {
  @apply text-lg font-semi-bold mb-5;
}

.subtitle {
  @apply text-left mb-3;
}
.input >>> .p-inputtext {
  @apply bg-gray-100-60  text-gray-800;
}
</style>

import axios from './axios'
import { store } from '@/store'
import { getIdToken } from 'firebase/auth'

axios.interceptors.request.use(
  async (config) => {
    // auto token reloading after expiration in 1 hour
    if (store.state.user.currentUser) {
      config.headers = {
        'http-firebase-token': await getIdToken(store.state.user.currentUser),
      }
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

export default axios

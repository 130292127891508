<template>
  <div class="h-full base-container">
    <ProjectFiltersBar
      :filters="filters"
      :filtering-options="filterItems"
      :selected-project="selectedProject"
    >
    </ProjectFiltersBar>
    <main>
      <div class="flex flex-wrap px-8 mb-8">
        <div class="flex w-2/3">
          <MilestonesTable
            :filters="filters"
            project-page
            :key="milestoneChartKey"
          ></MilestonesTable>
          <Divider layout="vertical"></Divider>
        </div>
        <VelocityFactorTable
          :filters="filters"
          project-page
          :key="chartsKey"
          custom-height
        ></VelocityFactorTable>
        <Divider class="my-8" />
        <div class="flex w-1/2">
          <WorkloadChart
            :filters="filters"
            project-page
            :key="chartsKey"
          ></WorkloadChart>
          <Divider layout="vertical"></Divider>
        </div>
        <BillableTimeChart
          :filters="filters"
          project-page
          :key="chartsKey"
        ></BillableTimeChart>
        <Divider class="my-8" />
        <div :class="showEstimationAccuracyChart ? 'flex w-1/2' : 'w-full'">
          <EstimationCoverageChart
            :filters="filters"
            project-page
            :key="chartsKey"
          ></EstimationCoverageChart>
          <Divider
            layout="vertical"
            v-if="showEstimationAccuracyChart"
          ></Divider>
        </div>
        <EstimationAccuracyChart
          v-if="showEstimationAccuracyChart"
          :filters="filters"
          project-page
          :key="chartsKey"
        ></EstimationAccuracyChart>
        <Divider class="my-8" />
        <div class="flex w-1/2">
          <LOCChart :filters="filters" :key="chartsKey" project-page></LOCChart>
          <Divider layout="vertical"></Divider>
        </div>
        <CommentsRatesChart
          :filters="filters"
          project-page
          :key="chartsKey"
        ></CommentsRatesChart>
        <Divider class="my-8" />
        <div class="flex w-1/2">
          <UserActivityChart
            :filters="filters"
            project-page
            :key="chartsKey"
          ></UserActivityChart>
          <Divider layout="vertical"></Divider>
        </div>
        <ReopenedTicketsChart
          :filters="filters"
          project-page
          :key="chartsKey"
        ></ReopenedTicketsChart>
        <Divider class="my-8" />
        <div class="flex w-1/2">
          <ScopeVsDeliveredChart
            :filters="filters"
            project-page
            :key="chartsKey"
          ></ScopeVsDeliveredChart>
          <Divider layout="vertical"></Divider>
        </div>
        <BudgetPlannedVsUsedChart
          :filters="filters"
          project-page
          :key="chartsKey"
        ></BudgetPlannedVsUsedChart>
        <Divider class="my-8" />
        <div class="flex w-1/2" v-if="showVelocityFactorChart">
          <BurndownChart
            :filters="filters"
            project-page
            :key="chartsKey"
          ></BurndownChart>
          <Divider layout="vertical"></Divider>
        </div>
        <BugMetricsChart
          :filters="filters"
          project-page
          :key="chartsKey"
        ></BugMetricsChart>
        <Divider class="my-8" />
        <div class="flex w-full">
          <TicketsTable
            :filters="filters"
            project-page
            :key="chartsKey"
            :type="chartTypes.LargestTicketsByEstimation"
          ></TicketsTable>
        </div>
        <Divider class="my-8" />
        <div class="flex w-2/3">
          <Activities
            :filters="filters"
            project-page
            :key="chartsKey"
          ></Activities>
          <Divider layout="vertical"></Divider>
        </div>
        <VelocityFactor
          :filters="filters"
          project-page
          :key="chartsKey"
        ></VelocityFactor>
        <Divider class="my-8" />
        <div class="flex w-full">
          <StuckIssueTable :filters="filters" project-page :key="chartsKey">
          </StuckIssueTable>
        </div>
      </div>
    </main>
  </div>
</template>

<script setup lang="ts">
import ProjectFiltersBar from '@/components/common/menu/ProjectFiltersBar.vue'
import { useStore } from '@/store'
import { computed, ref, watchEffect } from 'vue'
import VelocityFactor from '@/components/charts/velocity-factor/VelocityFactor.vue'
import TicketsTable from '@/components/charts/largest-tickets-by-estimation/TicketsTable.vue'
import { ESTIMATION_TYPE } from '@/constants/constants'
import { Project } from '@/store/modules/admin/admin'
import { omit } from 'ramda'
import WorkloadChart from '@/components/charts/workload/WorkloadChart.vue'
import BillableTimeChart from '@/components/charts/billable-time/BillableTimeChart.vue'
import EstimationCoverageChart from '@/components/charts/estimation-coverage/EstimationCoverageChart.vue'
import EstimationAccuracyChart from '@/components/charts/estimation-accuracy/EstimationAccuracyChart.vue'
import ReopenedTicketsChart from '@/components/charts/reopened-tickets/ReopenedTicketsChart.vue'
import ScopeVsDeliveredChart from '@/components/charts/scope-vs-delivered/ScopeVsDeliveredChart.vue'
import BudgetPlannedVsUsedChart from '@/components/charts/budget-planned-vs-used/BudgetPlannedVsUsedChart.vue'
import StuckIssueTable from '@/components/charts/stuck-issues/StuckIssueTable.vue'
import Activities from '@/components/charts/user-activity/Activities.vue'
import BugMetricsChart from '@/components/charts/bug-metrics/BugMetricsChart.vue'
import LOCChart from '@/components/charts/loc/LOCChart.vue'
import CommentsRatesChart from '@/components/charts/comments-rates/CommentsRatesChart.vue'
import UserActivityChart from '@/components/charts/user-activity/UserActivityChart.vue'
import VelocityFactorTable from '@/components/charts/velocity-factor/VelocityFactorTable.vue'
import MilestonesTable from '@/components/charts/milestones/MilestonesTable.vue'
import { chartTypes } from '@/constants/charts/constants'
import BurndownChart from '@/components/charts/burndown/BurndownChart.vue'

const store = useStore()
const filters = computed(() => store.state.filters.projectFilters)
const filterItems = computed(() => store.getters['filters/filterItems'])

const chartsKey = computed(() =>
  JSON.stringify(omit(['scale_type'], filters.value))
)

const milestoneChartKey = computed(() =>
  JSON.stringify(omit(['since', 'until'], filters.value))
)

const isStoryPointEstimation = ref(false)

watchEffect(async () => {
  if (filters.value?.projects?.length) {
    store
      .dispatch('admin/getRelatedProjects', filters.value.projects)
      .then(
        (data) =>
          (isStoryPointEstimation.value =
            data[0]?.estimation_type === ESTIMATION_TYPE.STORY_POINTS)
      )
  }
})

const selectedProject = computed(() => {
  return filterItems.value.projects.find((project: Project) => {
    return Array.isArray(filters.value?.projects)
      ? filters.value?.projects.includes(String(project.id))
      : filters.value?.projects === String(project.id)
  })
})

const showEstimationAccuracyChart = computed(
  () => !isStoryPointEstimation.value
)

const showVelocityFactorChart = computed(() => !isStoryPointEstimation.value)
</script>

<style lang="scss" scoped>
.base-container {
  max-height: 100vh;
  flex-grow: 1 !important;
  padding-bottom: 32px;
  overflow: auto;
}
</style>

<template>
  <div class="height flex" v-if="loading">
    <LoaderWrapper></LoaderWrapper>
  </div>
  <div v-else-if="areTokensAdded">
    <ProjectsTable></ProjectsTable>
    <Divider class="my-8"></Divider>
    <UsersTable></UsersTable>
    <Divider class="my-8"></Divider>
    <CommitsTable></CommitsTable>
    <Divider class="mt-8"></Divider>
  </div>
  <div v-else class="flow-wrapper">
    <GitlabConnectionFlow class="w-1/2" with-title></GitlabConnectionFlow>
  </div>
</template>

<script setup lang="ts">
import ProjectsTable from '@/components/admin-dashboard/gitlab/projects/ProjectsTable.vue'
import UsersTable from '@/components/admin-dashboard/gitlab/users/UsersTable.vue'
import CommitsTable from '@/components/admin-dashboard/gitlab/commits/CommitsTable.vue'
import { onMounted, ref, watchEffect } from 'vue'
import { showToastError } from '@/utils/utils'
import { useStore } from '@/store'
import { useToast } from 'primevue/usetoast'
import GitlabConnectionFlow from '@/components/admin-dashboard/gitlab/GitlabConnectionFlow.vue'
import LoaderWrapper from '@/components/common/loader/LoaderWrapper.vue'

const store = useStore()
const toast = useToast()
const areTokensAdded = ref(false)
const loading = ref(false)

onMounted(() => {
  loading.value = true
  store
    .dispatch('gitlab/getTokens')
    .catch((e) => {
      showToastError(toast, e)
    })
    .finally(() => (loading.value = false))
})

watchEffect(() => {
  areTokensAdded.value = !!store.state.gitlab.tokens.length
})
</script>

<style scoped>
.flow-wrapper {
  @apply bg-gray-50 p-8;
}
.height {
  height: 450px;
}
</style>

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.169 3.4989C16.7585 3.12944 16.1262 3.16272 15.7567 3.57323C15.3873 3.98374 15.4205 4.61603 15.831 4.98549L20.169 8.88963C20.3797 9.07927 20.5 9.34943 20.5 9.63292V18.4989C20.5 19.0512 20.9477 19.4989 21.5 19.4989C22.0523 19.4989 22.5 19.0512 22.5 18.4989V9.18756C22.5 8.62058 22.2594 8.08025 21.8379 7.70097L17.169 3.4989ZM8.03521 4.05715L3.03521 8.39048L4.34507 9.90186L3.03521 8.39049C2.37772 8.96031 2 9.7875 2 10.6576V17.0009C2 18.6578 3.34315 20.0009 5 20.0009H15C16.6569 20.0009 18 18.6578 18 17.0009V10.6576C18 9.7875 17.6223 8.96031 16.9648 8.39048L11.9648 4.05715C10.8372 3.07991 9.1628 3.07991 8.03521 4.05715L9.34507 5.56853L8.03521 4.05715ZM9.34507 5.56853L4.34507 9.90186C4.12591 10.0918 4 10.3675 4 10.6576V17.0009C4 17.5532 4.44772 18.0009 5 18.0009H15C15.5523 18.0009 16 17.5532 16 17.0009V10.6576C16 10.3675 15.8741 10.0918 15.6549 9.90186L10.6549 5.56853C10.2791 5.24278 9.72093 5.24278 9.34507 5.56853Z"
      fill="currentColor"
    />
  </svg>
</template>

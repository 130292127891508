<template>
  <ChartWrapper
    :title="chartTitle[chartTypes.StuckTickets]"
    :tooltip="chartExplanations[chartTypes.StuckTickets]"
    :fetch-error="fetchError"
    :loading="loading"
    :is-chart-data-empty="isEmpty(stuckTickets)"
    :type="chartTypes.StuckTickets"
    :icons="['jira']"
  >
    <template #buttons>
      <TableSearchInput
        v-model:value="search"
        placeholder="Search by ticket..."
      ></TableSearchInput>
    </template>
    <MyTable
      :table-data="stuckTickets"
      :columns-data="columnsData"
      :scrollable="scrollableTable"
      :lazy-table="true"
      :lazy-loading="lazyLoading"
      custom-height="300px"
      @load="onLazyLoad"
      @sort="onSort"
      @row-click="onRowClick"
    >
      <template #issue_key="{ data }">
        <Link :href="data.absolute_url" style-class="truncate">
          {{ data.issue_key }}: {{ data.issue_name }}
        </Link>
      </template>
      <template #total_stuck_seconds="{ data }">
        <div>
          {{ convertSecondsToReadableFormat(data.total_stuck_seconds) }}
        </div>
      </template>
      <template #status_name="{ data }">
        <TicketStatusChip :status="data.status_name" />
      </template>
    </MyTable>
    <StuckIssueDrilldown
      :open="!!clickedIssue"
      :issue-data="clickedIssue"
      :filters="filters"
      @close="clickedIssue = null"
    ></StuckIssueDrilldown>
  </ChartWrapper>
</template>

<script setup lang="ts">
import { computed, defineProps, ref, watch } from 'vue'
import { Filters } from '@/store/modules/filters'
import { Dictionary, equals, isEmpty, omit, reject } from 'ramda'
import MyTable from '@/components/common/table/MyTable.vue'
import Link from '@/components/common/Link.vue'
import {
  chartExplanations,
  chartTitle,
  chartTypes,
} from '@/constants/charts/constants'
import ChartWrapper from '@/components/charts/ChartWrapper.vue'
import { useStore } from '@/store'
import { isArray } from 'ramda-adjunct'
import { calcSortOrderingAndField } from '@/utils/utils'
import { debounce } from 'lodash'
import TableSearchInput from '@/components/common/table/TableSearchInput.vue'
import StuckIssueDrilldown from '@/components/charts/stuck-issues/StuckIssueDrilldown.vue'
import TicketStatusChip from '@/components/common/TicketStatusChip.vue'
import { convertSecondsToReadableFormat } from '../../../utils/date-utils'

const clickedIssue = ref(null)

const props = defineProps<{ projectPage: boolean; filters: Filters }>()
const scrollableTable = ref(true)
const store = useStore()
const fetchError = ref('')
const loading = ref(true)
const lazyLoading = ref(false)
const sortField = ref<string | null>(null)
const search = ref(null)
const nextPage = ref(null)
const stuckTickets = ref([])

const debouncedGetRequest = debounce(getFirstPageOfStuckTickets, 1000)

const companyId = computed(() => store.state.company.selectedCompanyId)
const activeFilters = computed(() => {
  const filters = {
    ...omit(['scale_type'], props.filters),
    ordering: sortField.value,
    search: search.value,
  }
  return reject(equals(null))(filters as Dictionary<any>)
})

async function getFirstPageOfStuckTickets() {
  fetchError.value = ''
  const projects = props.filters.projects
  const project_id = isArray(projects) ? projects[0] : projects
  const company_id = companyId.value
  if (activeFilters.value.ordering || activeFilters.value.search) {
    lazyLoading.value = true
  } else {
    loading.value = true
  }
  try {
    const response = await store.dispatch(
      'stuckTickets/getFirstPageOfStuckTickets',
      {
        filters: activeFilters.value,
        project_id,
        company_id,
      }
    )
    nextPage.value = response.next
    stuckTickets.value = response.results
  } catch (e) {
    if (e instanceof Error) {
      fetchError.value = e?.message
    }
  } finally {
    loading.value = false
    lazyLoading.value = false
  }
}

watch([activeFilters, search], async () => {
  if (props.projectPage && !activeFilters.value.projects) return
  if (
    store.getters['company/selectedCompanyId'] &&
    activeFilters.value.since &&
    activeFilters.value.until
  ) {
    if (search.value) {
      debouncedGetRequest()
    } else {
      await getFirstPageOfStuckTickets()
    }
  }
})

const columnsData = [
  {
    header: 'Ticket',
    field: 'issue_key',
    is_sortable: true,
    use_template: true,
    styles: 'flex-basis:600px; padding-right:10px',
    classes: 'sm:max-w-sm md:max-w-lg lg:max-w-xl xl:max-w-2xl 2xl:max-w-4xl',
  },
  {
    header: 'Time duration in the column',
    field: 'total_stuck_seconds',
    is_sortable: true,
    use_template: true,
    styles: 'min-width: 200px; padding-right: 10px',
  },
  {
    header: 'Status',
    field: 'status_name',
    is_sortable: true,
    use_template: true,
    styles: 'min-width: 180px; padding-right: 10px',
  },
]

/* istanbul ignore next */
async function onLazyLoad(event: { first: number; last: number }) {
  if (lazyLoading.value) return
  const { last } = event
  lazyLoading.value = true
  try {
    if (nextPage.value && last === stuckTickets.value.length) {
      const page = await store.dispatch(
        'stuckTickets/getNextPageOfStuckTickets',
        nextPage.value
      )
      nextPage.value = page.next
      stuckTickets.value = stuckTickets.value.concat(page.results)
    }
  } catch (e) {
    if (e instanceof Error) {
      fetchError.value = e?.message
    }
  } finally {
    lazyLoading.value = false
  }
}

function onSort(event: Event) {
  sortField.value = calcSortOrderingAndField(event)
}

function onRowClick(event: Event) {
  store.commit('stuckTickets/setDrilldownFilters', {
    since: props.filters.since,
    until: props.filters.until,
    issue: String(event.data.issue_id),
    status: String(event.data.status_id),
  })
  clickedIssue.value = event.data
}
</script>

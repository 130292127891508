<template>
  <Dialog
    :visible="open"
    :dismissableMask="true"
    @update:visible="closeDialog"
    position="bottom"
    modal
  >
    <template #header>
      <div class="header-wrapper">
        <div>User mapping</div>
      </div>
    </template>
    <div class="title">New user mapping</div>
    <UserMappingForm
      v-model:name="formData.name"
      v-model:email="formData.email"
      v-model:is_active="formData.is_active"
      v-model:position="formData.position"
      v-model:role="formData.role"
      v-model:projects="formData.projects"
      :v$="v$"
    ></UserMappingForm>
    <template #footer>
      <Button class="p-button-outlined" size="large" @click="closeDialog">
        Cancel
      </Button>
      <Button size="large" @click="onCreateUser">Create</Button>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import Dialog from 'primevue/dialog/Dialog.vue'
import { defineProps, ref, defineEmits } from 'vue'
import Button from '@/components/common/buttons/Button.vue'
import { required, email } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { useToast } from 'primevue/usetoast'
import { useStore } from '@/store'
import UserMappingForm from '@/components/admin-dashboard/system/user-mapping/UserMappingForm.vue'
import { showToastError } from '@/utils/utils'
const initialForm = () => ({
  name: '',
  email: '',
  position: '',
  role: '',
  projects: [],
  is_active: true,
})
defineProps<{ open: boolean }>()
const emit = defineEmits<{
  (e: 'update:open', value: boolean): void
  (e: 'save-user-id', value: number): void
}>()
const toast = useToast()
const store = useStore()
const formData = ref(initialForm())

const formRules = {
  name: { required, $lazy: true },
  email: { required, email, $lazy: true },
  position: { required, $lazy: true },
  role: { required, $lazy: true },
}

const v$ = useVuelidate(formRules, formData)

async function onCreateUser() {
  v$.value.$touch()
  if (v$.value.$invalid) {
    return
  }
  try {
    const user = await store.dispatch('admin/addUser', formData.value)
    emit('save-user-id', user.id)
    closeDialog()
  } catch (e) {
    showToastError(toast, e)
  }
}

function closeDialog() {
  formData.value = initialForm()
  v$.value.$reset()
  emit('update:open', false)
}
</script>

<style scoped>
.header-wrapper {
  @apply flex items-center justify-between w-full;
}
.title {
  @apply text-xl font-semi-bold mb-3;
}
</style>

<template>
  <IconButton
    icon="pi-chevron-down"
    icon-position="right"
    icon-size="12px"
    class="toggle-button p-button-text"
    @click="openOverlayPanel($event)"
    >{{ buttonTitle }}</IconButton
  >
  <OverlayPanel
    ref="panel"
    id="panel"
    :class="[
      $style['time-filter-panel'],
      showCustomPeriod ? $style['custom-period-panel'] : '',
    ]"
    @hide="onClickOutside"
  >
    <div v-if="showCustomPeriod">
      <div class="p-3 bg-gray-50 flex justify-between items-center">
        <div>
          <span class="font-semi-bold">Selected period:</span>
          {{ selectedPeriodTitle }}
        </div>
        <Button
          :disabled="!dateRange || !dateRange[0] || !dateRange[1]"
          @click="onApplyClick"
          >Apply</Button
        >
      </div>
      <div class="flex">
        <BaseCalendar
          v-model:value="dateRange"
          :number-of-months="2"
          mode="range"
          inline
        ></BaseCalendar>
      </div>
    </div>
    <div v-else class="w-full p-1">
      <TimeFilterButton
        v-for="title of timeFilterOptions"
        :key="title"
        :title="title"
        :filters="filters"
        @click="onDateChangeFromList(title)"
      ></TimeFilterButton>
      <Divider :class="$style['time-filter-divider']"></Divider>
      <TimeFilterButton
        title="Custom Period"
        :filters="filters"
        custom-period
        @click="showCustomPeriod = true"
      ></TimeFilterButton>
    </div>
  </OverlayPanel>
</template>
<script setup lang="ts">
import IconButton from '@/components/common/buttons/IconButton.vue'
import OverlayPanel from 'primevue/overlaypanel/OverlayPanel.vue'
import Divider from 'primevue/divider/Divider.vue'
import { defineEmits, ref, defineProps, watchEffect, computed } from 'vue'
import TimeFilterButton from '@/components/common/filters/global/time-filter/TimeFilterButton.vue'
import Button from '@/components/common/buttons/Button.vue'
import {
  subDays,
  subYears,
  subMonths,
  format,
  startOfWeek,
  endOfWeek,
} from 'date-fns'
import { FILTERS_DATE_FORMAT, TIME_FILTER_BUTTONS } from '@/constants/constants'
import { calcTimeFilterTitle } from '@/utils/utils'
import { TimeFilters } from '@/store/modules/filters'
import BaseCalendar from '@/components/common/base/BaseCalendar.vue'

const props = defineProps<{ filters: TimeFilters }>()
const emit = defineEmits<{
  (
    e: 'change-time',
    value: { since: string | null; until: string | null }
  ): void
}>()

const panel = ref()
const showCustomPeriod = ref(false)
const dateRange = ref<(string | null)[] | null>(null)

watchEffect(() => {
  if (!props.filters.since && !props.filters.until) {
    dateRange.value = null
  } else {
    dateRange.value = [props.filters.since, props.filters.until]
  }
})

const selectedPeriodTitle = computed(() =>
  dateRange.value ? `${dateRange.value[0]} → ${dateRange.value[1] || ''} ` : ''
)

const buttonTitle = computed(() => calcTimeFilterTitle(props.filters))

const timeFilterOptions = [
  TIME_FILTER_BUTTONS.PREVIOUS_WEEK,
  TIME_FILTER_BUTTONS.LAST_MONTH,
  TIME_FILTER_BUTTONS.LAST_3_MONTHS,
  TIME_FILTER_BUTTONS.LAST_6_MONTHS,
  TIME_FILTER_BUTTONS.LAST_YEAR,
]

const onClickOutside = () => {
  showCustomPeriod.value = false
}

const onApplyClick = () => {
  if (!dateRange.value) return
  const [start, end] = dateRange.value
  if (start && end) {
    emit('change-time', {
      since: start,
      until: end,
    })
    hideOverlayPanel()
  }
}

const onDateChangeFromList = (period: string) => {
  let payload: TimeFilters = {
    since: null,
    until: format(new Date(), FILTERS_DATE_FORMAT),
  }
  switch (period) {
    case TIME_FILTER_BUTTONS.PREVIOUS_WEEK:
      payload.since = format(
        startOfWeek(subDays(new Date(), 7), { weekStartsOn: 1 }),
        FILTERS_DATE_FORMAT
      )
      payload.until = format(
        endOfWeek(subDays(new Date(), 7), { weekStartsOn: 1 }),
        FILTERS_DATE_FORMAT
      )
      break
    case TIME_FILTER_BUTTONS.LAST_MONTH:
      payload.since = format(subDays(new Date(), 30), FILTERS_DATE_FORMAT)
      break
    case TIME_FILTER_BUTTONS.LAST_3_MONTHS:
      payload.since = format(subMonths(new Date(), 3), FILTERS_DATE_FORMAT)
      break
    case TIME_FILTER_BUTTONS.LAST_6_MONTHS:
      payload.since = format(subMonths(new Date(), 6), FILTERS_DATE_FORMAT)
      break
    case TIME_FILTER_BUTTONS.LAST_YEAR:
      payload.since = format(subYears(new Date(), 1), FILTERS_DATE_FORMAT)
      break
    default:
      payload.since = format(subDays(new Date(), 30), FILTERS_DATE_FORMAT)
  }
  emit('change-time', payload)
  hideOverlayPanel()
}

const openOverlayPanel = (event: Event) => {
  panel.value.show(event)
}

const hideOverlayPanel = () => {
  panel.value.hide()
}
</script>

<style module>
.time-filter-divider {
  @apply my-1 mx-3 w-auto;
}
.time-filter-panel {
  min-width: 240px;
}
.custom-period-panel {
  right: 4px !important;
  left: auto !important;
}
</style>

<template>
  <Dialog
    :visible="open"
    :dismissableMask="true"
    @update:visible="closeDialog"
    position="bottom"
    modal
  >
    <template #header>
      <div class="header-wrapper">Gitlab • Projects</div>
    </template>
    <div class="title">Add projects</div>
    <FormRow title="Token">
      <BaseDropdown
        :options="tokens"
        v-model:selected="selectedToken"
        @change="getProjectsByToken"
      ></BaseDropdown>
    </FormRow>
    <div
      v-if="!selectedToken"
      class="mt-5 flex flex-col flex-grow h-full justify-center items-center"
    >
      <span class="text-lg"> Please select token to see the projects… </span>
    </div>
    <template v-else>
      <div v-if="!loading" class="flex justify-end items-center mr-2">
        <TableSearchInput
          v-model:value="search"
          placeholder="Search by project"
        ></TableSearchInput>
      </div>
      <DataTable
        :value="projectsByToken"
        :scrollable="true"
        scroll-height="356px"
        v-model:selection="selectedProjects"
        class="p-datatable-sm flex-grow mt-5 custom"
        removableSort
        :loading="loading"
        :filters="filters"
        :globalFilterFields="filterFields"
      >
        <template #empty> No records found </template>
        <Column
          selectionMode="multiple"
          bodyStyle="flex-grow:0; flex-basis:30px"
          headerStyle="flex-grow:0; flex-basis:30px"
        ></Column>
        <Column
          v-for="(col, i) of columnsData"
          :key="`${col.field}-${i}`"
          :field="col.field"
          :header="col.header"
          :sortable="col.is_sortable"
        >
          <template v-if="col.field === 'original_project'" #body="slotProps">
            <BaseDropdown
              :options="originalProjects"
              v-model:selected="slotProps.data.original_project"
              v-model:newly-created-option="newlyCreatedProject"
              target="project"
              table
              search
              :key="i"
              :option-id="slotProps.data.project_id"
              @add="onAddNewProject(slotProps.data.project_id)"
              :disabled="
                !selectedProjects.some(
                  ({ project_id }) => project_id === slotProps.data.project_id
                )
              "
              @update:selected="
                onChangeOriginalProject($event!, slotProps.data.index)
              "
            ></BaseDropdown>
          </template>
        </Column>
      </DataTable>
    </template>
    <AddProjectMappingDialog
      v-model:open="showAddOriginalProjectDialog"
      @save-project-id="newlyCreatedProject.new_option_id = $event"
    ></AddProjectMappingDialog>
    <template #footer>
      <Button class="p-button-outlined" size="large" @click="closeDialog">
        Cancel
      </Button>
      <Button
        size="large"
        class="add"
        :disabled="!allOriginalNameAdded"
        @click="onAddProjects"
        >Add</Button
      >
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import Dialog from 'primevue/dialog/Dialog.vue'
import { defineProps, ref, defineEmits, computed } from 'vue'
import Button from '@/components/common/buttons/Button.vue'
import { useToast } from 'primevue/usetoast'
import { useStore } from '@/store'
import FormRow from '@/components/common/form/FormRow.vue'
import DataTable from 'primevue/datatable/DataTable.vue'
import Column from 'primevue/column/Column.vue'
import { GitlabProject } from '@/store/modules/admin/gitlab'
import {
  isAllOriginalNameAdded,
  showGitlabProjectsErrorToasts,
  showToastError,
} from '@/utils/utils'
import { isArray } from 'ramda-adjunct'
import AddProjectMappingDialog from '@/components/admin-dashboard/system/project-mapping/AddProjectMappingDialog.vue'
import BaseDropdown from '@/components/common/base/BaseDropdown.vue'
import TableSearchInput from '@/components/common/table/TableSearchInput.vue'
import useTableSearch from '@/utils/hooks/useTableSearch'

defineProps<{ open: boolean }>()
const emit = defineEmits<{ (e: 'update:open', value: boolean): void }>()
const toast = useToast()
const store = useStore()
const tokens = computed(() => store.state.gitlab.tokens)
const originalProjects = computed(() => store.state.admin.original_projects)

const selectedToken = ref(null)
const projectsByToken = ref([])
const selectedProjects = ref<GitlabProject[]>([])
const loading = ref(false)
const showAddOriginalProjectDialog = ref(false)
const newlyCreatedProject = ref<{ option_id?: number; new_option_id?: number }>(
  {}
)
const columnsData = [
  {
    header: 'Project name',
    field: 'name',
    is_sortable: true,
  },
  {
    header: 'Original project',
    field: 'original_project',
    is_sortable: true,
  },
]
const filterFields = ['name']
const { search, filters } = useTableSearch()

const allOriginalNameAdded = computed(() =>
  isAllOriginalNameAdded(selectedProjects.value)
)

async function onAddProjects() {
  try {
    const data = await store.dispatch('gitlab/addProjects', {
      token: selectedToken.value,
      projects: selectedProjects.value,
    })
    if (isArray(data) && data.some((error) => error.message)) {
      showGitlabProjectsErrorToasts(toast, data)
      if (data.length !== selectedProjects.value.length) {
        closeDialog()
      }
    } else {
      closeDialog()
    }
  } catch (e) {
    showToastError(toast, e)
  }
}

async function getProjectsByToken() {
  loading.value = true
  try {
    const allProjects = await store.dispatch(
      'gitlab/getProjectsByToken',
      selectedToken.value
    )
    projectsByToken.value = allProjects.filter(
      (project: GitlabProject) => !project.id
    )
    loading.value = false
  } catch (e) {
    loading.value = false
    showToastError(toast, e)
  }
  selectedProjects.value = []
}

function onChangeOriginalProject(
  orig_project: string | number,
  selectIndex: number
) {
  selectedProjects.value = selectedProjects.value.map(
    (project: GitlabProject, index: number) => {
      if (selectIndex === index) {
        project.original_project = +orig_project
      }
      return project
    }
  )
}

function closeDialog() {
  selectedProjects.value = []
  selectedToken.value = null
  emit('update:open', false)
}

const onAddNewProject = (id: number) => {
  newlyCreatedProject.value.option_id = id
  showAddOriginalProjectDialog.value = true
}
</script>

<style scoped>
.header-wrapper {
  @apply flex items-center justify-between w-full;
}
.title {
  @apply text-xl font-semi-bold mb-3;
}
</style>

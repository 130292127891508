import axios from '@/services/axios'
import { Filters } from '@/store/modules/filters'
import { ActionContext } from 'vuex'
import { State as GlobalState } from '../../index'
import { omit, path } from 'ramda'
import { ESTIMATION_TYPE } from '@/constants/constants'

export interface ScopeVsDeliveredItem {
  total_adaptive_estimates: number
  delivered_adaptive_estimates: number
  in_progress_adaptive_estimates: number
  date: string
}

export interface Data {
  guideline: number
  per_data: ScopeVsDeliveredItem[]
  estimation_type: ESTIMATION_TYPE
}

export interface Label {
  name: string
}

export interface ScopeVsDeliveredDot {
  issue_key: string
  issue_name: string
  absolute_url: string
  adaptive_estimate: number
  status: string
}

type Context = ActionContext<Data, GlobalState>

const actions = {
  async getData(
    { rootGetters }: Context,
    params: {
      filters: Filters
      project_id: number
    }
  ): Promise<Data> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/project-mapping/${params.project_id}/charts/scope-vs-delivered/`,
        {
          params: params.filters,
        }
      )
      .then(({ data }) => ({
        guideline: data.data?.length
          ? data.data[data.data?.length - 1]?.total_adaptive_estimates || 0
          : 0,
        per_data: data.data,
        estimation_type: data.estimation_type,
      }))
  },
  getLabels({ rootGetters }: Context, params: Filters): Promise<Label[]> {
    return axios
      .get(
        `api/companies/${rootGetters['company/selectedCompanyId']}/project-mapping/${params.projects?.[0]}/charts/labels/`,
        {
          params,
        }
      )
      .then(({ data }) => data)
  },
  getDotDetails(
    { rootGetters }: Context,
    params: {
      date?: string
      year: string
      week?: string
      month?: string
      scale_type: string
      id: number
      labels: string[] | null
      line_type: string
      historical: boolean
      ordering?: string
      search?: string
    }
  ): Promise<ScopeVsDeliveredDot[]> {
    return axios
      .get(
        `api/companies/${rootGetters['company/selectedCompanyId']}/project-mapping/${params.id}/charts/scope-vs-delivered/drilldown/?limit=50`,
        { params: omit(['id'], params) }
      )
      .then(({ data }) => data)
  },
  getNextPageOfDotDetails(_: Context, url: string): Promise<void> {
    return axios.get(`${url}`).then(path(['data']))
  },
}

export default {
  namespaced: true,
  actions,
}

<template>
  <ChartWrapper
    :title="chartTitle[chartTypes.Activities]"
    :loading="loading"
    :fetch-error="fetchError"
    :tooltip="chartExplanations[chartTypes.Activities]"
    :is-chart-data-empty="isEmpty(rawData)"
    :type="chartTypes.Activities"
    :icons="['gitlab']"
  >
    <template #buttons>
      <div class="flex gap-1">
        <AggregationFilter
          v-if="localTimeAggregation"
          :scale-type="filters.scale_type"
          :time-filters="timeFilters"
          size="small"
          @change="onAggregationChange"
        >
        </AggregationFilter>
        <SortingMenu
          v-if="sortingOptions.length"
          :sorting-options="sortingOptions"
          @change-sorting="sorting = $event"
        ></SortingMenu>
      </div>
    </template>
    <BasicChart
      :data="chartData"
      :options="(chartOptions as any)"
      :chart-name="chartTypes.Activities"
      :bar-width="56"
      type="bubble"
      @dot-data="saveUserDataAndShowMenu"
      @resize="onResize"
    ></BasicChart>
    <UserActivityDrilldown
      :open="!!drilldownData"
      :drilldown-data="drilldownData"
      :filters="filters"
      use-scale
      @close="drilldownData = null"
    ></UserActivityDrilldown>
  </ChartWrapper>
</template>

<script setup lang="ts">
import ChartWrapper from '@/components/charts/ChartWrapper.vue'
import SortingMenu from '@/components/common/menu/SortingMenu.vue'
import BasicChart from '@/components/charts/BasicChart.vue'
import { Filters, SCALE_TYPE, TimeFilters } from '@/store/modules/filters'
import { computed, defineProps, ref, watchEffect, withDefaults } from 'vue'
import {
  chartExplanations,
  chartTitle,
  chartTypes,
} from '@/constants/charts/constants'
import { Dictionary, equals, isEmpty, pick, reject, uniq } from 'ramda'
import {
  generateTickForXAxisTimeCharts,
  hideCursorOnLegendLeave,
  showCursorOnLegendHover,
  toggleCursorOnChartDataHover,
  usedColors,
} from '@/utils/chart-utils'
import {
  AGGREGATE_BY,
  FILTERS_DATE_FORMAT,
  USER_ACTIVITIES,
} from '@/constants/constants'
import UserActivityDrilldown from '@/components/charts/user-activity/UserActivityDrilldown.vue'
import { format } from 'date-fns'
import AggregationFilter from '@/components/common/filters/global/AggregationFilter.vue'
import { calcAggregationAccordingToTimeFilters } from '@/utils/date-utils'
import { useStore } from '@/store'
import {
  ActivitiesData,
  Activity,
  ProjectItem,
  UserItem,
} from '@/store/modules/charts/activities'
import { Aggregate_By_Type } from '@/types/types'
import { ActiveElement, ChartEvent, LegendElement, LegendItem } from 'chart.js'

export interface DrilldownData {
  user?: { name: string; id: number }
  project?: { name: string; id: number }
  activity: string
  date: string
}

interface DotItem {
  x: number
  y: number
  r: number
  key: string
  stack: string
  user?: string
  project?: string
  id: number
  date: string
}

const props = withDefaults(
  defineProps<{
    filters: Filters
    projectPage?: boolean
    aggregation?: Aggregate_By_Type
    localTimeAggregation?: boolean
  }>(),
  { aggregation: 'users' }
)

const timeFilters = computed(() => pick(['since', 'until'], props.filters))

const sorting = ref('')
const hiddenSortingOptions = ref([])
const scaleType = ref<SCALE_TYPE>(
  props.filters.scale_type ||
    calcAggregationAccordingToTimeFilters(timeFilters.value as TimeFilters)
)
const response = ref<ActivitiesData | null>(null)
const fetchError = ref('')
const loading = ref(true)
const store = useStore()

const drilldownData = ref<DrilldownData | null>(null)

const isAggregateByProjects = computed(
  () => props.aggregation === AGGREGATE_BY.PROJECTS
)

const filters = computed(() => ({
  ...props.filters,
  aggregate_by: isAggregateByProjects.value
    ? AGGREGATE_BY.PROJECTS
    : AGGREGATE_BY.USERS,
  scale_type: scaleType.value,
}))

const activeFilters = computed(() => {
  return reject(equals(null))(filters.value as Dictionary<any>)
})

const getChartData = async () => {
  fetchError.value = ''
  loading.value = true
  try {
    response.value = await store.dispatch(
      'activities/getData',

      filters.value
    )
    loading.value = false
  } catch (e) {
    if (e instanceof Error) {
      fetchError.value = e?.message
    }
    loading.value = false
  }
}

watchEffect(async () => {
  if (props.projectPage && !activeFilters.value.projects) return
  if (store.getters['company/selectedCompanyId'] && activeFilters.value.until) {
    await getChartData()
  }
})

watchEffect(() => {
  scaleType.value =
    props.filters.scale_type ||
    calcAggregationAccordingToTimeFilters(timeFilters.value as TimeFilters)
})

const baseSortingOptions = computed(() => [
  isAggregateByProjects.value
    ? {
        text: 'Project',
        value:
          sorting.value === 'project_name' ? '-project_name' : 'project_name',
      }
    : {
        text: 'Author',
        value: sorting.value === 'user_name' ? '-user_name' : 'user_name',
      },
  {
    text: 'Commits',
    value:
      sorting.value === 'commits_count' ? '-commits_count' : 'commits_count',
  },
  {
    text: 'Merge requests',
    value: sorting.value === 'mrs_count' ? '-mrs_count' : 'mrs_count',
  },
  {
    text: 'Notes',
    value:
      sorting.value === 'comments_count' ? '-comments_count' : 'comments_count',
  },
])

const sortingOptions = computed(() =>
  baseSortingOptions.value.filter(
    (opt) => !hiddenSortingOptions.value.includes(opt.value)
  )
)

const scaleX = ref(1)
const scaleY = ref(1)

function onResize(evt: { width: number; height: number }) {
  const { width, height } = evt
  scaleX.value = width
  scaleY.value = height
}

const rawData = computed(() => {
  return response.value?.data || []
})

const datesArray = computed(() => {
  return (
    uniq(
      rawData.value
        ?.map(({ data }: ProjectItem | UserItem) =>
          data.map(({ date }: Activity) => date)
        )
        .flat()
    ) as string[]
  ).sort(
    (a: string, b: string) => new Date(a).getTime() - new Date(b).getTime()
  )
})
const scale = computed(() => {
  return {
    x: scaleX.value / datesArray.value.length || 1,
    y: scaleY.value / (rawData.value?.length || 1),
  }
})

const preparedData: any = computed(() => {
  if (!rawData.value || rawData.value.length === 0) {
    return []
  }

  const sortedData = [...rawData.value].sort((a, b) => {
    switch (sorting.value) {
      case 'user_name':
        return b.user_name.localeCompare(a.user_name)
      case '-user_name':
        return a.user_name.localeCompare(b.user_name)
      case 'project_name':
        return b.project_name.localeCompare(a.project_name)
      case '-project_name':
        return a.project_name.localeCompare(b.project_name)
      case 'comments_count':
        return (
          a.data.reduce(
            (acc: number, { comments_count }) => (acc += comments_count || 0),
            0
          ) -
          b.data.reduce(
            (acc: number, { comments_count }) => (acc += comments_count || 0),
            0
          )
        )
      case '-comments_count':
        return (
          b.data.reduce(
            (acc: number, { comments_count }) => (acc += comments_count || 0),
            0
          ) -
          a.data.reduce(
            (acc: number, { comments_count }) => (acc += comments_count || 0),
            0
          )
        )
      case 'commits_count':
        return (
          a.data.reduce(
            (acc: number, { commits_count }) => (acc += commits_count || 0),
            0
          ) -
          b.data.reduce(
            (acc: number, { commits_count }) => (acc += commits_count || 0),
            0
          )
        )
      case '-commits_count':
        return (
          b.data.reduce(
            (acc: number, { commits_count }) => (acc += commits_count || 0),
            0
          ) -
          a.data.reduce(
            (acc: number, { commits_count }) => (acc += commits_count || 0),
            0
          )
        )
      case 'mrs_count':
        return (
          a.data.reduce(
            (acc: number, { mrs_count }) => (acc += mrs_count || 0),
            0
          ) -
          b.data.reduce(
            (acc: number, { mrs_count }) => (acc += mrs_count || 0),
            0
          )
        )
      case '-mrs_count':
        return (
          b.data.reduce(
            (acc: number, { mrs_count }) => (acc += mrs_count || 0),
            0
          ) -
          a.data.reduce(
            (acc: number, { mrs_count }) => (acc += mrs_count || 0),
            0
          )
        )
      default:
        return isAggregateByProjects.value
          ? b.project_name.localeCompare(a.project_name)
          : b.user_name.localeCompare(a.user_name)
    }
  })
  const commits: DotItem[] = []
  const mrs: DotItem[] = []
  const notes: DotItem[] = []
  const labels: string[] = []
  const dates: string[] = []

  let x = 1
  let y = 1
  const bubbleMaxRadius = 20
  const radiusScale =
    bubbleMaxRadius /
    Math.max(
      ...rawData.value
        .map(({ data }: ProjectItem | UserItem) =>
          data.map(({ commits_count, mrs_count, comments_count }: Activity) =>
            Math.max(commits_count || 0, mrs_count || 0, comments_count || 0)
          )
        )
        .flat()
    )

  function getMinRadius(radius: number) {
    return radius && radius * radiusScale > 5 ? radius * radiusScale : 5
  }
  sortedData.forEach(
    (
      { data, user_name, user_id, project_name, original_project_id }: any,
      userIndex: number
    ) => {
      labels.push(isAggregateByProjects.value ? project_name : user_name)
      y = userIndex * scale.value.y

      data.forEach(
        ({ date, commits_count, mrs_count, comments_count }: Activity) => {
          x = (datesArray.value.indexOf(date) + 1) * scale.value.x
          if (!dates.includes(date)) {
            dates.push(date)
          }
          let commitsX = x
          let mrsX = x
          let notesX = x
          let commitsY = y
          let mrsY = y
          let notesY = y

          if (commits_count && mrs_count && !comments_count) {
            commitsX = x - getMinRadius(commits_count)
            mrsX = x + getMinRadius(mrs_count)
          } else if (commits_count && !mrs_count && comments_count) {
            commitsX = x - getMinRadius(commits_count)
            notesX = x + getMinRadius(comments_count)
          } else if (!commits_count && mrs_count && comments_count) {
            mrsX = x - getMinRadius(mrs_count)
            notesX = x + getMinRadius(comments_count)
          } else if (commits_count && mrs_count && comments_count) {
            notesX =
              x - getMinRadius(comments_count) - getMinRadius(commits_count)
            mrsX = x + getMinRadius(mrs_count) + getMinRadius(commits_count)
          }

          const rawItemData = isAggregateByProjects.value
            ? {
                date,
                project: project_name,
                id: original_project_id,
                stack: project_name,
              }
            : {
                date,
                user: user_name,
                id: user_id,
                stack: user_name,
              }

          commits_count &&
            commits.push({
              x: commitsX,
              y: commitsY,
              r: Math.max(5, Math.round(radiusScale * commits_count)),
              key: 'commits_count',
              ...rawItemData,
            })

          mrs_count &&
            mrs.push({
              x: mrsX,
              y: mrsY,
              r: Math.max(5, Math.round(radiusScale * mrs_count)),
              key: 'mrs_count',
              ...rawItemData,
            })
          comments_count &&
            notes.push({
              x: notesX,
              y: notesY,
              r: Math.max(5, Math.round(radiusScale * comments_count)),
              key: 'comments_count',
              ...rawItemData,
            })
        }
      )
    }
  )
  dates.sort(
    (a: string, b: string) => new Date(a).getTime() - new Date(b).getTime()
  )
  return { commits, mrs, notes, labels, dates }
})

const chartOptions = computed(() => ({
  maintainAspectRatio: false,
  responsive: true,
  onHover: (e: ChartEvent, chartElement: ActiveElement[]): void =>
    toggleCursorOnChartDataHover(e, chartElement[0]),
  plugins: {
    datalabels: null,
    legend: {
      onHover: (evt: ChartEvent): void => showCursorOnLegendHover(evt),
      onLeave: (evt: ChartEvent): void => hideCursorOnLegendLeave(evt),
      onClick: function (
        _e: ChartEvent,
        legendItem: LegendItem,
        legend: LegendElement<any>
      ): void {
        const ci = legend.chart
        const index = legendItem.datasetIndex
        const key = baseSortingOptions.value[index + 1].value.replace('-', '')
        if (!hiddenSortingOptions.value.includes(key)) {
          hiddenSortingOptions.value.push(key)
          ci.hide(index)
        } else {
          hiddenSortingOptions.value = hiddenSortingOptions.value.filter(
            (item) => item !== key
          )
          ci.show(index)
        }
      },
      align: 'start',
      labels: {
        borderRadius: 4,
        boxWidth: 12,
        boxHeight: 12,
      },
    },
    tooltip: {
      callbacks: {
        label: function ({ label, raw }: any) {
          return (
            label +
            ': ' +
            rawData.value
              .find(({ user_name, project_name }: any) =>
                isAggregateByProjects.value
                  ? project_name === raw.project
                  : user_name === raw.user
              )
              .data.find(({ date }: Activity) => date === raw.date)[raw.key]
          )
        },
      },
    },
  },
  indexAxis: 'y',
  interaction: {
    mode: 'nearest',
  },
  scales: {
    x: {
      suggestedMax: preparedData.value.dates.length * scale.value.x,
      suggestedMin: 0,
      position: 'top',
      ticks: {
        display: true,
        font: {
          size: 10,
        },
        stepSize: scale.value.x,
        callback: function (_: string, index: number) {
          return generateTickForXAxisTimeCharts(
            preparedData.value.dates[index - 1],
            scaleType.value
          )
        },
      },
      grid: {
        display: false,
        offset: true,
        beginAtZero: true,
      },
    },
    y: {
      max:
        preparedData.value.labels.length * scale.value.y -
        scale.value.y / 2 -
        1,
      suggestedMin: preparedData.value.labels.length > 1 ? 0 : -1,
      ticks: {
        callback: function (_: number, index: number) {
          return preparedData.value.labels[
            index - Number(preparedData.value.labels.length === 1)
          ]
        },
        stepSize: scale.value.y,
        font: {
          size: 14,
        },
      },
      grid: {
        display: true,
        offset: true,
        borderWidth: 0,
      },
    },
  },
}))

const chartData = computed(() => ({
  labels: preparedData.value.labels,
  datasets: [
    {
      label: 'Commits',
      data: preparedData.value.commits,
      backgroundColor: usedColors['key-300'],
    },
    {
      label: 'Merge requests',
      data: preparedData.value.mrs,
      backgroundColor: usedColors['danger-400'],
    },
    {
      label: 'Notes',
      data: preparedData.value.notes,
      backgroundColor: usedColors['warning-300'],
    },
  ],
}))
const saveUserDataAndShowMenu = (point: any) => {
  const activities: string[] = [
    USER_ACTIVITIES.COMMITS,
    USER_ACTIVITIES.MERGE_REQUESTS,
    USER_ACTIVITIES.COMMENTS,
  ]
  const itemData = isAggregateByProjects.value
    ? {
        project: {
          name: point.element.$context.raw.project,
          id: point.element.$context.raw.id,
        },
      }
    : {
        user: {
          name: point.element.$context.raw.user,
          id: point.element.$context.raw.id,
        },
      }

  drilldownData.value = {
    ...itemData,
    activity: activities[point.datasetIndex],
    date: format(
      new Date(point.element.$context.raw.date),
      FILTERS_DATE_FORMAT
    ),
  }
}

const onAggregationChange = (value: SCALE_TYPE) => {
  scaleType.value = value
}
</script>

<template>
  <div class="flex text-base flex-col items-start justify-start text-left">
    <div v-if="withTitle" class="title">Connect Slack…</div>
    <div class="w-full mb-3">
      1. Create a new Slack application for your workspace at
      <Link href="https://api.slack.com/apps" style-class="font-bold"
        >https://api.slack.com/apps</Link
      >
    </div>
    <div class="w-full mb-3">
      2. Click <strong>“OAuth & Permissions”</strong> in the left menu bar and
      navigate to <strong> “Scopes” </strong>
    </div>
    <div class="w-full mb-3">
      3. Here add the following scopes:
      <span class="code">chat:write</span>,
      <span class="code">users:read</span>,
      <span class="code">channels:read</span>,
      <span class="code">groups:read</span>
    </div>
    <div class="w-full mb-3">
      4. Then navigate to and click <strong> “Install to workspace” </strong>
    </div>
    <div class="w-full mb-3">
      5. Once you have installed the app to your workspace, you will see the
      token under the same page (<strong>“OAuth & Permissions” </strong>) on top
    </div>
    <div class="w-full mb-3">
      <div class="text-black mb-1">6. Paste your token:</div>
      <BaseInputText
        class="w-full"
        v-model:value="formData.token"
        :errors="v$.token.$errors"
      ></BaseInputText>
    </div>
    <div class="w-full mb-5">
      <div class="text-black mb-1">7. Paste your channel ID:</div>
      <BaseInputText
        class="w-full"
        v-model:value="formData.channel_id"
        :errors="v$.channel_id.$errors"
      ></BaseInputText>
    </div>
    <Button @click="onAddToken">Add Token</Button>
  </div>
</template>

<script setup lang="ts">
import { defineEmits, defineProps, ref } from 'vue'
import Button from '@/components/common/buttons/Button.vue'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { useToast } from 'primevue/usetoast'
import { useStore } from '@/store'
import { showToastError } from '@/utils/utils'
import BaseInputText from '@/components/common/base/BaseInputText.vue'
import Link from '@/components/common/Link.vue'

defineProps<{ withTitle?: boolean }>()
const emit = defineEmits<{ (e: 'connected'): void }>()

const initialForm = () => ({
  channel_id: '',
  token: '',
})

const toast = useToast()
const store = useStore()
const formData = ref(initialForm())
const $externalResults = ref({})

const formRules = {
  token: { required, $autoDirty: true, $lazy: true },
  channel_id: { required, $autoDirty: true, $lazy: true },
}

const v$ = useVuelidate(formRules, formData, { $externalResults })

async function onAddToken() {
  v$.value.$touch()
  if (v$.value.$invalid) {
    return
  }
  try {
    await store.dispatch('slack/addToken', formData.value)
    emit('connected')
  } catch (e: any) {
    if (e.response?.data?.token || e.response?.data?.channel_id) {
      $externalResults.value = e.response.data
    } else {
      showToastError(toast, e)
    }
  }
}
</script>

<style scoped>
.title {
  @apply text-lg font-semi-bold mb-5;
}
.code {
  border-radius: 2px;
  padding: 2px 3px;
  @apply bg-gray-200;
}
</style>

export function addQueryParamSilently(name: string, value: number): void {
  history.pushState({}, '', `${window.location.href}&${name}=${value}`)
}

export function deleteQueryParamSilently(name: string): void {
  const search = window.location.search
  const query = new URLSearchParams(search)
  query.delete(name)
  history.pushState(
    {},
    '',
    window.location.origin + window.location.pathname + '?' + query.toString()
  )
}

export function getQueryParamFromUrl(param: string): string | null {
  const search = window.location.search
  const query = new URLSearchParams(search)
  return query.get(param)
}

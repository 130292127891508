<template>
  <div class="btn-wrapper">
    <IconButton
      :class="{ 'filter-button__active': active, 'p-button-outlined': !active }"
      size="small"
      icon="pi-angle-down"
      icon-position="right"
      icon-size="12px"
      :disabled="disabled"
      @click="showOverlayPanel"
    >
      <slot name="btn"></slot>
    </IconButton>
  </div>
  <OverlayPanel ref="op" id="overlay-panel" :style="{ minWidth: '320px' }">
    <div v-if="!hideControls" class="header-wrapper">
      <Button class="clear p-button-outlined" @click="onClearFilters">
        Clear Filters
      </Button>
      <Button class="apply" @click="onApplyFilters">Apply</Button>
    </div>
    <div class="py-4 px-3">
      <slot></slot>
    </div>
  </OverlayPanel>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, ref } from 'vue'
import OverlayPanel from 'primevue/overlaypanel/OverlayPanel.vue'
import IconButton from '@/components/common/buttons/IconButton.vue'
import Button from '@/components/common/buttons/Button.vue'

defineProps<{ active?: boolean; disabled?: boolean; hideControls?: boolean }>()
const emit = defineEmits<{
  (e: 'clear-filters'): void
  (e: 'apply-filters'): void
}>()

const op = ref()

const showOverlayPanel = (event: Event) => {
  op.value.show(event)
}

const onClearFilters = () => {
  emit('clear-filters')
  op.value.hide()
}

const onApplyFilters = () => {
  emit('apply-filters')
  op.value.hide()
}
</script>

<style lang="scss" scoped>
.btn-wrapper {
  @apply mr-2;
}

.header-wrapper {
  @apply flex justify-between p-3 bg-gray-50;
}

.filter-button {
  &__active {
    @apply border-key-300 text-key-500 bg-key-50;

    &:hover {
      @apply border-key-300 text-key-500 bg-key-50;
    }
    &:active {
      @apply border-key-400 text-key-600 bg-key-100;
    }
  }
}
</style>

<template>
  <IconButton
    icon="pi-chevron-down"
    icon-position="right"
    icon-size="12px"
    class="p-button-text"
    @click="toggleOverlayPanel"
    >{{ buttonTitle }}</IconButton
  >
  <OverlayPanel
    ref="op"
    id="overlay-panel"
    @hide="onClickOutside"
    :dismissable="isNotEmpty(selectedArray)"
  >
    <Listbox
      class="multiple-list"
      :list-style="{ overflow: 'auto', height: calculatedHeight }"
      v-model="selectedArray"
      :options="searchMatchedItems"
      :multiple="true"
    >
      <template #header>
        <BaseInputText
          v-model:value="searchItem"
          placeholder="Search"
          class="px-3 py-2"
          icon-left="pi-search"
        ></BaseInputText>
        <div
          v-if="!searchItem"
          class="flex px-3 py-2 all-selected items-center"
          @click="toggleSelection"
        >
          <i
            v-if="isAllSelected"
            class="pi pi-check text-key-500 mr-3"
            style="font-size: 1rem; flex: none"
          ></i>
          <div v-else class="mr-3 w-4" style="flex: none"></div>
          <div>All {{ name }}</div>
        </div>
        <Divider class="divider"></Divider>
      </template>
      <template #option="slotProps">
        <div class="flex items-center item group">
          <i
            v-if="selectedIds.includes(slotProps.option.id)"
            class="pi pi-check text-key-500 mr-3"
            style="font-size: 1rem; flex: none"
          ></i>
          <div v-else class="mr-3 w-4" style="flex: none"></div>
          <div>{{ slotProps.option.name }}</div>
          <div class="justify-end flex flex-grow ml-2">
            <Button
              class="p-button-outlined invisible group-hover:visible only-button"
              size="small"
              @click.stop="onOnlyButtonClick(slotProps.option.id)"
              >Only</Button
            >
          </div>
        </div>
      </template>
    </Listbox>
  </OverlayPanel>
</template>

<script setup lang="ts">
import OverlayPanel from 'primevue/overlaypanel/OverlayPanel.vue'
import IconButton from '@/components/common/buttons/IconButton.vue'
import Button from '@/components/common/buttons/Button.vue'
import Listbox from 'primevue/listbox/Listbox.vue'
import Divider from 'primevue/divider/Divider.vue'
import { defineEmits, defineProps, ref, computed, watchEffect } from 'vue'
import { LIST_ITEM_HEIGHT, PERCENT_OF_WINDOW_70 } from '@/constants/constants'
import BaseInputText from '@/components/common/base/BaseInputText.vue'
import { isNotEmpty } from 'ramda-adjunct'
import { Project } from '@/store/modules/admin/admin'
import { User } from '@firebase/auth'

type OptionItem = Project | User

const props = defineProps<{
  filteringOptions: OptionItem[]
  filters?: string[] | string
  name: string
}>()

const emit = defineEmits<{
  (e: 'change-filtering', value: number[]): void
}>()

const selectedArray: OptionItem[] = ref([])
const searchItem = ref('')
const op = ref()

const defaultFilteringValue = computed(() => {
  if (props.filters) {
    return props.filteringOptions.filter((option: any) => {
      return props.filters?.includes(String(option.id))
    })
  } else {
    return props.filteringOptions
  }
})

watchEffect(() => {
  selectedArray.value = defaultFilteringValue.value
})

const selectedIds = computed(() => {
  return selectedArray.value.map((item: OptionItem) => item.id)
})

const buttonTitle = computed(() => {
  if (isAllSelected.value) {
    return `All ${props.name}`
  } else {
    return `Selected(${selectedArray.value.length})`
  }
})

const isAllSelected = computed(() => {
  return selectedArray.value.length === props.filteringOptions.length
})

const searchMatchedItems = computed(() => {
  if (searchItem.value) {
    return props.filteringOptions.filter((item: OptionItem) => {
      return item.name.toLowerCase().includes(searchItem.value.toLowerCase())
    })
  }
  return props.filteringOptions
})

const calculatedHeight = computed(() => {
  return searchMatchedItems.value.length * LIST_ITEM_HEIGHT >
    PERCENT_OF_WINDOW_70
    ? `${PERCENT_OF_WINDOW_70}px`
    : 'auto'
})

const toggleOverlayPanel = (event: Event) => {
  op.value.toggle(event)
}

const toggleSelection = () => {
  if (isAllSelected.value) {
    selectedArray.value = []
  } else {
    selectedArray.value = props.filteringOptions
  }
}

const onClickOutside = () => {
  emit('change-filtering', selectedIds.value)
  searchItem.value = ''
}

const onOnlyButtonClick = (id: number) => {
  selectedArray.value = [
    props.filteringOptions.find((option: OptionItem) => id === option.id),
  ]
  op.value.hide()
  onClickOutside()
}
</script>

<style lang="scss">
.all-selected {
  @apply rounded-md cursor-pointer;

  &:hover {
    @apply bg-gray-50;
  }
}

.multiple-list {
  @apply p-1;
  .p-listbox-list .p-listbox-item.p-highlight:hover {
    @apply text-black bg-gray-50;
  }

  .p-listbox-list .p-listbox-item.p-highlight {
    @apply text-black bg-white;
  }
  .divider {
    @apply my-1 mx-3 w-auto;
    height: 1px;
  }
}
</style>

<template>
  <FormRow title="Jira project">
    <BaseDropdown
      v-model:selected="jiraProjectValue"
      :options="notAddedJiraProjects"
      :errors="validation.jira_project.$errors"
    ></BaseDropdown>
  </FormRow>
</template>

<script setup lang="ts">
import FormRow from '@/components/common/form/FormRow.vue'
import { computed, defineEmits, defineProps, onMounted } from 'vue'
import { useStore } from '@/store'
import { showToastError } from '@/utils/utils'
import { useToast } from 'primevue/usetoast'
import { isEmpty } from 'ramda'
import { JiraProject } from '@/store/modules/admin/jira'
import { TempoProject } from '@/store/modules/admin/tempo'
import BaseDropdown from '@/components/common/base/BaseDropdown.vue'
import { VuelidateValidation } from '@/types/types'

interface TempoProjectsValidation extends VuelidateValidation {
  jira_project: VuelidateValidation
}

const props = defineProps<{
  jiraProject: number | null
  validation: TempoProjectsValidation
}>()
const emit = defineEmits<{
  (e: 'update:jiraProject', value: number | null): void
}>()

const store = useStore()
const toast = useToast()
const jiraProjects = computed(() => store.state.jira.projects)
const tempoProjects = computed(() => store.state.tempo.projects)
const notAddedJiraProjects = computed(() => {
  return jiraProjects.value.filter(
    (jiraProject: JiraProject) =>
      !tempoProjects.value.some(
        ({ jira_project }: TempoProject) => jira_project === jiraProject.id
      )
  )
})

onMounted(() => {
  if (isEmpty(jiraProjects.value)) {
    store.dispatch('jira/getProjects').catch((e) => {
      showToastError(toast, e)
    })
  }
  store.dispatch('tempo/getProjects')
})

const jiraProjectValue = computed({
  get() {
    return props.jiraProject
  },
  set(value: number | null) {
    emit('update:jiraProject', value)
  },
})
</script>

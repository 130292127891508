<template>
  <Button
    v-bind="$attrs"
    :size="size"
    class="svg-button"
    :class="{
      'svg-button--icon-only': !iconPosition,
      'svg-button--icon-left': iconPosition === iconPositions.left,
      'svg-button--icon-right': iconPosition === iconPositions.right,
    }"
  >
    <i
      v-if="iconPosition === iconPositions.left"
      class="pi"
      :class="icon"
      :style="iconStyle"
    ></i>
    <slot></slot>
    <i
      v-if="iconPosition === iconPositions.right"
      class="pi"
      :class="icon"
      :style="iconStyle"
    ></i>
  </Button>
</template>

<script setup lang="ts">
import Button from './Button.vue'
import { defineProps, computed } from 'vue'
import { Dictionary } from 'ramda'

const defaultIconSizes: Dictionary<string> = {
  normal: '1.25rem',
  small: '1rem',
  large: '1.5rem',
}

const iconPositions = {
  left: 'left',
  right: 'right',
}

const props = defineProps<{
  size?: string
  icon?: string
  iconSize?: string
  iconWidth?: string
  iconHeight?: string
  iconPosition?: string
}>()

const size = computed<string>(() => {
  return props.size || 'normal'
})

const iconStyle = computed(() => {
  return {
    'font-size': props.iconSize || defaultIconSizes[size.value],
    width: props.iconWidth || props.iconSize || defaultIconSizes[size.value],
    height: props.iconHeight || props.iconSize || defaultIconSizes[size.value],
  }
})
</script>

<style lang="scss" scoped>
.p-button.svg-button.p-button-sm {
  padding: 3px 8px;
  i.pi {
    font-size: 12px;
  }
}

.p-button.svg-button.p-button-lg {
  padding: 11px 20px;
}

.p-button.svg-button {
  padding: 7px 16px;

  i {
    @apply m-0;
  }

  &--icon-right {
    @apply pl-4;

    i {
      @apply ml-4;
    }

    &.p-button-sm {
      @apply pl-2;

      i {
        @apply ml-2;
      }
    }

    &.p-button-lg {
      @apply pl-5;

      i {
        @apply ml-6;
      }
    }

    &.p-button-text {
      i {
        @apply ml-1;
      }
    }
  }

  &--icon-left {
    @apply pr-4;

    i {
      @apply mr-4;
    }

    &.p-button-sm {
      @apply pr-2;

      i {
        @apply mr-2;
      }
    }

    &.p-button-lg {
      @apply pr-5;

      i {
        @apply mr-6;
      }
    }

    &.p-button-text {
      i {
        @apply mr-2;
      }
    }
  }

  &--icon-only {
    padding: 7px;
    @apply w-9 flex justify-center items-center;

    &.p-button-sm {
      padding: 3px;
      @apply w-6;
    }

    &.p-button-lg {
      padding: 11px;
      @apply w-12;
    }
  }
}
</style>

<template>
  <div
    :class="{ selected }"
    v-tooltip.left="
      title?.includes('Sprints')
        ? 'When you select sprints, the time range will not apply to this chart'
        : ''
    "
  >
    <IconButton
      class="p-button-text relative"
      size="small"
      @click="showOverlayPanel"
    >
      <i
        class="pi pi-filter"
        :class="{ 'text-key-500': selected }"
        style="font-size: 12px"
      ></i>
      <Badge class="badge" v-if="selected" :value="selected.length"></Badge>
    </IconButton>
  </div>
  <OverlayPanel ref="op" id="overlay-panel" :style="{ minWidth: '360px' }">
    <div class="header-wrapper">
      <div class="title">{{ title }}</div>
      <div class="flex gap-3">
        <Button class="clear p-button-outlined" @click="onClearFilters">
          Clear
        </Button>
        <Button class="apply" @click="onApplyFilters">Apply</Button>
      </div>
    </div>
    <div class="px-4 py-3">
      <LoaderWrapper v-if="loading" class="height"></LoaderWrapper>
      <MultipleCheckboxes
        v-else
        v-model:selected="selectedItems"
        :options="options"
        show-all-button
        :all-title="`All ${title?.split(' ')[1]}`"
      ></MultipleCheckboxes>
    </div>
  </OverlayPanel>
</template>

<script setup lang="ts">
import IconButton from '@/components/common/buttons/IconButton.vue'
import Button from '@/components/common/buttons/Button.vue'
import OverlayPanel from 'primevue/overlaypanel/OverlayPanel.vue'
import Badge from 'primevue/badge'
import { defineProps, defineEmits, ref, watchEffect } from 'vue'
import MultipleCheckboxes from '@/components/common/form/MultipleCheckboxes.vue'
import LoaderWrapper from '@/components/common/loader/LoaderWrapper.vue'

type Selected = string[] | number[] | null

const props = defineProps<{
  title?: string
  selected: Selected
  options: any
  loading?: boolean
}>()
const emit = defineEmits<{
  (e: 'update:selected', value: Selected): void
}>()

const selectedItems = ref<Selected>(null)
const op = ref()

watchEffect(() => {
  selectedItems.value = props.selected
})

const showOverlayPanel = (event: Event) => {
  op.value.show(event)
}

const onClearFilters = () => {
  selectedItems.value = null
  emit('update:selected', null)
}

const onApplyFilters = () => {
  emit('update:selected', selectedItems.value)
  op.value.hide()
}
</script>

<style lang="scss" scoped>
.header-wrapper {
  @apply flex justify-between p-3 bg-gray-50 items-center rounded-t-lg;
}

.title {
  @apply text-lg font-semi-bold;
}

.selected :deep(.p-button.p-component.p-button-text) {
  @apply bg-key-50 hover:bg-key-100;
}

.badge {
  @apply absolute;
  top: -7px;
  right: -7px;
}

.height {
  height: 300px;
}
</style>

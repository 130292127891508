import { useRoute } from 'vue-router'
import { useStore } from '@/store'
import { computed } from 'vue'

export default function usePageFilters() {
  const route = useRoute()
  const store = useStore()

  const pageFilters = (page?: string) => {
    const pageName = page || route.path
    return computed(() => {
      if (pageName.includes('portfolio')) {
        return store.state.filters.portfolioFilters
      } else if (pageName.includes('team')) {
        return store.state.filters.teamFilters
      } else if (pageName.includes('projects')) {
        return store.state.filters.projectFilters
      } else {
        return {}
      }
    })
  }
  return { pageFilters }
}

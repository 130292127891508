import axios from '@/services/axios'
import { omit, path } from 'ramda'
import { LIMIT_OF_COMMITS } from '@/constants/constants'
import { ActionContext } from 'vuex'
import { State as GlobalState } from '../../index'

export interface BitbucketProject {
  id?: number | null
  original_project?: number | null
  project_id?: number
  git_http_url?: string
  name?: string
  include_all_data?: boolean
  token?: number
  commit_message_exclusion_rule: null | string
  file_path_exclusion_rule: string | null
  web_url: string
  removed_irrelevant: boolean
  last_push: null
  avatar_url: null | string
}

export interface BitbucketToken {
  name?: string
  id?: number
  token?: string
  base_url?: string
  company?: number
  status?: string
}

export interface BitbucketUser {
  avatar_url?: string
  company?: number
  email?: string | null
  id: number
  name?: string
  original_name?: number | null
  user_id?: number
  display_name?: string
  username?: string
}

export interface BitbucketCommit {
  author: number | null
  committed_date: string
  created_at: string
  excluded: boolean
  id: string
  message: string
  title: string
  web_url: string
  original_name_name: string
  author_name: string
  deletions: number
  duplicated_lines: number
  ignored_lines: number
  insertions: number
  original_deletions: number
  original_insertions: number
  original_name: number
  original_project: number
  original_project_name: string
  project: number
  project_name: string
}

export interface CommitsFilter {
  projects: null | number[]
  users: null | number[]
  since: null | string
  until: null | string
  insertions_gte: null | string
  insertions_lte: null | string
  deletions_gte: null | string
  deletions_lte: null | string
  duplicated_lines_gte: null | string
  duplicated_lines_lte: null | string
  excluded?: string | null
}

export interface GlobalExclusionRules {
  commit_message_exclusion_rule: string | null
  file_path_exclusion_rule: string | null
  id?: number
}

export interface State {
  tokens: BitbucketToken[]
  projects: BitbucketProject[]
  commits_filters: CommitsFilter
  search: string | null
  global_exclusion_rules: GlobalExclusionRules
  isBitbucketConnected: boolean
}

type Context = ActionContext<State, GlobalState>

const state = (): State => ({
  tokens: [],
  projects: [],
  commits_filters: {
    projects: null,
    users: null,
    since: null,
    until: null,
    insertions_gte: null,
    insertions_lte: null,
    deletions_gte: null,
    deletions_lte: null,
    duplicated_lines_gte: null,
    duplicated_lines_lte: null,
  },
  search: null,
  global_exclusion_rules: {
    commit_message_exclusion_rule: null,
    file_path_exclusion_rule: null,
  },
  isBitbucketConnected: false,
})

const mutations = {
  setProjects(state: State, projects: BitbucketProject[]): void {
    state.projects = projects
  },
  setBitbucketConnection(state: State, connected: boolean): void {
    state.isBitbucketConnected = connected
  },
  setTokens(state: State, tokens: BitbucketToken[]): void {
    state.tokens = tokens
  },
  setCommitsFilters(state: State, filters: CommitsFilter): void {
    state.commits_filters = filters
  },
  setSearchText(state: State, search: string | null): void {
    state.search = search
  },
  setGlobalExclusionRules(state: State, rules: GlobalExclusionRules): void {
    state.global_exclusion_rules = rules || {
      commit_message_exclusion_rule: null,
      file_path_exclusion_rule: null,
    }
  },
}

const actions = {
  getProjects({ commit, rootGetters }: Context): Promise<void> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/git/repositories/?git_hosting=Bitbucket`
      )
      .then((data) => commit('setProjects', data.data))
  },
  getProject({ rootGetters }: Context, id: number): Promise<void> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/git/repositories/${id}/?git_hosting=Bitbucket`
      )
      .then(path(['data']))
  },
  editProject(
    { dispatch, rootGetters }: Context,
    project: BitbucketProject
  ): Promise<void> {
    return axios
      .patch(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/git/repositories/${project.id}/?git_hosting=Bitbucket`,
        omit(['id'], project)
      )
      .then(path(['data']))
      .then(() => dispatch('getProjects'))
  },
  deleteProject({ dispatch, rootGetters }: Context, id: number): Promise<void> {
    return axios
      .delete(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/git/repositories/${id}/?git_hosting=Bitbucket`
      )
      .then(path(['data']))
      .then(() => dispatch('getProjects'))
  },
  getProjectsByToken({ rootGetters }: Context, id: number): Promise<void> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/bitbucket/${id}/get_repositories/`
      )
      .then(path(['data']))
  },
  addProjects(
    { dispatch, rootGetters }: Context,
    payload: { id: number; name: string; original_project: number }
  ): Promise<BitbucketProject[]> {
    return axios
      .post(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/bitbucket/add_repositories/`,
        payload
      )
      .then((data) => {
        dispatch('getProjects')
        return data.data
      })
  },
  getNotAddedProjects({ rootGetters }: Context): Promise<void> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/bitbucket/not_added_repositories/`
      )
      .then(path(['data']))
  },
  getFirstPageOfUsers(
    { rootGetters }: Context,
    params: { ordering: string | null; search: string }
  ): Promise<void> {
    return axios
      .get(
        `/api/companies/${
          rootGetters['company/selectedCompanyId']
        }/bitbucket/users/?limit=50&ordering=${params.ordering}&unmapped=false${
          params.search ? `&search=${params.search}` : ''
        }`
      )
      .then(path(['data']))
  },
  getNextPageOfUsers(_: Context, url: string): Promise<void> {
    return axios.get(`${url}`).then(path(['data']))
  },
  editUser(
    { rootGetters }: Context,
    user: { original_name: number; id: number }
  ): Promise<void> {
    return axios
      .patch(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/bitbucket/users/${user.id}/`,
        omit(['id'], user)
      )
      .then(path(['data']))
  },
  getFirstPageOfCommits(
    { rootGetters }: Context,
    params: { params: CommitsFilter; signal: AbortSignal }
  ): Promise<void> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/git/commits/?limit=${LIMIT_OF_COMMITS}&git_hosting=Bitbucket`,
        params
      )
      .then(path(['data']))
  },
  getNextPageOfCommits(_: Context, url: string): Promise<void> {
    return axios.get(`${url}`).then(path(['data']))
  },
  editCommit(
    { rootGetters }: Context,
    commit: { excluded: boolean; id: number }
  ): Promise<void> {
    return axios
      .patch(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/git/commits/${commit.id}/?git_hosting=Bitbucket`,
        omit(['id'], commit)
      )
      .then(path(['data']))
  },
  getGlobalExclusionRules({ commit, rootGetters }: Context): Promise<void> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/git/commit-exclusion-rules/`
      )
      .then((data) => commit('setGlobalExclusionRules', data.data))
  },
  addGlobalExclusionRules(
    { rootGetters }: Context,
    rules: GlobalExclusionRules
  ): Promise<void> {
    return axios
      .post(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/git/commit-exclusion-rules/`,
        rules
      )
      .then(path(['data']))
  },
  editGlobalExclusionRules(
    { rootGetters }: Context,
    rules: GlobalExclusionRules
  ): Promise<void> {
    return axios
      .put(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/git/commit-exclusion-rules/`,
        rules
      )
      .then(path(['data']))
  },
  deleteGlobalExclusionRules({ commit, rootGetters }: Context): Promise<void> {
    return axios
      .delete(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/git/commit-exclusion-rules/`
      )
      .then(() => commit('setGlobalExclusionRules', null))
  },
  checkBitbucketConnection({ commit, rootGetters }: Context): Promise<void> {
    return axios
      .get(
        `api/companies/${rootGetters['company/selectedCompanyId']}/bitbucket/atlassian-connect/check-connection/`
      )
      .then((data) => commit('setBitbucketConnection', data.data.connected))
  },
  editStatus(
    { rootGetters }: Context,
    status: { original_status?: number; id: number }
  ): Promise<void> {
    return axios
      .patch(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/bitbucket/statuses/${status.id}/`,
        omit(['id'], status)
      )
      .then(path(['data']))
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}

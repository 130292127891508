import { ActionContext } from 'vuex'
import { State as GlobalState } from '../index'
import { Project, User } from '@/store/modules/admin/admin'

export type SCALE_TYPE = 'week' | 'month' | 'date'

export interface Filters {
  since?: string
  until?: string
  users?: number[] | null
  projects?: number[] | null
  scale_type?: SCALE_TYPE
}

export interface TimeFilters {
  since: string
  until: string
}

export interface State {
  portfolioFilters: Filters
  projectFilters: Filters
  teamFilters: Filters
}

type Context = ActionContext<State, GlobalState>

const state = (): State => ({
  portfolioFilters: {},
  projectFilters: {},
  teamFilters: {},
})

const getters = {
  filterItems: (
    state: State,
    _rootGetters: any,
    rootState: GlobalState
  ): { projects: Project[]; users: User[] } => {
    return {
      projects: rootState.admin.original_projects,
      users: rootState.admin.original_users,
    }
  },
}

const mutations = {
  setPortfolioFilters(state: State, params: Filters): void {
    state.portfolioFilters = params
  },

  setProjectFilters(state: State, params: Filters): void {
    state.projectFilters = params
  },

  setTeamFilters(state: State, params: Filters): void {
    state.teamFilters = params
  },
}

const actions = {
  savePortfolioFilters({ commit }: Context, filters: Filters): void {
    commit('setPortfolioFilters', filters)
  },

  saveProjectFilters({ commit }: Context, filters: Filters): void {
    commit('setProjectFilters', filters)
  },

  saveTeamFilters({ commit }: Context, filters: Filters): void {
    commit('setTeamFilters', filters)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}

export interface State {
  showSidebar: boolean
}

const state = (): State => ({
  showSidebar: false,
})

export const mutations = {
  toggleSidebarValue(state: State): void {
    state.showSidebar = !state.showSidebar
  },
}

export default {
  namespaced: true,
  state,
  mutations,
}

<template>
  <Dialog
    :visible="!!id"
    :dismissableMask="true"
    @update:visible="closeDialog"
    position="bottom"
    modal
  >
    <template #header>
      <div class="header-wrapper">
        <div class="capitalize">Gitlab • Tokens</div>
        <div class="flex gap-1">
          <IconButton
            class="p-button-text"
            @click="showDeleteConfirmationDialog = true"
          >
            <i aria-hidden="true" class="pi pi-trash"></i>
          </IconButton>
        </div>
      </div>
    </template>
    <div class="title">{{ formData.name || 'Token Name' }}</div>
    <TokenForm
      v-model:name="formData.name"
      v-model:token="formData.token"
      v-model:base_url="formData.base_url"
      :status="formData.status"
      edit
      :v$="v$"
    ></TokenForm>
    <template #footer>
      <Button class="p-button-outlined" size="large" @click="closeDialog">
        Cancel
      </Button>
      <Button size="large" @click="onEditToken">Save Changes</Button>
    </template>
    <DeleteConfirmationDialog
      v-model:open="showDeleteConfirmationDialog"
      :name="formData.name"
      :loading="loading"
      @delete="onDeleteToken"
      input-confirmation
      subtitle="Deleting the token will delete all related and mapped data."
    ></DeleteConfirmationDialog>
  </Dialog>
</template>

<script setup lang="ts">
import Dialog from 'primevue/dialog/Dialog.vue'
import { defineProps, ref, defineEmits, watchEffect } from 'vue'
import Button from '@/components/common/buttons/Button.vue'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { useToast } from 'primevue/usetoast'
import { useStore } from '@/store'
import IconButton from '@/components/common/buttons/IconButton.vue'
import TokenForm from '@/components/admin-dashboard/gitlab/tokens/TokenForm.vue'
import { diffFields, showToastError } from '@/utils/utils'
import DeleteConfirmationDialog from '@/components/common/dialogs/DeleteConfirmationDialog.vue'
import { clone, pickBy } from 'ramda'

const props = defineProps<{ id: boolean | number }>()

const initialForm = () => ({
  name: '',
  token: '',
  base_url: 'https://gitlab.com',
  status: '',
})

const emit = defineEmits<{ (e: 'update:id', value: boolean): void }>()
const toast = useToast()
const store = useStore()
const formData = ref(initialForm())
const $externalResults = ref({})
const showDeleteConfirmationDialog = ref(false)
const loading = ref(false)
const originalToken = ref({})

const formRules = {
  name: { required, $lazy: true },
  token: { onlyBackend: () => true, $autoDirty: true },
  base_url: { required, $autoDirty: true, $lazy: true },
}
const v$ = useVuelidate(formRules, formData, { $externalResults })

watchEffect(async () => {
  if (props.id) {
    formData.value = {
      ...(await store.dispatch('gitlab/getToken', props.id)),
      token: 'placeholder',
    }
    originalToken.value = clone(formData.value)
  }
})

async function onEditToken() {
  v$.value.$touch()
  if (v$.value.$invalid) {
    return
  }
  try {
    await store.dispatch('gitlab/editToken', {
      ...pickBy(diffFields(originalToken.value), formData.value),
      id: props.id,
    })
    closeDialog()
  } catch (e: any) {
    if (e.response?.data?.token || e.response?.data?.base_url) {
      $externalResults.value = e.response.data
    } else {
      showToastError(toast, e)
    }
  }
}

async function onDeleteToken(verificationCode: string) {
  loading.value = true
  try {
    await store.dispatch('gitlab/deleteToken', {
      id: props.id,
      verificationCode: verificationCode,
    })
    loading.value = false
    showDeleteConfirmationDialog.value = false
    closeDialog()
  } catch (e) {
    loading.value = false
    showToastError(toast, e)
  }
}

function closeDialog() {
  formData.value = initialForm()
  v$.value.$reset()

  emit('update:id', false)
}
</script>

<style scoped>
.header-wrapper {
  @apply flex items-center justify-between w-full;
}
.title {
  @apply text-xl font-semi-bold mb-3;
}
</style>

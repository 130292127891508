<template>
  <div class="flex text-base flex-col items-start justify-start text-left">
    <div v-if="withTitle" class="title">Connect Gitlab…</div>
    <div class="text-black mb-5">
      1. Copy current or create new token with these follow rights to connect
      Gitlab: <span class="code">api</span>, <span class="code">read_api</span>,
      <span class="code">read_repository</span>,
      <span class="code">write_repository</span>
    </div>
    <div class="w-full mb-5">
      <div class="text-black mb-1">2. Add name of the token:</div>
      <BaseInputText
        class="w-full"
        v-model:value="formData.name"
        :errors="v$.name.$errors"
      ></BaseInputText>
    </div>
    <div class="w-full mb-3">
      <div class="text-black mb-1">3. Paste your token:</div>
      <BaseInputText
        class="w-full"
        v-model:value="formData.token"
        :errors="v$.token.$errors"
      ></BaseInputText>
    </div>
    <div class="w-full mb-5">
      <div class="text-black mb-1">4. Add base URL:</div>
      <BaseInputText
        class="w-full"
        v-model:value="formData.base_url"
        :errors="v$.base_url.$errors"
      ></BaseInputText>
    </div>
    <Button @click="onAddToken">Add Token</Button>
  </div>
</template>

<script setup lang="ts">
import { defineEmits, defineProps, ref } from 'vue'
import Button from '@/components/common/buttons/Button.vue'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { useToast } from 'primevue/usetoast'
import { useStore } from '@/store'
import { showToastError } from '@/utils/utils'
import BaseInputText from '@/components/common/base/BaseInputText.vue'

defineProps<{ withTitle?: boolean }>()
const emit = defineEmits<{ (e: 'connected'): void }>()

const initialForm = () => ({
  name: '',
  token: '',
  base_url: 'https://gitlab.com',
})

const toast = useToast()
const store = useStore()
const formData = ref(initialForm())
const $externalResults = ref({})

const formRules = {
  name: { required, $lazy: true },
  token: { required, $autoDirty: true, $lazy: true },
  base_url: { required, $autoDirty: true, $lazy: true },
}

const v$ = useVuelidate(formRules, formData, { $externalResults })

async function onAddToken() {
  v$.value.$touch()
  if (v$.value.$invalid) {
    return
  }
  try {
    await store.dispatch('gitlab/addToken', formData.value)
    emit('connected')
  } catch (e: any) {
    if (e.response?.data?.token || e.response?.data?.base_url) {
      $externalResults.value = e.response.data
    } else {
      showToastError(toast, e)
    }
  }
}
</script>

<style scoped>
.title {
  @apply text-lg font-semi-bold mb-5;
}
.code {
  border-radius: 2px;
  padding: 2px 3px;
  @apply bg-gray-200;
}
</style>

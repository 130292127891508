<template>
  <TableWrapper :title="tableTitle">
    <template #button>
      <TableSearchInput
        v-model:value="search"
        placeholder="Search by project"
      ></TableSearchInput>
    </template>
    <template #default>
      <LoaderWrapper v-if="loading"></LoaderWrapper>
      <MyTable
        v-else
        :table-data="bitbucketUsers"
        :columns-data="columnsData"
        :lazy-table="true"
        :lazy-loading="lazyLoading"
        :scrollable="scrollableTable"
        @load="onLazyLoad"
        @sort="onSort"
      >
        <template #original_name="{ data }">
          <BaseDropdown
            :options="originalUsers"
            v-model:selected="data.original_name"
            v-model:newly-created-option="newlyCreatedUser"
            :option-id="data.id"
            search
            deselect
            table
            @update:selected="onChangeOriginalUser($event, data.id)"
            @add="onAddNewUser(data.id)"
          ></BaseDropdown>
        </template>
      </MyTable>
      <AddUserMappingDialog
        v-model:open="showUserCreateDialog"
        @save-user-id="newlyCreatedUser.new_option_id = $event"
      ></AddUserMappingDialog>
    </template>
  </TableWrapper>
</template>

<script setup lang="ts">
import TableWrapper from '@/components/common/table/TableWrapper.vue'
import { useStore } from '@/store'
import { computed, onMounted, ref, watch } from 'vue'
import MyTable from '@/components/common/table/MyTable.vue'
import { useToast } from 'primevue/usetoast'
import { calcSortOrderingAndField, showToastError } from '@/utils/utils'
import { BitbucketUser } from '@/store/modules/admin/bitbucket'
import LoaderWrapper from '@/components/common/loader/LoaderWrapper.vue'
import AddUserMappingDialog from '@/components/admin-dashboard/system/user-mapping/AddUserMappingDialog.vue'
import TableSearchInput from '@/components/common/table/TableSearchInput.vue'
import useTableSearch from '@/utils/hooks/useTableSearch'
import { debounce } from 'lodash'
import BaseDropdown from '@/components/common/base/BaseDropdown.vue'

const store = useStore()
const toast = useToast()
const totalRecords = ref<number>(0)
const nextPage = ref<string | null>(null)
const bitbucketUsers = ref<BitbucketUser[]>([])
const scrollableTable = ref(true)
const { search } = useTableSearch()
const debouncedGetRequest = debounce(getFirstPageOfUsers, 1000)

onMounted(() => {
  getFirstPageOfUsers()
})

const loading = ref(false)
const lazyLoading = ref(false)
const showUserCreateDialog = ref(false)
const newlyCreatedUser = ref<{ option_id?: number; new_option_id?: number }>({})
const originalUsers = computed(() => store.state.admin.original_users)
const tableTitle = computed(() => `Users • ${totalRecords.value}`)
const columnsData = [
  {
    header: 'Full name',
    field: 'display_name',
    is_sortable: true,
    use_template: false,
  },
  {
    header: 'User name',
    field: 'nickname',
    is_sortable: true,
    use_template: false,
  },
  {
    header: 'Original name',
    field: 'original_name',
    use_template: true,
  },
]

watch([search], () => {
  if (search.value) {
    debouncedGetRequest()
  } else {
    getFirstPageOfUsers()
  }
})

async function onChangeOriginalUser(
  originalUserId: number | string | null,
  id: number
) {
  try {
    await store.dispatch('bitbucket/editUser', {
      original_name: originalUserId,
      id: id,
    })
  } catch (e) {
    showToastError(toast, e)
  }
}
/* istanbul ignore next */
async function onLazyLoad(event: { first: number; last: number }) {
  if (lazyLoading.value) return
  const { last } = event
  lazyLoading.value = true
  try {
    if (nextPage.value && last === bitbucketUsers.value.length) {
      const page = await store.dispatch(
        'bitbucket/getNextPageOfUsers',
        nextPage.value
      )
      nextPage.value = page.next
      bitbucketUsers.value = bitbucketUsers.value.concat(page.results)
    }
    lazyLoading.value = false
  } catch (e) {
    lazyLoading.value = false
    showToastError(toast, e)
  }
}

function getFirstPageOfUsers(ordering?: null | string) {
  if (typeof ordering !== 'undefined') {
    lazyLoading.value = true
  } else {
    loading.value = true
  }
  store
    .dispatch('bitbucket/getFirstPageOfUsers', {
      ordering: ordering || null,
      search: search.value,
    })
    .then((data) => {
      totalRecords.value = data.count
      nextPage.value = data.next
      bitbucketUsers.value = data.results
      lazyLoading.value = false
      loading.value = false
    })
    .catch((e) => {
      lazyLoading.value = false
      loading.value = false
      showToastError(toast, e)
    })
}

function onSort(event: Event) {
  getFirstPageOfUsers(calcSortOrderingAndField(event))
}

const onAddNewUser = (id: number) => {
  newlyCreatedUser.value.option_id = id
  showUserCreateDialog.value = true
}
</script>

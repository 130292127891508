<template>
  <div>
    <span
      class="w-full"
      :class="[
        iconLeft && 'p-input-icon-left',
        iconRight && inputValue && 'p-input-icon-right',
      ]"
    >
      <i v-if="iconLeft" class="pi" :class="iconLeft" aria-hidden="true" />
      <InputText
        v-model="inputValue"
        ref="input"
        :type="type"
        class="w-full"
        :placeholder="placeholder"
        :class="{ 'p-invalid': errors.length }"
        :disabled="disabled"
        v-bind="$attrs"
      />
      <i
        v-if="showRightIcon"
        class="pi clear-icon"
        :class="iconRight"
        @click.stop="onIconClick"
        aria-hidden="true"
      />
    </span>
    <div
      v-for="error of errors"
      class="text-left text-sm text-danger-600 mt-1"
      :key="error.$uid"
    >
      {{ error.$message }}
    </div>
  </div>
</template>

<script setup lang="ts">
import InputText from 'primevue/inputtext/InputText.vue'
import {
  computed,
  defineEmits,
  defineProps,
  ref,
  watchEffect,
  withDefaults,
} from 'vue'

const props = withDefaults(
  defineProps<{
    value: null | string | number
    errors?: any
    placeholder?: string
    type?: string
    iconLeft?: string
    iconRight?: string
    disabled?: boolean
    autofocus?: boolean
  }>(),
  { type: 'text', iconRight: 'pi-times-circle', errors: [], autofocus: false }
)

const emit = defineEmits<{
  (e: 'update:value', value: null | number | string): void
  (e: 'icon-click'): void
  (e: 'clear'): void
}>()

const input = ref<{ $el: HTMLInputElement } | null>(null)

const inputValue = computed({
  get() {
    return props.value
  },
  set(value: string | null | number) {
    emit('update:value', value)
  },
})

const isClearIcon = computed(() => props.iconRight === 'pi-times-circle')
const showRightIcon = computed(() => {
  if (isClearIcon.value) {
    return !!inputValue.value && !props.disabled
  } else {
    return props.disabled
  }
})

const focus = () => {
  if (input.value && input.value.$el) {
    input.value.$el.focus()
  }
}

watchEffect(() => {
  if (props.autofocus) {
    focus()
  }
})

const onIconClick = () => {
  if (isClearIcon.value) {
    emit('update:value', '')
    emit('clear')
    focus()
  } else {
    emit('icon-click')
  }
}
</script>

<style>
.clear-icon {
  cursor: pointer;
}
</style>

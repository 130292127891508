<template>
  <div class="text-base">
    <Checkbox
      :id="id"
      :value="valueOption"
      v-model="selectedValue"
      :name="nameOption"
      class="mr-3"
      @change="$emit('change')"
      v-bind="$attrs"
      :disabled="disabled"
      :binary="binary"
    ></Checkbox>
    <label :for="id">{{ label }}</label>
  </div>
</template>

<script setup lang="ts">
import Checkbox from 'primevue/checkbox/Checkbox.vue'
import { computed, defineEmits, defineProps, withDefaults } from 'vue'
const props = withDefaults(
  defineProps<{
    id?: number | string
    value: boolean | number[] | null
    label: string
    nameOption?: string
    valueOption?: string | number
    disabled?: boolean
    binary?: boolean
  }>(),
  { nameOption: 'author', disabled: false }
)

const emit = defineEmits<{
  (e: 'update:value', value: boolean | number[] | null): void
  (e: 'change'): void
}>()

const selectedValue = computed({
  get() {
    return props.value
  },
  set(value: boolean | number[] | null) {
    emit('update:value', value)
  },
})
</script>

<template>
  <ChartWrapper
    :title="chartTitle[chartTypes.VelocityFactor]"
    :tooltip="chartExplanations[chartTypes.VelocityFactor]"
    :fetch-error="fetchError"
    :loading="loading"
    :is-chart-data-empty="isNil(velocityFactor)"
    :type="chartTypes.VelocityFactor"
    :icons="['jira']"
    tooltip-class="max-width-small-tooltip"
  >
    <div
      class="h-full flex flex-grow items-center justify-center text-3xl font-bold"
    >
      {{ velocityFactor.toFixed(3) }}
    </div>
  </ChartWrapper>
</template>

<script setup lang="ts">
import { computed, defineProps } from 'vue'
import { isNil, omit } from 'ramda'
import { Filters } from '@/store/modules/filters'
import {
  chartExplanations,
  chartTypes,
  chartTitle,
} from '@/constants/charts/constants'
import useGettingChartData from '@/utils/hooks/useGettingChartData'
import ChartWrapper from '@/components/charts/ChartWrapper.vue'
import { AGGREGATE_BY } from '@/constants/constants'

const props = defineProps<{ filters: Filters; projectPage: boolean }>()

const { response, loading, fetchError } = useGettingChartData(
  {
    ...props,
    filters: {
      ...omit(['scale_type'], props.filters),
      aggregate_by: AGGREGATE_BY.PROJECTS,
    },
  },
  chartTypes.VelocityFactor
)

const velocityFactor = computed(
  () => response.value?.data.data[0]?.velocity_factor || 0
)
</script>

<template>
  <div class="w-full">
    <Dropdown
      v-model="selectedValue"
      :options="searchMatchedItems"
      :option-value="optionValue"
      :option-label="optionLabel"
      placeholder="- Select -"
      class="w-full"
      v-bind="$attrs"
      :class="{
        'p-invalid': errors.length,
        'table-dropdown p-inputtext-sm': table,
      }"
      :disabled="disabled"
      @change="$emit('change')"
      @hide="onHide"
      @show="focusInput"
    >
      <template v-if="search" #header>
        <BaseInputText
          v-model:value="searchItem"
          class="px-2 py-1"
          icon-left="pi-search"
          placeholder="Search"
          :autofocus="isInputShouldBeOnFocus"
        ></BaseInputText>
      </template>
      <template #value="slotProps">
        <slot name="value" :slotProps="slotProps"></slot>
      </template>
      <template #option="slotProps">
        <slot name="option" :slotProps="slotProps"></slot>
      </template>
      <template v-if="newlyCreatedOption || (deselect && selected)" #footer>
        <Divider class="divider"></Divider>
        <div
          v-if="deselect && selected"
          class="unselect-button"
          @click="$emit('update:selected', null)"
        >
          <span class="pi pi-minus text-gray-600 mr-3"></span>
          <div>Deselect {{ target || 'option' }}</div>
        </div>
        <div v-if="newlyCreatedOption" class="add-button" @click="$emit('add')">
          <span class="pi pi-plus text-gray-600 mr-3"></span>
          <div>Add new {{ target }}</div>
        </div>
      </template></Dropdown
    >
    <div
      v-for="error of errors"
      class="text-left text-sm text-danger-600 mt-1"
      :key="error.$uid"
    >
      {{ error.$message }}
    </div>
  </div>
</template>

<script setup lang="ts">
import Dropdown from 'primevue/dropdown/Dropdown.vue'
import Divider from 'primevue/divider/Divider.vue'
import {
  computed,
  defineProps,
  defineEmits,
  withDefaults,
  ref,
  watchEffect,
} from 'vue'
import BaseInputText from '@/components/common/base/BaseInputText.vue'

const props = withDefaults(
  defineProps<{
    selected: null | number | string
    options: any
    optionValue?: string
    optionLabel?: string
    stringArray?: boolean
    errors?: any
    table?: boolean
    disabled?: boolean
    target?: string
    newlyCreatedOption?: { option_id?: number; new_option_id?: number }
    optionId?: number | string
    search?: boolean
    deselect?: boolean
  }>(),
  { optionValue: 'id', optionLabel: 'name', errors: [], target: 'user' }
)

const emit = defineEmits<{
  (e: 'update:selected', value: null | number | string): void
  (e: 'change'): void
  (e: 'add'): void
  (
    e: 'update:newly-created-option',
    value: { option_id?: number; new_option_id?: number }
  ): void
}>()

watchEffect(() => {
  if (
    props.newlyCreatedOption?.new_option_id &&
    props.optionId === props.newlyCreatedOption.option_id
  ) {
    emit('update:selected', props.newlyCreatedOption.new_option_id)
    emit('update:newly-created-option', {})
  }
})

const optionValue = computed(() =>
  props.stringArray ? null : props.optionValue
)
const optionLabel = computed(() =>
  props.stringArray ? null : props.optionLabel
)

const selectedValue = computed({
  get() {
    return props.selected
  },
  set(value: null | number | string) {
    emit('update:selected', value)
  },
})

const searchItem = ref('')
const isInputShouldBeOnFocus = ref(false)

const searchMatchedItems = computed(() => {
  if (searchItem.value) {
    return props.options.filter((item: any) => {
      return item.name.toLowerCase().includes(searchItem.value.toLowerCase())
    })
  }
  return props.options
})

const onHide = () => {
  searchItem.value = ''
  isInputShouldBeOnFocus.value = false
}

const focusInput = () => {
  if (props.table && !selectedValue.value) {
    isInputShouldBeOnFocus.value = true
  }
}
</script>

<style scoped>
.table-dropdown {
  @apply font-semi-bold text-gray-900;
}

.divider {
  @apply mx-2 w-auto my-1;
  height: 1px;
}
.add-button,
.unselect-button {
  @apply flex items-center px-2 py-2 rounded-md hover:bg-gray-50 cursor-pointer;
}
</style>

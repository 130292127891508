<template>
  <div
    class="item"
    :class="{
      selected:
        (customPeriod && isCustomPeriodButtonSelected) ||
        isKnownPeriodButtonSelected,
      'items-center': customPeriod,
    }"
  >
    <div>{{ title }}</div>
    <i
      v-if="isKnownPeriodButtonSelected && !customPeriod"
      class="pi pi-check selected-icon"
      aria-hidden="true"
    ></i>
    <i
      v-if="customPeriod"
      aria-hidden="true"
      class="pi pi-chevron-right text-gray-600"
    ></i>
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps } from 'vue'
import {
  isCustomFilterButtonSelected,
  isFilterButtonSelected,
} from '@/utils/utils'

const props = defineProps<{
  title: string
  filters: {
    since: string | null
    until: string | null
  }
  customPeriod?: boolean
}>()

const isKnownPeriodButtonSelected = computed(() =>
  isFilterButtonSelected(props.title, props.filters)
)

const isCustomPeriodButtonSelected = computed(() =>
  isCustomFilterButtonSelected(props.title, props.filters)
)
</script>

<style scoped lang="scss">
.item {
  min-width: 150px;
  @apply cursor-pointer flex justify-between  text-black px-3 py-2 rounded-md hover:bg-gray-50;
}
.selected {
  @apply bg-key-50 hover:bg-key-50;
  .selected-icon {
    width: 14px;
    height: 14px;
    @apply text-key-500 text-base;
  }
}
</style>

<template>
  <FiltersWrapper>
    <FilterButton v-if="!isLOC" :active="!!selectedProjects" hide-controls>
      <template #btn>
        <span>Projects</span>
        <span>{{ selectedProjects }}</span>
      </template>
      <template #default>
        <MultipleCheckboxes
          v-model:selected="formData.projects"
          :options="projectsOptions"
        ></MultipleCheckboxes>
      </template>
    </FilterButton>
    <FilterButton v-if="!isLOC" :active="!!selectedAuthors" hide-controls>
      <template #btn>
        <span>Authors</span>
        <span>{{ selectedAuthors }}</span>
      </template>
      <template #default>
        <MultipleCheckboxes
          v-model:selected="formData.users"
          :options="authorsOptions"
        ></MultipleCheckboxes>
      </template>
    </FilterButton>
    <FilterButton v-if="!isLOC" :active="!!selectedDates" hide-controls>
      <template #btn>
        <span>Date</span>
        <span>{{ selectedDates }}</span>
      </template>
      <template #default>
        <div class="flex flex-nowrap items-center">
          <BaseCalendar
            v-model:value="formData.since"
            input-style="width:124px"
            show-button-bar
          ></BaseCalendar>
          <span class="px-4">to</span>
          <BaseCalendar
            v-model:value="formData.until"
            input-style="width:124px"
            show-button-bar
          ></BaseCalendar>
        </div>
      </template>
    </FilterButton>
    <FilterButton :active="!!selectedInsertions" hide-controls>
      <template #btn>
        <span>Insertions</span>
        <span>{{ selectedInsertions }}</span>
      </template>
      <template #default>
        <div class="flex flex-nowrap items-center">
          <BaseInputNumber
            :value="formData.insertions_gte"
            @update:value="
              formData.insertions_gte = isNotNil($event)
                ? String($event)
                : $event
            "
            inputStyle="width: 124px"
          ></BaseInputNumber>
          <span class="px-4">to</span>
          <BaseInputNumber
            :value="formData.insertions_lte"
            @update:value="
              formData.insertions_lte = isNotNil($event)
                ? String($event)
                : $event
            "
            inputStyle="width: 124px"
          ></BaseInputNumber>
        </div>
      </template>
    </FilterButton>
    <FilterButton :active="!!selectedDeletions" hide-controls>
      <template #btn>
        <span>Deletions</span>
        <span>{{ selectedDeletions }}</span>
      </template>
      <template #default>
        <div class="flex flex-nowrap items-center">
          <BaseInputNumber
            :value="formData.deletions_gte"
            @update:value="
              formData.deletions_gte = isNotNil($event)
                ? String($event)
                : $event
            "
            inputStyle="width: 124px"
          ></BaseInputNumber>
          <span class="px-4">to</span>
          <BaseInputNumber
            :value="formData.deletions_lte"
            @update:value="
              formData.deletions_lte = isNotNil($event)
                ? String($event)
                : $event
            "
            inputStyle="width: 124px"
          ></BaseInputNumber>
        </div>
      </template>
    </FilterButton>
    <FilterButton :active="!!selectedDuplicates" hide-controls>
      <template #btn>
        <span>Duplicates</span>
        <span>{{ selectedDuplicates }}</span>
      </template>
      <template #default>
        <div class="flex flex-nowrap items-center">
          <BaseInputNumber
            :value="formData.duplicated_lines_gte"
            @update:value="
              formData.duplicated_lines_gte = isNotNil($event)
                ? String($event)
                : $event
            "
            inputStyle="width: 124px"
          ></BaseInputNumber>
          <span class="px-4">to</span>
          <BaseInputNumber
            :value="formData.duplicated_lines_lte"
            @update:value="
              formData.duplicated_lines_lte = isNotNil($event)
                ? String($event)
                : $event
            "
            inputStyle="width: 124px"
          ></BaseInputNumber>
        </div>
      </template>
    </FilterButton>
    <div class="btn-wrapper mr-2">
      <Button class="apply" size="small" @click="onApplyFilters">Apply</Button>
    </div>
    <div v-if="isFiltersActive" class="btn-wrapper">
      <Button
        class="clear p-button-outlined"
        size="small"
        @click="onClearFilters"
      >
        Clear Filters
      </Button>
    </div>
  </FiltersWrapper>
</template>

<script setup lang="ts">
import { computed, ref, defineProps } from 'vue'
import { CommitsFilter } from '@/store/modules/admin/gitlab'
import { useStore, State } from '@/store'
import MultipleCheckboxes from '@/components/common/form/MultipleCheckboxes.vue'
import { Store } from 'vuex'
import BaseInputNumber from '@/components/common/base/BaseInputNumber.vue'
import BaseCalendar from '@/components/common/base/BaseCalendar.vue'
import FiltersWrapper from '@/components/common/filters/local/filterbar/FiltersWrapper.vue'
import FilterButton from '@/components/common/filters/local/filterbar/FilterButton.vue'
import Button from '@/components/common/buttons/Button.vue'
import { onBeforeRouteUpdate } from 'vue-router'
import { isNotNil } from 'ramda-adjunct'

const props = defineProps<{ isLOC: boolean }>()

const store: Store<State> = useStore()

const initialFormData = () => {
  return {
    projects: null,
    users: null,
    since: null,
    until: null,
    insertions_gte: null,
    insertions_lte: null,
    deletions_gte: null,
    deletions_lte: null,
    duplicated_lines_gte: null,
    duplicated_lines_lte: null,
  }
}

const formData = ref<CommitsFilter>(initialFormData())

const authorsOptions = computed(() => {
  return store.state.admin.original_users
})

const projectsOptions = computed(() => {
  return store.state.admin.original_projects
})

const selectedDuplicates = computed(() => {
  return formData.value.duplicated_lines_gte ||
    formData.value.duplicated_lines_lte
    ? `: ${formData.value.duplicated_lines_gte || 0} to ${
        formData.value.duplicated_lines_lte || 'Max'
      }`
    : ''
})

const selectedDeletions = computed(() => {
  return formData.value.deletions_gte || formData.value.deletions_lte
    ? `: ${formData.value.deletions_gte || 0} to ${
        formData.value.deletions_lte || 'Max'
      }`
    : ''
})

const selectedInsertions = computed(() => {
  return formData.value.insertions_gte || formData.value.insertions_lte
    ? `: ${formData.value.insertions_gte || 0} to ${
        formData.value.insertions_lte || 'Max'
      }`
    : ''
})

const selectedDates = computed(() => {
  if (!formData.value.since && !formData.value.until) {
    return ''
  }
  const since = formData.value.since?.replaceAll('-', '/') || 'first day'
  const until = formData.value.until?.replaceAll('-', '/') || 'now'
  return `: ${since} to ${until}`
})

const selectedAuthors = computed(() => {
  return formData.value.users && formData.value.users?.length
    ? `: ${formData.value.users.length}`
    : ''
})

const selectedProjects = computed(() => {
  return formData.value.projects && formData.value.projects?.length
    ? `: ${formData.value.projects.length}`
    : ''
})

const isFiltersActive = computed(() => {
  return [
    !!selectedDuplicates.value,
    !!selectedDeletions.value,
    !!selectedInsertions.value,
    !!selectedDates.value,
    !!selectedAuthors.value,
    !!selectedProjects.value,
  ].some(Boolean)
})

const onApplyFilters = () => {
  store.commit(`${props.isLOC ? 'loc' : 'gitlab'}/setCommitsFilters`, {
    ...formData.value,
  })
}

const onClearFilters = () => {
  store.commit(
    `${props.isLOC ? 'loc' : 'gitlab'}/setCommitsFilters`,
    initialFormData()
  )
  formData.value = initialFormData()
}

onBeforeRouteUpdate(() => {
  onClearFilters()
})
</script>

<template>
  <div class="h-full base-container">
    <FiltersBar
      :filters="filters"
      :filtering-options="filterItems"
    ></FiltersBar>
    <main>
      <div class="flex flex-wrap px-8 mb-8">
        <div class="flex w-2/3">
          <MilestonesTable
            :filters="filters"
            :key="milestoneChartKey"
          ></MilestonesTable>
          <Divider layout="vertical"></Divider>
        </div>
        <VelocityFactorTable
          :filters="filters"
          :key="chartKey"
          :aggregation="AGGREGATE_BY.PROJECTS"
          custom-height
        ></VelocityFactorTable>
        <Divider class="my-8" />
        <div class="flex w-full">
          <ProjectAllocationChart
            :filters="filters"
            :key="chartKey"
          ></ProjectAllocationChart>
        </div>
        <Divider class="my-8" />
        <div class="flex w-1/2">
          <Activities
            :filters="filters"
            :key="chartKey"
            :aggregation="AGGREGATE_BY.PROJECTS"
            localTimeAggregation
          ></Activities>
          <Divider layout="vertical"></Divider>
        </div>
        <LOCChart
          :filters="filters"
          :key="chartKey"
          :aggregation="AGGREGATE_BY.PROJECTS"
        ></LOCChart>
        <Divider class="my-8" />
        <div class="flex w-1/2">
          <BillableTimeChart
            :filters="filters"
            :key="chartKey"
            :aggregation="AGGREGATE_BY.PROJECTS"
          ></BillableTimeChart>
          <Divider layout="vertical"></Divider>
        </div>
        <EstimationCoverageChart
          :filters="filters"
          :key="chartKey"
          :aggregation="AGGREGATE_BY.PROJECTS"
        ></EstimationCoverageChart>
        <Divider class="my-8" />
        <div class="flex w-1/2">
          <EstimationAccuracyChart
            :filters="filters"
            :key="chartKey"
            :aggregation="AGGREGATE_BY.PROJECTS"
          ></EstimationAccuracyChart>
          <Divider layout="vertical"></Divider>
        </div>
        <ReopenedTicketsChart
          :filters="filters"
          :key="chartKey"
          :aggregation="AGGREGATE_BY.PROJECTS"
        ></ReopenedTicketsChart>
      </div>
    </main>
  </div>
</template>
<script setup lang="ts">
import FiltersBar from '@/components/common/menu/PortfolioFiltersBar.vue'
import { useStore } from '@/store'
import { computed } from 'vue'
import { AGGREGATE_BY } from '@/constants/constants'
import LOCChart from '@/components/charts/loc/LOCChart.vue'
import BillableTimeChart from '@/components/charts/billable-time/BillableTimeChart.vue'
import EstimationCoverageChart from '@/components/charts/estimation-coverage/EstimationCoverageChart.vue'
import EstimationAccuracyChart from '@/components/charts/estimation-accuracy/EstimationAccuracyChart.vue'
import ReopenedTicketsChart from '@/components/charts/reopened-tickets/ReopenedTicketsChart.vue'
import ProjectAllocationChart from '@/components/charts/project-allocation/ProjectAllocationChart.vue'
import VelocityFactorTable from '@/components/charts/velocity-factor/VelocityFactorTable.vue'
import MilestonesTable from '@/components/charts/milestones/MilestonesTable.vue'
import Activities from '@/components/charts/user-activity/Activities.vue'
import { omit } from 'ramda'

const store = useStore()
const filters = computed(() => store.state.filters.portfolioFilters)
const filterItems = computed(() => store.getters['filters/filterItems'])

const chartKey = computed(() => JSON.stringify(filters.value))
const milestoneChartKey = computed(() =>
  JSON.stringify(omit(['since', 'until'], filters.value))
)
</script>

<style lang="scss" scoped>
.base-container {
  max-height: 100vh;
  flex-grow: 1 !important;
  overflow: auto;
  padding-bottom: 32px;
}
</style>

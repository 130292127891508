import axios from '@/services/axios'

import { ActionContext } from 'vuex'
import { State as GlobalState } from '../../index'
import { omit, path } from 'ramda'
import { ESTIMATION_TYPE } from '@/constants/constants'

export interface Ticket {
  summary: string
  status: string
  adaptive_estimation: number
  key: string
  base_url: string
  estimation_type: string
  absolute_url: string
}

export interface Page {
  count: number
  next: string
  previous: string
  results: Ticket[]
}

export interface TicketsFilters {
  since: string | null
  until: string | null
  users?: number[] | null
  projects?: number[] | null
  scale_type?: string
  ordering?: string | null
  limit?: string | null
  search?: string | null
  releases?: string | null
  exclude_done?: string
}

export interface TotalsTickets {
  estimation_type: ESTIMATION_TYPE
  total_adaptive_estimation: number
  total_difference_amount: number
  total_time_spent_seconds: number
}

type Context = ActionContext<Page, GlobalState>

const actions = {
  getFirstPageOfTickets(
    _: Context,
    params: { filters: TicketsFilters; project_id: number; company_id: number }
  ): Promise<void> {
    return axios
      .get(
        `/api/companies/${params.company_id}/project-mapping/${params.project_id}/charts/issues/`,
        { params: params.filters }
      )
      .then(path(['data']))
  },
  getNextPageOfTickets(_: Context, url: string): Promise<void> {
    return axios.get(`${url}`).then(path(['data']))
  },
  getTotalsOfTickets(
    _: Context,
    params: { filters: TicketsFilters; project_id: number; company_id: number }
  ): Promise<TotalsTickets | undefined> {
    return axios
      .get(
        `/api/companies/${params.company_id}/project-mapping/${params.project_id}/charts/issues/total-stats/`,
        { params: omit(['ordering', 'search'], params.filters) }
      )
      .then(path(['data']))
  },
}

export default {
  namespaced: true,
  actions,
}

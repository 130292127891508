<template>
  <div>
    <span v-tooltip.right="tooltip">
      <i class="status-icon pi" :class="cellClass"></i>
    </span>
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps } from 'vue'
const props = defineProps<{ tooltip: string; status: string }>()

const cellClass = computed(() => {
  if (props.status === 'success') {
    return 'pi-check bg-success-500'
  } else if (props.status === 'danger') {
    return 'bg-danger-500 pi-times'
  } else if (props.status === 'warning') {
    return 'bg-warning-500 pi-sync'
  } else {
    return ''
  }
})
</script>

<style scoped lang="scss">
.status-icon {
  padding: 3px;
  font-size: 9px;
  @apply rounded-circle text-white font-bold cursor-default mr-4;
}
</style>

<template>
  <TableWrapper :title="tableTitle">
    <template #button>
      <TableSearchInput
        v-model:value="search"
        placeholder="Search by user"
        class="mr-2"
      ></TableSearchInput>

      <IconButton
        class="p-button-outlined add-user-mapping-button"
        size="small"
        icon="pi-plus"
        icon-position="left"
        icon-size="12px"
        @click="showCreateDialog = true"
        >Add</IconButton
      >
    </template>
    <template #default>
      <LoaderWrapper v-if="loading"></LoaderWrapper>
      <MyTable
        v-else
        :table-data="users"
        :columns-data="columnsData"
        :scrollable="scrollableTable"
        :filters="filters"
        :filter-fields="filterFields"
        class="user-mapping-table"
      >
        <template #projects="{ data }">
          <span
            class="cursor-default"
            v-tooltip.bottom="{
              value: projectsTooltip(data.projects),
              class: 'max-width-tooltip',
            }"
          >
            {{ projectsTitle(data.projects) }}</span
          >
        </template>
        <template #is_active="{ data }">
          <StatusWidget :status="data.is_active"></StatusWidget>
        </template>
        <template #position="{ data }">
          {{ USER_POSITIONS_TITLES[data.position] }}
        </template>
        <template #role="{ data }">
          {{ USER_ROLES_TITLES[data.role] }}
        </template>
        <template #editable="{ data }">
          <IconButton
            size="small"
            class="p-button-text invisible group-hover:visible"
            @click="selectedUserId = data.id"
            :disabled="calcIsDisabledEditButton(data.role)"
            v-tooltip.left="
              calcIsDisabledEditButton(data.role)
                ? 'Only owner can edit other owners'
                : ''
            "
            ><i
              class="pi pi-pencil"
              style="font-size: 12px"
              aria-hidden="true"
            ></i
          ></IconButton>
        </template>
      </MyTable>
      <AddUserMappingDialog
        v-model:open="showCreateDialog"
      ></AddUserMappingDialog>
      <EditUserMappingDialog
        v-model:id="selectedUserId"
      ></EditUserMappingDialog>
    </template>
  </TableWrapper>
</template>

<script setup lang="ts">
import TableWrapper from '@/components/common/table/TableWrapper.vue'
import IconButton from '@/components/common/buttons/IconButton.vue'
import { useStore } from '@/store'
import { computed, onMounted, ref } from 'vue'
import StatusWidget from '@/components/common/StatusWidget.vue'
import MyTable from '@/components/common/table/MyTable.vue'
import useTableSearch from '@/utils/hooks/useTableSearch'
import AddUserMappingDialog from '@/components/admin-dashboard/system/user-mapping/AddUserMappingDialog.vue'
import EditUserMappingDialog from '@/components/admin-dashboard/system/user-mapping/EditUserMappingDialog.vue'
import { showToastError } from '@/utils/utils'
import { useToast } from 'primevue/usetoast'
import {
  USER_POSITIONS_TITLES,
  USER_ROLES_TITLES,
  USER_ROLES,
} from '@/constants/constants'
import LoaderWrapper from '@/components/common/loader/LoaderWrapper.vue'
import TableSearchInput from '@/components/common/table/TableSearchInput.vue'
import { Project } from '@/store/modules/admin/admin'

const store = useStore()
const showCreateDialog = ref(false)
const selectedUserId = ref(false)
const toast = useToast()
const loading = ref(false)
const scrollableTable = ref(true)
const filterFields = ['name', 'email', 'position', 'role']
const { search, filters } = useTableSearch('')

onMounted(() => {
  loading.value = true
  store
    .dispatch('admin/getUsers')
    .catch((e) => {
      showToastError(toast, e)
    })
    .finally(() => (loading.value = false))
})

const users = computed(() => store.state.admin.original_users)
const originalProjects = computed(() => store.state.admin.original_projects)
const tableTitle = computed(() => `User mapping • ${users.value.length}`)
const columnsData = [
  {
    header: 'Name',
    field: 'name',
    is_sortable: true,
    use_template: false,
    styles: 'flex-basis:150px',
  },
  {
    header: 'Email',
    field: 'email',
    is_sortable: true,
    use_template: false,
    styles: 'flex-basis:300px',
  },
  {
    header: 'Position',
    field: 'position',
    is_sortable: true,
    use_template: true,
    styles: 'flex-basis:100px',
  },
  {
    header: 'Role',
    field: 'role',
    is_sortable: true,
    use_template: true,
    styles: 'flex-basis:100px',
  },
  {
    header: 'Projects',
    field: 'projects',
    is_sortable: true,
    use_template: true,
    styles: 'flex-basis:100px',
  },
  {
    header: 'Status',
    field: 'is_active',
    is_sortable: true,
    use_template: true,
    styles: 'flex-basis:70px',
  },
  {
    header: '',
    field: 'editable',
    small: true,
    is_sortable: false,
    use_template: true,
    styles: 'flex-grow:0; flex-basis:30px',
  },
]

const projectsTitle = (data: number[]) => {
  if (!data) {
    return ''
  } else if (data.length === 1) {
    return originalProjects.value.find(
      (project: Project) => project.id === data[0]
    )?.name
  } else if (data.length === originalProjects.value.length) {
    return 'All projects'
  } else {
    return `${data.length} projects`
  }
}

const projectsTooltip = (projects: number[]) => {
  return projects && projects.length > 1
    ? projects
        .map(
          (project_id: number) =>
            originalProjects.value.find(
              (project: Project) => project.id === project_id
            )?.name
        )
        .join(', ')
    : ''
}

const calcIsDisabledEditButton = (role: string) => {
  return (
    role === USER_ROLES.OWNER &&
    store.getters['company/userRole'] !== USER_ROLES.OWNER
  )
}
</script>

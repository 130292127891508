<template>
  <div class="flex text-base flex-col items-start justify-start text-left">
    <div v-if="withTitle" class="title">Connect Tempo…</div>
    <div class="w-full mb-3">
      1. In the Tempo application go to Settings and select the API integration
      section.
    </div>
    <div class="w-full mb-3">
      2. In there create a new token or select an existing one, making sure all
      scopes have view rights.
    </div>
    <div class="w-full mb-3">
      <div class="text-black mb-1">3. Add name of the token:</div>
      <BaseInputText
        class="w-full"
        v-model:value="formData.name"
        :errors="v$.name.$errors"
      ></BaseInputText>
    </div>
    <div class="w-full mb-5">
      <div class="text-black mb-1">4. Paste your token:</div>
      <BaseInputText
        class="w-full"
        v-model:value="formData.token"
        :errors="v$.token.$errors"
      ></BaseInputText>
    </div>
    <Button @click="onAddToken">Add Token</Button>
  </div>
</template>

<script setup lang="ts">
import { defineProps, ref, defineEmits } from 'vue'
import Button from '@/components/common/buttons/Button.vue'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { useToast } from 'primevue/usetoast'
import { useStore } from '@/store'
import { showToastError } from '@/utils/utils'
import BaseInputText from '@/components/common/base/BaseInputText.vue'

defineProps<{ withTitle?: boolean }>()
const emit = defineEmits<{ (e: 'connected'): void }>()

const initialForm = () => ({
  name: '',
  token: '',
})

const toast = useToast()
const store = useStore()
const formData = ref(initialForm())
const $externalResults = ref({})

const formRules = {
  name: { required, $lazy: true },
  token: { required, $autoDirty: true, $lazy: true },
}

const v$ = useVuelidate(formRules, formData, { $externalResults })

async function onAddToken() {
  v$.value.$touch()
  if (v$.value.$invalid) {
    return
  }
  try {
    await store.dispatch('tempo/addToken', formData.value)
    emit('connected')
  } catch (e: any) {
    if (e.response?.data?.token) {
      $externalResults.value = e.response.data
    } else {
      showToastError(toast, e)
    }
  }
}
</script>

<style scoped>
.title {
  @apply text-lg font-semi-bold mb-5;
}
</style>

import axios from '@/services/axios'
import { Filters } from '@/store/modules/filters'
import { ActionContext } from 'vuex'
import { State as GlobalState } from '../../index'
import { Aggregate_By_Type } from '@/types/types'

export interface Activity {
  date: string
  commits_count: number
  mrs_count: number
  comments_count: number
}

export interface UserItem {
  user_id: number
  user_name: string
  data: Activity[]
}

export interface ProjectItem {
  original_project_id: number
  project_name: string
  data: Activity[]
}

export interface ActivitiesData {
  aggregate_by: Aggregate_By_Type
  scale_type: string
  data: UserItem[] | ProjectItem[]
}

export interface ActivitiesFilters extends Filters {
  aggregate_by: Aggregate_By_Type
}

type Context = ActionContext<ActivitiesData, GlobalState>

const actions = {
  async getData(
    { rootGetters }: Context,

    filters: ActivitiesFilters
  ): Promise<ActivitiesData> {
    return axios
      .get(
        `api/companies/${rootGetters['company/selectedCompanyId']}/charts/bubble/`,
        { params: filters }
      )
      .then(({ data }) => data)
  },
}

export default {
  namespaced: true,
  actions,
}

<template>
  <Dialog
    :visible="open"
    :dismissableMask="true"
    @update:visible="closeDialog"
    position="bottom"
    modal
  >
    <template #header>
      <div class="header-wrapper">
        <div>Slack</div>
        <div class="flex">
          <Button
            v-if="isNotificationsEnabled"
            class="margin-right p-button-danger disable p-button-text"
            @click="disableNotifications"
            >Disable Notifications</Button
          >
        </div>
      </div>
    </template>
    <div class="title">Slack Settings</div>
    <FormRow title="Non-billable Threshold">
      <BaseInputNumber
        v-model:value="formData.non_billable_threshold"
        class="w-full"
        :min="0"
      ></BaseInputNumber>
    </FormRow>
    <FormRow
      v-if="formData.non_billable_threshold"
      title="Non-billable Template"
      :invalid-state="v$.non_billable_notification_template.$invalid"
    >
      <BaseTextarea
        v-model:value="formData.non_billable_notification_template"
        :errors="v$.non_billable_notification_template.$errors"
      ></BaseTextarea>
    </FormRow>
    <FormRow
      title="Monthly Untracked Notification Templ."
      :invalid-state="v$.untracked_notification_template.$invalid"
    >
      <BaseTextarea
        v-model:value="formData.untracked_notification_template"
        :errors="v$.untracked_notification_template.$errors"
      ></BaseTextarea>
    </FormRow>
    <FormRow
      title="Daily Untracked Notification Templ."
      :invalid-state="v$.daily_untracked_notification_template.$invalid"
    >
      <BaseTextarea
        v-model:value="formData.daily_untracked_notification_template"
        :errors="v$.daily_untracked_notification_template.$errors"
      ></BaseTextarea>
    </FormRow>
    <template #footer>
      <Button
        class="cancel p-button-outlined"
        size="large"
        @click="closeDialog"
      >
        Cancel
      </Button>
      <Button
        class="enable"
        v-if="!isNotificationsEnabled"
        size="large"
        @click="enableNotifications"
        >Enable Notifications</Button
      >
      <Button class="save" v-else size="large" @click="onChangeSettings"
        >Save Changes</Button
      >
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import Dialog from 'primevue/dialog/Dialog.vue'
import { defineProps, ref, defineEmits, watchEffect } from 'vue'
import Button from '@/components/common/buttons/Button.vue'
import { useToast } from 'primevue/usetoast'
import { useStore } from '@/store'
import { showToastError } from '@/utils/utils'
import FormRow from '@/components/common/form/FormRow.vue'
import { clone } from 'ramda'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import BaseInputNumber from '@/components/common/base/BaseInputNumber.vue'
import BaseTextarea from '@/components/common/base/BaseTextarea.vue'

const initialForm = () => ({
  non_billable_threshold: null,
  untracked_notification_template:
    'Hey <@{SLACK_USER}> currently you have {MONTHLY_UNTRACKED_HOURS} untracked hours. Currently tracked {MONTHLY_TRACKED_HOURS} / {REQUIRED_WORKHOURS} hours',
  non_billable_notification_template:
    'Hey <@{SLACK_USER}> you have tracked {MONTHLY_TRACKED_NON_BILLABLE_HOURS_PERCENT}% of your working time to non-billable accounts - allowed {THRESHOLD}% percent',
  daily_untracked_notification_template:
    "Hey <@{SLACK_USER}> yesterday you didn't track {YESTERDAY_UNTRACKED_HOURS} hours. {YESTERDAY_TRACKED_HOURS} / {REQUIRED_WORKHOURS} hours tracked",
})

const props = defineProps<{ open: boolean }>()
const emit = defineEmits<{ (e: 'update:open', value: boolean): void }>()
const toast = useToast()
const store = useStore()
const formData = ref(initialForm())
const isNotificationsEnabled = ref(false)

watchEffect(() => {
  isNotificationsEnabled.value = !!store.state.slack.settings
  if (props.open && isNotificationsEnabled.value) {
    formData.value = { ...formData.value, ...clone(store.state.slack.settings) }
  }
})

const formRules = {
  untracked_notification_template: { required, $lazy: true },
  non_billable_notification_template: { required, $lazy: true },
  daily_untracked_notification_template: { required, $lazy: true },
}

const v$ = useVuelidate(formRules, formData)

async function enableNotifications() {
  v$.value.$touch()
  if (v$.value.$invalid) {
    return
  }
  try {
    await store.dispatch('slack/addSettings', formData.value)
    closeDialog()
  } catch (e) {
    showToastError(toast, e)
  }
}

async function onChangeSettings() {
  v$.value.$touch()
  if (v$.value.$invalid) {
    return
  }
  try {
    await store.dispatch('slack/editSettings', formData.value)
    closeDialog()
  } catch (e) {
    showToastError(toast, e)
  }
}

async function disableNotifications() {
  try {
    await store.dispatch('slack/deleteSettings')
    closeDialog()
  } catch (e) {
    showToastError(toast, e)
  }
}

function closeDialog() {
  formData.value = initialForm()
  emit('update:open', false)
}
</script>

<style scoped>
.header-wrapper {
  @apply flex items-center justify-between w-full;
}
.title {
  @apply text-xl font-semi-bold mb-3;
}

.margin-right {
  @apply mr-1;
}
</style>

<template>
  <FormRow title="Project Name" :invalid-state="validation.name.$invalid">
    <BaseInputText
      class="w-full project-name-input"
      :errors="validation.name.$errors"
      icon-right="pi-times-circle"
      v-model:value="nameValue"
    ></BaseInputText>
  </FormRow>
  <FormRow title="Status">
    <StatusToggleButton v-model:status="isActiveValue"></StatusToggleButton>
  </FormRow>
</template>

<script setup lang="ts">
import FormRow from '@/components/common/form/FormRow.vue'
import StatusToggleButton from '@/components/common/buttons/StatusToggleButton.vue'
import { defineEmits, defineProps, computed } from 'vue'
import BaseInputText from '@/components/common/base/BaseInputText.vue'
import { VuelidateValidation } from '@/types/types'

interface ProjectFormValidation extends VuelidateValidation {
  name: VuelidateValidation
}

const props = defineProps<{
  name: string
  is_active: boolean
  validation: ProjectFormValidation
}>()
const emit = defineEmits<{
  (e: 'update:name', value: string): void
  (e: 'update:is_active', value: boolean): void
}>()

const nameValue = computed({
  get() {
    return props.name
  },
  set(value: string) {
    emit('update:name', value)
  },
})

const isActiveValue = computed({
  get() {
    return props.is_active
  },
  set(value: boolean) {
    emit('update:is_active', value)
  },
})
</script>

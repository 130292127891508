<template>
  <ChartWrapper
    :title="chartTitle.loc"
    :loading="loading"
    :fetch-error="fetchError"
    :tooltip="chartExplanations.loc"
    :is-chart-data-empty="isEmpty(chartData)"
    type="loc"
    :icons="['gitlab']"
  >
    <template #buttons>
      <SelectSourceMenu
        v-if="sourceSelectorOptions"
        :source-options="sourceSelectorOptions"
        @change-source="gitSource = $event"
      ></SelectSourceMenu>
      <SortingMenu
        v-if="sortingOptions.length"
        :sorting-options="sortingOptions"
        @change-sorting="sorting = $event"
      ></SortingMenu>
    </template>
    <BasicChart
      :data="locData"
      :options="locOptions"
      type="bar"
      :chart-name="chartTypes.LoC"
      @dot-data="updateLOCData"
    ></BasicChart>
    <LOCDrilldown
      :open="!!clickedUserData || !!clickedProjectData"
      :user-data="clickedUserData"
      :project-data="clickedProjectData"
      :selected-source="gitSource"
      @close="closeLOCDrilldownModal"
      @reload-data="getChartData"
    ></LOCDrilldown>
  </ChartWrapper>
</template>

<script setup lang="ts">
import ChartWrapper from '@/components/charts/ChartWrapper.vue'
import SortingMenu from '@/components/common/menu/SortingMenu.vue'
import BasicChart from '@/components/charts/BasicChart.vue'
import { Filters } from '@/store/modules/filters'
import { computed, defineProps, onMounted, ref, withDefaults } from 'vue'
import { Store } from 'vuex'
import { State, useStore } from '@/store'
import {
  chartExplanations,
  chartTitle,
  chartTypes,
} from '@/constants/charts/constants'
import { isEmpty, path, sortBy } from 'ramda'
import { LOCPerUserState } from '@/store/modules/charts/loc'
import { ActiveElement, ChartEvent, LegendElement, LegendItem } from 'chart.js'
import {
  hideCursorOnLegendLeave,
  showCursorOnLegendHover,
  toggleCursorOnChartDataHover,
  verticalAnnotation,
} from '@/utils/chart-utils'
import { AGGREGATE_BY, usedColors } from '@/constants/constants'
import LOCDrilldown from '@/components/charts/loc/LOCDrilldown.vue'
import {
  addQueryParamSilently,
  deleteQueryParamSilently,
  getQueryParamFromUrl,
} from '@/utils/router-utils'
import { User, Project } from '@/store/modules/admin/admin'
import useGettingChartData from '@/utils/hooks/useGettingChartData'
import SelectSourceMenu from '@/components/common/menu/SelectSourceMenu.vue'
import { Aggregate_By_Type } from '@/types/types'

const props = withDefaults(
  defineProps<{
    filters: Filters
    projectPage?: boolean
    aggregation?: Aggregate_By_Type
  }>(),
  { aggregation: AGGREGATE_BY.USERS }
)
const store: Store<State> = useStore()
const sorting = ref('')
const clickedUserData = ref<{ id: number; name: string } | null>(null)
const clickedProjectData = ref<{ id: number; name: string } | null>(null)
const sortingOptions = ref<{ text: string; value: string }[]>([])

const SORTING_OPTIONS = [
  { text: 'Alphabetically', value: 'user.name' },
  { text: 'Insertions', value: 'insertions' },
  { text: 'Duplicates', value: 'duplicated_lines' },
  { text: 'Deletions', value: 'deletions' },
]

const isAggregationByProjects = computed(
  () => props.aggregation === AGGREGATE_BY.PROJECTS
)

const { response, loading, fetchError, getChartData } = useGettingChartData(
  props,
  chartTypes.LoC
)
const gitSource = ref('DevFlow')

const users = computed(() => store.state.admin.original_users)
const projects = computed(() => store.state.admin.original_projects)

const chartData = computed(() => {
  if (isAggregationByProjects.value) {
    return response.value?.per_project
  }
  return response.value?.per_user
})

const sortedLabels = computed(() => {
  const sorted = sortBy(
    path(sorting.value.split('.')) as any,
    chartData.value || []
  )
  return sorted.map((data: any) => {
    if (isAggregationByProjects.value) {
      return data.project?.name
    }
    return data.user?.name
  })
})

const sourceSelectorOptions = [{ value: 'DevFlow' }, { value: 'Git' }]

const calcLoCData = (value: LOCPerUserState) => ({
  ...value,
  insertions:
    gitSource.value === 'Git' ? value.original_insertions : value.insertions,
  deletions:
    gitSource.value === 'Git' ? value.original_deletions : value.deletions,
  duplicated_lines: gitSource.value === 'Git' ? 0 : value.duplicated_lines,
})

const locData = computed(() => ({
  labels: sortedLabels.value,
  datasets: [
    {
      label: 'Insertions',
      key: 'insertions',
      data: chartData.value?.map(calcLoCData),
      parsing: {
        yAxisKey: isAggregationByProjects.value ? 'project.name' : 'user.name',
        xAxisKey: 'insertions',
      },
      backgroundColor: usedColors['success-400'],
      borderRadius: 4,
    },
    {
      label: 'Deletions',
      key: 'deletions',
      data: chartData.value?.map(calcLoCData),
      parsing: {
        yAxisKey: isAggregationByProjects.value ? 'project.name' : 'user.name',
        xAxisKey: 'deletions',
      },
      backgroundColor: usedColors['danger-400'],
      borderRadius: 4,
    },
    {
      label: 'Duplicates',
      key: 'duplicated_lines',
      data: chartData.value?.map(calcLoCData),
      parsing: {
        yAxisKey: isAggregationByProjects.value ? 'project.name' : 'user.name',
        xAxisKey: 'duplicated_lines',
      },
      backgroundColor: usedColors['warning-300'],
      borderRadius: 4,
      hidden: true,
    },
    {
      type: 'line',
      label: 'Avg. Int. Ins.',
      key: 'avg_int_ins',
      borderColor: usedColors['success-400'],
      backgroundColor: 'white',
      borderWidth: 2,
      hidden: true,
    },
    {
      type: 'line',
      label: 'Avg. Int. Del.',
      key: 'avg_int_del',
      borderColor: usedColors['danger-400'],
      backgroundColor: 'white',
      borderWidth: 2,
      hidden: true,
    },
    {
      type: 'line',
      label: 'Avg. Int. Dupl.',
      key: 'avg_int_dupl',
      borderColor: usedColors['warning-300'],
      backgroundColor: 'white',
      borderWidth: 2,
      hidden: true,
    },
    {
      type: 'line',
      label: 'Avg. Int. Sel. Ins.',
      key: 'avg_int_sel_ins',
      backgroundColor: 'white',
      borderColor: usedColors['success-400'],
      borderDash: [2, 2],
      borderWidth: 2,
      hidden: true,
    },
    {
      type: 'line',
      label: 'Avg. Int. Sel. Del.',
      key: 'avg_int_sel_del',
      backgroundColor: 'white',
      borderColor: usedColors['danger-400'],
      borderDash: [2, 1],
      borderWidth: 2,
      hidden: true,
    },
    {
      type: 'line',
      borderDash: [2, 1],
      label: 'Avg. Int. Sel. Dupl.',
      key: 'avg_int_sel_dupl',
      borderColor: usedColors['warning-300'],
      backgroundColor: 'white',
      borderWidth: 2,
      hidden: true,
    },
    {
      type: 'line',
      label: 'Avg. Ext. Ins.',
      key: 'avg_ext_ins',
      backgroundColor: 'white',
      borderColor: usedColors['success-600'],
      borderWidth: 2,
      hidden: true,
    },
    {
      type: 'line',
      label: 'Avg. Ext. Del.',
      key: 'avg_ext_del',
      backgroundColor: 'white',
      borderColor: usedColors['danger-600'],
      borderWidth: 2,
      hidden: true,
    },
    {
      type: 'line',
      label: 'Avg. Ext. Dupl.',
      key: 'avg_ext_dupl',
      borderColor: usedColors['warning-500'],
      backgroundColor: 'white',
      borderWidth: 2,
      hidden: true,
    },
  ],
}))

const locOptions = computed(() => ({
  onHover: (e: ChartEvent, chartElement: ActiveElement[]): void =>
    toggleCursorOnChartDataHover(e, chartElement[0]),
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      position: 'top',
      ticks: {
        callback: function (val: number): string {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return `${val === 0 ? 'Lines ' : ''}${this.getLabelForValue(val)}`
        },
      },
    },
    y: { grid: { drawOnChartArea: false } },
  },
  indexAxis: 'y',
  elements: {
    bar: {
      barThickness: 4,
    },
  },
  plugins: {
    datalabels: null,
    legend: {
      onHover: (evt: ChartEvent): void => showCursorOnLegendHover(evt),
      onLeave: (evt: ChartEvent): void => hideCursorOnLegendLeave(evt),
      onClick: function (
        _e: ChartEvent,
        legendItem: LegendItem,
        legend: LegendElement<any>
      ): void {
        const ci = legend.chart
        const index = legendItem.datasetIndex
        const datasetKey = ci.data.datasets[index].key
        if (ci.isDatasetVisible(index)) {
          Object.keys(ci.config.options.plugins.annotation.annotations).forEach(
            (key) => {
              if (key === datasetKey) {
                ci.config.options.plugins.annotation.annotations[key].display =
                  false
              }
            }
          )
          sortingOptions.value = sortingOptions.value.filter(
            ({ value }) => value !== datasetKey
          )
          ci.hide(index)
        } else {
          Object.keys(ci.config.options.plugins.annotation.annotations).forEach(
            (key) => {
              if (key === datasetKey) {
                ci.config.options.plugins.annotation.annotations[key].display =
                  true
              }
            }
          )
          sortingOptions.value = SORTING_OPTIONS.filter(
            (item) =>
              sortingOptions.value.some(({ value }) => value === item.value) ||
              item.value === datasetKey
          )
          ci.show(index)
        }
      },
      align: 'start',
      labels: {
        borderRadius: 4,
        boxWidth: 12,
        boxHeight: 12,
      },
    },
    annotation: {
      annotations: {
        avg_int_ins: verticalAnnotation({
          color: usedColors['success-400'],
          label: 'Avg. Insertions',
          value: response.value?.avg_int_ins,
          display: false,
        }),
        avg_int_del: verticalAnnotation({
          color: usedColors['danger-400'],
          label: 'Avg. Deletions',
          value: response.value?.avg_int_del,
          display: false,
        }),
        avg_int_dupl: verticalAnnotation({
          color: usedColors['warning-300'],
          label: 'Avg. Duplications',
          value: response.value?.avg_int_dupl,
          display: false,
        }),
        avg_int_sel_ins: verticalAnnotation({
          color: usedColors['success-400'],
          label: 'Avg. Insertions',
          value: response.value?.avg_int_sel_ins,
          display: false,
          borderDash: [5, 5],
        }),
        avg_int_sel_del: verticalAnnotation({
          color: usedColors['danger-400'],
          label: 'Avg. Deletions',
          value: response.value?.avg_int_sel_del,
          display: false,
          borderDash: [5, 5],
        }),
        avg_int_sel_dupl: verticalAnnotation({
          color: usedColors['warning-300'],
          label: 'Avg. Duplications',
          value: response.value?.avg_int_sel_dupl,
          display: false,
          borderDash: [5, 5],
        }),
        avg_ext_ins: verticalAnnotation({
          color: usedColors['success-600'],
          label: 'Avg. Insertions',
          value: response.value?.avg_ext_ins,
          display: false,
        }),
        avg_ext_del: verticalAnnotation({
          color: usedColors['danger-600'],
          label: 'Avg. Deletions',
          value: response.value?.avg_ext_del,
          display: false,
        }),
        avg_ext_dupl: verticalAnnotation({
          color: usedColors['warning-500'],
          label: 'Avg. Duplications',
          value: response.value?.avg_ext_dupl,
          display: false,
        }),
      },
    },
  },
  categoryPercentage: 0.8,
  barPercentage: 0.8,
}))

onMounted(async () => {
  sortingOptions.value = SORTING_OPTIONS.filter(({ value }) =>
    locData.value.datasets.some(
      ({ parsing, hidden }: any) =>
        !hidden &&
        [parsing?.xAxisKey, parsing?.yAxisKey, parsing?.key].includes(value)
    )
  )
  const locUser = getQueryParamFromUrl('locUser')
  if (locUser) {
    clickedUserData.value = {
      id: +locUser,
      name: users.value.find((u: User) => u.id === +locUser)?.name || '',
    }
  }

  const project = getQueryParamFromUrl('project')
  if (project) {
    clickedProjectData.value = {
      id: +project,
      name: projects.value.find((p: Project) => p.id === +project)?.name || '',
    }
  }
})

const updateLOCData = (point: any) => {
  if (isAggregationByProjects.value) {
    clickedProjectData.value = point.element.$context.raw.project
    clickedProjectData.value &&
      addQueryParamSilently('project', clickedProjectData.value.id)
  } else {
    clickedUserData.value = point.element.$context.raw.user
    clickedUserData.value &&
      addQueryParamSilently('locUser', clickedUserData.value.id)
  }
}

const closeLOCDrilldownModal = () => {
  clickedUserData.value = null
  clickedProjectData.value = null
  deleteQueryParamSilently('locUser')
  deleteQueryParamSilently('project')
}
</script>

<template>
  <ChartWrapper
    :title="chartTitle[chartTypes.CommentsRates]"
    :loading="loading"
    :fetch-error="fetchError"
    :tooltip="chartExplanations[chartTypes.CommentsRates]"
    :is-chart-data-empty="isEmpty(chartData)"
    :type="chartTypes.CommentsRates"
    :icons="['gitlab']"
  >
    <BasicChart
      :data="commentsData"
      :options="(commentsOptions as ChartOptions)"
      type="bar"
      :chart-name="chartTypes.CommentsRates"
      @dot-data="saveCommentsData"
    ></BasicChart>
    <CommentsRatesDrilldown
      :open="!!clickedCommentsData"
      :comments-data="clickedCommentsData"
      :filters="filters"
      @close="clickedCommentsData = null"
    ></CommentsRatesDrilldown>
  </ChartWrapper>
</template>

<script setup lang="ts">
import ChartWrapper from '@/components/charts/ChartWrapper.vue'
import BasicChart from '@/components/charts/BasicChart.vue'
import { Filters } from '@/store/modules/filters'
import { computed, defineProps, ref } from 'vue'
import {
  chartExplanations,
  chartTitle,
  chartTypes,
} from '@/constants/charts/constants'
import { isEmpty } from 'ramda'
import { usedColors } from '@/constants/constants'
import useGettingChartData from '@/utils/hooks/useGettingChartData'
import CommentsRatesDrilldown from '@/components/charts/comments-rates/CommentsRatesDrilldown.vue'
import {
  hideCursorOnLegendLeave,
  showCursorOnLegendHover,
  toggleCursorOnChartDataHover,
} from '@/utils/chart-utils'
import { ActiveElement, ChartEvent, ChartOptions } from 'chart.js'
import { CommentsRatesItem } from '@/store/modules/charts/comments-rates'

const props = defineProps<{ filters: Filters; projectPage?: boolean }>()
const clickedCommentsData = ref()

const {
  response: chartData,
  loading,
  fetchError,
} = useGettingChartData(
  { ...props, filters: props.filters },
  chartTypes.CommentsRates
)

const commentsData = computed(() => ({
  type: 'bar',
  labels: chartData.value.map((item: CommentsRatesItem) => item.user.name),
  datasets: [
    {
      label: '1',
      data: chartData.value.map((item: CommentsRatesItem) => ({
        y: item.user.name,
        x: item.data.rate_1,
        rate: 1,
        userId: item.user.id,
        label: '1',
      })),
      backgroundColor: usedColors['danger-500'],
    },
    {
      label: '2',
      data: chartData.value.map((item: CommentsRatesItem) => ({
        y: item.user.name,
        x: item.data.rate_2,
        rate: 2,
        userId: item.user.id,
        label: '2',
      })),
      backgroundColor: usedColors['warning-500'],
    },
    {
      label: '3',
      data: chartData.value.map((item: CommentsRatesItem) => ({
        y: item.user.name,
        x: item.data.rate_3,
        rate: 3,
        userId: item.user.id,
        label: '3',
      })),
      backgroundColor: usedColors['info-500'],
    },
    {
      label: '4',
      data: chartData.value.map((item: CommentsRatesItem) => ({
        y: item.user.name,
        x: item.data.rate_4,
        rate: 4,
        userId: item.user.id,
        label: '4',
      })),
      backgroundColor: usedColors['key-300'],
    },
    {
      label: '5',
      data: chartData.value.map((item: CommentsRatesItem) => ({
        y: item.user.name,
        x: item.data.rate_5,
        rate: 5,
        userId: item.user.id,
        label: '5',
      })),
      backgroundColor: usedColors['success-600'],
    },
  ],
}))

const commentsOptions = computed(() => ({
  responsive: true,
  maintainAspectRatio: false,
  onHover: (e: ChartEvent, chartElement: ActiveElement[]): void =>
    toggleCursorOnChartDataHover(e, chartElement[0]),
  plugins: {
    datalabels: {
      display: false,
    },
    legend: {
      onHover: (evt: ChartEvent): void => showCursorOnLegendHover(evt),
      onLeave: (evt: ChartEvent): void => hideCursorOnLegendLeave(evt),
      align: 'start',
      labels: {
        borderRadius: 4,
        boxWidth: 12,
        boxHeight: 12,
      },
    },
    tooltip: {
      yAlign: 'bottom',
      callbacks: {
        label: function (context: any) {
          return context.parsed.x || 0
        },
      },
    },
  },
  interaction: {
    mode: 'nearest',
  },
  indexAxis: 'y',
  scales: {
    x: {
      position: 'top',
      stacked: true,
      ticks: {
        display: true,
      },
    },
    y: {
      stacked: true,
    },
  },
  categoryPercentage: 1,
  barPercentage: 0.8,
}))

const saveCommentsData = (firstPoint: any) => {
  clickedCommentsData.value = {
    user: {
      name: firstPoint.element.$context.raw.y,
      id: firstPoint.element.$context.raw.userId,
    },
    rate: firstPoint.element.$context.raw.rate,
  }
}
</script>

<template>
  <div class="title">Let's connect some application to your company</div>
  <div class="text-lg mb-5">Connect the following applications:</div>
  <div class="w-2/3 mb-8">
    <CustomAccordionTab title="Gitlab" :connected="gitlabConnected">
      <GitlabConnectionFlow
        @connected="gitlabConnected = true"
      ></GitlabConnectionFlow>
    </CustomAccordionTab>
    <CustomAccordionTab title="Bitbucket" :connected="bitbucketConnected">
      <BitbucketConnectionFlow></BitbucketConnectionFlow>
    </CustomAccordionTab>
    <CustomAccordionTab title="Jira" :connected="jiraConnected">
      <JiraConnectionFlow></JiraConnectionFlow>
    </CustomAccordionTab>
    <CustomAccordionTab title="Tempo" :connected="tempoConnected">
      <TempoConnectionFlow
        @connected="tempoConnected = true"
      ></TempoConnectionFlow>
    </CustomAccordionTab>
    <CustomAccordionTab title="Slack" :connected="slackConnected">
      <SlackConnectionFlow
        @connected="slackConnected = true"
      ></SlackConnectionFlow>
    </CustomAccordionTab>
  </div>
  <Button class="skip" size="large" @click="onSkipStep">{{
    buttonTitle
  }}</Button>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import CustomAccordionTab from '@/components/common/CustomAccordionTab.vue'
import GitlabConnectionFlow from '@/components/admin-dashboard/gitlab/GitlabConnectionFlow.vue'
import BitbucketConnectionFlow from '@/components/admin-dashboard/bitbucket/BitbucketConnectionFlow.vue'
import JiraConnectionFlow from '@/components/admin-dashboard/jira/JiraConnectionFlow.vue'
import TempoConnectionFlow from '@/components/admin-dashboard/tempo/TempoConnectionFlow.vue'
import SlackConnectionFlow from '@/components/admin-dashboard/slack/SlackConnectionFlow.vue'
import { useStore } from '@/store'
import { useRouter } from 'vue-router'
import Button from '@/components/common/buttons/Button.vue'

const store = useStore()
const router = useRouter()

const gitlabConnected = ref(false)
const tempoConnected = ref(false)
const slackConnected = ref(false)
const jiraConnected = computed(() => store.state.jira.isJiraConnected)
const bitbucketConnected = computed(
  () => store.state.bitbucket.isBitbucketConnected
)

const isSomeServiceConnected = computed(() =>
  [
    gitlabConnected.value,
    bitbucketConnected.value,
    jiraConnected.value,
    tempoConnected.value,
    slackConnected.value,
  ].some((connected: boolean) => connected)
)

const buttonTitle = computed(() =>
  isSomeServiceConnected.value ? 'Next' : 'Skip this step'
)

const onSkipStep = () => {
  router.push(
    `/company/${store.getters['company/selectedCompanyId']}/administration`
  )
}
</script>

<style scoped>
.title {
  @apply text-2xl font-bold mb-8;
}
</style>

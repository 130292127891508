<template>
  <FormRow title="Role">
    <BaseDropdown
      v-model:selected="roleValue"
      :options="roles"
      :errors="validation.role.$errors"
      search
    ></BaseDropdown>
  </FormRow>
  <FormRow title="Rate" :invalid-state="validation.rate.$invalid">
    <BaseInputNumber
      v-model:value="rateValue"
      class="w-full"
      :min="0"
      suffix=" CHF/h"
      :minFractionDigits="2"
      :maxFractionDigits="2"
      :errors="validation.rate.$errors"
    ></BaseInputNumber>
  </FormRow>
  <FormRow title="Date" :invalid-state="validation.rate_date.$invalid">
    <BaseCalendar
      v-model:value="dateValue"
      :errors="validation.rate_date.$errors"
      class="w-full"
    ></BaseCalendar>
  </FormRow>
</template>

<script setup lang="ts">
import FormRow from '@/components/common/form/FormRow.vue'
import { defineEmits, defineProps, computed } from 'vue'
import { useStore } from '@/store'
import BaseDropdown from '@/components/common/base/BaseDropdown.vue'
import BaseInputNumber from '@/components/common/base/BaseInputNumber.vue'
import BaseCalendar from '@/components/common/base/BaseCalendar.vue'
import { VuelidateValidation } from '@/types/types'

interface RoleRateFormValidation extends VuelidateValidation {
  role: VuelidateValidation
  rate: VuelidateValidation
  rate_date: VuelidateValidation
}

const props = defineProps<{
  role: number | null
  rate: number | null
  rate_date: string | null
  validation: RoleRateFormValidation
}>()
const emit = defineEmits<{
  (e: 'update:role', value: number | null): void
  (e: 'update:rate', value: number | null): void
  (e: 'update:rate_date', value: string | null): void
}>()
const store = useStore()

const roles = computed(() => store.state.tempo.roles)

const roleValue = computed({
  get() {
    return props.role
  },
  set(value: number | null) {
    emit('update:role', value)
  },
})

const rateValue = computed({
  get() {
    return props.rate
  },
  set(value: number | null) {
    emit('update:rate', value)
  },
})

const dateValue = computed({
  get() {
    return props.rate_date
  },
  set(value: string | null) {
    emit('update:rate_date', value)
  },
})
</script>

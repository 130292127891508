<template>
  <TableWrapper :title="tableTitle" :danger-title="unmapped">
    <template #button>
      <TableSearchInput
        v-model:value="search"
        placeholder="Search by status"
        :class="{ 'mr-2': unmapped }"
      ></TableSearchInput>
      <IconButton
        v-if="unmapped"
        size="small"
        class="p-button-text"
        @click="getStatuses"
        ><i class="pi pi-refresh" style="font-size: 14px"></i
      ></IconButton>
    </template>
    <template #default>
      <LoaderWrapper v-if="loading"></LoaderWrapper>
      <MyTable
        v-else
        :table-data="statuses"
        :columns-data="columnsData"
        :scrollable="scrollableTable"
        :filters="filters"
        :filter-fields="filterFields"
      >
        <template #description="{ data }">
          {{ data.description }}
        </template>
        <template #original_status="{ data }">
          <BaseDropdown
            :key="refreshData"
            :options="originalStatuses"
            v-model:selected="data.original_status"
            option-label="text"
            table
            @update:selected="onChangeOriginalStatus($event, data.id)"
          ></BaseDropdown>
        </template>
      </MyTable>
    </template>
  </TableWrapper>
</template>

<script setup lang="ts">
import TableWrapper from '@/components/common/table/TableWrapper.vue'
import { useStore } from '@/store'
import { computed, ref, defineProps, watchEffect, onMounted } from 'vue'
import MyTable from '@/components/common/table/MyTable.vue'
import { showToastError } from '@/utils/utils'
import { useToast } from 'primevue/usetoast'
import { JiraStatus } from '@/store/modules/admin/jira'
import IconButton from '@/components/common/buttons/IconButton.vue'
import LoaderWrapper from '@/components/common/loader/LoaderWrapper.vue'
import TableSearchInput from '@/components/common/table/TableSearchInput.vue'
import useTableSearch from '@/utils/hooks/useTableSearch'
import { clone } from 'ramda'
import BaseDropdown from '@/components/common/base/BaseDropdown.vue'

const props = defineProps<{ unmapped?: boolean }>()
const store = useStore()
const loading = ref(false)
const toast = useToast()
const refreshData = ref(0)
const scrollableTable = ref(true)
const filterFields = ['name', 'description']
const { search, filters } = useTableSearch()
const statuses = ref([])

onMounted(() => {
  getStatuses()
})

watchEffect(() => {
  statuses.value = clone(
    store.state.jira.statuses.filter(({ original_status }: JiraStatus) =>
      props.unmapped ? !original_status : original_status
    )
  )
})

const originalStatuses = [
  { text: 'Backlog', id: 'BACKLOG' },
  { text: 'To Do', id: 'TO DO' },
  { text: 'In Progress', id: 'IN PROGRESS' },
  { text: 'Done', id: 'DONE' },
]
const tableTitle = computed(
  () =>
    `${props.unmapped ? 'Unmapped Statuses' : 'Statuses'} • ${
      statuses.value.length
    }`
)
const columnsData = [
  {
    header: 'Status Name',
    field: 'name',
    is_sortable: true,
    use_template: false,
    styles: 'flex-basis: 150px',
  },
  {
    header: 'Description',
    field: 'description',
    is_sortable: true,
    use_template: true,
    styles: 'flex-basis: 500px',
  },
  {
    header: 'Original status',
    field: 'original_status',
    is_sortable: true,
    use_template: true,
    styles: 'flex-basis: 200px',
  },
]

async function onChangeOriginalStatus(originalStatusId: number, id: number) {
  try {
    await store.dispatch('jira/editStatus', {
      original_status: originalStatusId,
      id: id,
    })
  } catch (e) {
    showToastError(toast, e)
  }
}

const getStatuses = () => {
  loading.value = true
  store
    .dispatch('jira/getStatuses')
    .catch((e) => {
      showToastError(toast, e)
    })
    .finally(() => (loading.value = false))
  refreshData.value++
}
</script>

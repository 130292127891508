<template>
  <ChartWrapper
    :title="chartTitle[chartTypes.ScopeVsDelivered]"
    :loading="loading"
    :fetch-error="fetchError"
    :tooltip="chartExplanations[chartTypes.ScopeVsDelivered]"
    :is-chart-data-empty="isEmpty(chartData)"
    :type="chartTypes.ScopeVsDelivered"
    :icons="['jira', 'tempo']"
  >
    <template #buttons>
      <MultipleFilter
        title="Jira Labels"
        v-model:selected="selectedLabels"
        :options="labels"
      ></MultipleFilter>
    </template>
    <BasicChart
      :data="scopeVsDeliveredData"
      :options="scopeVsDeliveredOptions"
      type="line"
      :chart-name="chartTypes.ScopeVsDelivered"
      @dot-data="showScopeVsDeliveredDrilldown"
    ></BasicChart>
    <ScopeVsDeliveredDrilldown
      :open="!!scopeVsDeliveredDetails"
      :details="scopeVsDeliveredDetails"
      :labels="selectedLabels"
      :project-id="projectId"
      @close="scopeVsDeliveredDetails = null"
    ></ScopeVsDeliveredDrilldown>
  </ChartWrapper>
</template>

<script setup lang="ts">
import ChartWrapper from '@/components/charts/ChartWrapper.vue'
import BasicChart from '@/components/charts/BasicChart.vue'
import { Filters } from '@/store/modules/filters'
import { computed, defineProps, ref, watchEffect } from 'vue'
import {
  chartExplanations,
  chartTitle,
  chartTypes,
} from '@/constants/charts/constants'
import { Dictionary, equals, isEmpty, reject } from 'ramda'
import {
  AGGREGATION_TYPE,
  ESTIMATION_TYPE,
  usedColors,
} from '@/constants/constants'
import {
  ActiveElement,
  ChartEvent,
  LegendElement,
  LegendItem,
  TooltipItem,
} from 'chart.js'
import {
  generateTickForXAxisTimeCharts,
  generateTooltipTitleForXAxisTimeCharts,
  hideCursorOnLegendLeave,
  showCursorOnLegendHover,
  toggleCursorOnChartDataHover,
  verticalAnnotation,
} from '@/utils/chart-utils'
import {
  Data,
  Label,
  ScopeVsDeliveredItem,
} from '@/store/modules/charts/scope-vs-delivered'
import MultipleFilter from '@/components/common/filters/local/MultipleFilter.vue'
import { useStore } from '@/store'
import { isArray } from 'ramda-adjunct'
import { format, getISOWeek, getMonth, getYear } from 'date-fns'
import ScopeVsDeliveredDrilldown from '@/components/charts/scope-vs-delivered/ScopeVsDeliveredDrilldown.vue'
import { getEstimationInHoursString } from '@/utils/utils'

const props = defineProps<{ filters: Filters; milestonePopup?: boolean }>()
const selectedLabels = ref(null)
const labels = ref([])
const response = ref<Data>({
  per_data: [],
  guideline: 0,
  estimation_type: ESTIMATION_TYPE.TIME,
})
const loading = ref(true)
const labelsLoading = ref(false)
const fetchError = ref('')
const scopeVsDeliveredDetails = ref<{
  date?: string
  year: string
  week?: string
  month?: string
  scale_type: string
  line_type: string
  label: string
} | null>(null)
const store = useStore()

const GUIDELINE_LABEL = 'Guideline'

const chartData = computed(() => response.value?.per_data || [])
const chartLabels = computed(() =>
  chartData.value?.map((data: ScopeVsDeliveredItem) => data.date)
)

const projectId = computed(() => {
  const projects = props.filters.projects
  return Number(isArray(projects) ? projects[0] : projects)
})

const annotations = computed(() => {
  const annotations = {
    guideline: {
      type: 'line',
      yMin: 0,
      yMax: response.value?.guideline,
      borderColor: '#A0A0A0',
      borderWidth: 2,
      display: false,
    },
  }
  if (props.milestonePopup) {
    annotations.current_date = verticalAnnotation({
      color: 'black',
      label: '',
      value: chartLabels.value[chartLabels.value?.length - 1],
      display: true,
      borderDash: [5, 5],
      hideTooltip: true,
    })
  }
  return annotations
})

const scopeVsDeliveredData = computed(() => ({
  labels: chartLabels.value,
  datasets: [
    {
      label: 'Total',
      key: 'total_adaptive_estimates',
      data: chartData.value,
      parsing: {
        yAxisKey: 'total_adaptive_estimates',
        xAxisKey: 'date',
      },
      backgroundColor: usedColors['danger-500'],
      borderColor: usedColors['danger-500'],
      pointRadius: function (context: any): number {
        return (props.milestonePopup &&
          (context.index === 0 ||
            context.index === chartData.value?.length - 1)) ||
          !props.milestonePopup
          ? 3
          : 0
      },
      borderWidth: 2,
    },
    {
      label: 'Delivered',
      key: 'delivered_adaptive_estimates',
      data: chartData.value,
      parsing: {
        xAxisKey: 'date',
        yAxisKey: 'delivered_adaptive_estimates',
      },
      backgroundColor: '#4CD990A5',
      borderColor: usedColors['success-400'],
      borderRadius: 4,
      borderWidth: 2,
      pointRadius: function (context: any): number {
        return (props.milestonePopup &&
          (context.index === 0 ||
            context.index === chartData.value?.length - 1)) ||
          !props.milestonePopup
          ? 3
          : 0
      },
      fill: true,
    },
    {
      label: 'In progress',
      key: 'in_progress_adaptive_estimates',
      data: chartData.value,
      parsing: {
        xAxisKey: 'date',
        yAxisKey: 'in_progress_adaptive_estimates',
      },
      backgroundColor: '#61C5E3B7',
      borderColor: usedColors['info-300'],
      borderRadius: 4,
      borderWidth: 2,
      pointRadius: function (context: any): number {
        return (props.milestonePopup &&
          (context.index === 0 ||
            context.index === chartData.value?.length - 1)) ||
          !props.milestonePopup
          ? 3
          : 0
      },
      fill: true,
    },
    {
      label: GUIDELINE_LABEL,
      backgroundColor: '#A0A0A0',
      borderColor: '#A0A0A0',
      borderRadius: 4,
      borderWidth: 2,
      hidden: true,
    },
  ],
}))

const scopeVsDeliveredOptions = computed(() => ({
  type: 'line',
  onHover: (e: ChartEvent, chartElement: ActiveElement[]): void =>
    toggleCursorOnChartDataHover(e, chartElement[0]),
  spanGaps: true,
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      position: 'bottom',
      grid: { drawOnChartArea: false },
      ticks: {
        callback: function (val: number, context, ticks): string {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const dateValue = this.getLabelForValue(val)
          if (props.milestonePopup) {
            const formattedDate = format(new Date(dateValue), 'dd MMM, yyyy')
            if (val === 0 || val === ticks.length - 1) {
              return formattedDate
            }
            return ''
          } else {
            if (dateValue.split(' ').length > 1) return ''
            return generateTickForXAxisTimeCharts(
              dateValue,
              props.filters.scale_type
            )
          }
        },
        autoSkip: !props.milestonePopup,
        maxTicksLimit: props.milestonePopup ? 0 : false,
        maxRotation: props.milestonePopup ? 0 : 50,
      },
    },
    y: {
      grid: { drawOnChartArea: false },
      ticks: {
        callback: function (value: number): string {
          return getEstimationInHoursString(
            value,
            response.value?.estimation_type
          )
        },
      },
    },
  },
  indexAxis: 'x',
  interaction: {
    mode: 'nearest',
    intersect: false,
  },
  plugins: {
    datalabels: null,
    tooltip: {
      mode: 'nearest',
      callbacks: {
        label: function (context: TooltipItem<'line'>): string {
          const { label } = context.dataset
          const transformedLabel = label?.replace('Total', 'Total scope')
          return `${transformedLabel}: ${getEstimationInHoursString(
            context.parsed.y,
            response.value?.estimation_type
          )}`
        },
        title: function (context: TooltipItem<'line'>[]): string {
          const label = context[0].label
          return generateTooltipTitleForXAxisTimeCharts(
            label,
            props.filters.scale_type
          )
        },
      },
    },
    legend: {
      onHover: (evt: ChartEvent): void => showCursorOnLegendHover(evt),
      onLeave: (evt: ChartEvent): void => hideCursorOnLegendLeave(evt),
      onClick: function (
        _e: ChartEvent,
        legendItem: LegendItem,
        legend: LegendElement<any>
      ): void {
        const ci = legend.chart
        const index = legendItem.datasetIndex
        if (ci.isDatasetVisible(index)) {
          if (index === 3) {
            ci.config.options.plugins.annotation.annotations.guideline.display =
              false
          }
          ci.hide(index)
        } else {
          if (index === 3) {
            ci.config.options.plugins.annotation.annotations.guideline.display =
              true
          }
          ci.show(index)
        }
      },
      align: 'start',
      labels: {
        borderRadius: 4,
        boxWidth: 10,
        boxHeight: 10,
        generateLabels(chart) {
          return chart.data.datasets.map((dataset, i) => ({
            datasetIndex: i,
            text:
              !props.milestonePopup || dataset.label === GUIDELINE_LABEL
                ? dataset.label
                : `${dataset.label}: ${getEstimationInHoursString(
                    dataset.data[dataset.data.length - 1][dataset.key],
                    response.value?.estimation_type
                  )}`,
            fillStyle: dataset.backgroundColor,
            strokeStyle: dataset.backgroundColor,
            hidden: !chart.isDatasetVisible(i),
          }))
        },
      },
    },
    annotation: {
      annotations: annotations.value,
    },
  },
}))

const activeFilters = computed(() => {
  const filters = { ...props.filters, labels: selectedLabels.value }
  return {
    ...reject(equals(null))(filters as Dictionary<any>),
  }
})

const getChartData = async () => {
  fetchError.value = ''
  loading.value = true
  try {
    response.value = await store.dispatch('scopeVsDelivered/getData', {
      filters: activeFilters.value,
      project_id: projectId.value,
    })
    loading.value = false
  } catch (e) {
    if (e instanceof Error) {
      fetchError.value = e?.message
    }
    loading.value = false
  }
}

watchEffect(async () => {
  if (
    store.getters['company/selectedCompanyId'] &&
    activeFilters.value.projects &&
    activeFilters.value.until
  ) {
    await getChartData()
  }
})

watchEffect(async () => {
  if (props.filters.projects) {
    labelsLoading.value = true
    const labelsResponse = await store.dispatch(
      'scopeVsDelivered/getLabels',
      props.filters
    )
    labelsLoading.value = false
    labels.value = (labelsResponse || []).map((label: Label) => ({
      ...label,
      id: label.name,
    }))
  }
})

const showScopeVsDeliveredDrilldown = (point: any) => {
  if (props.filters.scale_type) {
    const baseData = {
      year: `${getYear(new Date(point.element.$context.raw.date))}`,
      line_type: point.element.$context.dataset.key,
      label: point.element.$context.dataset.label,
      scale_type: props.filters.scale_type,
    }

    if (props.filters.scale_type === AGGREGATION_TYPE.DATE) {
      scopeVsDeliveredDetails.value = {
        ...baseData,
        date: point.element.$context.raw.date,
      }
    } else if (props.filters.scale_type === AGGREGATION_TYPE.WEEK) {
      scopeVsDeliveredDetails.value = {
        ...baseData,
        week: `${getISOWeek(new Date(point.element.$context.raw.date))}`,
      }
    } else {
      scopeVsDeliveredDetails.value = {
        ...baseData,
        month: `${getMonth(new Date(point.element.$context.raw.date)) + 1}`,
      }
    }
  }
}
</script>

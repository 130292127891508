<template>
  <aside class="sidebar">
    <div class="flex sidebar-header">
      <Avatar
        :label="company.name ? company.name[0] : ''"
        class="mr-3"
      ></Avatar>

      <div class="text-left">
        <div>{{ company.name || 'Company name' }}</div>
        <div class="name">{{ user?.displayName || 'User name' }}</div>
      </div>
    </div>
    <SidebarItem
      v-for="page of SIDEBAR_PAGES"
      :key="page.value"
      open
      disabled
      :page-details="page"
      :link="{
        path: '/',
        query: {},
      }"
    ></SidebarItem>
  </aside>
</template>

<script setup lang="ts">
import { computed, defineProps } from 'vue'
import { useStore } from '@/store'
import { Company } from '@/store/modules/company'
import Avatar from '@/components/common/Avatar.vue'
import SidebarItem from '@/components/common/sidebar/SidebarItem.vue'
import { SIDEBAR_PAGES } from '@/constants/constants'

const store = useStore()
defineProps<{ company: Company }>()
const user = computed(() => store.state.user.currentUser)
</script>

<style lang="scss" scoped>
.sidebar {
  @apply bg-gray-50 text-gray-800 font-semi-bold relative w-60;
}

.sidebar-header {
  @apply px-3 py-2 mb-2  text-base flex text-black flex items-center;
}

.name {
  @apply font-normal text-sm;
}

.skeleton-circle {
  @apply bg-black w-6 h-6;
}
</style>

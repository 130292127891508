<template>
  <Dialog
    :visible="open"
    :dismissableMask="true"
    @update:visible="closeDialog"
    position="bottom"
    modal
  >
    <template #header>
      <div class="header-wrapper">
        <div>Company Profile</div>
        <div class="flex gap-2">
          <IconButton class="p-button-text" @click="showEditDialog = true">
            <span class="pi pi-pencil"></span>
          </IconButton>
        </div>
      </div>
    </template>
    <div class="font-bold text-xl mb-5">{{ selectedCompany?.name }}</div>
    <Message v-if="showSuccessMessage" severity="success" icon="pi-check-circle"
      ><div class="ml-1">Changes saved successfully!</div></Message
    >
    <FormRow title="Owners"
      ><div class="text-left w-full">{{ owner }}</div></FormRow
    >
    <FormRow title="Number of Users"
      ><div class="text-left w-full">{{ users.length }}</div></FormRow
    >
    <EditCompanyInfoDialog
      v-model:open="showEditDialog"
      :company="selectedCompany"
      @close-info-dialog="closeDialog"
      @save="showSuccessMessage = true"
    ></EditCompanyInfoDialog>
  </Dialog>
</template>

<script setup lang="ts">
import IconButton from '@/components/common/buttons/IconButton.vue'
import Dialog from 'primevue/dialog/Dialog.vue'
import { computed, defineEmits, defineProps, ref } from 'vue'
import { useStore } from '@/store'
import FormRow from '@/components/common/form/FormRow.vue'
import { User } from '@/store/modules/admin/admin'
import { USER_ROLES } from '@/constants/constants'
import EditCompanyInfoDialog from '@/components/companies/EditCompanyInfoDialog.vue'
import Message from '@/components/common/Message.vue'

defineProps<{ open: boolean }>()
const emit = defineEmits<{ (e: 'update:open', value: boolean): void }>()
const store = useStore()

const showEditDialog = ref(false)
const showSuccessMessage = ref(false)
const selectedCompany = computed(() => store.state.company.selectedCompany)
const users = computed(() => store.state.admin.original_users)
const owner = computed(() =>
  users.value
    ?.filter((user: User) => user.role === USER_ROLES.OWNER)
    .map((user: User) => user.name)
    .join(', ')
)

function closeDialog() {
  emit('update:open', false)
  showSuccessMessage.value = false
}
</script>

<style scoped>
.header-wrapper {
  @apply flex items-center justify-between w-full;
}
</style>

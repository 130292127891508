<template>
  <Avatar
    :image="url || null"
    class="avatar flex-shrink-0"
    :label="_label || null"
    :size="size"
    :style="avatarStyles"
    @error="onImageLoadError"
  />
</template>

<script setup lang="ts">
import Avatar from 'primevue/avatar/Avatar.vue'
import { computed, defineProps, ref, watchEffect, withDefaults } from 'vue'

const props = withDefaults(
  defineProps<{
    url?: string
    label?: string
    size?: 'small' | 'normal' | 'large' | 'xlarge'
  }>(),
  { size: 'normal' }
)
const _label = ref<null | string | undefined>(null)

watchEffect(() => {
  _label.value = props.url ? null : props.label?.charAt(0)
})
const onImageLoadError = () => {
  _label.value = props.label?.charAt(0)
}

const avatarStyles = computed(() => {
  return props.size === 'small'
    ? {
        width: '24px',
        height: '24px',
        'min-width': '24px',
        'border-radius': '10px',
      }
    : {}
})
</script>

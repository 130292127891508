<template>
  <Dialog
    :visible="open"
    :dismissableMask="true"
    @update:visible="closeDialog"
    position="bottom"
    modal
  >
    <template #header>
      <div class="header-wrapper">
        <div>Tempo</div>
      </div>
    </template>
    <div class="title">Tempo Settings</div>
    <FormRow
      title="Default Team"
      tooltip="Please select a tempo team that will be the used in case there is no project specific team or multiple team are assigned to a project. This team should contain all users. To verify team members, please check the Tempo Jira application."
    >
      <BaseDropdown
        v-model:selected="selectedTeam"
        :options="teams"
        target="team"
        deselect
        search
      ></BaseDropdown>
    </FormRow>
    <template #footer>
      <Button
        class="cancel p-button-outlined"
        size="large"
        @click="closeDialog"
      >
        Cancel
      </Button>

      <Button class="save" size="large" @click="onChangeSettings"
        >Save Changes</Button
      >
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import Dialog from 'primevue/dialog/Dialog.vue'
import FormRow from '@/components/common/form/FormRow.vue'
import Button from '@/components/common/buttons/Button.vue'
import { computed, defineEmits, defineProps, onMounted, ref } from 'vue'
import { State, useStore } from '@/store'
import { Store } from 'vuex'
import BaseDropdown from '@/components/common/base/BaseDropdown.vue'
import { TempoTeam } from '@/store/modules/admin/tempo'
import { showToastError } from '@/utils/utils'
import { useToast } from 'primevue/usetoast'

defineProps<{ open: boolean }>()
const emit = defineEmits<{ (e: 'update:open', value: boolean): void }>()
const store: Store<State> = useStore()
const toast = useToast()
const teams = computed(() => store.state.tempo.teams)
const selectedTeam = ref<number | null>(null)
const defaultTeam = computed(() => store.getters['tempo/defaultTeam'])

onMounted(() => {
  store.dispatch('tempo/getTeams').then(() => {
    selectedTeam.value =
      teams.value.find((team: TempoTeam) => team.default)?.id || null
  })
})

function closeDialog() {
  emit('update:open', false)
}

async function onChangeSettings() {
  try {
    if (selectedTeam.value || defaultTeam.value) {
      await store.dispatch(
        'tempo/editTeam',
        !selectedTeam.value && defaultTeam.value
          ? { team_id: defaultTeam.value.id, default: false }
          : {
              team_id: selectedTeam.value,
              default: true,
            }
      )
    }
    closeDialog()
  } catch (e) {
    showToastError(toast, e)
  }
}
</script>

<style scoped>
.header-wrapper {
  @apply flex items-center justify-between w-full;
}
.title {
  @apply text-xl font-semi-bold mb-3;
}
</style>

<template>
  <Dialog
    :visible="open"
    :dismissableMask="true"
    @update:visible="closeDialog"
    position="bottom"
    modal
  >
    <template #header>
      <div class="header-wrapper">Tempo • Projects • Budget</div>
    </template>
    <div class="title">New Budget</div>
    <BudgetForm
      v-model:budget="formData.budget"
      v-model:date="formData.date"
      :validation="v$"
    ></BudgetForm>
    <template #footer>
      <Button class="p-button-outlined" size="large" @click="closeDialog">
        Cancel
      </Button>
      <Button size="large" class="add" @click="onAddBudget">Create</Button>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import Dialog from 'primevue/dialog/Dialog.vue'
import { defineProps, ref, defineEmits } from 'vue'
import Button from '@/components/common/buttons/Button.vue'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { useToast } from 'primevue/usetoast'
import { useStore } from '@/store'
import { showToastError } from '@/utils/utils'
import BudgetForm from '@/components/admin-dashboard/tempo/projects/budget/BudgetForm.vue'

const initialForm = () => ({
  budget: 0,
  date: null,
})

const props = defineProps<{ open: boolean; project_id: number }>()
const emit = defineEmits<{
  (e: 'update:open', value: boolean): void
  (e: 'refresh-table'): void
}>()
const toast = useToast()
const store = useStore()
const formData = ref(initialForm())

const formRules = {
  budget: { required, $lazy: true },
  date: { required, $lazy: true },
}

const v$ = useVuelidate(formRules, formData)

async function onAddBudget() {
  v$.value.$touch()
  if (v$.value.$invalid) {
    return
  }
  try {
    await store.dispatch('tempo/addBudget', {
      project_id: props.project_id,
      budget: formData.value,
    })
    closeDialog()
    emit('refresh-table')
  } catch (e) {
    showToastError(toast, e)
  }
}

function closeDialog() {
  formData.value = initialForm()
  v$.value.$reset()
  emit('update:open', false)
}
</script>

<style scoped>
.header-wrapper {
  @apply flex items-center justify-between w-full;
}
.title {
  @apply text-xl font-semi-bold mb-3;
}
</style>

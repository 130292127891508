import axios from '@/services/axios'
import { addOverFillerData } from '@/utils/chart-utils'
import { Filters } from '@/store/modules/filters'
import { ActionContext } from 'vuex'
import { State as GlobalState } from '../../index'
import { Aggregate_By_Type } from '@/types/types'

export interface BillableTimeItem {
  billable_time_percent: number
  billable_time_seconds: number
  time_spent_seconds: number
  project_name?: string
  original_project_id?: number
  user_name?: string
  user_id?: number
  filler?: number
  over_filler?: number
}

export interface BillableTimeResponse {
  aggregate_by: Aggregate_By_Type
  avg_data: {
    all_avg_percent: number
    avg_percent: number
  }
  data: BillableTimeItem[]
}

export interface State {
  data: BillableTimeResponse
}

type Context = ActionContext<State, GlobalState>

const actions = {
  getData(
    { rootGetters }: Context,
    params: Filters
  ): Promise<BillableTimeResponse> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/charts/billable-time/`,
        { params }
      )
      .then(({ data }) => addOverFillerData(data, 'billable_time_percent'))
  },
}

export default {
  namespaced: true,
  actions,
}

<template>
  <ChartWrapper
    :title="chartTitle[type]"
    :tooltip="chartExplanations[type]"
    :fetch-error="fetchError"
    :loading="loading"
    :is-chart-data-empty="isEmpty(tickets)"
    :type="type"
    :icons="['jira']"
  >
    <template #buttons v-if="type !== chartTypes.LargestTicketsByEstimation">
      <TableSearchInput
        v-model:value="search"
        placeholder="Search by ticket..."
      ></TableSearchInput>
    </template>
    <MyTable
      :table-data="tickets"
      :columns-data="columnsData"
      :scrollable="scrollableTable"
      :lazy-table="chartTypes.LargestTicketsByEstimation !== type"
      :lazy-loading="lazyLoading"
      custom-height="300px"
      @load="onLazyLoad"
      @sort="onSort"
    >
      <template #key="{ data }">
        <Link :href="data.absolute_url" style-class="truncate">
          {{ data.key }}: {{ data.summary }}
        </Link>
      </template>
      <template #adaptive_estimation="{ data }">
        <div>
          {{
            getEstimationShortReadableString(
              data.adaptive_estimation,
              data.estimation_type
            )
          }}
        </div>
      </template>
      <template #time_spent_seconds="{ data }">
        <div>
          {{
            getEstimationShortReadableString(
              data.time_spent_seconds,
              data.estimation_type
            )
          }}
        </div>
      </template>
      <template #difference_amount="{ data }">
        <div
          :class="{
            'text-key-500': data.difference_amount < 0,
            'text-danger-500': data.difference_amount > 0,
          }"
        >
          {{ calcDifferenceAmountText(data.difference_amount) }}
        </div>
      </template>
      <template #status="{ data }">
        <TicketStatusChip :status="data.status" />
      </template>
      <template v-if="totalsTickets && popup" #footer>
        <ColumnGroup type="footer">
          <Row>
            <Column
              v-for="column of footerColumnsData"
              :key="column.key"
              :footer="column.footer"
              :footer-style="column.styles"
            ></Column>
          </Row>
        </ColumnGroup>
      </template>
    </MyTable>
  </ChartWrapper>
</template>

<script setup lang="ts">
import { computed, defineProps, ref, watchEffect } from 'vue'
import { Filters } from '@/store/modules/filters'
import { Dictionary, equals, isEmpty, omit, reject } from 'ramda'
import MyTable from '@/components/common/table/MyTable.vue'
import Link from '@/components/common/Link.vue'
import {
  chartExplanations,
  chartTitle,
  chartTypes,
} from '@/constants/charts/constants'
import ChartWrapper from '@/components/charts/ChartWrapper.vue'
import TableSearchInput from '@/components/common/table/TableSearchInput.vue'
import {
  calcSortOrderingAndField,
  getEstimationShortReadableString,
  calcDifferenceAmountText,
  showToastError,
} from '@/utils/utils'
import TicketStatusChip from '@/components/common/TicketStatusChip.vue'
import { debounce } from 'lodash'
import { isArray } from 'ramda-adjunct'
import { useStore } from '@/store'
import { ESTIMATION_TYPE } from '@/constants/constants'
import ColumnGroup from 'primevue/columngroup/ColumnGroup.vue'
import Column from 'primevue/column/Column.vue'
import Row from 'primevue/row/Row.vue'
import { useToast } from 'primevue/usetoast'

const props = defineProps<{
  projectPage: boolean
  filters: Filters
  popup?: boolean
  type: string
}>()

const HEADER_TITLES = {
  TICKET: 'Ticket',
  STATUS: 'Status',
  ORIGINAL_ESTIMATE: 'Original estimate',
  TIME_SPENT: 'Time Spent',
  DIFFERENCE: 'Difference',
}

const store = useStore()
const scrollableTable = ref(true)
const fetchError = ref('')
const loading = ref(true)
const lazyLoading = ref(false)
const { toast } = useToast()
const sortField = ref<string | null>(
  props.type === chartTypes.LargestTicketsByEstimation
    ? '-adaptive_estimation'
    : ''
)
const search = ref('')
const nextPage = ref(null)
const tickets = ref([])
const totalsTickets = ref(null)

const isEstimationTypeTime = computed(
  () =>
    tickets.value.length &&
    tickets.value[0].estimation_type === ESTIMATION_TYPE.TIME
)

const debouncedGetRequest = debounce(getFirstPageOfTickets, 1000)

const companyId = computed(() => store.state.company.selectedCompanyId)

const activeFilters = computed(() => {
  const filters = {
    ...omit(['scale_type'], props.filters),
    ordering: sortField.value,
    search: search.value,
    limit: props.type === chartTypes.LargestTicketsByEstimation ? '10' : '50',
    exclude_done: `${props.type === chartTypes.LargestTicketsByEstimation}`,
  }
  return reject(equals(null))(filters as Dictionary<any>)
})

const projectId = computed(() => {
  const projects = props.filters.projects
  return isArray(projects) ? projects[0] : projects
})

async function getTotalsValuesOfTickets() {
  try {
    totalsTickets.value = await store.dispatch(
      'jiraTickets/getTotalsOfTickets',
      {
        filters: activeFilters.value,
        project_id: projectId.value,
        company_id: companyId.value,
      }
    )
  } catch (e) {
    showToastError(toast, e)
  }
}

async function getFirstPageOfTickets() {
  fetchError.value = ''
  if (activeFilters.value.ordering || activeFilters.value.search) {
    lazyLoading.value = true
  } else {
    loading.value = true
  }
  try {
    const response = await store.dispatch('jiraTickets/getFirstPageOfTickets', {
      filters: activeFilters.value,
      project_id: projectId.value,
      company_id: companyId.value,
    })
    nextPage.value = response.next
    tickets.value = response.results
  } catch (e) {
    if (e instanceof Error) {
      fetchError.value = e?.message
    }
  } finally {
    loading.value = false
    lazyLoading.value = false
  }
}

watchEffect(async () => {
  if (props.projectPage && !activeFilters.value.projects) return
  if (!props.popup && !activeFilters.value.since && !activeFilters.value.until)
    return
  if (store.getters['company/selectedCompanyId']) {
    if (search.value) {
      debouncedGetRequest()
    } else {
      await getFirstPageOfTickets()
      await getTotalsValuesOfTickets()
    }
  }
})

/* istanbul ignore next */
async function onLazyLoad(event: { first: number; last: number }) {
  if (lazyLoading.value) return
  const { last } = event
  lazyLoading.value = true
  try {
    if (nextPage.value && last === tickets.value.length) {
      const page = await store.dispatch(
        'jiraTickets/getNextPageOfTickets',
        nextPage.value
      )
      nextPage.value = page.next
      tickets.value = tickets.value.concat(page.results)
    }
  } catch (e) {
    if (e instanceof Error) {
      fetchError.value = e?.message
    }
  } finally {
    lazyLoading.value = false
  }
}

function onSort(event: Event) {
  if (props.type === chartTypes.LargestTicketsByEstimation) return
  sortField.value = calcSortOrderingAndField(event)
}

const columnsData = computed(() => {
  const columns = [
    {
      header: HEADER_TITLES.TICKET,
      field: 'key',
      is_sortable: true,
      use_template: true,
      styles: 'flex-basis:600px; padding-right:10px',
      classes: props.popup
        ? 'sm:max-w-xxs md:max-w-xs lg:max-w-sm xl:max-w-md 2xl:max-w-lg'
        : 'sm:max-w-sm md:max-w-lg lg:max-w-xl xl:max-w-2xl 2xl:max-w-4xl',
    },
    {
      header: HEADER_TITLES.ORIGINAL_ESTIMATE,
      field: 'adaptive_estimation',
      is_sortable: true,
      use_template: true,
      styles: 'min-width: 130px; padding-right: 10px',
    },
  ]
  if (props.popup) {
    columns.push({
      header: HEADER_TITLES.TIME_SPENT,
      field: 'time_spent_seconds',
      is_sortable: true,
      use_template: true,
      styles: 'min-width: 130px; padding-right: 10px',
    })

    isEstimationTypeTime.value &&
      columns.push({
        header: HEADER_TITLES.DIFFERENCE,
        field: 'difference_amount',
        is_sortable: true,
        use_template: true,
        styles: 'min-width: 130px; padding-right: 10px',
      })
  }
  const status = {
    header: HEADER_TITLES.STATUS,
    field: 'status',
    is_sortable: true,
    use_template: true,
    styles: 'min-width: 180px; padding-right: 10px',
  }
  if (props.popup) {
    columns.splice(1, 0, status)
  } else {
    columns.push(status)
  }
  return columns
})

const calculateFooterField = (header) => {
  switch (header) {
    case HEADER_TITLES.TICKET:
      return 'Totals:'
    case HEADER_TITLES.STATUS:
      return ''
    case HEADER_TITLES.ORIGINAL_ESTIMATE:
      return getEstimationShortReadableString(
        totalsTickets.value?.total_adaptive_estimation,
        totalsTickets.value?.estimation_type
      )
    case HEADER_TITLES.TIME_SPENT:
      return getEstimationShortReadableString(
        totalsTickets.value?.total_time_spent_seconds,
        totalsTickets.value?.estimation_type
      )
    case HEADER_TITLES.DIFFERENCE:
      return calcDifferenceAmountText(
        totalsTickets.value?.total_difference_amount
      )
    default:
      return ''
  }
}

const footerColumnsData = computed(() => {
  return columnsData.value.map((column) => {
    let differenceStyles = ''
    if (
      column.header === HEADER_TITLES.DIFFERENCE &&
      totalsTickets.value?.total_difference_amount > 0
    ) {
      differenceStyles = '; color: #ED6F6F'
    } else if (
      column.header === HEADER_TITLES.DIFFERENCE &&
      totalsTickets.value?.total_difference_amount < 0
    ) {
      differenceStyles = '; color: #5063D5'
    }

    return {
      key: column.header,
      footer: calculateFooterField(column.header),
      styles: column.styles + differenceStyles,
    }
  })
})
</script>

<style lang="scss" scoped>
.p-datatable .p-virtualscroller-content {
  max-width: 100%;
}
</style>

<template>
  <ChartWrapper
    :title="chartTitle[chartTypes.Burndown]"
    :loading="loading"
    :fetch-error="fetchError"
    :tooltip="chartExplanations[chartTypes.Burndown]"
    :is-chart-data-empty="isChartDataEmpty"
    :type="chartTypes.Burndown"
    :icons="['jira', 'tempo']"
  >
    <template v-if="!milestonePopup" #buttons>
      <MultipleFilter
        v-model:selected="selectedSprints"
        :options="sprints"
        title="Jira Sprints"
        :loading="loading"
      ></MultipleFilter>
    </template>
    <BasicChart
      :data="burndownData"
      :options="burndownOptions"
      type="line"
      :chart-name="chartTypes.Burndown"
      @dot-data="updateBurndownDotData"
    ></BasicChart>
    <BurndownDrilldown
      :open="!!burndownDotData"
      :burndown-details="burndownDotData"
      :sprints="selectedSprints"
      :estimation-type="chartData?.estimation_type"
      @close="burndownDotData = null"
    ></BurndownDrilldown>
  </ChartWrapper>
</template>

<script setup lang="ts">
import ChartWrapper from '@/components/charts/ChartWrapper.vue'
import BasicChart from '@/components/charts/BasicChart.vue'
import { Filters } from '@/store/modules/filters'
import { computed, defineProps, ref, toRaw, watchEffect } from 'vue'
import { Store } from 'vuex'
import { State, useStore } from '@/store'
import {
  chartExplanations,
  chartTitle,
  chartTypes,
} from '@/constants/charts/constants'
import {
  Dictionary,
  equals,
  isEmpty,
  map,
  omit,
  prop,
  reject,
  reverse,
  sortBy,
  uniq,
  values,
} from 'ramda'
import {
  usedColors,
  AGGREGATION_TYPE,
  ESTIMATION_TYPE,
} from '@/constants/constants'
import {
  ActiveElement,
  ChartEvent,
  LegendElement,
  TooltipItem,
  LegendItem,
} from 'chart.js'
import {
  generateTickForXAxisTimeCharts,
  generateTooltipTitleForXAxisTimeCharts,
  hideCursorOnLegendLeave,
  showCursorOnLegendHover,
  toggleCursorOnChartDataHover,
  verticalAnnotation,
} from '@/utils/chart-utils'
import { BurndownData, Sprint } from '@/store/modules/charts/burndown'
import { isArray } from 'ramda-adjunct'
import {
  eachDayOfInterval,
  eachWeekendOfInterval,
  format,
  isBefore,
  isWeekend,
  isSunday,
  previousFriday,
  nextMonday,
  startOfWeek,
  startOfMonth,
  getYear,
  getISOWeek,
  getMonth,
} from 'date-fns'
import BurndownDrilldown from '@/components/charts/burndown/BurndownDrilldown.vue'
import MultipleFilter from '@/components/common/filters/local/MultipleFilter.vue'
import { getEstimationInHoursString } from '@/utils/utils'
import { DATE_FORMATS } from '@/utils/date-utils'

const props = defineProps<{ filters: Filters; milestonePopup?: boolean }>()
const store: Store<State> = useStore()
const loading = ref(true)
const sprintsLoading = ref(false)
const fetchError = ref('')
const sprints = ref<Sprint[]>([])
const selectedSprints = ref<null | number[]>(null)
const showNonWorkingDays = ref(true)
const burndownDotData = ref<{
  filter_date: string
  date?: string
  week?: string
  month?: string
  year: string
  line_type: string
  label: string
  scale_type: string
} | null>(null)

const chartData = ref<BurndownData>({
  guideline: [],
  adaptive_spent: [],
  adaptive_remaining: [],
  estimation_type: ESTIMATION_TYPE.TIME,
})

const GUIDELINE_LABEL = 'Guideline'
const NON_WORKING_DAYS_LABEL = 'Non-working days'

const createDotObject = (
  day: Date,
  value: number,
  strFormat = 'yyyy-MM-dd'
) => {
  return {
    date: format(day, strFormat),
    value: value > 0 ? value : 0,
  }
}

const isDayAggregation = computed(
  () => props.filters.scale_type === AGGREGATION_TYPE.DATE
)

const firstDay = computed(() => {
  let day
  if (selectedSprints.value || !isDayAggregation.value) {
    const adaptiveRemainingFirstDate = new Date(
      chartData.value.adaptive_remaining[0].date
    )
    if (chartData.value.adaptive_spent[0]) {
      const adaptiveSpentFirstDate = new Date(
        chartData.value.adaptive_spent[0].date
      )
      day = isBefore(adaptiveSpentFirstDate, adaptiveRemainingFirstDate)
        ? adaptiveSpentFirstDate
        : adaptiveRemainingFirstDate
    } else {
      day = adaptiveRemainingFirstDate
    }
  } else {
    day = props.filters.since && new Date(props.filters.since)
  }
  return (
    day && (!showNonWorkingDays.value && isWeekend(day) ? nextMonday(day) : day)
  )
})

const lastDay = computed(() => {
  let day
  if (selectedSprints.value || !isDayAggregation.value) {
    const adaptiveRemainingLastDate = new Date(
      chartData.value.adaptive_remaining[
        chartData.value.adaptive_remaining.length - 1
      ].date
    )
    if (chartData.value.adaptive_spent[0]) {
      const adaptiveSpentLastDate = new Date(
        chartData.value.adaptive_spent[
          chartData.value.adaptive_spent.length - 1
        ].date
      )
      day = isBefore(adaptiveSpentLastDate, adaptiveRemainingLastDate)
        ? adaptiveRemainingLastDate
        : adaptiveSpentLastDate
    } else {
      day = adaptiveRemainingLastDate
    }
  } else {
    day = props.filters.until && new Date(props.filters.until)
  }
  return (
    day &&
    (!showNonWorkingDays.value && isWeekend(day) ? previousFriday(day) : day)
  )
})

//calculate guideline line
const updatedChartData = computed(() => {
  if (
    isEmpty(chartData.value.adaptive_remaining) ||
    !firstDay.value ||
    !lastDay.value
  ) {
    return {
      ...chartData.value,
      guideline: [],
    }
  } else if (!showNonWorkingDays.value) {
    return {
      adaptive_spent: chartData.value.adaptive_spent.filter(
        ({ date }) => !isWeekend(new Date(date))
      ),
      adaptive_remaining: chartData.value.adaptive_remaining.filter(
        ({ date }) => !isWeekend(new Date(date))
      ),
      guideline: [
        createDotObject(
          firstDay.value,
          chartData.value.adaptive_remaining?.[0].value
        ),
        createDotObject(lastDay.value, 0),
      ],
      estimation_type: chartData.value.estimation_type,
    }
  } else if (!isDayAggregation.value) {
    return {
      ...chartData.value,
      guideline: [
        createDotObject(
          firstDay.value,
          chartData.value.adaptive_remaining?.[0].value
        ),
        createDotObject(lastDay.value, 0),
      ],
    }
  } else {
    let guidelineValue = chartData.value.adaptive_remaining?.[0].value
    const weekends = eachWeekendOfInterval({
      start: firstDay.value,
      end: lastDay.value,
    })
    const eachDayOfPeriod = eachDayOfInterval({
      start: firstDay.value,
      end: lastDay.value,
    })
    const extraDay = isWeekend(firstDay.value) ? 0 : 1
    const amountOfWorkingDays =
      (eachDayOfPeriod.length - weekends.length) * 2 - extraDay
    const secondsStep = guidelineValue / amountOfWorkingDays
    const guideline = eachDayOfPeriod
      .map((day, i, days) => {
        const guidelineDot = createDotObject(day, guidelineValue)
        guidelineValue =
          isSunday(days[i + 1]) || isSunday(day)
            ? guidelineValue
            : guidelineValue - secondsStep
        const halfDayDot = createDotObject(
          day,
          guidelineValue,
          DATE_FORMATS.YEAR_MONTH_DAY_HOUR_MINUTE
        )
        guidelineValue = isWeekend(days[i + 1])
          ? guidelineValue
          : guidelineValue - secondsStep
        return [guidelineDot, halfDayDot]
      })
      .flat()
    return { ...toRaw(chartData.value), guideline }
  }
})

const isChartDataEmpty = computed(
  () =>
    !updatedChartData.value.adaptive_remaining?.length &&
    !updatedChartData.value.adaptive_spent?.length
)

const sortedLabels = computed(() =>
  uniq(
    values(updatedChartData.value)
      .map((item) => (isArray(item) ? item.map((data) => data.date) : []))
      .flat()
      .sort()
  )
)

const annotations = computed(() => {
  const weekends =
    showNonWorkingDays.value && isDayAggregation.value
      ? updatedChartData.value.guideline
          .map((item, i) => ({ ...item, index: i }))
          .filter((item) => isWeekend(new Date(item.date)))
      : []
  const weekendsAnnotations = weekends.map((weekend, i) => [
    `weekend${i}`,
    {
      type: 'box',
      drawTime: 'beforeDatasetsDraw',
      xMin: weekends[i].index - 1,
      xMax: weekends[i].index,
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      borderWidth: 0,
    },
  ])
  const annotations = Object.fromEntries(weekendsAnnotations)
  if (props.milestonePopup) {
    annotations.release_date = verticalAnnotation({
      color: 'black',
      label: '',
      value: sortedLabels.value[sortedLabels.value.length - 1],
      display: true,
      borderDash: [5, 5],
      hideTooltip: true,
    })
  }
  return annotations
})

const burndownData = computed(() => ({
  labels: sortedLabels.value,
  datasets: [
    {
      label: 'Time Spent',
      key: 'adaptive_spent',
      data: updatedChartData.value?.adaptive_spent,
      parsing: {
        yAxisKey: 'value',
        xAxisKey: 'date',
      },
      backgroundColor: usedColors['info-500'],
      borderColor: usedColors['info-500'],
      borderWidth: 2,
      borderRadius: 4,
      pointRadius: function (context: any): number {
        return (props.milestonePopup &&
          (context.index === 0 ||
            context.index ===
              updatedChartData.value?.adaptive_spent.length - 1)) ||
          !props.milestonePopup
          ? 3
          : 0
      },
      spanGaps: true,
    },
    {
      label: 'Remaining Values',
      key: 'adaptive_remaining',
      data: updatedChartData.value?.adaptive_remaining,
      parsing: {
        xAxisKey: 'date',
        yAxisKey: 'value',
      },
      backgroundColor: usedColors['danger-500'],
      borderColor: usedColors['danger-500'],
      borderWidth: 2,
      borderRadius: 4,
      pointRadius: function (context: any): number {
        return (props.milestonePopup &&
          (context.index === 0 ||
            context.index ===
              updatedChartData.value?.adaptive_remaining.length - 1)) ||
          !props.milestonePopup
          ? 3
          : 0
      },
      spanGaps: true,
    },
    {
      label: GUIDELINE_LABEL,
      key: 'guideline',
      backgroundColor: '#A0A0A0',
      borderColor: '#A0A0A0',
      data: updatedChartData.value?.guideline,
      parsing: {
        xAxisKey: 'date',
        yAxisKey: 'value',
      },
      borderRadius: 4,
      borderWidth: 2,
      pointRadius: 0,
      pointHoverRadius: 0,
    },
    {
      label: NON_WORKING_DAYS_LABEL,
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      borderColor: 'rgba(0, 0, 0, 0.05)',
    },
  ],
}))

const burndownOptions = computed(() => ({
  type: 'line',
  responsive: true,
  maintainAspectRatio: false,
  onHover: (e: ChartEvent, chartElement: ActiveElement[]): void =>
    toggleCursorOnChartDataHover(e, chartElement[0]),
  scales: {
    x: {
      position: 'bottom',
      grid: { drawOnChartArea: false },
      ticks: {
        callback: function (val: number, context, ticks): string {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const dateValue = this.getLabelForValue(val)
          if (props.milestonePopup) {
            const formattedDate = format(new Date(dateValue), 'dd MMM, yyyy')
            if (val === 0 || val === ticks.length - 1) {
              return formattedDate
            }
            return ''
          } else {
            if (dateValue.split(' ').length > 1) return ''
            return generateTickForXAxisTimeCharts(
              dateValue,
              props.filters.scale_type
            )
          }
        },
        autoSkip: !props.milestonePopup,
        maxTicksLimit: props.milestonePopup ? 0 : false,
        maxRotation: props.milestonePopup ? 0 : 50,
      },
    },
    y: {
      grid: { drawOnChartArea: false },
      ticks: {
        callback: (value: number): string =>
          getEstimationInHoursString(value, chartData.value.estimation_type),
      },
    },
  },
  indexAxis: 'x',
  interaction: {
    mode: 'nearest',
    intersect: false,
  },
  plugins: {
    datalabels: null,
    tooltip: {
      mode: 'nearest',
      callbacks: {
        title: (context: TooltipItem<any>[]): string => {
          if (isEmpty(context)) return ''
          const label = context[0].label
          return generateTooltipTitleForXAxisTimeCharts(
            label,
            props.filters.scale_type
          )
        },
        label: (context: TooltipItem<any>): string => {
          const { label } = context.dataset
          return label
            ? `${label}: ${getEstimationInHoursString(
                context.parsed.y,
                chartData.value.estimation_type
              )}`
            : ''
        },
      },
      filter: function (tooltipItem: TooltipItem<any>) {
        return tooltipItem.datasetIndex !== 2
      },
    },
    legend: {
      onHover: (evt: ChartEvent): void => showCursorOnLegendHover(evt),
      onLeave: (evt: ChartEvent): void => hideCursorOnLegendLeave(evt),
      onClick: function (
        _e: ChartEvent,
        legendItem: LegendItem,
        legend: LegendElement<any>
      ): void {
        const ci = legend.chart
        const index = legendItem.datasetIndex
        const annKeys = Object.keys(
          ci.config.options.plugins.annotation.annotations
        )
        if (ci.isDatasetVisible(index)) {
          if (legendItem.text === NON_WORKING_DAYS_LABEL) {
            annKeys.forEach((key) => {
              ci.config.options.plugins.annotation.annotations[key].display =
                false
            })
            showNonWorkingDays.value = false
          }
          ci.hide(index)
        } else {
          if (legendItem.text === NON_WORKING_DAYS_LABEL) {
            annKeys.forEach((key) => {
              ci.config.options.plugins.annotation.annotations[key].display =
                true
            })
            showNonWorkingDays.value = true
          }
          ci.show(index)
        }
      },
      align: 'start',
      labels: {
        filter: (item: LegendItem) =>
          isDayAggregation.value ? item : item.datasetIndex < 3,
        borderRadius: 4,
        boxWidth: 10,
        boxHeight: 10,
        generateLabels(chart) {
          return chart.data.datasets.map((dataset, i) => ({
            datasetIndex: i,
            text:
              !props.milestonePopup ||
              dataset.label === GUIDELINE_LABEL ||
              dataset.label === NON_WORKING_DAYS_LABEL
                ? dataset.label
                : `${dataset.label}: ${getEstimationInHoursString(
                    dataset.data[dataset.data.length - 1]?.value,
                    chartData.value.estimation_type
                  )}`,
            fillStyle: dataset.backgroundColor,
            strokeStyle: dataset.backgroundColor,
            hidden: !chart.isDatasetVisible(i),
          }))
        },
      },
    },
    annotation: {
      annotations: annotations.value,
    },
  },
}))

const activeFilters = computed(() => {
  const filters = selectedSprints.value
    ? {
        ...omit(['since', 'until'], props.filters),
        sprints: selectedSprints.value,
      }
    : props.filters
  return {
    ...reject(equals(null))(filters as Dictionary<any>),
  }
})

const getChartData = async () => {
  fetchError.value = ''
  loading.value = true
  const projects = props.filters.projects
  const project_id = isArray(projects) ? projects[0] : projects
  try {
    chartData.value = await store.dispatch('burndown/getData', {
      filters: activeFilters.value,
      project_id,
    })
    loading.value = false
  } catch (e) {
    if (e instanceof Error) {
      fetchError.value = e?.message
    }
    loading.value = false
  }
}

watchEffect(async () => {
  if (
    store.getters['company/selectedCompanyId'] &&
    activeFilters.value.projects &&
    (activeFilters.value.until ||
      activeFilters.value.sprints ||
      activeFilters.value.releases)
  ) {
    getChartData()
  }
})

const updateBurndownDotData = (point: any) => {
  if (point.datasetIndex < 2 && props.filters.scale_type) {
    const baseData = {
      year: `${getYear(new Date(point.element.$context.raw.date))}`,
      line_type: point.element.$context.dataset.key,
      label: point.element.$context.dataset.label,
      scale_type: props.filters.scale_type,
      project_id: `${props.filters.projects[0]}`,
    }
    if (props.filters.scale_type === AGGREGATION_TYPE.DATE) {
      burndownDotData.value = {
        date: point.element.$context.raw.date,
        filter_date: point.element.$context.raw.date,
        ...baseData,
      }
    } else if (props.filters.scale_type === AGGREGATION_TYPE.WEEK) {
      burndownDotData.value = {
        ...baseData,
        filter_date: `${format(
          startOfWeek(new Date(point.element.$context.raw.date), {
            weekStartsOn: 1,
          }),
          'yyyy-MM-dd'
        )}`,
        week: `${getISOWeek(new Date(point.element.$context.raw.date))}`,
      }
    } else if (props.filters.scale_type === AGGREGATION_TYPE.MONTH) {
      burndownDotData.value = {
        ...baseData,
        filter_date: `${format(
          startOfMonth(new Date(point.element.$context.raw.date)),
          'yyyy-MM-dd'
        )}`,
        month: `${getMonth(new Date(point.element.$context.raw.date)) + 1}`,
      }
    }
  }
}

watchEffect(async () => {
  if (props.filters.projects) {
    sprintsLoading.value = true
    const sprintsResponse = await store.dispatch(
      'burndown/getSprints',
      props.filters.projects
    )
    sprintsLoading.value = false
    sprints.value = map(
      (sprint: Sprint) =>
        sprint.state === 'active'
          ? { ...sprint, name: `${sprint.name} (${sprint.state})` }
          : sprint,
      reverse(sortBy(prop('id'), sprintsResponse))
    )
  }
})
</script>

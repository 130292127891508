<template>
  <ChartWrapper
    :title="chartTitle.workload"
    :loading="loading"
    :fetch-error="fetchError"
    :tooltip="chartExplanations.workload"
    :is-chart-data-empty="isEmpty(chartData)"
    :type="chartTypes.Workload"
    :icons="['tempo']"
  >
    <template #buttons>
      <SortingMenu
        v-if="sortingOptions.length"
        :sorting-options="sortingOptions"
        @change-sorting="sorting = $event"
      ></SortingMenu>
    </template>
    <BasicChart
      :data="workloadData"
      :options="workloadOptions"
      type="bar"
      :chart-name="chartTypes.Workload"
      @dot-data="openWorkloadReport"
    ></BasicChart>
  </ChartWrapper>
</template>

<script setup lang="ts">
import ChartWrapper from '@/components/charts/ChartWrapper.vue'
import SortingMenu from '@/components/common/menu/SortingMenu.vue'
import BasicChart from '@/components/charts/BasicChart.vue'
import { Filters } from '@/store/modules/filters'
import { computed, defineProps, ref } from 'vue'
import {
  chartExplanations,
  chartTitle,
  chartTypes,
} from '@/constants/charts/constants'
import { isEmpty, omit, path, sortBy } from 'ramda'
import { usedColors } from '@/constants/constants'
import { ActiveElement, ChartEvent, LegendItem, TooltipItem } from 'chart.js'
import { WorkloadPerUser } from '@/store/modules/charts/workload'
import {
  toggleCursorOnChartDataHover,
  verticalAnnotation,
} from '@/utils/chart-utils'
import useGettingChartData from '@/utils/hooks/useGettingChartData'
import { useStore } from '@/store'

const props = defineProps<{ filters: Filters; projectPage?: boolean }>()
const sorting = ref('')
const store = useStore()

const { response, loading, fetchError } = useGettingChartData(
  { ...props, filters: omit(['scale_type'], props.filters) },
  chartTypes.Workload
)

const sortingOptions = [
  { text: 'Author', value: 'user.name' },
  { text: 'Workload', value: 'workload_percent' },
]

const chartData = computed(() => response.value?.data)

const sortedLabels = computed(() => {
  const sorted = sortBy(
    path(sorting.value.split('.')) as any,
    chartData.value || []
  )
  return sorted.map((data: WorkloadPerUser) => data.user?.name)
})

const workloadData = computed(() => ({
  labels: sortedLabels.value,
  datasets: [
    {
      label: 'Workload',
      key: 'workload_percent',
      data: chartData.value,
      parsing: {
        yAxisKey: 'user.name',
        xAxisKey: 'workload_percent',
      },
      backgroundColor: usedColors['success-400'],
      borderRadius: {
        topRight: 4,
        bottomRight: 4,
      },
      datalabels: {
        labels: {
          filler: null,
        },
      },
    },
    {
      label: 'All Avg',
      backgroundColor: usedColors['danger-400'],
      data: [],
    },
    {
      label: 'Avg.',
      backgroundColor: usedColors['warning-300'],
      data: [],
    },
    {
      key: 'filler',
      datalabels: {
        labels: {
          workload: null,
        },
      },
      parsing: {
        yAxisKey: 'user.name',
        xAxisKey: 'filler',
      },
      data: chartData.value,
      borderRadius: 4,
      backgroundColor: '#f5f5f5',
    },
    {
      key: 'over_filler',
      datalabels: {
        labels: {
          workload: null,
        },
      },
      parsing: {
        yAxisKey: 'user.name',
        xAxisKey: 'over_filler',
      },
      data: chartData.value,
      borderRadius: 4,
      backgroundColor: '#ff6666',
    },
  ],
}))

const workloadOptions = computed(() => ({
  responsive: true,
  maintainAspectRatio: false,
  onHover: (e: ChartEvent, chartElement: ActiveElement[]): void =>
    toggleCursorOnChartDataHover(e, chartElement[0]),
  scales: {
    x: {
      ticks: {
        stepSize: 1,
      },
      display: false,
      stacked: true,
    },
    y: { display: false, stacked: true },
  },
  indexAxis: 'y',
  interaction: {
    mode: 'index',
  },
  plugins: {
    tooltip: {
      mode: 'index',
      callbacks: {
        label: (context: TooltipItem<'bar'>): string => {
          const { label } = context.dataset
          const dataUser = context.dataset.data.find(
            (data: WorkloadPerUser) => data.user.name === context.label
          )
          return label === 'Workload'
            ? `${label}: ${Math.round(
                (dataUser?.over_filler || 0) + context.parsed.x
              )}% / ${Math.round(
                dataUser.time_spent_seconds / 3600
              )}h of ${Math.round(dataUser.required_time_seconds / 3600)}h`
            : ''
        },
      },
    },
    legend: {
      onClick: false,
      display: true,
      align: 'start',
      labels: {
        filter: (item: LegendItem): boolean => item.datasetIndex < 3,
        borderRadius: 4,
        boxWidth: 12,
        boxHeight: 12,
      },
    },
    datalabels: {
      labels: {
        workload: {
          anchor: 'start',
          display: 'auto',
          align: 'right',
          formatter: (value: WorkloadPerUser): string => value.user.name,
        },
        filler: {
          anchor: 'end',
          align: 'left',
          display: 'auto',
          formatter: (value: WorkloadPerUser): string =>
            Math.round((value.over_filler || 0) + value.workload_percent) +
            '%' +
            ' / ' +
            Math.round(value.time_spent_seconds / 3600) +
            'h',
        },
      },
    },
    annotation: {
      annotations: {
        avg: verticalAnnotation({
          color: usedColors['warning-300'],
          label: 'Avg.',
          value: chartData.value?.avg_data?.avg_percent,
          showPercentage: true,
          display: true,
        }),
        all_avg: verticalAnnotation({
          color: usedColors['danger-400'],
          label: 'All Avg.',
          value: chartData.value?.avg_data?.all_avg_percent,
          showPercentage: true,
          display: true,
        }),
      },
    },
  },
  categoryPercentage: 1,
  barPercentage: 0.8,
}))

const openWorkloadReport = async (ev: any) => {
  const userId = ev.element.$context.raw.user.id
  const response = await store.dispatch(
    'workload/getLinkToOpenWorkloadReport',
    {
      id: userId,
      filters: props.filters,
    }
  )
  window.open(response.url, '_blank', 'noopener,noreferrer')
}
</script>

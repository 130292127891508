<template>
  <ChartWrapper
    :title="chartTitle[chartTypes.EstimationCoverage]"
    :loading="loading"
    :fetch-error="fetchError"
    :tooltip="chartExplanations[chartTypes.EstimationCoverage]"
    :is-chart-data-empty="isEmpty(chartData)"
    :type="chartTypes.EstimationCoverage"
    :icons="['jira']"
  >
    <template #buttons>
      <SortingMenu
        v-if="sortingOptions.length"
        :sorting-options="sortingOptions"
        @change-sorting="sorting = $event"
      ></SortingMenu>
    </template>
    <BasicChart
      :data="estimationCoverageData"
      :options="estimationCoverageOptions"
      type="bar"
      :chart-name="chartTypes.EstimationCoverage"
    ></BasicChart>
  </ChartWrapper>
</template>

<script setup lang="ts">
import ChartWrapper from '@/components/charts/ChartWrapper.vue'
import SortingMenu from '@/components/common/menu/SortingMenu.vue'
import BasicChart from '@/components/charts/BasicChart.vue'
import { Filters } from '@/store/modules/filters'
import { computed, defineProps, ref, withDefaults } from 'vue'
import {
  chartExplanations,
  chartTitle,
  chartTypes,
} from '@/constants/charts/constants'
import { isEmpty, omit, path, sortBy } from 'ramda'
import { AGGREGATE_BY, usedColors } from '@/constants/constants'
import { LegendItem, TooltipItem } from 'chart.js'
import { verticalAnnotation } from '@/utils/chart-utils'
import { EstimationCoveragePerUser } from '@/store/modules/charts/estimation-coverage'
import useGettingChartData from '@/utils/hooks/useGettingChartData'
import { Aggregate_By_Type } from '@/types/types'

const props = withDefaults(
  defineProps<{
    filters: Filters
    projectPage?: boolean
    aggregation?: Aggregate_By_Type
  }>(),
  { aggregation: AGGREGATE_BY.USERS }
)

const isAggregationByProjects = computed(
  () => props.aggregation === AGGREGATE_BY.PROJECTS
)

const sorting = ref('')

const sortingOptions = [
  isAggregationByProjects.value
    ? { text: 'Project', value: 'project.name' }
    : { text: 'Author', value: 'user.name' },
  { text: 'Coverage', value: 'coverage_percent' },
]

const { response, loading, fetchError } = useGettingChartData(
  {
    ...props,
    filters: {
      ...omit(['scale_type'], props.filters),
      aggregate_by: isAggregationByProjects.value
        ? AGGREGATE_BY.PROJECTS
        : AGGREGATE_BY.USERS,
    },
  },
  chartTypes.EstimationCoverage
)

const chartData = computed(() => response.value?.data)

const sortedLabels = computed(() => {
  const sorted = sortBy(
    path(sorting.value.split('.')) as any,
    chartData.value || []
  )
  return sorted.map((data: EstimationCoveragePerUser) =>
    isAggregationByProjects.value ? data.project_name : data.user_name
  )
})

const estimationCoverageData = computed(() => ({
  labels: sortedLabels.value,
  datasets: [
    {
      label: 'Coverage',
      key: 'coverage_percent',
      data: chartData.value,
      parsing: {
        yAxisKey: `${
          isAggregationByProjects.value ? 'project_name' : 'user_name'
        }`,
        xAxisKey: 'coverage_percent',
      },
      backgroundColor: usedColors['success-400'],
      borderRadius: {
        topRight: 4,
        bottomRight: 4,
      },
      datalabels: {
        labels: {
          filler: null,
        },
      },
    },
    {
      label: 'All Avg.',
      backgroundColor: usedColors['danger-400'],
      data: [],
    },
    {
      label: 'Avg.',
      backgroundColor: usedColors['warning-300'],
      data: [],
    },
    {
      key: 'filler',
      datalabels: {
        labels: {
          coverage_percent: null,
        },
      },
      parsing: {
        yAxisKey: `${
          isAggregationByProjects.value ? 'project_name' : 'user_name'
        }`,
        xAxisKey: 'filler',
      },
      data: chartData.value,
      borderRadius: 4,
      backgroundColor: '#f5f5f5',
    },
  ],
}))

const estimationCoverageOptions = computed(() => ({
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: 'index',
  },
  scales: {
    x: { display: false, max: 100, stacked: true },
    y: { display: false, stacked: true },
  },
  indexAxis: 'y',
  plugins: {
    tooltip: {
      mode: 'index',
      callbacks: {
        label: ({ dataset, dataIndex }: TooltipItem<any>): string => {
          let { label } = dataset
          if (label === 'Coverage') {
            label = `${dataset.data[dataIndex].estimated_issues_count} of ${dataset.data[dataIndex].issues_count} issues estimated.`
            return label
          }
          return ''
        },
      },
    },
    legend: {
      onClick: false,
      display: true,
      align: 'start',
      labels: {
        filter: (item: LegendItem): boolean => item.datasetIndex !== 3,
        borderRadius: 4,
        boxWidth: 12,
        boxHeight: 12,
      },
    },
    datalabels: {
      labels: {
        coverage_percent: {
          anchor: 'start',
          display: 'auto',
          align: 'right',
          formatter: (value: EstimationCoveragePerUser): string =>
            value[
              isAggregationByProjects.value ? 'project_name' : 'user_name'
            ] || '',
        },
        filler: {
          anchor: 'end',
          align: 'left',
          display: 'auto',
          formatter: (value: EstimationCoveragePerUser): string =>
            Math.round(value.coverage_percent) + '%',
        },
      },
    },
    annotation: {
      annotations: {
        avg_count: verticalAnnotation({
          color: usedColors['warning-300'],
          label: 'Avg. Count',
          value: response.value?.avg_data.avg_count,
          showPercentage: true,
          display: true,
        }),
        all_avg_count: verticalAnnotation({
          color: usedColors['danger-400'],
          label: 'All Avg. Count',
          value: response.value?.avg_data.all_avg_count,
          showPercentage: true,
          display: true,
        }),
      },
    },
  },
  categoryPercentage: 1,
  barPercentage: 0.8,
}))
</script>

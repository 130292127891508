<template>
  <div class="w-full">
    <MultiSelect
      v-model="selectedValue"
      :options="searchMatchedItems"
      :option-label="optionLabel"
      :option-value="optionValue"
      placeholder="- Select -"
      display="chip"
      v-bind="$attrs"
      :showToggleAll="false"
      :class="{ 'p-invalid': errors.length }"
      @hide="onHide"
    >
      <template v-if="search" #header>
        <BaseInputText
          v-model:value="searchItem"
          class="px-2 py-1"
          icon-left="pi-search"
          placeholder="Search"
        ></BaseInputText>
      </template>
    </MultiSelect>
    <div
      v-for="error of errors"
      class="text-left text-sm text-danger-600 mt-1"
      :key="error.$uid"
    >
      {{ error.$message }}
    </div>
  </div>
</template>

<script setup lang="ts">
import MultiSelect from 'primevue/multiselect/MultiSelect.vue'
import { computed, defineProps, defineEmits, withDefaults, ref } from 'vue'
import BaseInputText from '@/components/common/base/BaseInputText.vue'

const props = withDefaults(
  defineProps<{
    selected: null | number[]
    options: any
    optionValue?: string
    optionLabel?: string
    stringArray?: boolean
    errors?: any
    search?: boolean
  }>(),
  { optionValue: 'id', optionLabel: 'name', errors: [] }
)

const emit = defineEmits<{
  (e: 'update:selected', value: null | number[]): void
  (e: 'change'): void
}>()

const optionValue = computed(() =>
  props.stringArray ? null : props.optionValue
)
const optionLabel = computed(() =>
  props.stringArray ? null : props.optionLabel
)

const selectedValue = computed({
  get() {
    return props.selected
  },
  set(value: null | number[]) {
    emit('update:selected', value)
  },
})

const searchItem = ref('')

const searchMatchedItems = computed(() => {
  if (searchItem.value) {
    return props.options.filter((item: any) => {
      return item.name.toLowerCase().includes(searchItem.value.toLowerCase())
    })
  }
  return props.options
})

const onHide = () => {
  searchItem.value = ''
}
</script>

<template>
  <Dialog
    :visible="open"
    :style="{ width: '90vw' }"
    :dismissableMask="true"
    @update:visible="$emit('close')"
    position="bottom"
    modal
  >
    <template #header>
      <div class="header-wrapper">{{ commentsData?.user?.name }}</div>
    </template>
    <div class="text-xl font-semi-bold mb-4">
      Comments rated by {{ commentsData?.rate }}
    </div>
    <div class="height flex mb-8" v-if="loading">
      <LoaderWrapper></LoaderWrapper>
    </div>
    <MyTable
      v-else
      :table-data="drilldwonData"
      :columns-data="columnsData"
      customHeight="70vh"
      :scrollable="scrollableTable"
      class="mb-8"
      lazy-table
      :lazy-loading="lazyLoading"
      @load="onLazyLoad"
    >
      <template #comment="{ data }">
        <Link :href="data.url" style-class="truncate">
          {{ data.comment }}
        </Link>
      </template>
      <template #created="{ data }">
        {{ calcDateFormat(data.created) }}
      </template>
    </MyTable>
  </Dialog>
</template>

<script setup lang="ts">
import Dialog from 'primevue/dialog/Dialog.vue'
import { computed, defineProps, ref, watchEffect } from 'vue'
import LoaderWrapper from '@/components/common/loader/LoaderWrapper.vue'
import MyTable from '@/components/common/table/MyTable.vue'
import { useStore } from '@/store'
import { showToastError } from '@/utils/utils'
import { useToast } from 'primevue/usetoast'
import { Dictionary, equals, reject } from 'ramda'
import { Filters } from '@/store/modules/filters'
import Link from '@/components/common/Link.vue'
import { calcDateFormat } from '@/utils/date-utils'

const props = defineProps<{
  open: boolean
  commentsData?: {
    user: { id: number; name: string }
    rate?: number
  } | null
  filters: Filters
}>()

const drilldwonData = ref([])
const nextPage = ref(null)
const loading = ref(false)
const lazyLoading = ref(false)
const store = useStore()
const toast = useToast()
const scrollableTable = ref(true)

const columnsData = [
  {
    header: 'Comment',
    field: 'comment',
    is_sortable: true,
    use_template: true,
    styles: 'flex-grow: 2.5; padding-right: 10px',
    classes: 'sm:max-w-xs md:max-w-lg lg:max-w-2xl xl:max-w-4xl ',
  },
  {
    header: 'Project Name',
    field: 'original_project_name',
    is_sortable: true,
    use_template: false,
    styles: 'padding-right: 10px',
    classes: '',
  },
  {
    header: 'Date/Time',
    field: 'created',
    is_sortable: true,
    use_template: true,
    styles: 'padding-right: 10px; min-width: 117px',
    classes: '',
  },
]

watchEffect(() => {
  if (props.commentsData) {
    getActivitiesDetails()
  }
})

const activeFilters = computed(() =>
  reject(equals(null))(props.filters as Dictionary<null>)
)

async function getActivitiesDetails() {
  loading.value = true
  try {
    const page = await store.dispatch(`commentsRates/getFirstPageOfComments`, {
      ...activeFilters.value,
      users: `${props.commentsData?.user.id}`,
      rate: `${props.commentsData?.rate}`,
    })
    drilldwonData.value = page.results
    nextPage.value = page.next
    loading.value = false
  } catch (e) {
    showToastError(toast, e)
    loading.value = false
  }
}

/* istanbul ignore next */
async function onLazyLoad(event: { first: number; last: number }) {
  const { last } = event
  if (!last || lazyLoading.value) return
  try {
    if (nextPage.value && last === drilldwonData.value.length) {
      lazyLoading.value = true
      const page = await store.dispatch(
        'userActivity/getNextPageOfActivities',
        nextPage.value
      )
      nextPage.value = page.next
      drilldwonData.value = drilldwonData.value.concat(page.results)
    }
    lazyLoading.value = false
  } catch (e) {
    lazyLoading.value = false
    showToastError(toast, e)
  }
}
</script>

<style scoped>
.header-wrapper {
  @apply flex items-center justify-between w-full text-base;
}
.height {
  height: 70vh;
}
</style>

<template>
  <Dialog
    :visible="open"
    :style="{ width: '90vw' }"
    :dismissableMask="true"
    @update:visible="$emit('close')"
    position="bottom"
    modal
  >
    <template #header>
      <div class="header-wrapper">
        {{ drilldownData?.user?.name || drilldownData?.project?.name }}
      </div>
    </template>
    <div class="text-xl font-semi-bold mb-4">
      Activities: {{ drilldownData?.activity }}
    </div>
    <div class="height flex mb-8" v-if="loading">
      <LoaderWrapper></LoaderWrapper>
    </div>
    <MyTable
      v-else
      :table-data="drilldwonData"
      :columns-data="columnsData"
      customHeight="70vh"
      :scrollable="scrollableTable"
      class="mb-8"
      lazy-table
      :lazy-loading="lazyLoading"
      @load="onLazyLoad"
    >
      <template #title="{ data }">
        <Link :href="data.web_url || data.url" style-class="truncate">
          {{ data.title }}
        </Link>
      </template>
      <template #message="{ data }">
        <Link :href="data.web_url || data.url" style-class="truncate">
          {{ data.message }}
        </Link>
      </template>
      <template #comment="{ data }">
        <Link :href="data.url" style-class="truncate">
          {{ data.comment }}
        </Link>
      </template>
      <template #user="{ data }">
        {{ data.user.name }}
      </template>
      <template #created="{ data }">
        {{ calcDateFormat(data.created) }}
      </template>
      <template #committed_date="{ data }">
        {{ calcDateFormat(data.committed_date) }}
      </template>
    </MyTable>
  </Dialog>
</template>

<script setup lang="ts">
import Dialog from 'primevue/dialog/Dialog.vue'
import { computed, defineProps, ref, watchEffect } from 'vue'
import LoaderWrapper from '@/components/common/loader/LoaderWrapper.vue'
import MyTable from '@/components/common/table/MyTable.vue'
import { useStore } from '@/store'
import { showToastError } from '@/utils/utils'
import { useToast } from 'primevue/usetoast'
import { Dictionary, equals, reject } from 'ramda'
import { Filters } from '@/store/modules/filters'
import Link from '@/components/common/Link.vue'
import { calcDateFormat } from '@/utils/date-utils'
import {
  AGGREGATION_TYPE,
  FILTERS_DATE_FORMAT,
  USER_ACTIVITIES,
} from '@/constants/constants'
import { addMonths, format, addWeeks } from 'date-fns'
import { DrilldownData } from '@/components/charts/user-activity/Activities.vue'

const props = defineProps<{
  open: boolean
  drilldownData: DrilldownData | null
  filters: Filters
  useScale?: boolean
}>()

const drilldwonData = ref([])
const nextPage = ref(null)
const loading = ref(false)
const lazyLoading = ref(false)
const store = useStore()
const toast = useToast()
const scrollableTable = ref(true)

const header = computed(() => {
  switch (props.drilldownData?.activity) {
    case USER_ACTIVITIES.COMMENTS:
      return 'Comment'
    case USER_ACTIVITIES.COMMITS:
      return 'Commit Message'
    case USER_ACTIVITIES.MERGE_REQUESTS:
      return 'Title'
    default:
      return 'Title'
  }
})
const field = computed(() => {
  switch (props.drilldownData?.activity) {
    case USER_ACTIVITIES.COMMENTS:
      return 'comment'
    case USER_ACTIVITIES.COMMITS:
      return 'message'
    case USER_ACTIVITIES.MERGE_REQUESTS:
      return 'title'
    default:
      return 'title'
  }
})

const columnsData = computed(() => {
  let columns = [
    {
      header: header.value,
      field: field.value,
      is_sortable: true,
      use_template: true,
      styles: 'flex-grow: 2.5; padding-right: 10px',
      classes:
        props.drilldownData?.activity === USER_ACTIVITIES.COMMENTS
          ? 'sm:max-w-xs md:max-w-lg lg:max-w-2xl xl:max-w-4xl '
          : '',
    },
  ]
  columns = columns.concat([
    props.drilldownData?.project
      ? {
          header: 'Author',
          field: 'user',
          is_sortable: true,
          use_template: true,
          styles: 'padding-right: 10px',
          classes: '',
        }
      : {
          header: 'Project Name',
          field: `${
            props.drilldownData?.activity === USER_ACTIVITIES.COMMITS
              ? 'original_project_name'
              : 'repository_name'
          }`,
          is_sortable: true,
          use_template: false,
          styles: 'padding-right: 10px',
          classes: '',
        },
    {
      header: 'Date/Time',
      field: `${
        props.drilldownData?.activity === USER_ACTIVITIES.COMMITS
          ? 'committed_date'
          : 'created'
      }`,
      is_sortable: true,
      use_template: true,
      styles: 'padding-right: 10px; min-width: 117px',
      classes: '',
    },
  ])
  return columns
})

watchEffect(() => {
  if (props.drilldownData) {
    getActivitiesDetails()
  }
})

const activeFilters = computed(() =>
  reject(equals(null))(props.filters as Dictionary<null>)
)

const drilldownDateRange = computed(() =>
  props.useScale && props.filters.scale_type
    ? {
        [AGGREGATION_TYPE.DATE]: {
          since: props.drilldownData?.date,
          until: props.drilldownData?.date,
        },
        [AGGREGATION_TYPE.WEEK]: {
          since: props.drilldownData?.date,
          until: format(
            addWeeks(new Date(props.drilldownData?.date || ''), 1),
            FILTERS_DATE_FORMAT
          ),
        },
        [AGGREGATION_TYPE.MONTH]: {
          since: props.drilldownData?.date,
          until: format(
            addMonths(new Date(props.drilldownData?.date || ''), 1),
            FILTERS_DATE_FORMAT
          ),
        },
      }[props.filters.scale_type]
    : {}
)

async function getActivitiesDetails() {
  loading.value = true
  const itemData = props.drilldownData?.project
    ? { projects: `${props.drilldownData.project.id}` }
    : { users: `${props.drilldownData?.user?.id}` }
  try {
    const page = await store.dispatch(
      `userActivity/getFirstPageOf${props.drilldownData?.activity.replace(
        ' ',
        ''
      )}`,
      {
        ...activeFilters.value,
        ...itemData,
        ...drilldownDateRange.value,
      }
    )
    drilldwonData.value = page.results
    nextPage.value = page.next
    loading.value = false
  } catch (e) {
    showToastError(toast, e)
    loading.value = false
  }
}

/* istanbul ignore next */
async function onLazyLoad(event: { first: number; last: number }) {
  const { last } = event
  if (!last || lazyLoading.value) return
  try {
    if (nextPage.value && last === drilldwonData.value.length) {
      lazyLoading.value = true
      const page = await store.dispatch(
        'userActivity/getNextPageOfActivities',
        nextPage.value
      )
      nextPage.value = page.next
      drilldwonData.value = drilldwonData.value.concat(page.results)
    }
    lazyLoading.value = false
  } catch (e) {
    lazyLoading.value = false
    showToastError(toast, e)
  }
}
</script>

<style scoped>
.header-wrapper {
  @apply flex items-center justify-between w-full text-base;
}
.height {
  height: 70vh;
}
</style>

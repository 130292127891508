<template>
  <ChartWrapper
    :title="chartTitle[chartTypes.BugMetrics]"
    :loading="loading"
    :fetch-error="fetchError"
    :tooltip="chartExplanations[chartTypes.BugMetrics]"
    :is-chart-data-empty="isEmpty(chartData)"
    :type="chartTypes.BugMetrics"
    :icons="['jira', 'tempo']"
  >
    <BasicChart
      :data="bugMetricsData"
      :options="bugMetricsOptions"
      type="bar"
      :chart-name="chartTypes.BugMetrics"
      @dot-data="saveMetricsData"
    ></BasicChart>
    <BugMetricsDrilldown
      :open="!!clickedMetricsData"
      :bug-metrics-data="clickedMetricsData"
      :filters="filters"
      @close="clickedMetricsData = null"
    ></BugMetricsDrilldown>
  </ChartWrapper>
</template>

<script setup lang="ts">
import ChartWrapper from '@/components/charts/ChartWrapper.vue'
import BasicChart from '@/components/charts/BasicChart.vue'
import { Filters } from '@/store/modules/filters'
import { computed, defineProps, ref } from 'vue'
import {
  chartExplanations,
  chartTitle,
  chartTypes,
} from '@/constants/charts/constants'
import { isEmpty } from 'ramda'
import { AGGREGATION_TYPE, usedColors } from '@/constants/constants'
import useGettingChartData from '@/utils/hooks/useGettingChartData'
import { BugMetricsItem } from '@/store/modules/charts/bug-metrics'
import BugMetricsDrilldown from '@/components/charts/bug-metrics/BugMetricsDrilldown.vue'
import {
  generateTickForXAxisTimeCharts,
  hideCursorOnLegendLeave,
  showCursorOnLegendHover,
  toggleCursorOnChartDataHover,
} from '@/utils/chart-utils'
import { ActiveElement, ChartEvent } from 'chart.js'

const props = defineProps<{ filters: Filters; projectPage?: boolean }>()
const clickedMetricsData = ref(null)

const {
  response: chartData,
  loading,
  fetchError,
} = useGettingChartData(
  { ...props, filters: props.filters },
  chartTypes.BugMetrics
)

const preparedChartData = computed(() => {
  return (
    chartData.value?.map((item: BugMetricsItem) => ({
      ...item,
      sum_new_resolved_at_this_week: item.sum_new_resolved_at_this_week * -1,
      sum_old_resolved_at_this_week: item.sum_old_resolved_at_this_week * -1,
    })) || []
  )
})

const bugMetricsData = computed(() => ({
  type: 'bar',
  datasets: [
    {
      type: 'bar',
      label: 'Found old (past)',
      data: preparedChartData.value.map((item) => ({
        x: item.date,
        y: item.sum_old_not_resolved_at_this_week,
        key: 'old_not_resolved_at_this_week',
        label: 'Found old (past)',
      })),
      backgroundColor: usedColors['danger-600'],
    },
    {
      label: 'Found new (week)',
      data: preparedChartData.value.map((item) => ({
        x: item.date,
        y: item.sum_new_not_resolved_at_this_week,
        key: 'new_not_resolved_at_this_week',
        label: 'Found new (week)',
      })),
      backgroundColor: usedColors['danger-400'],
    },
    {
      label: 'Found (past) and fixed (week)',
      data: preparedChartData.value.map((item) => ({
        x: item.date,
        y: item.sum_old_resolved_at_this_week,
        key: 'old_resolved_at_this_week',
        label: 'Found (past) and fixed (week)',
      })),
      backgroundColor: usedColors['success-600'],
    },
    {
      label: 'Found and fixed (week)',
      data: preparedChartData.value.map((item) => ({
        x: item.date,
        y: item.sum_new_resolved_at_this_week,
        key: 'new_resolved_at_this_week',
        label: 'Found and fixed (week)',
      })),
      backgroundColor: usedColors['success-400'],
    },
  ],
}))

const bugMetricsOptions = computed(() => ({
  responsive: true,
  maintainAspectRatio: false,
  onHover: (e: ChartEvent, chartElement: ActiveElement[]): void =>
    toggleCursorOnChartDataHover(e, chartElement[0]),
  plugins: {
    datalabels: {
      display: false,
    },
    legend: {
      onHover: (evt: ChartEvent): void => showCursorOnLegendHover(evt),
      onLeave: (evt: ChartEvent): void => hideCursorOnLegendLeave(evt),
      align: 'start',
      labels: {
        borderRadius: 4,
        boxWidth: 12,
        boxHeight: 12,
      },
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          const label = context.dataset.label || ''
          const value = context.parsed.y || 0
          return `${label}: ${Math.abs(value)}`
        },
      },
    },
  },
  interaction: {
    mode: 'nearest',
  },
  scales: {
    x: {
      stacked: true,
      ticks: {
        display: true,
        font: {
          size: 10,
        },
        callback: function (value: string, index: number) {
          return generateTickForXAxisTimeCharts(
            preparedChartData.value[index].date,
            AGGREGATION_TYPE.WEEK
          )
        },
      },
    },
    y: {
      stacked: true,
    },
  },
}))

const saveMetricsData = (firstPoint: any) => {
  clickedMetricsData.value = firstPoint.element.$context.raw
}
</script>

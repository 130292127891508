import axios from '@/services/axios'
import { Filters } from '@/store/modules/filters'
import { omit, path } from 'ramda'
import { ActionContext } from 'vuex'
import { State as GlobalState } from '../../index'
import { ESTIMATION_TYPE } from '@/constants/constants'

export interface BurndownFilters extends Filters {
  sprints?: number[]
}

export interface BurndownItem {
  date: string
  value: number
}

export interface BurndownData {
  guideline?: BurndownItem[]
  adaptive_spent: BurndownItem[]
  adaptive_remaining: BurndownItem[]
  estimation_type: ESTIMATION_TYPE
}

export interface Sprint {
  board_id: number
  complete_date: string
  end_date: string
  goal: string
  id: number
  name: string
  project: number
  start_date: string
  state: string
}

interface BurndownDotData {
  from_seconds: number
  issue_id: string
  issue_key: string
  issue_name: string
  start_date: string
  to_seconds: number
  type: string
  absolute_url: string
}

type Context = ActionContext<BurndownData, GlobalState>

const actions = {
  getData(
    { rootGetters }: Context,
    params: { filters: BurndownFilters; project_id: number }
  ): Promise<BurndownData> {
    return axios
      .get(
        `api/companies/${rootGetters['company/selectedCompanyId']}/project-mapping/${params.project_id}/charts/burndown/`,
        {
          params: params.filters,
        }
      )
      .then(({ data }) => data)
  },
  getSprints({ rootGetters }: Context, id: number): Promise<Sprint[]> {
    return axios
      .get(
        `api/companies/${rootGetters['company/selectedCompanyId']}/project-mapping/${id}/charts/sprints/`
      )
      .then(({ data }) => data)
  },
  getBurndownDotDetails(
    { rootGetters }: Context,
    params: {
      date?: string
      scale_type: string
      id: number
      labels: string[] | null
      line_type: string
      historical: boolean
      ordering?: string
      search?: string
    }
  ): Promise<BurndownDotData[]> {
    return axios
      .get(
        `api/companies/${rootGetters['company/selectedCompanyId']}/project-mapping/${params.id}/charts/burndown/drilldown/?limit=50`,
        { params: omit(['id'], params) }
      )
      .then(({ data }) => data)
  },
  getNextPageOfBurndownDetails(_: Context, url: string): Promise<void> {
    return axios.get(`${url}`).then(path(['data']))
  },
}

export default {
  namespaced: true,
  actions,
}

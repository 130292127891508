import {
  addDays,
  addMonths,
  addSeconds,
  addWeeks,
  differenceInDays,
  format,
  parseISO,
  startOfMonth,
  startOfWeek,
  intervalToDuration,
} from 'date-fns'
import { AGGREGATION_TYPE, FILTERS_DATE_FORMAT } from '@/constants/constants'
import { SCALE_TYPE, TimeFilters } from '@/store/modules/filters'

export const DATE_FORMATS = {
  DAY_MONTH_YEAR_HOUR_MINUTE: 'dd.MM.yyyy HH:mm',
  YEAR_MONTH_DAY_HOUR_MINUTE: 'yyyy-MM-dd HH:mm',
  HOUR_MINUTE: 'HH:mm',
}

export function calcDateFormat(date: string): string {
  return date
    ? format(parseISO(date), DATE_FORMATS.DAY_MONTH_YEAR_HOUR_MINUTE)
    : ''
}

export function secondsToDays(seconds: number): number {
  return differenceInDays(addSeconds(0, seconds), 0)
}

export const calcAggregationAccordingToTimeFilters = ({
  since,
  until,
}: TimeFilters): SCALE_TYPE => {
  const daysInMonth = 31
  const daysIn3Months = 93
  const differenceBetweenDays =
    differenceInDays(new Date(until), new Date(since)) + 1
  if (
    differenceBetweenDays >= daysInMonth &&
    differenceBetweenDays <= daysIn3Months
  ) {
    return AGGREGATION_TYPE.WEEK
  } else if (differenceBetweenDays > daysIn3Months) {
    return AGGREGATION_TYPE.MONTH
  } else {
    return AGGREGATION_TYPE.DATE
  }
}

// we assume that 1 day is 8 hours
// and 1 month is 4 weeks
export const convertSecondsToReadableWorkTime = (seconds: number) => {
  const units = [
    { label: 'y', value: 365 * 8 * 60 * 60 }, // 1 year = 365 days, 1 day = 8 hours
    { label: 'm', value: 4 * 7 * 8 * 60 * 60 }, // 1 month = 4 weeks
    { label: 'w', value: 7 * 8 * 60 * 60 }, // 1 week = 7 days
    { label: 'd', value: 8 * 60 * 60 }, // 1 day = 8 hours
    { label: 'h', value: 60 * 60 }, // 1 hour = 60 minutes
    { label: 'm', value: 60 }, // 1 minute = 60 seconds
  ]

  const result = []

  for (const { label, value } of units) {
    const unitCount = Math.floor(seconds / value)
    if (unitCount > 0) {
      result.push(`${unitCount}${label}`)
      seconds -= unitCount * value
    }
  }

  return result.join(' ') || '0m'
}

export const convertSecondsToReadableFormat = (seconds: number) => {
  return Object.entries(
    intervalToDuration({
      start: 0,
      end: seconds * 1000,
    })
  )
    .map((item: [string, number]) => {
      return item[1] && item[0] !== 'seconds' ? `${item[1]}${item[0][0]}` : ''
    })
    .join(' ')
}

export const getFirstDayOfWeek = (date: Date) => {
  return format(startOfWeek(date, { weekStartsOn: 1 }), FILTERS_DATE_FORMAT)
}

export const getFirstDayOfMonth = (date: Date) => {
  return format(startOfMonth(date), FILTERS_DATE_FORMAT)
}

export const getFirstDayOfWeekOrMonth = (
  date: Date,
  scaleType: string | undefined
) => {
  if (scaleType === AGGREGATION_TYPE.WEEK) {
    return getFirstDayOfWeek(date)
  } else if (scaleType === AGGREGATION_TYPE.MONTH) {
    return getFirstDayOfMonth(date)
  } else {
    return format(date, FILTERS_DATE_FORMAT)
  }
}

export const getFirstDaysArray = (
  since: string,
  until: string,
  scaleType: string | undefined
) => {
  // Convert the input strings to Date objects
  const sinceDate = getFirstDayOfWeekOrMonth(new Date(since), scaleType)
  const untilDate = getFirstDayOfWeekOrMonth(new Date(until), scaleType)

  // Generate an array containing all the first days between sinceDate and untilDate
  const datesArray = []
  let currentDate = new Date(sinceDate)
  while (currentDate <= new Date(untilDate)) {
    datesArray.push(format(currentDate, FILTERS_DATE_FORMAT))
    if (scaleType === AGGREGATION_TYPE.WEEK) {
      currentDate = addWeeks(currentDate, 1)
    } else if (scaleType === AGGREGATION_TYPE.MONTH) {
      currentDate = addMonths(currentDate, 1)
    } else {
      currentDate = addDays(currentDate, 1)
    }
  }

  return datesArray
}

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.34507 9.90186L5.03521 8.39049L10.0352 4.05715L11.3451 5.56853L6.34507 9.90186ZM6.34507 9.90186L5.03521 8.39049C4.37772 8.96031 4 9.7875 4 10.6576V17.0009C4 18.6578 5.34315 20.0009 7 20.0009H17C18.6569 20.0009 20 18.6578 20 17.0009V10.6576C20 9.7875 19.6223 8.96031 18.9648 8.39049L13.9648 4.05715C12.8372 3.07991 11.1628 3.07991 10.0352 4.05715L11.3451 5.56853C11.7209 5.24278 12.2791 5.24278 12.6549 5.56853L17.6549 9.90186C17.8741 10.0918 18 10.3675 18 10.6576V17.0009C18 17.5532 17.5523 18.0009 17 18.0009H7C6.44772 18.0009 6 17.5532 6 17.0009V10.6576C6 10.3675 6.12591 10.0918 6.34507 9.90186Z"
      fill="currentColor"
    />
  </svg>
</template>

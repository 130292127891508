<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9 5.5C7.34315 5.5 6 6.84315 6 8.5C6 10.1569 7.34315 11.5 9 11.5C10.6569 11.5 12 10.1569 12 8.5C12 6.84315 10.6569 5.5 9 5.5ZM4 8.5C4 5.73858 6.23858 3.5 9 3.5C11.7614 3.5 14 5.73858 14 8.5C14 11.2614 11.7614 13.5 9 13.5C6.23858 13.5 4 11.2614 4 8.5ZM2.46447 15.9645C3.40215 15.0268 4.67392 14.5 6 14.5H12C13.3261 14.5 14.5979 15.0268 15.5355 15.9645C16.4732 16.9021 17 18.1739 17 19.5C17 20.0523 16.5523 20.5 16 20.5C15.4477 20.5 15 20.0523 15 19.5C15 18.7044 14.6839 17.9413 14.1213 17.3787C13.5587 16.8161 12.7956 16.5 12 16.5H6C5.20435 16.5 4.44129 16.8161 3.87868 17.3787C3.31607 17.9413 3 18.7044 3 19.5C3 20.0523 2.55228 20.5 2 20.5C1.44772 20.5 1 20.0523 1 19.5C1 18.1739 1.52678 16.9021 2.46447 15.9645ZM16.2483 3.66125C15.7133 3.52426 15.1685 3.84694 15.0315 4.38196C14.8945 4.91699 15.2172 5.46176 15.7522 5.59875C16.3975 5.76398 16.9695 6.13928 17.3779 6.66548C17.7864 7.19169 18.0081 7.83887 18.0081 8.505C18.0081 9.17113 17.7864 9.81831 17.3779 10.3445C16.9695 10.8707 16.3975 11.246 15.7522 11.4113C15.2172 11.5482 14.8945 12.093 15.0315 12.628C15.1685 13.1631 15.7133 13.4857 16.2483 13.3488C17.3238 13.0734 18.2771 12.4479 18.9578 11.5709C19.6386 10.6939 20.0081 9.61521 20.0081 8.505C20.0081 7.39479 19.6386 6.31615 18.9578 5.43914C18.2771 4.56213 17.3238 3.93663 16.2483 3.66125ZM19.2502 14.6618C18.7155 14.5237 18.1701 14.8453 18.032 15.38C17.8939 15.9147 18.2155 16.4602 18.7502 16.5982C19.3938 16.7644 19.964 17.1396 20.3713 17.665C20.7784 18.1901 20.9996 18.8356 21.0002 19.5C21.0002 20.0523 21.448 20.5 22.0002 20.5C22.5525 20.5 23.0002 20.0515 23.0002 19.4993C22.9994 18.3914 22.6307 17.3152 21.9519 16.4396C21.2732 15.5641 20.3229 14.9387 19.2502 14.6618Z"
      fill="currentColor"
    />
  </svg>
</template>

<template>
  <div class="menu-item">
    <div class="flex items-center w-full">
      <Avatar :label="name[0]" class="mr-3"></Avatar>
      <div class="text truncate">
        <div class="font-semi-bold truncate">{{ name }}</div>
        <div class="capitalize">{{ userRole }}</div>
      </div>
    </div>
    <span class="pi pi-arrow-right icon"></span>
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps } from 'vue'
import Avatar from '@/components/common/Avatar.vue'

const props = defineProps<{ name: string; role: string }>()
const userRole = computed(() => props.role.toLowerCase())
</script>

<style scoped lang="scss">
.menu-item {
  @apply px-3 py-2 -mx-3 flex items-center justify-between cursor-pointer rounded-md;
  max-width: 500px;

  .text {
    @apply text-base text-gray-800 text-left;
  }
  .icon {
    @apply hidden;
  }
}
.menu-item:hover {
  @apply bg-black-5;
  .text {
    @apply text-black;
  }
  .icon {
    @apply inline-flex;
  }
}
</style>

import { ActionContext } from 'vuex'
import { State as GlobalState } from '../index'

export interface GlobalLoadingState {
  globalLoading: boolean
}

type Context = ActionContext<GlobalLoadingState, GlobalState>

const state = (): GlobalLoadingState => ({
  globalLoading: false,
})

const mutations = {
  setGlobalLoading(state: GlobalLoadingState, value: boolean): void {
    state.globalLoading = value
  },
}

const actions = {
  startGlobalLoading({ commit }: Context): void {
    commit('setGlobalLoading', true)
  },
  finishGlobalLoading({ commit }: Context): void {
    commit('setGlobalLoading', false)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}

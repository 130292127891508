import { apply, values } from 'ramda'
import { ActiveElement, ChartEvent } from 'chart.js'
import { LineAnnotationOptions } from 'chartjs-plugin-annotation'
import { AGGREGATION_TYPE } from '@/constants/constants'
import { format, getISOWeek, getYear } from 'date-fns'

export const usedColors = {
  'success-400': '#4CD990',
  'key-300': '#97A7F2',
  'warning-300': '#F9DD76',
  'danger-400': '#F18984',
  'info-300': '#61C5E3',
}

export const milestonesColors = {
  tick: 'rgba(0, 0, 0, 0.40)',
  'highlight-tick-color': '#FFFFFF',
  'highlight-tick-bg': '#000000',
  'tooltip-body': '#CFCFCF',
  'point-background': '#000000',
  'point-border': '#F1F1F1',
  'cell-0': '#FFFFFF',
  'cell-1': '#F2F4FE',
  'cell-2': '#DDE3FC',
  'cell-3': '#C2CBF4',
  'cell-4': '#97A7F2',
  'danger-border': '#ED6F6F',
  'danger-background': '#FEF5F1',
  'warning-border': '#EEB61E',
  'warning-background': '#FEFBE8',
}

export const randomizedColorsArray = (): string[] => {
  const colors = values(usedColors)
  const colorRandomizerNumber = 16777215
  for (let i = 0; i < 95; i++) {
    let randomColor = Math.floor(
      Math.random() * colorRandomizerNumber
    ).toString(16)
    while (randomColor.length < 6) {
      randomColor = Math.floor(Math.random() * colorRandomizerNumber).toString(
        16
      )
    }
    colors.push(`#${randomColor}`)
  }
  return colors
}

export const roundNumberToThreeSymbols = (value: number): number => {
  return Math.round(value * 1000) / 1000
}

export function addFillerData(data: any, key: string, relative = true): any {
  const dataToParse = data.per_user ? data.per_user : data.data
  const avgData = data.avg_data ? data.avg_data : data
  const maxValueFromData = apply(
    Math.max,
    dataToParse.map((item: any) => item[key])
  )
  const maxValueForReopenedTickets =
    maxValueFromData < avgData.all_avg_count
      ? avgData.all_avg_count
      : maxValueFromData

  const maxValue = relative ? 100 : maxValueForReopenedTickets
  return {
    ...data,
    [data.per_user ? 'per_user' : 'data']: dataToParse.map((data: any) => ({
      ...data,
      filler: maxValue - data[key],
    })),
  }
}

export function addOverFillerData(data: any, key: string): any {
  return {
    ...data,
    [data.per_user ? 'per_user' : 'data']: data[
      data.per_user ? 'per_user' : 'data'
    ].map((item: any) => {
      return 100 < item[key]
        ? {
            ...item,
            [key]: 100,
            over_filler: item[key] - 100,
          }
        : {
            ...item,
            filler: 100 - item[key],
          }
    }),
  }
}

export const showCursorOnLegendHover = (evt: ChartEvent): void => {
  const target = evt.native?.target as HTMLElement
  target.style.cursor = 'pointer'
}

export const hideCursorOnLegendLeave = (evt: ChartEvent): void => {
  const target = evt.native?.target as HTMLElement
  target.style.cursor = ''
}

export const toggleCursorOnChartDataHover = (
  evt: ChartEvent,
  element: ActiveElement
): void => {
  const target = evt.native?.target as HTMLElement
  target.style.cursor = element ? 'pointer' : 'default'
}

export const generateTickForXAxisTimeCharts = (
  value: string,
  scale_type?: string
): string => {
  if (!scale_type || !value) return ''
  if (scale_type === AGGREGATION_TYPE.WEEK) {
    return `${format(new Date(value), 'MMM')} CW${getISOWeek(new Date(value))}`
  } else {
    return format(
      new Date(value),
      scale_type === AGGREGATION_TYPE.DATE ? 'dd, MMM' : 'MMM'
    )
  }
}

export const generateTooltipTitleForXAxisTimeCharts = (
  value: string,
  scale_type?: string
): string => {
  if (scale_type === AGGREGATION_TYPE.WEEK) {
    return `week ${getISOWeek(new Date(value))} of ${getYear(new Date(value))}`
  } else {
    return format(
      new Date(value),
      scale_type === AGGREGATION_TYPE.DATE ? 'dd MMM yyyy' : 'MMMM, yyyy'
    )
  }
}

export const verticalAnnotation = (fields: {
  color: string
  label: string
  value?: any
  display?: boolean
  borderDash?: number[]
  showPercentage?: boolean
  hideTooltip?: boolean
}): LineAnnotationOptions => {
  const {
    color,
    label,
    value,
    display,
    borderDash,
    showPercentage,
    hideTooltip,
  } = fields
  return {
    display: display,
    scaleID: 'x',
    value: value || 0,
    borderColor: color,
    borderDash: borderDash || [],
    borderWidth: 2,
    label: {
      enabled: (ctx: any) => {
        return !hideTooltip && ctx.hovered
      },
      content: () =>
        !hideTooltip
          ? `${label}: ${Math.round(value)}${showPercentage ? '%' : ''}`
          : '',
    },
    enter({ element }: any) {
      element.options.label.enabled = true
      return true
    },
    leave({ element }: any) {
      element.options.label.enabled = false
      return true
    },
  }
}

export const milestonePlanningBackground = (seconds: number): string => {
  const hours = seconds / 3600
  // 1FTE = 40h
  const overlayClass = 'hover:shadow-overlay'
  if (hours < 1) {
    return `bg-white ${overlayClass}`
  } else if (hours < 20) {
    return `bg-key-50 ${overlayClass}`
  } else if (hours < 40) {
    return `bg-key-100 ${overlayClass}`
  } else if (hours < 80) {
    return `bg-key-200 ${overlayClass}`
  } else {
    return `bg-key-300 ${overlayClass}`
  }
}

export const milestoneCellBorder = (
  status: 'green' | 'red' | 'yellow'
): string => {
  switch (status) {
    case 'red': {
      return 'border-b-2 border-danger-500'
    }
    case 'yellow': {
      return 'border-b-2 border-warning-500'
    }
    default:
      return ''
  }
}

export const milestoneStatusCellBackground = (
  status: 'green' | 'red' | 'yellow'
): string => {
  switch (status) {
    case 'red': {
      return 'bg-danger-50'
    }
    case 'yellow': {
      return 'bg-warning-50'
    }
    default:
      return ''
  }
}

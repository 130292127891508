import { User } from '@firebase/auth'

export interface State {
  currentUser: User | null
}

const state = (): State => ({
  currentUser: null,
})

const mutations = {
  setUser(state: State, user: User): void {
    state.currentUser = user
  },
}

export default {
  namespaced: true,
  state,
  mutations,
}

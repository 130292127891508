<template>
  <div class="empty-state-wrapper">
    <div class="font-semi-bold mb-2 text-lg">The widget is not working</div>
    <div
      class="text-base whitespace-pre-line text-center"
      :class="{ 'text-red-500': fetchError }"
    >
      {{ message }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps } from 'vue'
import { chartTypes } from '@/constants/charts/constants'
const props = defineProps<{ fetchError: string; chartType?: string }>()

const message = computed(() => {
  if (props.fetchError) {
    return 'Failed to fetch data'
  } else if (props.chartType === chartTypes.BudgetPlannedVsUsed) {
    return "You need to fill out budget and roles rates tables to see the data. \n You can find it in the tempo's projects table"
  } else {
    return 'Data is empty'
  }
})
</script>

<style scoped>
.empty-state-wrapper {
  @apply flex flex-1 flex-col flex-grow justify-center items-center bg-gray-50;
}
</style>

<template>
  <FormRow title="Name" :invalid-state="v$.name.$invalid">
    <BaseInputText
      class="w-full user-name-input"
      :errors="v$.name.$errors"
      v-model:value="nameValue"
    ></BaseInputText>
  </FormRow>
  <FormRow title="Email" :invalid-state="v$.email.$invalid">
    <BaseInputText
      class="w-full user-email-input"
      type="email"
      :errors="v$.email.$errors"
      v-model:value="emailValue"
      :disabled="isUserHimself"
      v-tooltip.bottom="{
        value: `${isUserHimself ? 'You can not change your email' : ''}`,
        class: 'max-width-tooltip',
      }"
    ></BaseInputText>
  </FormRow>
  <FormRow title="Position" :invalid-state="v$.position.$invalid">
    <BaseDropdown
      class="user-position-dropdown"
      v-model:selected="positionValue"
      :options="positionOptions"
      :errors="v$.position.$errors"
    ></BaseDropdown>
  </FormRow>
  <FormRow
    title="Role"
    :invalid-state="v$.role.$invalid"
    tooltip="The roles define what areas can users access. Owners and admins can see all users, projects and administrative settings. Team leads can see all projects they are allocated to and all the members in them. Members can only see themselves and project metrics and charts they are part of."
  >
    <BaseDropdown
      v-model:selected="roleValue"
      :options="roleOptions"
      :errors="v$.role.$errors"
      class="user-role-dropdown"
      :disabled="isUserHimself"
      v-tooltip.bottom="{
        value: `${isUserHimself ? 'You can not change your role' : ''}`,
        class: 'max-width-tooltip',
      }"
    ></BaseDropdown>
  </FormRow>
  <FormRow
    title="Projects"
    tooltip="The project allocation defines in which project should a user show up. Also, for team leads and members it defines what projects they should see."
  >
    <BaseMultiSelect
      v-model:selected="projectsValue"
      :options="projectsOptions"
      search
      class="user-projects-dropdown"
    ></BaseMultiSelect>
  </FormRow>
  <FormRow title="Status">
    <StatusToggleButton v-model:status="isActiveValue"></StatusToggleButton>
  </FormRow>
</template>

<script setup lang="ts">
import FormRow from '@/components/common/form/FormRow.vue'
import StatusToggleButton from '@/components/common/buttons/StatusToggleButton.vue'
import { defineEmits, defineProps, computed } from 'vue'
import { keys } from 'ramda'
import {
  USER_POSITIONS,
  USER_POSITIONS_TITLES,
  USER_ROLES,
  USER_ROLES_TITLES,
} from '@/constants/constants'
import { useStore } from '@/store'
import BaseDropdown from '@/components/common/base/BaseDropdown.vue'
import BaseMultiSelect from '@/components/common/base/BaseMultiSelect.vue'
import BaseInputText from '@/components/common/base/BaseInputText.vue'
import { VuelidateValidation } from '@/types/types'

interface UserFormValidation extends VuelidateValidation {
  name: VuelidateValidation
  email: VuelidateValidation
  position: VuelidateValidation
  role: VuelidateValidation
  projects: VuelidateValidation
}

const props = defineProps<{
  name: string
  email: string
  position: string
  role: string
  projects: number[]
  is_active: boolean
  v$: UserFormValidation
}>()
const emit = defineEmits<{
  (e: 'update:name', value: string): void
  (e: 'update:email', value: string): void
  (e: 'update:position', value: string): void
  (e: 'update:role', value: string): void
  (e: 'update:projects', value: number[]): void
  (e: 'update:is_active', value: boolean): void
}>()

const store = useStore()

const isUserHimself = computed(
  () => props.email === store.state.user?.currentUser?.email
)

const nameValue = computed({
  get() {
    return props.name
  },
  set(value: string) {
    emit('update:name', value)
  },
})

const emailValue = computed({
  get() {
    return props.email
  },
  set(value: string) {
    emit('update:email', value)
  },
})

const positionValue = computed({
  get() {
    return props.position
  },
  set(value: string) {
    emit('update:position', value)
  },
})
const roleValue = computed({
  get() {
    return props.role
  },
  set(value: string) {
    emit('update:role', value)
  },
})

const projectsValue = computed({
  get() {
    return props.projects
  },
  set(value: number[]) {
    emit('update:projects', value)
  },
})

const isActiveValue = computed({
  get() {
    return props.is_active
  },
  set(value: boolean) {
    emit('update:is_active', value)
  },
})

const positionOptions = computed(() => {
  return keys(USER_POSITIONS).map((position: string) => ({
    id: position,
    name: USER_POSITIONS_TITLES[position],
  }))
})

const roleOptions = computed(() => {
  const options = keys(USER_ROLES).map((role: string) => ({
    id: role,
    name: USER_ROLES_TITLES[role],
  }))
  if (
    props.role !== USER_ROLES.OWNER &&
    store.getters['company/userRole'] !== USER_ROLES.OWNER
  ) {
    options.pop()
  }
  return options
})

const projectsOptions = computed(() => {
  return store.state.admin.original_projects
})
</script>

import axios from '@/services/axios'
import { omit, path } from 'ramda'
import { ActionContext } from 'vuex'
import { State as GlobalState } from '../../index'
import { CommitsFilter } from './gitlab'
import { LIMIT_OF_COMMITS } from '@/constants/constants'

export interface GitConfigUser {
  id: number
  name?: string
  email?: string
  original_name?: number
  company?: number
}

interface GitConfigUsersPage {
  count: number
  next: string
  previous: string
  results: GitConfigUser[]
}

export interface State {
  usersSearch: string | null
}

type Context = ActionContext<State, GlobalState>

const state = (): State => ({
  usersSearch: null,
})

const mutations = {
  setUsersSearchText(state: State, search: string | null): void {
    state.usersSearch = search
  },
}

const actions = {
  getFirstPageOfUsers(
    { rootGetters }: Context,
    params: { ordering: null | string; search: null | string }
  ): Promise<void> {
    const { ordering, search } = params || { ordering: null }
    return axios
      .get(
        `/api/companies/${
          rootGetters['company/selectedCompanyId']
        }/git/users/?limit=50&ordering=${ordering}&unmapped=false${
          search ? `&search=${search}` : ''
        }`
      )
      .then(path(['data']))
  },
  getNextPageOfUsers(_: Context, url: string): Promise<void> {
    return axios.get(`${url}`).then(path(['data']))
  },
  getFirstPageOfCommits(
    { rootGetters }: Context,
    params: { params: CommitsFilter; signal: AbortSignal }
  ): Promise<void> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/git/commits/?limit=${LIMIT_OF_COMMITS}`,
        params
      )
      .then(path(['data']))
  },
  getNextPageOfCommits(_: Context, url: string): Promise<void> {
    return axios.get(`${url}`).then(path(['data']))
  },
  editUser(
    { rootGetters }: Context,
    user: { user: number; id: number }
  ): Promise<void> {
    return axios
      .patch(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/git/users/${user.id}/`,
        omit(['id'], user)
      )
      .then(path(['data']))
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}

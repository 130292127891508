import axios from '@/services/axios'
import { omit, path } from 'ramda'
import { ActionContext } from 'vuex'
import { State as GlobalState } from '../index'

export interface Company {
  name: string
  is_active?: boolean
  id?: number
  role_in_company?: string
}

export interface State {
  companies: Company[]
  selectedCompany: null | Company
  selectedCompanyId: number | null
}

type Context = ActionContext<State, GlobalState>

const savedCompany = window.localStorage.getItem('selectedCompany')

const state = (): State => ({
  companies: [],
  selectedCompany: savedCompany ? JSON.parse(savedCompany) : null,
  selectedCompanyId: null,
})

const getters = {
  selectedCompanyId(state: State): number | null {
    return state.selectedCompanyId || null
  },
  userRole: (state: State): string | undefined => {
    return state.selectedCompany?.role_in_company
  },
}

const mutations = {
  setCompanies(state: State, companies: Company[]): void {
    state.companies = companies
  },
  setSelectedCompany(state: State): void {
    const company = window.localStorage.getItem('selectedCompany')
    state.selectedCompany = company ? JSON.parse(company) : null
  },
  setSelectedCompanyId(state: State, id: number | null): void {
    state.selectedCompanyId = id
  },
}

const actions = {
  getCompanies({ commit }: Context): Promise<void> {
    return axios
      .get('/api/companies/')
      .then(({ data }) => commit('setCompanies', data))
  },
  getCompany(_: Context, company_id: number): Promise<Company> {
    return axios.get(`/api/companies/${company_id}`).then(({ data }) => data)
  },
  addCompany(_: Context, company: Company): Promise<void> {
    return axios.post('/api/companies/', company).then(path(['data']))
  },
  editCompany(_: Context, company: Company): Promise<void> {
    return axios
      .put(`/api/companies/${company.id}/`, omit(['id'], company))
      .then(path(['data']))
  },
  deleteCompany(_: Context, id: number): Promise<void> {
    return axios.delete(`/api/companies/${id}/`).then(path(['data']))
  },
  saveSelectedCompanyInLocalStorage(
    { commit }: Context,
    company: Company
  ): void {
    window.localStorage.setItem('selectedCompany', JSON.stringify(company))
    commit('setSelectedCompany')
    commit('setSelectedCompanyId', company.id)
  },
  removeSelectedCompanyFromLocalStorage({ commit }: Context): void {
    window.localStorage.removeItem('selectedCompany')
    commit('setSelectedCompany')
    commit('setSelectedCompanyId', null)
  },
  async takeIdFromRouteAndSaveCompanyInLocalStorage(
    { commit, dispatch, state }: Context,
    route_id: number
  ): Promise<void> {
    await dispatch('getCompanies')
    const selectedCompany = state.companies.find(
      ({ id }: Company) => route_id === id
    )
    if (selectedCompany) {
      await dispatch('saveSelectedCompanyInLocalStorage', selectedCompany)
    } else {
      commit('setSelectedCompanyId', route_id)
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

import axios from '@/services/axios'
import { addFillerData } from '@/utils/chart-utils'
import { Filters } from '@/store/modules/filters'
import { ActionContext } from 'vuex'
import { State as GlobalState } from '../../index'
import { Aggregate_By_Type } from '@/types/types'

export interface EstimationCoveragePerUser {
  coverage_percent: number
  estimated_issues_count: number
  issues_count: number
  user_name?: string
  user_id?: number
  project_name?: string
  original_project_id?: number
  filler?: number
}

export interface EstimationCoverageResponse {
  aggregate_by: Aggregate_By_Type
  avg_data: {
    all_avg_count: number
    avg_count: number
  }
  data: EstimationCoveragePerUser[]
}

export interface State {
  data: EstimationCoverageResponse
}

type Context = ActionContext<State, GlobalState>

const actions = {
  getData(
    { rootGetters }: Context,
    params: Filters
  ): Promise<EstimationCoverageResponse> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/charts/estimation-coverage/`,
        { params }
      )
      .then(({ data }) => addFillerData(data, 'coverage_percent'))
  },
}

export default {
  namespaced: true,
  actions,
}

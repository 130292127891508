<template>
  <div>
    <Calendar
      :inputStyle="inputStyle"
      v-model="calendarValue"
      :show-button-bar="showButtonBar"
      class="w-full"
      :class="{ 'p-invalid': errors.length }"
      :dateFormat="dateFormat"
      :selection-mode="mode"
      :inline="inline"
      :number-of-months="numberOfMonths"
      show-week
    >
      <template #date="slotProps">
        <div
          :class="
            mode === 'range' && isArray(value)
              ? calcDateCalendarClassForRange(slotProps.date, value)
              : calcSelectedDateCalendarClass(slotProps.date, value)
          "
        >
          {{ slotProps.date.day }}
        </div></template
      ></Calendar
    >
    <div
      v-for="error of errors"
      class="text-left text-sm text-danger-600 mt-1"
      :key="error.$uid"
    >
      {{ error.$message }}
    </div>
  </div>
</template>

<script setup lang="ts">
import Calendar from 'primevue/calendar/Calendar.vue'
import { computed, defineEmits, defineProps, withDefaults } from 'vue'
import {
  calcDateCalendarClassForRange,
  calcSelectedDateCalendarClass,
} from '@/utils/utils'
import { FILTERS_DATE_FORMAT } from '@/constants/constants'
import { format } from 'date-fns'
import { isArray } from 'ramda-adjunct'

const props = withDefaults(
  defineProps<{
    value: null | string | string[]
    errors?: any
    placeholder?: string
    inputStyle?: string
    iconRight?: string
    disabled?: boolean
    showButtonBar?: boolean
    dateFormat?: string
    mode?: string
    inline?: boolean
    numberOfMonths?: number
  }>(),
  {
    inputStyle: '',
    showButtonBar: false,
    disabled: false,
    errors: [],
    dateFormat: 'yy-mm-dd',
    mode: 'single',
    inline: false,
    numberOfMonths: 1,
  }
)

const emit = defineEmits<{
  (e: 'update:value', value: null | string | string[]): void
}>()

const calendarValue = computed({
  get() {
    return formatToDate()
  },
  set(value: null | Date | Date[]) {
    emit('update:value', formatToString(value))
  },
})

const formatToDate = () => {
  if (isArray(props.value)) {
    const [start, end] = props.value
    return [start ? new Date(start) : start, end ? new Date(end) : end]
  } else {
    return props.value ? new Date(props.value) : props.value
  }
}

const formatToString = (value: Date | Date[] | null) => {
  if (isArray(value)) {
    const [start, end] = value
    return [
      start ? format(start, FILTERS_DATE_FORMAT) : start,
      end ? format(end, FILTERS_DATE_FORMAT) : end,
    ]
  } else {
    return value ? format(value, FILTERS_DATE_FORMAT) : value
  }
}
</script>

<style scoped>
.special-day {
  @apply bg-key-500 font-semi-bold text-white w-full h-full flex justify-center items-center hover:bg-key-600;
}

.today-special-day {
  @apply text-black;
}
</style>

<template>
  <Dialog
    class="p-confirm-dialog"
    :visible="!!open"
    autoZIndex
    position="bottom"
    :closable="false"
    modal
  >
    <div class="mt-3 flex items-start">
      <img
        :src="require('../../../assets/icons/question.svg')"
        alt="Question icon"
        width="48"
        height="48"
        class="mr-8 mt-1"
      />
      <div>
        <div class="text-lg font-semi-bold">
          Are you sure you want to continue without saving?
        </div>
        <div class="mt-4">The data completed in the form will be deleted.</div>
      </div>
    </div>

    <template #footer>
      <Button class="no p-button-outlined" @click="closeDialog">
        Cancel
      </Button>
      <Button class="yes" @click="onYesButtonClick">Continue</Button>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import Button from '@/components/common/buttons/Button.vue'
import Dialog from 'primevue/dialog/Dialog.vue'
import { defineEmits, defineProps } from 'vue'

defineProps<{
  open: boolean | number
}>()

const emit = defineEmits<{
  (e: 'update:open', value: boolean): void
  (e: 'continue'): void
}>()

const onYesButtonClick = () => {
  emit('continue')
}

const closeDialog = () => {
  emit('update:open', false)
}
</script>

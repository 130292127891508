<template>
  <IconButton
    icon="pi-chevron-down"
    icon-position="right"
    icon-size="12px"
    class="p-button-text"
    @click="toggleOverlayPanel"
  >
    {{ buttonTitle }}
  </IconButton>
  <OverlayPanel ref="op" id="overlay-panel">
    <Listbox
      class="p-1"
      :list-style="{ overflow: 'auto', height: calculatedHeight }"
      v-model="selectedFiltering"
      :options="searchMatchedItems"
      @change="onChangeFiltering($event.value)"
    >
      <template #header>
        <BaseInputText
          v-if="search"
          v-model:value="searchItem"
          placeholder="Search"
          class="px-3 py-2"
          icon-left="pi-search"
        ></BaseInputText>
      </template>
      <template #option="slotProps">
        <div>{{ slotProps.option.name }}</div>
      </template>
    </Listbox>
  </OverlayPanel>
</template>

<script setup lang="ts">
import OverlayPanel from 'primevue/overlaypanel/OverlayPanel.vue'
import Listbox from 'primevue/listbox/Listbox.vue'
import { defineEmits, defineProps, ref, computed, watchEffect } from 'vue'
import { LIST_ITEM_HEIGHT, PERCENT_OF_WINDOW_70 } from '@/constants/constants'
import BaseInputText from '@/components/common/base/BaseInputText.vue'
import IconButton from '@/components/common/buttons/IconButton.vue'
import { Project } from '@/store/modules/admin/admin'

const props = defineProps<{
  filteringOptions: any
  filters?: string | null | string[]
  name?: string
  search?: boolean
}>()

const emit = defineEmits<{
  (e: 'change-filtering', value: number[]): void
}>()

const selectedFiltering: any = ref(null)
const searchItem = ref('')
const op = ref()

const buttonTitle = computed(() => {
  return (
    props.filteringOptions.find(
      (project: any) => project.id === selectedFiltering.value?.id
    )?.name || props.name
  )
})

watchEffect(() => {
  selectedFiltering.value = props.filteringOptions?.find((option: any) => {
    return props.filters?.includes(String(option.id))
  })
})

const searchMatchedItems = computed(() => {
  if (searchItem.value) {
    return props.filteringOptions.filter((item: any) => {
      return item.name.toLowerCase().includes(searchItem.value.toLowerCase())
    })
  }
  return props.filteringOptions
})

const calculatedHeight = computed(() => {
  return searchMatchedItems.value.length * LIST_ITEM_HEIGHT >
    PERCENT_OF_WINDOW_70
    ? `${PERCENT_OF_WINDOW_70}px`
    : 'auto'
})

const toggleOverlayPanel = (event: Event) => {
  op.value.toggle(event)
}

const onChangeFiltering = (value: Project) => {
  if (value) {
    emit('change-filtering', [value.id])
    searchItem.value = ''
    op.value.hide()
  } else {
    selectedFiltering.value = props.filteringOptions.find((option: any) => {
      return props.filters?.includes(String(option.id))
    })
  }
}
</script>

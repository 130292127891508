<template>
  <Dialog
    :visible="!!id"
    :dismissableMask="true"
    @update:visible="closeDialog"
    position="bottom"
    modal
  >
    <template #header>
      <div class="header-wrapper">
        <div>User mapping</div>
        <div class="flex" v-tooltip.bottom="deletionTooltipText">
          <IconButton
            class="p-button-text delete-user"
            :disabled="isUserHimself"
            @click="showDeleteConfirmationDialog = true"
          >
            <i class="pi pi-trash" aria-hidden="true"></i>
          </IconButton>
        </div>
      </div>
    </template>
    <div class="title">{{ formData.name || 'User Name' }}</div>
    <UserMappingForm
      v-model:name="formData.name"
      v-model:email="formData.email"
      v-model:is_active="formData.is_active"
      v-model:position="formData.position"
      v-model:role="formData.role"
      v-model:projects="formData.projects"
      :v$="v$"
    ></UserMappingForm>
    <template #footer>
      <Button class="p-button-outlined" size="large" @click="closeDialog">
        Cancel
      </Button>
      <Button size="large" class="edit-user" @click="onEditUser"
        >Save Changes</Button
      >
    </template>
    <DeleteConfirmationDialog
      v-model:open="showDeleteConfirmationDialog"
      :name="formData.name"
      :loading="loading"
      @delete="onDeleteUser"
    ></DeleteConfirmationDialog>
  </Dialog>
</template>

<script setup lang="ts">
import Dialog from 'primevue/dialog/Dialog.vue'
import { defineProps, ref, defineEmits, watchEffect, computed } from 'vue'
import Button from '@/components/common/buttons/Button.vue'
import { email, required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { useToast } from 'primevue/usetoast'
import { useStore } from '@/store'
import IconButton from '@/components/common/buttons/IconButton.vue'
import UserMappingForm from '@/components/admin-dashboard/system/user-mapping/UserMappingForm.vue'
import { showToastError } from '@/utils/utils'
import DeleteConfirmationDialog from '@/components/common/dialogs/DeleteConfirmationDialog.vue'
import { USER_ROLES } from '@/constants/constants'

const initialForm = () => ({
  name: '',
  email: '',
  position: '',
  role: '',
  projects: [],
  is_active: true,
})

const props = defineProps<{ id: boolean | number }>()
const emit = defineEmits<{ (e: 'update:id', value: boolean): void }>()
const toast = useToast()
const store = useStore()
const formData = ref(initialForm())
const showDeleteConfirmationDialog = ref(false)
const loading = ref(false)

const formRules = {
  name: { required, $lazy: true },
  email: { required, email, $lazy: true },
  position: { required, $lazy: true },
  role: { required, $lazy: true },
}

const v$ = useVuelidate(formRules, formData)

const isUserHimself = computed(
  () => formData.value.email === store.state.user?.currentUser?.email
)

const deletionTooltipText = computed(() => {
  if (isUserHimself.value) {
    return 'You can not delete yourself'
  } else {
    return ''
  }
})

watchEffect(async () => {
  if (props.id) {
    await store
      .dispatch('admin/getUser', props.id)
      .then((data) => {
        formData.value = data
      })
      .catch((e) => showToastError(toast, e))
  }
})

async function onEditUser() {
  v$.value.$touch()
  if (v$.value.$invalid) {
    return
  }
  try {
    await store.dispatch('admin/editUser', {
      ...formData.value,
      id: props.id,
    })
    closeDialog()
  } catch (e) {
    showToastError(toast, e)
  }
}

async function onDeleteUser() {
  loading.value = true
  try {
    await store.dispatch('admin/deleteUser', props.id)
    loading.value = false
    showDeleteConfirmationDialog.value = false
    closeDialog()
  } catch (e) {
    loading.value = false
    showToastError(toast, e)
  }
}

function closeDialog() {
  formData.value = initialForm()
  v$.value.$reset()
  emit('update:id', false)
}
</script>

<style scoped>
.header-wrapper {
  @apply flex items-center justify-between w-full;
}
.title {
  @apply text-xl font-semi-bold mb-3;
}
</style>

<template>
  <BaseInputText
    v-model:value="searchItem"
    placeholder="Search"
    icon-left="pi-search"
    class="mb-3"
  ></BaseInputText>
  <div v-if="!searchItem && showAllButton && options.length">
    <BaseCheckbox
      id="all"
      v-model:value="isAllSelected"
      :label="allTitle"
      binary
      @change="toggleSelection"
    ></BaseCheckbox>
    <Divider class="mb-1 mt-3"></Divider>
  </div>
  <div v-if="!searchMatchedItems.length">No available options</div>
  <ul
    v-else
    :style="{
      height: calculatedHeight,
      overflow: 'auto',
    }"
  >
    <li
      v-for="option in searchMatchedItems"
      :key="option.id"
      class="py-2 relative"
    >
      <BaseCheckbox
        :id="option.id"
        :value-option="option.id"
        v-model:value="selectedArray"
        @change="onListSelected"
        :label="option.name"
      ></BaseCheckbox>
    </li>
  </ul>
</template>

<script setup lang="ts">
import {
  ref,
  defineProps,
  computed,
  defineEmits,
  watchEffect,
  watch,
  onMounted,
} from 'vue'
import BaseInputText from '@/components/common/base/BaseInputText.vue'
import BaseCheckbox from '@/components/common/base/BaseCheckbox.vue'

type Selected = number[] | string[] | null

const maxShownRow = 5
const props = defineProps<{
  options: any
  selected: Selected
  allTitle?: string
  showAllButton?: boolean
}>()
const emit = defineEmits<{
  (e: 'update:selected', value: Selected): void
}>()
const searchItem = ref('')
const selectedArray = ref<Selected>([])
const isAllSelected = ref(selectedArray.value?.length === props.options.length)

watchEffect(() => {
  selectedArray.value = props.selected || []
})

watch([selectedArray], () => {
  isAllSelected.value = selectedArray.value?.length === props.options.length
})

onMounted(() => {
  isAllSelected.value = selectedArray.value?.length === props.options.length
})

const searchMatchedItems = computed(() => {
  if (searchItem.value) {
    return props.options.filter((item: any) => {
      return item.name.toLowerCase().includes(searchItem.value.toLowerCase())
    })
  }
  return props.options
})

const calculatedHeight = computed(() => {
  return props.options.length > maxShownRow ? '205px' : 'auto'
})

const onListSelected = () => {
  emit(
    'update:selected',
    selectedArray.value?.length ? selectedArray.value : null
  )
}

const toggleSelection = () => {
  if (isAllSelected.value) {
    selectedArray.value = props.options.map(({ id }: any) => id)
  } else {
    selectedArray.value = []
  }
  emit(
    'update:selected',
    selectedArray.value?.length ? selectedArray.value : null
  )
}
</script>

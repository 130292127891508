<template>
  <Dialog
    :visible="open"
    :style="{ width: '90vw' }"
    :dismissableMask="true"
    @update:visible="onCloseDialog"
    position="bottom"
    modal
  >
    <template #header>
      {{ `${issueData?.issue_key}: ${issueData?.issue_name}` }}
    </template>
    <div class="text-xl font-semi-bold mb-4">Tickets</div>
    <div class="height flex mb-8" v-if="loading">
      <LoaderWrapper></LoaderWrapper>
    </div>
    <MyTable
      v-else
      :table-data="ticketsData"
      :columns-data="columnsData"
      customHeight="70vh"
      :scrollable="true"
      class="mb-8"
      lazy-table
      :lazy-loading="lazyLoading"
      @load="onLazyLoad"
      @sort="onSort"
    >
      <template #issue_key="{ data }">
        <Link :href="data.absolute_url" style-class="truncate">
          {{ `${data.issue_key}: ${data.issue_name}` }}
        </Link>
      </template>
      <template #stuck_seconds="{ data }">
        {{
          formatDuration(
            intervalToDuration({
              start: 0,
              end: data.stuck_seconds * 1000,
            })
          )
        }}
      </template>
      <template #entered="{ data }">
        {{ formatIssueDate(data.entered) }}
      </template>
      <template #exited="{ data }">
        {{ formatIssueDate(data.exited) }}
      </template>
      <template #status_name="{ data }">
        <TicketStatusChip :status="data.status_name" />
      </template>
    </MyTable>
  </Dialog>
</template>

<script setup lang="ts">
import Dialog from 'primevue/dialog/Dialog.vue'
import { computed, defineEmits, defineProps, ref, watch } from 'vue'
import LoaderWrapper from '@/components/common/loader/LoaderWrapper.vue'
import MyTable from '@/components/common/table/MyTable.vue'
import { useStore } from '@/store'
import { calcSortOrderingAndField, showToastError } from '@/utils/utils'
import { useToast } from 'primevue/usetoast'
import { Dictionary, equals, reject } from 'ramda'
import Link from '@/components/common/Link.vue'
import { Filters } from '@/store/modules/filters'
import { DATE_FORMATS } from '@/utils/date-utils'
import { format, formatDuration, intervalToDuration } from 'date-fns'
import TicketStatusChip from '@/components/common/TicketStatusChip.vue'

const props = defineProps<{
  open: boolean
  issueData: {
    issue_id: number
    issue_key: string
    issue_name: string
    absolute_url: string
    status_id: number
    status_name: string
    total_stuck_seconds: number
  } | null
  filters: Filters
}>()

const emit = defineEmits<(e: 'close') => void>()

const ticketsData = ref([])
const loading = ref(false)
const lazyLoading = ref(false)
const store = useStore()
const toast = useToast()
const sortField = ref<string | null>(null)
const nextPage = ref<string | null>(null)

const issueDetails = computed(() => props.issueData)
const projectId = computed(() => store.state.filters.projectFilters.projects[0])
const companyId = computed(() => store.state.company.selectedCompanyId)
const drilldownFilters = computed(
  () => store.state.stuckTickets.drilldown_filters
)

watch([sortField, issueDetails, drilldownFilters], () => {
  issueDetails.value && getFirstPageOfStuckDrilldown()
})

const columnsData = [
  {
    header: 'Ticket',
    field: 'issue_key',
    is_sortable: true,
    use_template: true,
    styles: 'flex-grow:2; padding-right: 10px',
  },
  {
    header: 'Duration',
    field: 'stuck_seconds',
    is_sortable: true,
    use_template: true,
    styles: 'flex-grow:2; padding-right: 10px',
  },
  {
    header: 'Entered',
    field: 'entered',
    is_sortable: true,
    use_template: true,
    styles: 'flex-grow:1; padding-right: 10px',
  },
  {
    header: 'Exited',
    field: 'exited',
    is_sortable: true,
    use_template: true,
    styles: 'flex-grow:1; padding-right: 10px',
  },
  {
    header: 'Status',
    field: 'status_name',
    is_sortable: true,
    use_template: true,
    styles: 'flex-grow:1; padding-right: 10px',
  },
]

/* istanbul ignore next */
async function onLazyLoad(event: { first: number; last: number }) {
  const { last } = event
  if (!last || lazyLoading.value) return
  try {
    if (nextPage.value && last === ticketsData.value.length) {
      lazyLoading.value = true
      const page = await store.dispatch(
        'stuckTickets/getNextPageOfStuckDrilldown',
        nextPage.value
      )
      nextPage.value = page.next
      ticketsData.value = ticketsData.value.concat(page.results)
    }
  } catch (e) {
    showToastError(toast, e)
  } finally {
    lazyLoading.value = false
  }
}

async function getFirstPageOfStuckDrilldown() {
  const params = {
    ...drilldownFilters.value,
    ordering: sortField.value,
  }
  const activeParams = {
    ...reject(equals(null))(params as Dictionary<any>),
  }
  if (activeParams.ordering) {
    lazyLoading.value = true
  } else {
    loading.value = true
  }
  try {
    const page = await store.dispatch(
      'stuckTickets/getFirstPageOfStuckDrilldown',
      {
        filters: params,
        project_id: projectId.value,
        company_id: companyId.value,
      }
    )
    nextPage.value = page?.next
    ticketsData.value = page?.results
  } catch (e) {
    showToastError(toast, e)
  } finally {
    loading.value = false
    lazyLoading.value = false
  }
}

function onSort(event: Event) {
  sortField.value = calcSortOrderingAndField(event)
}

function formatIssueDate(date: string): string {
  if (!date) {
    return '-'
  }
  return format(new Date(date), DATE_FORMATS.DAY_MONTH_YEAR_HOUR_MINUTE)
}

const onCloseDialog = () => {
  emit('close')
  sortField.value = null
}
</script>

<style scoped>
.height {
  height: 75vh;
}
</style>

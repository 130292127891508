<template>
  <PrimeButton :class="buttonClass" type="button" v-bind="$attrs">
    <slot />
  </PrimeButton>
</template>

<script setup lang="ts">
import PrimeButton from 'primevue/button/Button.vue'
import { defineProps, computed } from 'vue'

const props = defineProps<{
  size?: string
}>()

const buttonClass = computed(() => {
  return {
    'p-button-sm': props.size === 'small',
    'p-button-lg': props.size === 'large',
  }
})
</script>

<template>
  <Dialog
    :visible="!!id"
    :dismissableMask="true"
    @update:visible="closeDialog"
    position="bottom"
    modal
  >
    <template #header>
      <div class="header-wrapper">
        <div>Tempo • Projects • Role Rate</div>
        <div class="flex gap-1">
          <IconButton class="delete p-button-text" @click="onDeleteRoleRate">
            <i class="pi pi-trash"></i>
          </IconButton>
        </div>
      </div>
    </template>
    <div class="title">Edit Role Rate</div>
    <RoleRateForm
      v-model:role="formData.role"
      v-model:rate="formData.rate"
      v-model:rate_date="formData.rate_date"
      :validation="v$"
    ></RoleRateForm>
    <template #footer>
      <Button class="p-button-outlined" size="large" @click="closeDialog">
        Cancel
      </Button>
      <Button size="large" class="save" @click="onEditRoleRate"
        >Save Changes</Button
      >
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import Dialog from 'primevue/dialog/Dialog.vue'
import { defineProps, ref, defineEmits, watchEffect } from 'vue'
import Button from '@/components/common/buttons/Button.vue'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { useToast } from 'primevue/usetoast'
import { useStore } from '@/store'
import IconButton from '@/components/common/buttons/IconButton.vue'
import { showToastError } from '@/utils/utils'
import RoleRateForm from '@/components/admin-dashboard/tempo/projects/roles-rates/RoleRateForm.vue'

const initialForm = () => ({
  role: null,
  rate: 0,
  rate_date: null,
})
const props = defineProps<{ id: number | boolean; project_id: number }>()
const emit = defineEmits<{
  (e: 'update:id', value: boolean): void
  (e: 'refresh-table'): void
}>()
const toast = useToast()
const store = useStore()
const formData = ref(initialForm())

const formRules = {
  role: { required, $lazy: true },
  rate: { required, $lazy: true },
  rate_date: { required, $lazy: true },
}

watchEffect(async () => {
  if (props.id) {
    try {
      formData.value = await store.dispatch('tempo/getRoleRate', {
        project_id: props.project_id,
        role_rate_id: props.id,
      })
    } catch (e) {
      showToastError(toast, e)
    }
  }
})

const v$ = useVuelidate(formRules, formData)

async function onEditRoleRate() {
  v$.value.$touch()
  if (v$.value.$invalid) {
    return
  }
  try {
    await store.dispatch('tempo/editRoleRate', {
      project_id: props.project_id,
      role_rate: formData.value,
    })
    closeDialog()
    emit('refresh-table')
  } catch (e) {
    showToastError(toast, e)
  }
}

async function onDeleteRoleRate() {
  try {
    await store.dispatch('tempo/deleteRoleRate', {
      project_id: props.project_id,
      role_rate_id: props.id,
    })
    closeDialog()
    emit('refresh-table')
  } catch (e) {
    showToastError(toast, e)
  }
}

function closeDialog() {
  formData.value = initialForm()
  v$.value.$reset()
  emit('update:id', false)
}
</script>

<style scoped>
.header-wrapper {
  @apply flex items-center justify-between w-full;
}
.title {
  @apply text-xl font-semi-bold mb-3;
}
</style>

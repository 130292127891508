import {
  onIdTokenChanged,
  getAuth,
  User,
  NextOrObserver,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  signOut,
} from 'firebase/auth'
import { initializeApp } from 'firebase/app'
import { store } from '@/store'
import axios from './axios-service'

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
}

initializeApp(firebaseConfig)
export const auth = getAuth()
const googleProvider = new GoogleAuthProvider()

const initializeAuth: Promise<User> = new Promise((resolve) => {
  onIdTokenChanged(auth, (async (user: User) => {
    store.commit('user/setUser', user)
    axios.defaults.headers.common['http-firebase-token'] =
      await user?.getIdToken()
    resolve(user)
  }) as NextOrObserver<User>)
})

export const authService = {
  authenticated(): Promise<boolean | null> {
    return initializeAuth.then((_user: User) => {
      return (
        store.state.user.currentUser &&
        !store.state.user.currentUser.isAnonymous
      )
    })
  },

  async loginWithEmail(email: string, password: string) {
    try {
      const data = await signInWithEmailAndPassword(auth, email, password)
      axios.defaults.headers.common['http-firebase-token'] =
        await data.user.getIdToken()
      store.commit('user/setUser', data.user)
      return data
    } catch (error) {
      console.log(error)
      return error
    }
  },

  async loginWithGoogle(): Promise<void> {
    try {
      const { user } = await signInWithPopup(auth, googleProvider)
      axios.defaults.headers.common['http-firebase-token'] =
        await user.getIdToken()
      store.commit('user/setUser', user)
    } catch (error) {
      console.log(error)
    }
  },

  async getLoginMethods(email: string): Promise<string[]> {
    return fetchSignInMethodsForEmail(auth, email)
  },

  async createUser(email: string, password: string): Promise<void> {
    try {
      const { user } = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      )
      axios.defaults.headers.common['http-firebase-token'] =
        await user.getIdToken()
      store.commit('user/setUser', user)
    } catch (e) {
      console.log(e)
    }
  },

  async logout(): Promise<void> {
    axios.defaults.headers.common['http-firebase-token'] = ''
    return signOut(auth).then(() => {
      console.log('logout done')
    })
  },
}

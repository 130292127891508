<template>
  <div v-if="page?.count || unmappedUsersSearch">
    <UnmappedUsersTable
      :page="page"
      :loading="loading"
      @load-first-page="loadFirstPageOfUnmappedUsers"
      @search="searchUnmappedUsers($event)"
    ></UnmappedUsersTable>
    <Divider class="my-8"></Divider>
  </div>
  <div v-if="isUnmappedStatusExist">
    <UnmappedJiraStatusesTable unmapped></UnmappedJiraStatusesTable>
    <Divider class="my-8"></Divider>
  </div>
  <div class="flex">
    <ProjectMappingTable style="width: 30%"></ProjectMappingTable>
    <Divider layout="vertical"></Divider>
    <UserMappingTable style="width: 70%"></UserMappingTable>
  </div>
  <Divider class="my-8"></Divider>
  <GitConfigUsersTable></GitConfigUsersTable>
</template>

<script setup lang="ts">
import UnmappedUsersTable from '@/components/admin-dashboard/system/UnmappedUsersTable.vue'
import UnmappedJiraStatusesTable from '@/components/admin-dashboard/jira/JiraStatusesTable.vue'
import ProjectMappingTable from '@/components/admin-dashboard/system/project-mapping/ProjectMappingTable.vue'
import UserMappingTable from '@/components/admin-dashboard/system/user-mapping/UserMappingTable.vue'
import { useStore } from '@/store'
import { useToast } from 'primevue/usetoast'
import { computed, onMounted, ref, watch } from 'vue'
import { Page } from '@/types/types'
import { JiraStatus } from '@/store/modules/admin/jira'
import { showToastError } from '@/utils/utils'
import GitConfigUsersTable from '@/components/admin-dashboard/system/GitConfigUsersTable.vue'
import { debounce } from 'lodash'

const store = useStore()
const toast = useToast()
const page = ref<Page>()
const loading = ref(false)

const unmappedUsersSearch = computed(
  () => store.state.admin.unmappedUsersSearch
)
const debouncedUnmappedUsersGetRequest = debounce(
  loadFirstPageOfUnmappedUsers,
  1000
)

const searchUnmappedUsers = (value: string) => {
  store.commit('admin/setUnmappedUsersSearch', value || null)
}

watch([unmappedUsersSearch], () => {
  if (unmappedUsersSearch.value) {
    debouncedUnmappedUsersGetRequest()
  } else {
    loadFirstPageOfUnmappedUsers()
  }
})

onMounted(() => {
  loadFirstPageOfUnmappedUsers()
  getJiraStatuses()
})

const isUnmappedStatusExist = computed(() => {
  return store.state.jira.statuses.some(
    ({ original_status }: JiraStatus) => !original_status
  )
})

async function loadFirstPageOfUnmappedUsers(ordering: string | null = null) {
  loading.value = true
  try {
    page.value = await store.dispatch('admin/getFirstPageOfUnmappedUsers', {
      ordering: ordering,
      search: unmappedUsersSearch.value,
    })
    loading.value = false
  } catch (e) {
    showToastError(toast, e)
    loading.value = false
  }
}

const getJiraStatuses = () => {
  store.dispatch('jira/getStatuses').catch((e) => {
    showToastError(toast, e)
  })
}
</script>

<template>
  <Dialog
    :visible="open"
    :dismissableMask="true"
    @update:visible="closeDialog"
    position="bottom"
    modal
  >
    <template #header>
      <div class="header-wrapper">Bitbucket • Projects</div>
    </template>
    <div class="title">Add projects</div>
    <div class="flex justify-end items-center mr-2">
      <TableSearchInput
        v-model:value="search"
        placeholder="Search by project"
      ></TableSearchInput>
    </div>
    <DataTable
      :value="allBitbucketProjects"
      :scrollable="true"
      scroll-height="356px"
      v-model:selection="selectedProjects"
      class="p-datatable-sm flex-grow mt-5 custom"
      removableSort
      :loading="loading"
      :filters="filters"
      :globalFilterFields="filterFields"
    >
      <template #empty> No records found </template>
      <Column
        selectionMode="multiple"
        bodyStyle="flex-grow:0; flex-basis:30px"
        headerStyle="flex-grow:0; flex-basis:30px"
      ></Column>
      <Column
        v-for="(col, i) of columnsData"
        :key="`${col.field}-${i}`"
        :field="col.field"
        :header="col.header"
        :sortable="col.is_sortable"
      >
        <template v-if="col.field === 'original_project'" #body="slotProps">
          <BaseDropdown
            :options="originalProjects"
            v-model:selected="slotProps.data.original_project"
            v-model:newly-created-option="newlyCreatedProject"
            target="project"
            search
            table
            :option-id="slotProps.data.project_id"
            @add="onAddNewProject(slotProps.data.project_id)"
            :disabled="
              !selectedProjects.some(
                ({ project_id }) => project_id === slotProps.data.project_id
              )
            "
            @update:selected="
              onChangeOriginalProject($event, slotProps.data.project_id)
            "
          ></BaseDropdown>
        </template>
      </Column>
    </DataTable>
    <AddProjectMappingDialog
      v-model:open="showCreateOriginalProjectDialog"
      @save-project-id="newlyCreatedProject.new_option_id = $event"
    ></AddProjectMappingDialog>
    <template #footer>
      <Button class="p-button-outlined" size="large" @click="closeDialog">
        Cancel
      </Button>
      <Button
        size="large"
        class="add"
        :disabled="!allOriginalNameAdded"
        @click="onAddProjects"
        >Add</Button
      >
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import Dialog from 'primevue/dialog/Dialog.vue'
import { defineProps, ref, defineEmits, computed, onMounted } from 'vue'
import Button from '@/components/common/buttons/Button.vue'
import { useToast } from 'primevue/usetoast'
import { useStore } from '@/store'
import DataTable from 'primevue/datatable/DataTable.vue'
import Column from 'primevue/column/Column.vue'
import { isAllOriginalNameAdded, showToastError } from '@/utils/utils'
import { BitbucketProject } from '@/store/modules/admin/bitbucket'
import AddProjectMappingDialog from '@/components/admin-dashboard/system/project-mapping/AddProjectMappingDialog.vue'
import TableSearchInput from '@/components/common/table/TableSearchInput.vue'
import useTableSearch from '@/utils/hooks/useTableSearch'
import BaseDropdown from '@/components/common/base/BaseDropdown.vue'

defineProps<{ open: boolean }>()
const emit = defineEmits<{ (e: 'update:open', value: boolean): void }>()
const toast = useToast()
const store = useStore()
const originalProjects = computed(() => store.state.admin.original_projects)

const allBitbucketProjects = ref([])
const selectedProjects = ref<BitbucketProject[]>([])
const allOriginalNameAdded = computed(() =>
  isAllOriginalNameAdded(selectedProjects.value)
)
const loading = ref(false)
const showCreateOriginalProjectDialog = ref(false)
const newlyCreatedProject = ref<{ option_id?: number; new_option_id?: number }>(
  {}
)
const columnsData = [
  {
    header: 'Repository name',
    field: 'name',
    is_sortable: true,
  },
  {
    header: 'Original project',
    field: 'original_project',
    is_sortable: true,
  },
]
const filterFields = ['name']
const { search, filters } = useTableSearch()

onMounted(() => {
  getNotAddedProjects()
})

async function onAddProjects() {
  try {
    await store.dispatch('bitbucket/addProjects', selectedProjects.value)
    closeDialog()
  } catch (e) {
    showToastError(toast, e)
  }
}

async function getNotAddedProjects() {
  loading.value = true
  try {
    allBitbucketProjects.value = await store.dispatch(
      'bitbucket/getNotAddedProjects'
    )
    loading.value = false
  } catch (e) {
    loading.value = false
    showToastError(toast, e)
  }
}

function onChangeOriginalProject(
  orig_project: string | null | number,
  id: string
) {
  selectedProjects.value = selectedProjects.value.map(
    (project: BitbucketProject) => {
      if (project.project_id === Number(id)) {
        project.original_project = Number(orig_project)
      }
      return project
    }
  )
}

function closeDialog() {
  selectedProjects.value = []
  emit('update:open', false)
}

const onAddNewProject = (id: number) => {
  newlyCreatedProject.value.option_id = id
  showCreateOriginalProjectDialog.value = true
}
</script>

<style scoped>
.header-wrapper {
  @apply flex items-center justify-between w-full;
}
.title {
  @apply text-xl font-semi-bold mb-3;
}
</style>

<template>
  <StickyTopbar title="Project Portfolio">
    <template #buttons>
      <TimeFilter
        :filters="timeFilters"
        @change-time="onDateChanged"
      ></TimeFilter>
      <MultipleFilteringMenu
        name="Projects"
        :filters="filters.projects"
        :filtering-options="filteringOptions.projects"
        @change-filtering="onFilterChanged($event, 'projects')"
      ></MultipleFilteringMenu>
      <MultipleFilteringMenu
        v-if="showMembersFilter"
        name="Members"
        :filters="filters.users"
        :filtering-options="filteringOptions.users"
        @change-filtering="onFilterChanged($event, 'users')"
      ></MultipleFilteringMenu>
    </template>
  </StickyTopbar>
</template>

<script setup lang="ts">
import { computed, defineProps } from 'vue'
import { useStore } from '@/store'
import { Dictionary, equals, omit, pick } from 'ramda'
import TimeFilter from '@/components/common/filters/global/time-filter/TimeFilter.vue'
import MultipleFilteringMenu from '@/components/common/filters/global/MultipleFilteringMenu.vue'
import { Filters, TimeFilters } from '@/store/modules/filters'
import { useRouter } from 'vue-router'
import { DASHBOARD_PAGES, USER_ROLES } from '@/constants/constants'
import StickyTopbar from '@/components/common/StickyTopbar.vue'
import {
  defineSelectedGlobalFiltersPayloadKeys,
  showToastError,
} from '@/utils/utils'
import { useToast } from 'primevue/usetoast'

const props = defineProps<{ filteringOptions: any; filters: Filters }>()
const timeFilters = computed(() => pick(['since', 'until'], props.filters))
const store = useStore()
const router = useRouter()
const toast = useToast()

const showMembersFilter = computed(() =>
  [USER_ROLES.ADMIN, USER_ROLES.OWNER, USER_ROLES.TEAM_LEAD].includes(
    store.getters['company/userRole']
  )
)

const onFilterChanged = async (ev: number[], prop: string) => {
  const pageUnits = defineSelectedGlobalFiltersPayloadKeys(prop)
  const payloadArr = ev.map((item: number) => ({ [pageUnits.id_key]: item }))
  try {
    if (
      ev.length === props.filteringOptions[prop].length &&
      !equals(null, (props.filters as Dictionary<any>)[prop])
    ) {
      // case when all projects/users selected
      await store.dispatch(`admin/${pageUnits.action}`, {
        page: DASHBOARD_PAGES.PORTFOLIO,
        [pageUnits.payload_key]: [],
      })
      await router.push({
        path: `/company/${store.getters['company/selectedCompanyId']}/portfolio`,
        query: { ...omit([prop], props.filters) },
      })
    } else if (
      !!ev.length &&
      !equals(ev, (props.filters as Dictionary<any>)[prop]) &&
      ev.length !== props.filteringOptions[prop].length
    ) {
      await store.dispatch(`admin/${pageUnits.action}`, {
        page: DASHBOARD_PAGES.PORTFOLIO,
        [pageUnits.payload_key]: payloadArr,
      })
      await router.push({
        path: `/company/${store.getters['company/selectedCompanyId']}/portfolio`,
        query: { ...props.filters, ...{ [prop]: ev } },
      })
    }
  } catch (e) {
    showToastError(toast, e)
  }
}

const onDateChanged = (dates: TimeFilters) => {
  if (
    !equals(dates.since, props.filters.since) ||
    !equals(dates.until, props.filters.until)
  ) {
    router.push({
      path: `/company/${store.getters['company/selectedCompanyId']}/portfolio`,
      query: { ...props.filters, ...dates },
    })
  }
}
</script>

<template>
  <Dialog
    :visible="open"
    :style="{ width: '90vw' }"
    :dismissableMask="true"
    @update:visible="$emit('close')"
    position="bottom"
    modal
  >
    <template #header>
      <div class="header-wrapper">
        {{ bugMetricsData?.label }}
      </div>
    </template>
    <div class="text-xl font-semi-bold mb-4">Bug metrics</div>
    <div class="height flex mb-8" v-if="loading">
      <LoaderWrapper></LoaderWrapper>
    </div>
    <MyTable
      v-else
      :table-data="drilldwonData"
      :columns-data="columnsData"
      customHeight="70vh"
      :scrollable="scrollableTable"
      class="mb-8"
    >
      <template #issue_key="{ data }">
        <Link :href="data.absolute_url" style-class="truncate">
          {{ `${data.issue_key}: ${data.issue_name}` }}
        </Link>
      </template>
      <template #status="{ data }">
        <TicketStatusChip :status="data.status" />
      </template>
      <template #created="{ data }">
        {{ data.created }}
      </template>
      <template #resolved_at="{ data }">
        {{ data.resolved_at }}
      </template>
    </MyTable>
  </Dialog>
</template>

<script setup lang="ts">
import Dialog from 'primevue/dialog/Dialog.vue'
import { computed, defineProps, ref, watchEffect } from 'vue'
import LoaderWrapper from '@/components/common/loader/LoaderWrapper.vue'
import MyTable from '@/components/common/table/MyTable.vue'
import { useStore } from '@/store'
import { showToastError } from '@/utils/utils'
import { useToast } from 'primevue/usetoast'
import { Dictionary, equals, reject } from 'ramda'
import { Filters } from '@/store/modules/filters'
import Link from '@/components/common/Link.vue'
import TicketStatusChip from '@/components/common/TicketStatusChip.vue'

const props = defineProps<{
  open: boolean
  bugMetricsData: { y: number; x: string; key: string; label: string } | null
  filters: Filters
}>()

const drilldwonData = ref([] as any[])
const loading = ref(false)
const store = useStore()
const toast = useToast()
const scrollableTable = ref(true)

const columnsData = computed(() =>
  hasResolved.value
    ? [
        {
          header: 'Ticket',
          field: 'issue_key',
          is_sortable: true,
          use_template: true,
          styles: 'flex-grow: 5; padding-right: 10px',
        },
        {
          header: 'Status',
          field: 'status',
          is_sortable: true,
          use_template: true,
          styles: 'padding-right: 10px; min-width: 180px',
        },
        {
          header: 'Created',
          field: 'created',
          is_sortable: true,
          use_template: true,
          styles: ' padding-right: 10px',
        },
        {
          header: 'Resolved',
          field: 'resolved_at',
          is_sortable: true,
          use_template: true,
        },
      ]
    : [
        {
          header: 'Ticket',
          field: 'issue_key',
          is_sortable: true,
          use_template: true,
          styles: 'flex-grow: 5; padding-right: 10px',
        },
        {
          header: 'Status',
          field: 'status',
          is_sortable: true,
          use_template: true,
          styles: 'padding-right: 10px',
        },
        {
          header: 'Created',
          field: 'created',
          is_sortable: true,
          use_template: true,
        },
      ]
)

watchEffect(() => {
  if (props.bugMetricsData) {
    getBugMetricsDetails()
  }
})

const hasResolved = computed(() =>
  drilldwonData.value.some((item) => item.resolved_at)
)

const activeFilters = computed(() =>
  reject(equals(null))(props.filters as Dictionary<null>)
)

async function getBugMetricsDetails() {
  loading.value = true
  try {
    drilldwonData.value = await store.dispatch(
      'bugMetrics/getBugMetricsDrilldownData',
      {
        projects: activeFilters.value.projects,
        date: props.bugMetricsData?.x,
        category: props.bugMetricsData?.key,
      }
    )
    loading.value = false
  } catch (e) {
    showToastError(toast, e)
    loading.value = false
  }
}
</script>

<style scoped>
.header-wrapper {
  @apply flex items-center justify-between w-full text-base;
}
.height {
  height: 70vh;
}
</style>

import axios from '@/services/axios'
import { Filters } from '@/store/modules/filters'
import { isEmpty, values } from 'ramda'
import { ActionContext } from 'vuex'
import { State as GlobalState } from '../../index'
import { getFirstDayOfWeekOrMonth, getFirstDaysArray } from '@/utils/date-utils'

export interface Budget {
  date: string
  budget?: number
  hideDot?: boolean
}

export interface BudgetPlannedVsUsed {
  budget: Budget[]
  used_budget: Budget[]
  planned_budget: Budget[]
}

type Context = ActionContext<BudgetPlannedVsUsed, GlobalState>

const actions = {
  async getData(
    { rootGetters }: Context,
    params: {
      filters: Filters
      project_id: number
    }
  ): Promise<BudgetPlannedVsUsed | []> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/project-mapping/${params.project_id}/charts/budget/`,
        {
          params: params.filters,
        }
      )
      .then(({ data: originalData }) => {
        if (values(originalData).some((budget) => isEmpty(budget)))
          return { budget: [], planned_budget: [], used_budget: [] }

        const firstDate = params.filters.since
          ? getFirstDayOfWeekOrMonth(
              new Date(params.filters.since),
              params.filters.scale_type
            )
          : originalData.planned_budget[0].date

        const lastDate = params.filters.until
          ? getFirstDayOfWeekOrMonth(
              new Date(params.filters.until),
              params.filters.scale_type
            )
          : originalData.planned_budget[originalData.planned_budget.length - 1]
              .date

        const firstWeekOfRange = {
          date: firstDate,
        }

        const lastWeekOfRange = {
          date: lastDate,
        }

        const beforeRange: Budget[] = []
        let inTheRange: Budget[] = []

        originalData.budget.forEach((item: Budget) => {
          if (
            item.date >= firstWeekOfRange.date &&
            item.date <= lastWeekOfRange.date
          ) {
            inTheRange.push(item)
          } else if (item.date < firstWeekOfRange.date) {
            beforeRange.push(item)
          }
        })

        if (isEmpty(beforeRange) && isEmpty(inTheRange)) {
          return {
            ...originalData,
            budget: [],
          }
        }
        const datesArray = getFirstDaysArray(
          firstDate,
          lastDate,
          params.filters.scale_type
        )
        let previousBudget = 0
        const newArray: Budget[] = []
        // Create budget object if planned_budget has no data in this week or month
        // Use previous budget as a budget
        datesArray.forEach((day) => {
          let found = false
          for (const budget of originalData.planned_budget) {
            if (day === budget.date) {
              previousBudget = budget.budget
              newArray.push(budget)
              found = true
              break
            }
          }
          if (!found) {
            newArray.push({
              date: day,
              budget: previousBudget,
            })
          }
        })
        originalData.planned_budget = newArray

        const updateInTheRangeArrayWithFirstAndLastWeek = () => {
          if (
            inTheRange[0].date !== firstWeekOfRange.date &&
            !!beforeRange.length
          ) {
            inTheRange = [
              {
                date: firstWeekOfRange.date,
                budget: beforeRange[beforeRange.length - 1].budget,
                hideDot: true,
              },
              ...inTheRange,
            ]
          }
          if (inTheRange[inTheRange.length - 1].date !== lastWeekOfRange.date) {
            inTheRange.push({
              date: lastWeekOfRange.date,
              budget: inTheRange[inTheRange.length - 1].budget,
              hideDot: true,
            })
          }
          return inTheRange
        }
        return {
          ...originalData,
          budget: isEmpty(inTheRange)
            ? [
                {
                  date: firstWeekOfRange.date,
                  budget: beforeRange[beforeRange.length - 1].budget,
                  hideDot: true,
                },
                {
                  date: lastWeekOfRange.date,
                  budget: beforeRange[beforeRange.length - 1].budget,
                  hideDot: true,
                },
              ]
            : updateInTheRangeArrayWithFirstAndLastWeek(),
        }
      })
  },
}

export default {
  namespaced: true,
  actions,
}

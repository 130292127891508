<template>
  <div class="height flex" v-if="loading">
    <LoaderWrapper></LoaderWrapper>
  </div>
  <div v-else-if="!showJiraProjects" class="flow-wrapper">
    <JiraConnectionFlow class="w-1/2" with-title></JiraConnectionFlow>
  </div>
  <div v-else>
    <JiraProjectsTable></JiraProjectsTable>
    <Divider class="my-8"></Divider>
    <JiraUsersTable></JiraUsersTable>

    <Divider class="my-8"></Divider>
    <JiraStatusesTable></JiraStatusesTable>
  </div>
</template>

<script setup lang="ts">
import JiraProjectsTable from '@/components/admin-dashboard/jira/projects/JiraProjectsTable.vue'
import JiraUsersTable from '@/components/admin-dashboard/jira/JiraUsersTable.vue'
import JiraStatusesTable from '@/components/admin-dashboard/jira/JiraStatusesTable.vue'
import { computed, onMounted, ref } from 'vue'
import { useStore } from '@/store'
import { showToastError } from '@/utils/utils'
import { useToast } from 'primevue/usetoast'
import JiraConnectionFlow from '@/components/admin-dashboard/jira/JiraConnectionFlow.vue'
import LoaderWrapper from '@/components/common/loader/LoaderWrapper.vue'

const store = useStore()
const toast = useToast()
const loading = ref(false)
const showJiraProjects = computed(() => store.state.jira.isJiraConnected)

onMounted(() => {
  loading.value = true
  store
    .dispatch('jira/checkJiraConnection')
    .catch((e) => {
      showToastError(toast, e)
    })
    .finally(() => (loading.value = false))
  if (showJiraProjects.value) {
    store.dispatch('jira/getStatuses').catch((e) => {
      showToastError(toast, e)
    })
  }
})
</script>

<style scoped>
.flow-wrapper {
  height: 380px;
  @apply bg-gray-50 p-8;
}
.height {
  height: 380px;
}
</style>

<template>
  <SelectButton
    v-model="selectionValue"
    :options="options"
    :optionValue="optionValue"
    :optionLabel="optionLabel"
    :allowEmpty="allowEmpty"
    :unselectable="allowEmpty"
    class="p-button-sm"
    @change="$emit('change', $event.value)"
  ></SelectButton>
</template>

<script setup lang="ts">
import { computed, defineProps, defineEmits } from 'vue'
import SelectButton from 'primevue/selectbutton/SelectButton.vue'

const props = defineProps<{
  options: any
  selection: boolean | string
  optionValue?: string
  optionLabel?: string
  simpleArray?: boolean
  allowEmpty?: boolean
}>()

const optionValue = computed(() =>
  props.simpleArray ? null : props.optionValue || 'value'
)
const optionLabel = computed(() =>
  props.simpleArray ? null : props.optionLabel || 'name'
)

const emit = defineEmits<{
  (e: 'update:selection', value: boolean | string): void
  (e: 'change', value: boolean | string): void
}>()

const selectionValue = computed({
  get() {
    return props.selection
  },
  set(value: boolean | string) {
    emit('update:selection', value)
  },
})
</script>

import axios from '@/services/axios'
import { omit, path } from 'ramda'
import { ActionContext } from 'vuex'
import { State as GlobalState } from '../../index'

export interface SlackToken {
  name?: string
  token?: string
  channel_id?: string
  company?: number
  status?: string
}

export interface SlackUser {
  company?: number
  id: number
  original_name?: number | null
  managed_by?: string
  username?: string
  enable_dm_notifications: boolean
}

export interface SlackSettings {
  non_billable_threshold: number | null
  untracked_notification_template: string
  non_billable_notification_template: string
  daily_untracked_notification_template: string
  company?: number
}

export interface State {
  token: SlackToken | null
  settings: SlackSettings | null
  search: string | null
}

type Context = ActionContext<State, GlobalState>

const state = (): State => ({
  token: null,
  settings: null,
  search: null,
})

const mutations = {
  setToken(state: State, token: SlackToken): void {
    state.token = token
  },
  setSettings(state: State, settings: SlackSettings): void {
    state.settings = settings
  },
  setSearchText(state: State, search: string | null): void {
    state.search = search
  },
}

const actions = {
  getToken({ commit, rootGetters }: Context): Promise<void> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/slack/token/`
      )
      .then((data) => commit('setToken', data.data))
  },
  addToken({ commit, rootGetters }: Context, token: SlackToken): Promise<void> {
    return axios
      .post(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/slack/token/`,
        token
      )
      .then((data) => commit('setToken', data.data))
  },
  editToken(
    { commit, rootGetters }: Context,
    token: SlackToken
  ): Promise<void> {
    return axios
      .patch(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/slack/token/`,
        token
      )
      .then((data) => commit('setToken', data.data))
  },
  deleteToken(
    { dispatch, commit, rootGetters }: Context,
    verificationCode: string
  ): Promise<void> {
    return axios
      .delete(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/slack/token/`,
        {
          data: {
            verification_code: verificationCode,
          },
        }
      )
      .then(() => commit('setToken', null))
      .then(() => dispatch('getSettings'))
  },
  getFirstPageOfUsers(
    { rootGetters }: Context,
    params: { ordering: null | string; search: null | string }
  ): Promise<void> {
    const { ordering, search } = params || { ordering: null }
    return axios
      .get(
        `/api/companies/${
          rootGetters['company/selectedCompanyId']
        }/slack/users/?limit=50&ordering=${ordering}&unmapped=false${
          search ? `&search=${search}` : ''
        }`
      )
      .then(path(['data']))
  },
  getNextPageOfUsers(_: Context, url: string): Promise<void> {
    return axios.get(`${url}`).then(path(['data']))
  },
  editUser(
    { rootGetters }: Context,
    user: {
      original_name?: number
      enable_dm_notifications?: boolean
      id: number
    }
  ): Promise<void> {
    return axios.patch(
      `/api/companies/${rootGetters['company/selectedCompanyId']}/slack/users/${user.id}/`,
      omit(['id'], user)
    )
  },
  getSettings({ commit, rootGetters }: Context): Promise<void> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/slack/settings/`
      )
      .then((data) => commit('setSettings', data.data))
  },
  addSettings(
    { commit, rootGetters }: Context,
    settings: SlackSettings
  ): Promise<void> {
    return axios
      .post(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/slack/settings/`,
        settings
      )
      .then((data) => commit('setSettings', data.data))
  },
  editSettings(
    { commit, rootGetters }: Context,
    settings: SlackSettings
  ): Promise<void> {
    return axios
      .patch(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/slack/settings/`,
        settings
      )
      .then((data) => commit('setSettings', data.data))
  },
  deleteSettings({ commit, rootGetters }: Context): Promise<void> {
    return axios
      .delete(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/slack/settings/`
      )
      .then(() => commit('setSettings', null))
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}

import axios from '@/services/axios'
import { omit, path } from 'ramda'
import { ActionContext } from 'vuex'
import { State as GlobalState } from '../../index'

export interface TempoProject {
  id: number
  include_all_data: boolean
  jira_project: number
  token: number
  teams: string[] | null[]
}

export interface TempoToken {
  name?: string
  id?: number
  token?: string
  base_url?: string
  company?: number
  status?: string
}

export interface TempoRole {
  name?: string
  id?: number
  role_id?: number
  default?: boolean
  company?: number
}

export interface TempoTeam {
  name?: string
  id?: number
  default?: boolean
}

export interface State {
  token: TempoToken | null
  projects: TempoProject[]
  roles: TempoRole[]
  teams: TempoTeam[]
}

type Context = ActionContext<State, GlobalState>

const state = (): State => ({
  token: null,
  projects: [],
  roles: [],
  teams: [],
})

const mutations = {
  setToken(state: State, token: TempoToken): void {
    state.token = token
  },
  setProjects(state: State, projects: TempoProject[]): void {
    state.projects = projects
  },
  setRoles(state: State, roles: TempoRole[]): void {
    state.roles = roles
  },
  setTeams(state: State, teams: TempoTeam[]): void {
    state.teams = teams
  },
}

const getters = {
  defaultTeam(state: State): TempoTeam | null {
    return state.teams.find((team: TempoTeam) => team.default) || null
  },
}

const actions = {
  addToken(
    { dispatch, rootGetters }: Context,
    token: TempoToken
  ): Promise<void> {
    return axios
      .post(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/tempo/token/`,
        {
          ...token,
        }
      )
      .then(path(['data']))
      .then(() => dispatch('getToken'))
  },
  getToken({ commit, rootGetters }: Context): Promise<void> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/tempo/token/`
      )
      .then((data) => commit('setToken', data.data))
  },
  editToken(
    { dispatch, rootGetters }: Context,
    token: TempoToken
  ): Promise<void> {
    return axios
      .patch(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/tempo/token/`,
        token
      )
      .then(path(['data']))
      .then(() => dispatch('getToken'))
  },
  deleteToken(
    { dispatch, rootGetters }: Context,
    verificationCode: string
  ): Promise<void> {
    return axios
      .delete(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/tempo/token/`,
        {
          data: {
            verification_code: verificationCode,
          },
        }
      )
      .then(path(['data']))
      .then(() => dispatch('getToken'))
  },
  getProjects({ commit, rootGetters }: Context): Promise<void> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/tempo/projects/`
      )
      .then((data) => commit('setProjects', data.data))
  },
  deleteProject({ dispatch, rootGetters }: Context, id: number): Promise<void> {
    return axios
      .delete(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/tempo/projects/${id}/`
      )
      .then(path(['data']))
      .then(() => dispatch('getProjects'))
  },
  addProject(
    { rootGetters }: Context,
    project: { jira_project: number; token: number }
  ): Promise<void> {
    return axios
      .post(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/tempo/projects/`,
        project
      )
      .then(path(['data']))
  },
  getBudgets({ rootGetters }: Context, id: number): Promise<void> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/tempo/projects/${id}/project-budget/`
      )
      .then(path(['data']))
  },
  getBudget(
    { rootGetters }: Context,
    payload: { project_id: number; budget_id: number }
  ): Promise<void> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/tempo/projects/${payload.project_id}/project-budget/${payload.budget_id}/`
      )
      .then(path(['data']))
  },
  addBudget(
    { rootGetters }: Context,
    payload: {
      project_id: number
      budget: { budget: number; date: string }
    }
  ): Promise<void> {
    return axios
      .post(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/tempo/projects/${payload.project_id}/project-budget/`,
        payload.budget
      )
      .then(path(['data']))
  },
  editBudget(
    { rootGetters }: Context,
    payload: {
      project_id: number
      budget: { id: number; budget: number; date: string }
    }
  ): Promise<void> {
    return axios
      .put(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/tempo/projects/${payload.project_id}/project-budget/${payload.budget.id}/`,
        payload.budget
      )
      .then(path(['data']))
  },
  deleteBudget(
    { rootGetters }: Context,
    payload: { project_id: number; budget_id: number }
  ): Promise<void> {
    return axios
      .delete(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/tempo/projects/${payload.project_id}/project-budget/${payload.budget_id}/`
      )
      .then(path(['data']))
  },
  getRolesRates({ rootGetters }: Context, id: number): Promise<void> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/tempo/projects/${id}/role-rate/`
      )
      .then(path(['data']))
  },
  getRoleRate(
    { rootGetters }: Context,
    payload: { project_id: number; role_rate_id: number }
  ): Promise<void> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/tempo/projects/${payload.project_id}/role-rate/${payload.role_rate_id}/`
      )
      .then(path(['data']))
  },
  addRoleRate(
    { rootGetters }: Context,
    payload: {
      project_id: number
      role_rate: { id: number; budget: number; date: string }
    }
  ): Promise<void> {
    return axios
      .post(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/tempo/projects/${payload.project_id}/role-rate/`,
        payload.role_rate
      )
      .then(path(['data']))
  },
  editRoleRate(
    { rootGetters }: Context,
    payload: {
      project_id: number
      role_rate: { id: number; budget: number; date: string }
    }
  ): Promise<void> {
    return axios
      .put(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/tempo/projects/${payload.project_id}/role-rate/${payload.role_rate.id}/`,
        payload.role_rate
      )
      .then(path(['data']))
  },
  deleteRoleRate(
    { rootGetters }: Context,
    payload: { project_id: number; role_rate_id: number }
  ): Promise<void> {
    return axios
      .delete(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/tempo/projects/${payload.project_id}/role-rate/${payload.role_rate_id}/`
      )
      .then(path(['data']))
  },
  getRoles({ commit, rootGetters }: Context): Promise<void> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/tempo/roles/`
      )
      .then((data) => commit('setRoles', data.data))
  },
  getTeams({ commit, rootGetters }: Context): Promise<void> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/tempo/teams/`
      )
      .then((data) => commit('setTeams', data.data))
  },
  editTeam(
    { dispatch, rootGetters }: Context,
    payload: { team_id: number; default: boolean }
  ): Promise<void> {
    return axios
      .patch(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/tempo/teams/${payload.team_id}/`,
        omit(['team_id'], payload)
      )
      .then(() => dispatch('getTeams'))
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}

import axios, { type AxiosStatic } from 'axios'
import qs from 'qs'

const axiosinstance = axios.create({
  paramsSerializer: (params: any) =>
    qs.stringify(params, {
      arrayFormat: 'comma',
      encoder(str) {
        return str
          .replace(/%3A/gi, ':')
          .replace(/%24/g, '$')
          .replace(/%2C/gi, ',')
          .replace(/%20/g, '+')
          .replace(/%5B/gi, '[')
          .replace(/%5D/gi, ']')
      },
    }),
})
axiosinstance.defaults.baseURL = process.env.VUE_APP_BASE_URL

export default axiosinstance as AxiosStatic

<template>
  <div ref="imgWrapper">
    <img
      v-for="(target, index) of items"
      :key="index"
      :src="target.src"
      :style="target.style"
      :alt="`Blur circle ${index}`"
      @load="moveTarget"
      @finish="moveTarget"
    />
  </div>
  <div class="login">
    <Toast />
    <div class="py-8">
      <img src="../assets/icons/logo.svg" alt="Logo" width="125" height="25" />
    </div>
    <div class="square-block">
      <slot></slot>
    </div>
    <div>
      <div class="py-8 terms text-gray-700 text-base">
        By continuing, you agree to DEVFLOW <br />
        <a href="">Terms &amp; Conditions</a> and
        <a href="">Privacy Statement</a>.
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import Toast from 'primevue/toast/Toast.vue'

interface Style {
  backgroundColor: string
  position: string
  borderRadius: string
  width: string
  height: string
  filter: string
  '-webkit-backdrop-filter': string
  pointerEvents: string
  left: string
  top: string
  zIndex: string
}

interface Color {
  color: string
}
const imgWrapper = ref()
const config: Color[] = [
  { color: '#697BDF' },
  { color: '#F9DD76' },
  { color: '#F18984' },
]
const items = ref<{ style: Style; src: string }[]>([])

onMounted(() => {
  items.value = config.map((item) => {
    const target = {
      style: {} as Style,
      src: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQImWNgYGBgAAAABQABh6FO1AAAAABJRU5ErkJggg==', // smallest png transparent 1px * 1px to toggle onload event
    }
    target.style.backgroundColor = item.color
    target.style.position = 'absolute'
    target.style.borderRadius = '50%'
    target.style.width = '500px'
    target.style.height = '500px'
    target.style.filter = 'blur(220px)'
    target.style['-webkit-backdrop-filter'] = 'blur(1px)' // safari filter bug
    target.style.pointerEvents = 'none'
    const [x, y] = makeNewPosition({
      offsetHeight: 500,
      offsetWidth: 500,
    } as HTMLElement)
    target.style.left = x + 'px'
    target.style.top = y + 'px'
    target.style.zIndex = '-1'
    return target
  })
})

function makeNewPosition(target: HTMLElement): number[] {
  const containerVspace =
    imgWrapper.value?.parentElement.offsetHeight - target.offsetHeight
  const containerHspace =
    imgWrapper.value?.parentElement.offsetWidth - target.offsetWidth
  const newX = Math.floor(Math.random() * containerVspace)
  const newY = Math.floor(Math.random() * containerHspace)
  return imgWrapper.value ? [newX, newY] : [0, 0]
}

function velocity(prev: number[], next: number[]): number {
  const x = Math.abs(prev[1] - next[1])
  const y = Math.abs(prev[0] - next[0])
  const larger = x > y ? x : y
  const speedModifier = 0.2
  return Math.ceil(larger / speedModifier)
}

//find way how to mock returned value of makeNewPosition function
/* istanbul ignore next */
function moveTarget(ev: AnimationEvent | Event): void {
  const target = ev.currentTarget?.effect?.target || ev.target
  const newPos = makeNewPosition(target)
  const oldTop = target.offsetTop
  const oldLeft = target.offsetLeft
  const animation = target.animate(
    [
      { top: oldTop + 'px', left: oldLeft + 'px' },
      { top: newPos[0] + 'px', left: newPos[1] + 'px' },
    ],
    {
      duration: velocity([oldTop, oldLeft], newPos),
      fill: 'forwards',
    }
  )
  animation.onfinish = moveTarget
}
</script>

<style scoped>
.login {
  @apply h-full w-full flex flex-col items-center justify-between;
}

.square-block {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  box-shadow: inset 0 124px 124px rgba(255, 255, 255, 0.1);
  border-radius: 12px 12px 0 0;
  min-width: 45%;
  min-height: 65%;
  @apply p-3 flex flex-col items-center justify-center;
}

.terms a {
  text-decoration: underline;
}
</style>

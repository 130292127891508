<template>
  <header class="sticky-header" ref="wrapper">
    <div class="header-wrapper" :class="{ 'border-none': !hideBigTitle }">
      <div class="small-title" :class="{ 'opacity-0': !hideBigTitle }">
        <slot name="smallAvatar"></slot>
        <span class="mr-1">{{ title }}</span>
        <slot name="smallFavouriteIcon"></slot>
        <slot name="tabs"></slot>
      </div>
      <div>
        <slot name="buttons"></slot>
      </div>
    </div>
    <div
      ref="bigTitle"
      class="big-title"
      :class="{ 'opacity-0 mb-0 h-0': hideBigTitle }"
    >
      <slot name="bigAvatar"></slot>
      <span class="mr-1">{{ title }}</span>
      <slot name="bigFavouriteIcon"></slot>
    </div>
  </header>
</template>

<script setup lang="ts">
import {
  ref,
  onMounted,
  defineProps,
  defineEmits,
  onUnmounted,
  watch,
} from 'vue'

defineProps<{ title: string }>()
const emit = defineEmits<{ (e: 'hide', value: boolean): void }>()

const hideBigTitle = ref(false)
const container = ref<HTMLElement | null>(null)

onMounted(() => {
  container.value = document.querySelector('.base-container')
  container.value?.addEventListener('scroll', handleScroll)
})

onUnmounted(() => {
  container.value?.removeEventListener('scroll', handleScroll)
})

watch([hideBigTitle], () => {
  emit('hide', hideBigTitle.value)
})

const handleScroll = (ev: Event) => {
  const bigTitleMarginBottom = hideBigTitle.value ? 0 : 32
  const target = ev.target as HTMLElement
  hideBigTitle.value = target.scrollTop > bigTitleMarginBottom
}
</script>

<style scoped>
.sticky-header {
  @apply sticky top-0 z-20 bg-white;
}
.header-wrapper {
  @apply flex flex-shrink justify-between pl-8 pr-3 py-2 items-center border-b border-black-5;
}
.small-title {
  @apply font-bold flex items-center transition-opacity flex items-center;
}
.big-title {
  @apply text-2xl font-bold mb-8 px-8 text-left transition-all flex items-center;
}
</style>

<template>
  <Dialog
    :visible="!!id"
    :dismissableMask="true"
    @update:visible="closeDialog"
    position="bottom"
    modal
  >
    <template #header>
      <div class="header-wrapper">
        <div>Bitbucket • Projects</div>
        <div class="flex gap-1">
          <IconButton
            class="delete p-button-text"
            :disabled="loading"
            @click="showDeleteConfirmationDialog = true"
          >
            <i class="pi pi-trash"></i>
          </IconButton>
        </div>
      </div>
    </template>
    <div class="title">{{ formData.name || 'Project Name' }}</div>
    <FormRow title="Original Project">
      <BaseDropdown
        v-model:selected="formData.original_project"
        v-model:newly-created-option="newlyCreatedProject"
        :options="originalProjects"
        target="project"
        search
        :option-id="id"
        @add="onAddNewProject(id)"
      ></BaseDropdown>
    </FormRow>
    <FormRow title="Commit Message Excl. Rule">
      <BaseTextarea
        v-model:value="formData.commit_message_exclusion_rule"
        rows="4"
        :errors="v$.commit_message_exclusion_rule.$errors"
      ></BaseTextarea>
    </FormRow>
    <FormRow title="File Path Excl. Rule">
      <div class="w-full flex flex-col">
        <BaseTextarea
          v-model:value="formData.file_path_exclusion_rule"
          rows="4"
          :errors="v$.file_path_exclusion_rule.$errors"
        ></BaseTextarea>
        <div>Use RegEx (Regular Expressions).</div>
        <Link
          href="https://docs.python.org/3/library/re.html"
          style-class="font-semi-bold"
        >
          Follow the link to find examples RegEx.
        </Link>
      </div>
    </FormRow>
    <template #footer>
      <Button class="p-button-outlined" size="large" @click="closeDialog">
        Cancel
      </Button>
      <Button class="save" size="large" @click="onEditProject"
        >Save Changes</Button
      >
    </template>
    <AddProjectMappingDialog
      v-model:open="showAddOriginalProjectDialog"
      @save-project-id="newlyCreatedProject.new_option_id = $event"
    ></AddProjectMappingDialog>
    <DeleteConfirmationDialog
      v-model:open="showDeleteConfirmationDialog"
      :name="formData.name"
      :loading="loading"
      @delete="onDeleteProject"
    ></DeleteConfirmationDialog>
  </Dialog>
</template>

<script setup lang="ts">
import Dialog from 'primevue/dialog/Dialog.vue'
import { defineProps, ref, defineEmits, watchEffect, computed } from 'vue'
import Button from '@/components/common/buttons/Button.vue'
import { useToast } from 'primevue/usetoast'
import { useStore } from '@/store'
import IconButton from '@/components/common/buttons/IconButton.vue'
import FormRow from '@/components/common/form/FormRow.vue'
import { showToastError } from '@/utils/utils'
import BaseDropdown from '@/components/common/base/BaseDropdown.vue'
import useVuelidate from '@vuelidate/core'
import BaseTextarea from '@/components/common/base/BaseTextarea.vue'
import DeleteConfirmationDialog from '@/components/common/dialogs/DeleteConfirmationDialog.vue'
import Link from '@/components/common/Link.vue'
import AddProjectMappingDialog from '@/components/admin-dashboard/system/project-mapping/AddProjectMappingDialog.vue'

const initialForm = () => ({
  name: '',
  original_project: null,
  commit_message_exclusion_rule: null,
  file_path_exclusion_rule: null,
})

const props = defineProps<{ id: number }>()
const emit = defineEmits<{ (e: 'update:id', value: boolean): void }>()
const toast = useToast()
const store = useStore()
const showDeleteConfirmationDialog = ref(false)
const loading = ref(false)
const showAddOriginalProjectDialog = ref(false)
const newlyCreatedProject = ref<{ option_id?: number; new_option_id?: number }>(
  {}
)

const formData = ref(initialForm())

const formRules = {
  commit_message_exclusion_rule: {
    onlyBackend: () => true,
    $autoDirty: true,
  },
  file_path_exclusion_rule: {
    onlyBackend: () => true,
    $autoDirty: true,
  },
}
const $externalResults = ref({})

const v$ = useVuelidate(formRules, formData, { $externalResults })

watchEffect(async () => {
  if (props.id) {
    try {
      formData.value = await store.dispatch('bitbucket/getProject', props.id)
    } catch (e) {
      showToastError(toast, e)
    }
  }
})

const tokens = computed(() => store.state.bitbucket.tokens)
const originalProjects = computed(() => store.state.admin.original_projects)

async function onEditProject() {
  try {
    await store.dispatch('bitbucket/editProject', {
      ...formData.value,
      id: props.id,
    })
    if (
      formData.value.commit_message_exclusion_rule ||
      formData.value.file_path_exclusion_rule
    ) {
      await store.dispatch('bitbucket/getFirstPageOfCommits')
    }
    closeDialog()
  } catch (e: any) {
    if (
      e.response?.data?.commit_message_exclusion_rule ||
      e.response?.data?.file_path_exclusion_rule
    ) {
      $externalResults.value = e.response.data
    } else {
      showToastError(toast, e)
    }
  }
}

async function onDeleteProject() {
  loading.value = true
  try {
    await store.dispatch('bitbucket/deleteProject', props.id)
    loading.value = false
    showDeleteConfirmationDialog.value = false
    closeDialog()
  } catch (e) {
    showToastError(toast, e)
    loading.value = false
  }
}

function closeDialog() {
  emit('update:id', false)
  formData.value = initialForm()
}

const onAddNewProject = (id: number) => {
  newlyCreatedProject.value.option_id = id
  showAddOriginalProjectDialog.value = true
}
</script>

<style scoped>
.header-wrapper {
  @apply flex items-center justify-between w-full;
}
.title {
  @apply text-xl font-semi-bold mb-3;
}
</style>

<template>
  <FormRow title="Token Name" :invalid-state="v$.name.$invalid">
    <BaseInputText
      class="w-full"
      v-model:value="nameValue"
      :errors="v$.name.$errors"
    ></BaseInputText>
  </FormRow>
  <FormRow title="Token" :invalid-state="v$.token.$invalid">
    <BaseInputText
      class="w-full"
      v-model:value="tokenValue"
      :type="edit ? 'password' : 'text'"
      :errors="v$.token.$errors"
    ></BaseInputText>
  </FormRow>
  <FormRow
    v-if="isNotNil(base_url)"
    title="Base URL"
    :invalid-state="v$.base_url.$invalid"
  >
    <BaseInputText
      class="w-full"
      v-model:value="baseURLValue"
      :errors="v$.base_url.$errors"
    ></BaseInputText>
  </FormRow>
  <FormRow v-if="edit" title="Status">
    <div class="w-full flex justify-start size">
      <Chip :label="TOKEN_STATUSES_TITLES[status]" :color="statusColor"></Chip>
    </div>
  </FormRow>
</template>

<script setup lang="ts">
import FormRow from '@/components/common/form/FormRow.vue'
import { defineEmits, defineProps, computed } from 'vue'
import BaseInputText from '@/components/common/base/BaseInputText.vue'
import { VuelidateValidation } from '@/types/types'
import { isNotNil } from 'ramda-adjunct'
import Chip from '@/components/common/Chip.vue'
import { TOKEN_STATUSES, TOKEN_STATUSES_TITLES } from '@/constants/constants'

interface TokenFormValidation extends VuelidateValidation {
  name: VuelidateValidation
  token: VuelidateValidation
  base_url: VuelidateValidation
}
const props = defineProps<{
  name: string
  base_url?: string
  token: string
  edit?: boolean
  status?: string
  v$: TokenFormValidation
}>()
const emit = defineEmits<{
  (e: 'update:name', value: string): void
  (e: 'update:token', value: string): void
  (e: 'update:base_url', value: string): void
}>()

const nameValue = computed({
  get() {
    return props.name
  },
  set(value: string) {
    emit('update:name', value)
  },
})

const tokenValue = computed({
  get() {
    return props.token
  },
  set(value: string) {
    emit('update:token', value)
  },
})
const baseURLValue = computed({
  get() {
    return props.base_url
  },
  set(value: string) {
    emit('update:base_url', value)
  },
})

const statusColor = computed(() => {
  const textColor = 'text-black '
  if (props.status === TOKEN_STATUSES.TOKEN_INVALID) {
    return textColor + 'bg-danger-100'
  } else if (props.status === TOKEN_STATUSES.DELETION_IN_PROGRESS) {
    return textColor + 'bg-warning-100'
  } else {
    return textColor + 'bg-success-50'
  }
})
</script>

<style scoped lang="scss">
.size :deep(.p-chip) {
  @apply text-base px-2;
}
</style>

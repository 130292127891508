<template>
  <Dialog
    :visible="!!id"
    :dismissableMask="true"
    @update:visible="closeDialog"
    position="bottom"
    modal
  >
    <template #header>
      <div class="header-wrapper">
        <div>Project mapping</div>
        <div class="flex gap-1">
          <IconButton
            class="p-button-text delete"
            @click="showDeleteConfirmationDialog = true"
          >
            <i class="pi pi-trash" aria-hidden="true"></i>
          </IconButton>
        </div>
      </div>
    </template>
    <div class="title">{{ formData.name || 'Project Name' }}</div>
    <ProjectMappingForm
      v-model:name="formData.name"
      v-model:is_active="formData.is_active"
      :validation="v$"
    ></ProjectMappingForm>
    <template #footer>
      <Button class="p-button-outlined" size="large" @click="closeDialog">
        Cancel
      </Button>
      <Button size="large" class="edit" @click="onEditProject"
        >Save Changes</Button
      >
    </template>
    <DeleteConfirmationDialog
      v-model:open="showDeleteConfirmationDialog"
      :name="formData.name"
      subtitle="Deleting this project will also delete the mapped Jira, Tempo and GitLab projects."
      :loading="loading"
      @delete="onDeleteProject"
    >
    </DeleteConfirmationDialog>
  </Dialog>
</template>

<script setup lang="ts">
import Dialog from 'primevue/dialog/Dialog.vue'
import { defineProps, ref, defineEmits, watchEffect } from 'vue'
import Button from '@/components/common/buttons/Button.vue'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { useToast } from 'primevue/usetoast'
import { useStore } from '@/store'
import IconButton from '@/components/common/buttons/IconButton.vue'
import ProjectMappingForm from '@/components/admin-dashboard/system/project-mapping/ProjectMappingForm.vue'
import { showToastError } from '@/utils/utils'
import DeleteConfirmationDialog from '@/components/common/dialogs/DeleteConfirmationDialog.vue'

const initialForm = () => ({
  name: '',
  is_active: true,
})

const props = defineProps<{ id: boolean | number }>()
const emit = defineEmits<{
  (e: 'update:id', value: boolean): void
  (e: 'refresh-table'): void
}>()
const toast = useToast()
const store = useStore()
const formData = ref(initialForm())
const showDeleteConfirmationDialog = ref(false)
const loading = ref(false)

const formRules = {
  name: { required, $lazy: true },
}
const v$ = useVuelidate(formRules, formData)

watchEffect(async () => {
  if (props.id) {
    formData.value = await store.dispatch('admin/getProject', props.id)
  }
})

async function onEditProject() {
  v$.value.$touch()
  if (v$.value.$invalid) {
    return
  }
  try {
    await store.dispatch('admin/editProject', {
      ...formData.value,
      id: props.id,
    })
    closeDialog()
    emit('refresh-table')
  } catch (e) {
    showToastError(toast, e)
  }
}

async function onDeleteProject() {
  loading.value = true
  try {
    await store
      .dispatch('admin/deleteProject', props.id)
      .then(() => emit('refresh-table'))
    loading.value = false
    showDeleteConfirmationDialog.value = false
    closeDialog()
  } catch (e) {
    loading.value = false
    showToastError(toast, e)
  }
}

function closeDialog() {
  formData.value = initialForm()
  v$.value.$reset()
  emit('update:id', false)
}
</script>

<style scoped>
.header-wrapper {
  @apply flex items-center justify-between w-full;
}
.title {
  @apply text-xl font-semi-bold mb-3;
}
</style>

import axios from '@/services/axios'
import { Filters } from '@/store/modules/filters'
import { ActionContext } from 'vuex'
import { State as GlobalState } from '../../index'

export interface LOCPerUserState {
  deletions: number
  duplicated_lines: number
  insertions: number
  original_deletions: number
  original_insertions: number
  user: { name: string; id: number }
}

export interface LOCPerProjectState {
  deletions: number
  duplicated_lines: number
  insertions: number
  original_deletions: number
  original_insertions: number
  project: { name: string; id: number }
}

export interface DataState {
  avg_int_sel_del: number
  avg_int_sel_dupl: number
  avg_int_sel_ins: number
  avg_int_del: number
  avg_int_dupl: number
  avg_int_ins: number
  avg_ext_del: number
  avg_ext_dupl: number
  avg_ext_ins: number
  per_user: LOCPerUserState[]
  per_project: LOCPerProjectState[]
}

interface LOCCommitsFilters {
  insertions_gte: null | string
  insertions_lte: null | string
  deletions_gte: null | string
  deletions_lte: null | string
  duplicated_lines_gte: null | string
  duplicated_lines_lte: null | string
}

export interface State {
  commits_filters: LOCCommitsFilters
  commitsFiltersSearch: null | string
}

type Context = ActionContext<State, GlobalState>

const state = (): State => ({
  commits_filters: {
    insertions_gte: null,
    insertions_lte: null,
    deletions_gte: null,
    deletions_lte: null,
    duplicated_lines_gte: null,
    duplicated_lines_lte: null,
  },
  commitsFiltersSearch: null,
})

const mutations = {
  setCommitsFilters(state: State, filters: LOCCommitsFilters): void {
    state.commits_filters = filters
  },
  setSearchText(state: State, search: string | null): void {
    state.commitsFiltersSearch = search
  },
}

const actions = {
  getData({ rootGetters }: Context, params: Filters): Promise<DataState> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/charts/lines-of-code/`,
        { params }
      )
      .then(({ data }) => {
        // return data, when BE is done and fields of the response are the same
        // if names of the fields are different, please leave this keys of the returned object, it's used in component
        return {
          avg_int_sel_del: data.avg_int_sel_del,
          avg_int_sel_dupl: data.avg_int_sel_dupl,
          avg_int_sel_ins: data.avg_int_sel_ins,
          avg_int_del: data.avg_int_del,
          avg_int_dupl: data.avg_int_dupl,
          avg_int_ins: data.avg_int_ins,
          avg_ext_del: data.avg_ext_del,
          avg_ext_dupl: data.avg_ext_dupl,
          avg_ext_ins: data.avg_ext_ins,
          per_user: data.per_user,
          per_project: data.per_project,
        }
      })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}

import axios from '@/services/axios'
import { Filters } from '@/store/modules/filters'
import { ActionContext } from 'vuex'
import { State as GlobalState } from '../../index'

export interface BugMetricsItem {
  date: string
  sum_new_resolved_at_this_week: number
  sum_new_not_resolved_at_this_week: number
  sum_old_resolved_at_this_week: number
  sum_old_not_resolved_at_this_week: number
}

export interface BugMetricsData {
  data: BugMetricsItem[]
}

type Context = ActionContext<BugMetricsData, GlobalState>

const actions = {
  getData({ rootGetters }: Context, params: Filters): Promise<BugMetricsData> {
    return axios
      .get(
        `api/companies/${rootGetters['company/selectedCompanyId']}/project-mapping/${params.projects}/charts/bug-metrics/`,
        { params }
      )
      .then(({ data }) => data)
  },
  getBugMetricsDrilldownData(
    { rootGetters }: Context,
    params: any
  ): Promise<any> {
    return axios
      .get(
        `api/companies/${rootGetters['company/selectedCompanyId']}/project-mapping/${params.projects}/charts/bug-metrics/drilldown/`,
        { params }
      )
      .then(({ data }) => data)
  },
}

export default {
  namespaced: true,
  actions,
}

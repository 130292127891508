import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import {
  AGGREGATION_TYPE,
  DASHBOARD_PAGES,
  FILTERS_DATE_FORMAT,
} from '@/constants/constants'
import { store } from '@/store'
import { State as FiltersState } from '@/store/modules/filters'
import { format, subDays } from 'date-fns'
import { Dictionary, equals, reject } from 'ramda'
import { isNotEmpty } from 'ramda-adjunct'

export const calculateQueryForDashboards = async (
  to: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  let pageKeys: {
    filtersAction: string
    filters: string
    page: null | string
  }
  if (to.name === 'projects') {
    pageKeys = {
      filtersAction: 'saveProjectFilters',
      filters: 'projectFilters',
      page: null,
    }
  } else if (to.name === 'portfolio') {
    pageKeys = {
      filtersAction: 'savePortfolioFilters',
      filters: 'portfolioFilters',
      page: DASHBOARD_PAGES.PORTFOLIO,
    }
  } else {
    pageKeys = {
      filtersAction: 'saveTeamFilters',
      filters: 'teamFilters',
      page: DASHBOARD_PAGES.TEAM,
    }
  }

  await store.dispatch(`filters/${pageKeys.filtersAction}`, to.query)

  await Promise.all([
    store.dispatch('admin/getActiveProjects', pageKeys.page),
    store.dispatch('admin/getUsers'),
  ]).then(async () => {
    const filters = store.state.filters[pageKeys.filters as keyof FiltersState]
    const filterItems = store.getters['filters/filterItems']

    const timeFilters = {
      since: !filters?.since
        ? format(subDays(new Date(), 30), FILTERS_DATE_FORMAT)
        : null,
      until: !filters?.until ? format(new Date(), FILTERS_DATE_FORMAT) : null,
    }
    const defaultPortfolioAndTeamParams = {
      projects:
        store.getters['admin/selectedProjectsIds'].length &&
        !filters?.projects?.length &&
        store.state.admin.original_projects.length !==
          store.getters['admin/selectedProjectsIds'].length
          ? store.getters['admin/selectedProjectsIds']
          : null,
      users:
        store.getters['admin/selectedUsersIds'].length &&
        !filters?.users?.length &&
        store.state.admin.original_users.length !==
          store.getters['admin/selectedUsersIds'].length
          ? store.getters['admin/selectedUsersIds']
          : null,
    }
    const defaultProjectsParams = {
      projects: !filters?.projects ? [filterItems.projects[0].id] : null,
      scale_type:
        !filters?.since && !filters?.until ? AGGREGATION_TYPE.DATE : null,
    }
    const defaultParams =
      to.name === 'projects'
        ? { ...defaultProjectsParams, ...timeFilters }
        : { ...defaultPortfolioAndTeamParams, ...timeFilters }
    const activeParams = reject(equals(null))(defaultParams as Dictionary<any>)
    if (isNotEmpty(activeParams)) {
      next({
        path: `/company/${store.getters['company/selectedCompanyId']}/${String(
          to.name
        )}`,
        query: {
          ...filters,
          ...activeParams,
        },
      })
    } else {
      next()
    }
  })
}

<template>
  <div class="flex justify-end pb-3">
    <IconButton
      class="p-button-outlined"
      size="small"
      icon="pi-plus"
      icon-position="left"
      icon-size="12px"
      @click="showAddBudgetDialog = true"
      >Add</IconButton
    >
  </div>
  <LoaderWrapper class="loader-height" v-if="loading"></LoaderWrapper>
  <MyTable
    v-else
    customHeight="30vh"
    :table-data="budgetArray"
    :columns-data="columnsData"
    :scrollable="scrollableTable"
  >
    <template #date="{ data }">
      {{ format(new Date(data.date), 'dd.MM.yyyy') }}
    </template>
    <template #editable="{ data }">
      <IconButton
        size="small"
        class="p-button-text invisible group-hover:visible"
        @click="selectedBudgetId = data.id"
        ><i class="pi pi-pencil" style="font-size: 12px"></i
      ></IconButton>
    </template>
  </MyTable>
  <AddBudgetDialog
    v-model:open="showAddBudgetDialog"
    :project_id="project_id"
    @refresh-table="getBudgetArray"
  ></AddBudgetDialog>
  <EditBudgetDialog
    v-model:id="selectedBudgetId"
    :project_id="project_id"
    @refresh-table="getBudgetArray"
  ></EditBudgetDialog>
</template>

<script setup lang="ts">
import IconButton from '@/components/common/buttons/IconButton.vue'
import { onMounted, ref, defineProps } from 'vue'
import LoaderWrapper from '@/components/common/loader/LoaderWrapper.vue'
import MyTable from '@/components/common/table/MyTable.vue'
import { showToastError } from '@/utils/utils'
import { useStore } from '@/store'
import { useToast } from 'primevue/usetoast'
import AddBudgetDialog from '@/components/admin-dashboard/tempo/projects/budget/AddBudgetDialog.vue'
import EditBudgetDialog from '@/components/admin-dashboard/tempo/projects/budget/EditBudgetDialog.vue'
import { format } from 'date-fns'

const props = defineProps<{ project_id: number; editFlow?: boolean }>()
const showAddBudgetDialog = ref(false)
const selectedBudgetId = ref(false)
const loading = ref(false)
const budgetArray = ref([])
const store = useStore()
const toast = useToast()
const scrollableTable = ref(true)

const columnsData = [
  {
    header: 'Budget, CHF ',
    field: 'budget',
    is_sortable: true,
    use_template: false,
  },
  {
    header: 'Date',
    field: 'date',
    is_sortable: true,
    use_template: true,
  },
  {
    header: '',
    field: 'editable',
    small: true,
    is_sortable: false,
    use_template: true,
    styles: 'flex-grow:0; flex-basis:30px',
  },
]

onMounted(() => {
  if (props.editFlow) {
    getBudgetArray()
  }
})

async function getBudgetArray() {
  loading.value = true
  try {
    budgetArray.value = (
      await store.dispatch('tempo/getBudgets', props.project_id)
    ).map((item: any) => ({
      ...item,
      budget: new Intl.NumberFormat('de-CH').format(item.budget),
    }))
    loading.value = false
  } catch (e) {
    showToastError(toast, e)
    loading.value = false
  }
}
</script>

<style>
.loader-height {
  height: 30vh;
}
</style>

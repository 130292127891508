<template>
  <div class="flex flex-col flex-grow justify-between">
    <div class="flex-grow">
      <div class="title">New project</div>
      <ProjectMappingForm
        v-model:name="formData.name"
        v-model:is_active="formData.is_active"
        :validation="v$"
      ></ProjectMappingForm>
    </div>
    <div class="flex justify-end py-8 gap-5">
      <Button
        size="large"
        class="mr-5 skip-button p-button-text"
        @click="onCreateProject"
      >
        Create and Skip next steps
      </Button>
      <IconButton
        icon="pi-arrow-right"
        icon-position="right"
        icon-size="20px"
        size="large"
        class="create-button"
        @click="onCreateProject('next')"
        >Next
      </IconButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import ProjectMappingForm from '@/components/admin-dashboard/system/project-mapping/ProjectMappingForm.vue'
import Button from '@/components/common/buttons/Button.vue'
import IconButton from '@/components/common/buttons/IconButton.vue'
import { useToast } from 'primevue/usetoast'
import { useStore } from '@/store'
import { defineEmits, ref } from 'vue'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { showToastError } from '@/utils/utils'

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'next-step'): void
  (e: 'project-id', value: number | undefined): void
}>()
const initialForm = () => ({ name: '', is_active: true })

const toast = useToast()
const store = useStore()
const formData = ref<{ name: string; is_active: boolean; id?: number }>(
  initialForm()
)

const formRules = {
  name: { required, $lazy: true },
}

const v$ = useVuelidate(formRules, formData)

async function onCreateProject(button?: string) {
  v$.value.$touch()
  if (v$.value.$invalid) {
    return
  }
  try {
    formData.value = await store.dispatch('admin/addProject', formData.value)
    emit('project-id', formData.value.id)
    button === 'next' ? emit('next-step') : emit('close')
  } catch (e) {
    showToastError(toast, e)
  }
}
</script>

<style scoped>
.title {
  @apply text-xl font-semi-bold mb-3;
}
</style>

import axios from '@/services/axios'
import { Filters } from '@/store/modules/filters'
import { ActionContext } from 'vuex'
import { State as GlobalState } from '../../index'
import { Aggregate_By_Type } from '@/types/types'

export interface State {
  aggregate_by: Aggregate_By_Type
  data: {
    velocity_factor: null | number
    project?: {
      id: number
      name: string
    }
    user?: {
      id: number
      name: string
    }
    name: string
  }[]
}

type Context = ActionContext<State, GlobalState>

const actions = {
  getData({ rootGetters }: Context, params: Filters): Promise<number> {
    return axios.get(
      `/api/companies/${rootGetters['company/selectedCompanyId']}/charts/velocity-factor/`,
      { params }
    )
  },
}

export default {
  namespaced: true,
  actions,
}

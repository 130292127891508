<template>
  <Dialog
    :visible="open"
    :dismissableMask="true"
    @update:visible="closeDialog"
    position="bottom"
    modal
  >
    <template #header>
      <div class="header-wrapper">Project mapping</div>
    </template>
    <div class="title">New project mapping</div>
    <ProjectMappingForm
      v-model:name="formData.name"
      v-model:is_active="formData.is_active"
      :validation="v$"
    ></ProjectMappingForm>
    <template #footer>
      <Button class="p-button-outlined close" size="large" @click="closeDialog">
        Cancel
      </Button>
      <Button class="create" size="large" @click="onCreateProject"
        >Create</Button
      >
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import Dialog from 'primevue/dialog/Dialog.vue'
import { defineProps, ref, defineEmits } from 'vue'
import Button from '@/components/common/buttons/Button.vue'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { useToast } from 'primevue/usetoast'
import { useStore } from '@/store'
import ProjectMappingForm from '@/components/admin-dashboard/system/project-mapping/ProjectMappingForm.vue'
import { showToastError } from '@/utils/utils'

const initialForm = () => ({ name: '', is_active: true })
defineProps<{ open: boolean }>()
const emit = defineEmits<{
  (e: 'update:open', value: boolean): void
  (e: 'save-project-id', value: number): void
}>()
const toast = useToast()
const store = useStore()
const formData = ref(initialForm())

const formRules = {
  name: { required, $lazy: true },
}

const v$ = useVuelidate(formRules, formData)

async function onCreateProject() {
  v$.value.$touch()
  if (v$.value.$invalid) {
    return
  }
  try {
    const project = await store.dispatch('admin/addProject', formData.value)
    emit('save-project-id', project.id)
    closeDialog()
  } catch (e) {
    showToastError(toast, e)
  }
}

function closeDialog() {
  formData.value = initialForm()
  v$.value.$reset()
  emit('update:open', false)
}
</script>

<style scoped>
.header-wrapper {
  @apply flex items-center justify-between w-full;
}
.title {
  @apply text-xl font-semi-bold mb-3;
}
</style>

<template>
  <div class="flex">
    <div
      class="rounded-circle w-5 h-5 mr-2 border-black-10"
      :class="background"
    ></div>
    <div class="capitalize">{{ value === 'yellow' ? 'amber' : value }}</div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, computed } from 'vue'

const props = defineProps<{ value: string }>()

const background = computed(() => {
  switch (props.value) {
    case 'green':
      return 'bg-success-400'
    case 'yellow':
      return 'bg-warning-400'
    default:
      return 'bg-danger-400'
  }
})
</script>

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 5.28846C4 4.57686 4.57686 4 5.28846 4H18.7115C19.4231 4 20 4.57686 20 5.28846V13C20 17.4183 16.4183 21 12 21C7.58172 21 4 17.4183 4 13V5.28846ZM6 12V13C6 16.3137 8.68629 19 12 19V13C12 12.4477 12.4477 12 13 12H18V6H12V11C12 11.5523 11.5523 12 11 12H6Z"
      fill="currentColor"
    />
  </svg>
</template>

<template>
  <div class="legend">
    <div class="mr-1.5">Planned hours according to</div>
    <img
      :src="require(`../../../assets/icons/tempo_title.svg`)"
      alt="Tempo"
      width="55"
      height="16"
      class="mr-3"
    />
    <div class="flex flex-nowrap items-center">
      <div class="legend-item border border-key-50">0</div>
      <div class="legend-item bg-key-50">0.2-0.5</div>
      <div class="legend-item bg-key-100">0.5-1</div>
      <div class="legend-item bg-key-200">1-2</div>
      <div class="legend-item bg-key-300">2+</div>
      <div class="ml-2">FTE</div>
    </div>
  </div>
</template>

<style lang="scss">
.legend {
  @apply flex flex-nowrap justify-start items-center h-5 mb-5 mt-2 text-sm;
}
.legend-item {
  @apply flex items-center justify-center w-12 h-5;
}
</style>

<template>
  <div class="wrapper" ref="chartContainer" id="chart-wrapper">
    <div class="relative" :style="{ height: chartHeight }">
      <canvas ref="myChartRef" @click="showDrilldown"></canvas>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Chart, ChartOptions, LegendItem } from 'chart.js'
import { clone } from 'ramda'
import {
  defineProps,
  defineEmits,
  ref,
  onMounted,
  watch,
  computed,
  withDefaults,
} from 'vue'
import { CHART_BAR_WIDTH } from '@/constants/constants'
import { chartTypes } from '@/constants/charts/constants'

const props = withDefaults(
  defineProps<{
    type: string
    options: ChartOptions
    data: any
    chartName: string
    barWidth?: number
    customHeight?: number
    plugins?: any
    sendAllDotes?: boolean
  }>(),
  { type: 'bar', barWidth: CHART_BAR_WIDTH }
)

const emit = defineEmits<{
  (e: 'dot-data', value: any): void
  (e: 'resize', value: { width: number; height: number }): void
  (e: 'labelX', value: any): void
  (e: 'labelY', value: any): void
}>()

let myChart: Chart
const myChartRef = ref<HTMLCanvasElement>()
const chartContainer = ref()
const minChartHeight = props.customHeight || 300
const chartHeight = computed(() => {
  const calculated = Math.max(
    (props.data?.labels?.length || 0) * props.barWidth,
    minChartHeight
  )
  const isNotBarChart = [
    chartTypes.Burndown,
    chartTypes.ScopeVsDelivered,
    chartTypes.ProjectAllocation,
    chartTypes.BudgetPlannedVsUsed,
    chartTypes.Activities,
  ].includes(props.chartName)
  return (
    (isNotBarChart ||
    !chartContainer.value?.offsetHeight ||
    calculated < chartContainer.value?.offsetHeight
      ? minChartHeight
      : calculated) + 'px'
  )
})

watch(
  props,
  (value) => {
    if (!value || !myChart) return
    emit('resize', {
      width: chartContainer.value?.offsetWidth || 0,
      height: chartContainer.value?.offsetHeight || 0,
    })
    const chartConfig = getChartConfig(value)
    myChart.data = chartConfig.data
    myChart.options = chartConfig.options

    // fixed issue when selection resets after changing ordering
    const hiddenDatasets =
      myChart.legend?.legendItems?.filter(({ hidden }: LegendItem) => hidden) ||
      []
    const allDatasets = clone(
      myChart.legend?.legendItems?.map((dataset: LegendItem) => ({
        ...dataset,
        annKey: dataset.text
          ?.replaceAll('.', '')
          .replaceAll(' ', '_')
          .toLowerCase(),
      }))
    )
    // pie chart doesn't have datasetIndex, it has index instead
    allDatasets?.forEach(({ datasetIndex, annKey, index }) => {
      const indexOfDataset = props.type === 'pie' ? index : datasetIndex
      myChart.setDatasetVisibility(
        indexOfDataset,
        !hiddenDatasets.find((dataset) => {
          return dataset.datasetIndex === indexOfDataset
        })
      )
      const legendItems: LegendItem[] | undefined = myChart.legend?.legendItems
      if (legendItems) {
        legendItems[indexOfDataset].hidden = !!hiddenDatasets.find(
          (dataset) => dataset.datasetIndex === indexOfDataset
        )
      }
      const annotations: any =
        myChart.config?.options?.plugins?.annotation?.annotations
      if (annotations && annotations[annKey]) {
        annotations[annKey].display = !hiddenDatasets.find(
          (dataset) => dataset.datasetIndex === indexOfDataset
        )
      }
    })
    myChart.update()
  },
  { immediate: true, deep: true }
)

const getChartConfig = (value: any) => {
  return clone({
    type: value.type,
    data: value.data,
    options: value.options,
    plugins: [
      {
        beforeInit(chart: any) {
          const originalFit = chart.legend.fit
          chart.legend.fit = function fit() {
            originalFit.bind(chart.legend)()
            if (chart.config.type === 'line') {
              this.height += 10
            }
          }
        },
      },
      ...(props.plugins || []),
    ],
  })
}
const chartConfig = getChartConfig(props)

onMounted(async () => {
  myChart = new Chart(myChartRef.value as HTMLCanvasElement, chartConfig as any)
  emit('resize', {
    width: chartContainer.value?.offsetWidth || 0,
    height: chartContainer.value?.offsetHeight || 0,
  })
})

const showDrilldown = (e: Event) => {
  if (props.type === 'pie') return
  const points = myChart.getElementsAtEventForMode(
    e,
    'nearest',
    { intersect: true },
    true
  )

  const value = myChart.scales.x.getValueForPixel(e.offsetX)
  const labelX = myChart.scales.x.ticks[value]

  const valueY = myChart.scales.y.getValueForPixel(e.offsetY)
  const labelY = myChart.scales.y.ticks[valueY]

  if (points.length) {
    // event is required to display context menu if there is more than one point
    emit('dot-data', props.sendAllDotes ? { points, event: e } : points[0])
  }

  if (labelY && !labelX) {
    emit('labelY', labelY)
  }

  if (labelX && !labelY) {
    emit('labelX', labelX)
  }
}
</script>
<style lang="scss" scoped>
.wrapper {
  flex: 1 1;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 10; // fix wrong position of scroll in safari
}
</style>

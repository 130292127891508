<template>
  <Dialog
    :visible="open"
    :dismissableMask="true"
    @update:visible="$emit('update:open', false)"
    position="bottom"
    modal
    style="min-height: 400px"
  >
    <template #header>
      <div class="header-wrapper">Gitlab</div>
    </template>
    <div class="title">Tokens</div>
    <div class="content-wrapper overflow-y-auto">
      <MyTable :table-data="tokens" :columns-data="columnsData">
        <template #status="{ data }">
          <StatusIcon
            :tooltip="TOKEN_STATUSES_TITLES[data.status]"
            :status="calcCategoryOfStatus(data.status)"
          ></StatusIcon>
        </template>
        <template #editable="{ data }">
          <IconButton
            size="small"
            class="p-button-text invisible group-hover:visible"
            @click="selectedTokenId = data.id"
            ><i
              class="pi pi-pencil"
              style="font-size: 12px"
              aria-hidden="true"
            ></i
          ></IconButton>
        </template>
      </MyTable>
    </div>
    <div class="add-row" @click="showCreateDialog = true">
      <i aria-hidden="true" class="pi pi-plus mr-2"></i>Add token
    </div>
    <Divider class="mb-8"></Divider>
    <AddTokenDialog v-model:open="showCreateDialog"></AddTokenDialog>
    <EditTokenDialog v-model:id="selectedTokenId"></EditTokenDialog>
  </Dialog>
</template>

<script setup lang="ts">
import Dialog from 'primevue/dialog/Dialog.vue'
import IconButton from '@/components/common/buttons/IconButton.vue'
import { computed, ref, defineProps } from 'vue'
import MyTable from '@/components/common/table/MyTable.vue'
import { useStore } from '@/store'
import AddTokenDialog from '@/components/admin-dashboard/gitlab/tokens/AddTokenDialog.vue'
import EditTokenDialog from '@/components/admin-dashboard/gitlab/tokens/EditTokenDialog.vue'
import { TOKEN_STATUSES, TOKEN_STATUSES_TITLES } from '@/constants/constants'
import StatusIcon from '@/components/common/table/StatusIcon.vue'

defineProps<{ open: boolean }>()
const store = useStore()
const showCreateDialog = ref(false)
const selectedTokenId = ref(false)
const tokens = computed(() => store.state.gitlab.tokens)

const columnsData = [
  {
    header: 'Token Name',
    field: 'name',
    is_sortable: true,
    use_template: false,
    styles: 'flex-grow:1; padding-right: 10px',
  },
  {
    header: 'Status',
    field: 'status',
    is_sortable: true,
    use_template: true,
    styles: 'flex-basis: 70px; padding-right: 10px',
  },
  {
    header: 'Base URL',
    field: 'base_url',
    is_sortable: true,
    use_template: false,
    styles: 'flex-grow:1; padding-right: 10px',
  },

  {
    header: '',
    field: 'editable',
    small: true,
    is_sortable: false,
    use_template: true,
    styles: 'flex-grow:0; width: 30px',
  },
]

const calcCategoryOfStatus = (status: string) => {
  if (status === TOKEN_STATUSES.TOKEN_VALID) {
    return 'success'
  } else if (status === TOKEN_STATUSES.TOKEN_INVALID) {
    return 'danger'
  } else {
    return 'warning'
  }
}
</script>

<style scoped>
.header-wrapper {
  @apply flex items-center justify-between w-full capitalize;
}
.title {
  @apply text-xl font-semi-bold mb-3;
}

.add-row {
  @apply py-2 font-semi-bold flex items-center cursor-pointer hover:bg-gray-50;
}

.content-wrapper {
  max-height: 70vh;
}
</style>
